@mixin background-color-with-opacity($color, $opacity) {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $color;
    opacity: $opacity;
}

@mixin drop-shadow {
    box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 15%);
}

@mixin disable-accessibility-border {
    outline: 0;
}

dxbl-events-interceptor {
    display: none;
}

