@mixin dx-line-box-size-sm(
    $padding-x-sm: $dx-line-box-padding-x-sm,
    $padding-y-sm: $dx-line-box-padding-y-sm
) {
    @include dx-font-size-sm();
    padding: $padding-y-sm $padding-x-sm;
}

@mixin dx-line-box-size-lg(
    $padding-x-lg: $dx-line-box-padding-x-lg,
    $padding-y-lg: $dx-line-box-padding-y-lg
) {
    @include dx-font-size-lg();
    padding: $padding-y-lg $padding-x-lg;
}

@mixin dx-line-box-size(
    $padding-x: $dx-line-box-padding-x,
    $padding-y: $dx-line-box-padding-y,
) {
    @include dx-font-size();
    padding: $padding-y $padding-x;
}

@mixin dx-font-size-sm() {
    @include dx-font-size($dx-font-size-sm, $dx-line-height-sm);
}

@mixin dx-font-size-lg() {
    @include dx-font-size($dx-font-size-lg, $dx-line-height-lg);
}

@mixin dx-font-size($font-size: $dx-font-size, $line-height: $dx-line-height) {
    font-size: $font-size;
    line-height: $line-height;
}
