.dxbs-context-menu > .modal-body, .dxbs-context-menu-submenu > .modal-body {
    & > ul {
        margin: 0;
        width: 100%;
    }

    .dropdown-divider {
        margin: 0;

        &:first-child {
            display: none;
        }
    }
}

.dxbs-context-menu-item.dropdown-item {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding-left: 8px;

    .has-icons > li > & {
        .dxbs-context-menu-item-text {
            display: inline-block;
            padding-left: 24px;
        }

        &.has-icon .dxbs-context-menu-item-text {
            padding-left: 8px;
        }
    }

    .dx-popout-image {
        position: absolute;
        height: 1rem;
        width: 1rem;
        top: calc(50% - 0.5rem);
        right: 0.25rem;
    }

    .dxbs-context-menu-item-icon {
        padding: 0;
        display: inline-flex;
    }

    img.dxbs-context-menu-item-icon {
        height: 16px;
        width: 16px;
    }
}

.modal-body {
    .dxbs-context-menu-submenu > &,
    .dxbs-context-menu > &,
    .dxbl-dropdown-dialog > & {
        padding: 0;
    }

    .dxbs-context-menu > &,
    .dxbs-context-menu-submenu > & {
        margin-bottom: 0;
    }
}
