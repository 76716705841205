@use "sass:color";

$dx-success: $success !default;
$dx-danger: $danger !default;

$dx-link-color: $link-color !default;
$dx-link-hover-color: $link-hover-color !default;

$dx-body-color: $body-color !default;

// Buttons
$dx-button-border-radius-sm: $btn-border-radius-sm !default;
$dx-button-border-radius: $btn-border-radius !default;
$dx-button-border-radius-lg: $btn-border-radius-lg !default;

// Editors
$dx-input-editor-border-width: $input-border-width !default;
$dx-input-editor-border-color: $input-border-color !default;
$dx-input-editor-border-radius-sm: $input-border-radius-sm !default;
$dx-input-editor-border-radius: $input-border-radius !default;
$dx-input-editor-border-radius-lg: $input-border-radius-lg !default;
$dx-input-editor-bg: $input-bg !default;
$dx-input-editor-disabled-bg: $input-disabled-bg !default;
$dx-input-editor-color: $input-color !default;
$dx-input-editor-focus-color: $input-focus-border-color !default;
$dx-input-editor-focus-shadow: $input-focus-box-shadow !default;

//Highlighting
$dx-mark-bg: rgba(color.red($primary), color.green($primary), color.blue($primary), .75) !default;
$dx-mark-color: if(function-exists("color-contrast"), color-contrast($dx-mark-bg), color-yiq($dx-mark-bg)) !default;
