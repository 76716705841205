@use "mixins" as editor-mixins;

@import "../popup/variables";

.dxbs-editor-disabled {
    opacity: 0.5;
}

.invalid {
    .dxbs-input-group input.form-control,
    .dxbs-input-group textarea.form-control,
    &.dxbs-tagbox .form-control { // stylelint-disable-line selector-class-pattern
        @include editor-mixins.editor-validation-error-border-color();
    }
}

.valid.modified {
    .dxbs-input-group input.form-control,
    .dxbs-input-group textarea.form-control,
    &.dxbs-tagbox .form-control { // stylelint-disable-line selector-class-pattern
        @include editor-mixins.editor-validation-valid-border-color();
    }
}

.dxbs-textbox,
.dxbs-combobox,
.dxbs-spin-edit,
.dxbs-memo,
.dxbs-date-time-edit,
.dxbs-tagbox, // stylelint-disable-line selector-class-pattern
.dxbs-listbox,
.dxbs-calendar,
.dxbs-checkbox {
    &.valid.modified,
    &.invalid {
        outline: none;
    }
}

.dxbs-input-group.input-group {
    > .dxbs-editor-input-container:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) { // stylelint-disable-line  max-line-length
        margin-left: 0;
    }

    input {
        &.form-control {
            padding-left: 0.5rem;
        }

        &.form-control-sm {
            padding-left: 0.375rem;
        }

        &.form-control-lg {
            padding-left: 0.75rem;
        }
    }
}

.dxbs-editor-dropdown {
    .dxbs-editor-dropdown-header {
        padding: 0;
        display: flex;
        border-top: 0;
        border-left: 0;
        border-right: 0;
    }

    .dxbs-editor-dropdown-body {
        padding: 0;

        > * {
            width: 100%;
            border: none;
        }
    }
}

#{$dropdown-dialog}.dxbs-editor-dropdown {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
}

div.dxbs-btn-group {
    &.dxbs-input-group-append > .btn.dx-btn:not(.dxbs-editor-clear-btn),
    &.dxbs-input-group-prepend > .btn.dx-btn {
        @include editor-mixins.editor-button-sizes();

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &.dxbs-input-group-prepend > .btn.dx-btn {
        border-left: 0;
    }


    &.dxbs-input-group-append > .btn.dx-btn {
        border-right: 0;
        margin: 0;
    }
}

div.dxbs-btn-group.dxbs-input-group-append > button.btn.btn-secondary.dxbs-edit-btn {
    border-radius: 0;
}

.input-group > .form-control {
    &.dxbs-input-group-prepend,
    &.dxbs-input-group-append {
        &.dxbs-btn-group {
            padding: 0;
            position: absolute;
            display: flex;
            z-index: 0;
            height: 100%;
            width: auto;
            border-color: transparent;
            background: none;
            box-shadow: none;
            overflow: hidden;
        }

        .btn.dx-btn {
            border-radius: 0;
        }
    }
}

.dxbs-input-group-prepend {
    &.form-control {
        left: 0;
        border-right: none;
        border-left: none;
    }

    margin-left: 1px;
}

.form-control.dxbs-input-group-append {
    right: 0;
    border-left: none;
}
