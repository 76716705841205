@import "popup/_variables";

.dx-btn.btn-link:not(:hover) {
        text-decoration: none;
}

#{$dropdown-body} table > :not(:first-child) {
    border-top-width: 0;
    border-top-color: inherit;
}
