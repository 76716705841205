.dxbs-grid-sort-asc,
.dxbs-grid-sort-desc {
    margin-left: auto;
    display: flex;
    overflow: visible !important; // stylelint-disable-line declaration-no-important
}

.dxbs-grid-sort-asc > .dx-image,
.dxbs-grid-sort-desc > .dx-image,
.dxbs-grid-dragging-up-target > .dx-image,
.dxbs-grid-dragging-down-target > .dx-image {
    width: 14px;
    height: 14px;
}

.dxbs-grid-sort-desc > .dx-image,
.dxbs-grid-dragging-down-target > .dx-image {
    transform: rotate(180deg);
}

.dxbs-grid-dragging-up-target,
.dxbs-grid-dragging-down-target {
    display: flex;
    position: absolute;
    z-index: 19999;
    transition: 0.1s;
    top: 0;
    left: 0;
    pointer-events: none;
}
