.dxbs-grid {
    .dxbs-grid-header-row .dxbs-grid-header-content,
    .dxbs-grid-group-panel-content-container .dxbs-grid-header-content {
        & > a:not(:hover) {
            text-decoration: none;
        }
    }

    .dxbs-scroll-viewer {
        &:not(:last-child) > .dxbs-scroll-viewer-content > .dxbs-grid-table {
            & > tfoot:last-child > tr:last-child > td {
                border-bottom: inherit;
            }

            & > tbody:last-child > tr:last-child > td {
                border-bottom: inherit;
            }
        }
    }

    .dxbs-grid-table {
        & > thead,
        & > tfoot,
        & > tbody {
            & > tr {
                & > th,
                & > td:not(.dxbs-grid-empty-cell) {
                    border-top: inherit;
                    border-top-width: inherit;
                }
            }

            & > tr:not(:last-child) > .dxbs-grid-table-border-bottom {
                border-bottom-width: inherit;
            }
        }

        & > thead > tr > th.dxbs-grid-indent-cell {
            border-top-width: 1px;
            border-top-color: transparent;
        }

        & > thead > .dxbs-grid-filter-row:not(:first-child) > td {
            border-top: 0;
            border-top-width: 0;
        }

        & > thead > tr:last-child > * {
            border-bottom-color: inherit;
        }

        & > tbody {
            & > tr:first-child {
                & > th,
                & > td:not(.dxbs-grid-empty-cell) {
                    border-top: 0;
                    border-top-width: 0;
                }
            }
        }
    }
}
