@import "../../components/mixins/_buttons";

@mixin dx-button-light-outline-custom() {
    $color: if(function-exists("color-contrast"), color-contrast($light), color-yiq($light));
    @include button-outline-variant(
            $color,
        $color-hover: $color,
        $active-background: $light,
        $active-border: $light
    );
    @include dx-button-shadow($color, $light, $btn-focus-width);

    border-color: $light;
}

@mixin dx-button-text-light-custom() {
    $color: if(function-exists("color-contrast"), color-contrast($light), color-yiq($light));
    @include button-outline-variant(
        $color,
        $color-hover: $color,
        $active-background: $light,
        $active-border: $light
    );
    @include dx-button-shadow($color, $light, $btn-focus-width);
}

@mixin dx-primary-button-custom() {
    $shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix($primary, $primary, 15%), .5);
    .btn-primary {
        @include dx-button-focused-shadow($shadow);
    }
}

@mixin dx-button-shadow($color, $button-main-color, $btn-focus-width) {
    $shadow: 0 0 0 $btn-focus-width rgba(mix($color, $button-main-color, 15%), .5);
    @include dx-button-focused-shadow($shadow);
}
