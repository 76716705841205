.dxbs-tagbox {
    display: flex;

    > .form-control {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0.2rem;
        height: inherit;
        width: 100%;
        overflow: hidden;
        background-color: inherit;
        color: inherit;
        display: flex;
        box-shadow: none;
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 0;

        .dxbs-input-group-append {
            top: 0;
        }

        .dxbs-tagbox-wrapper {
            display: flex;
            flex-grow: 1;
            flex-wrap: wrap;
            align-items: stretch;
            overflow: hidden;

            .dxbs-tag {
                padding-top: 0.1rem;
                padding-bottom: 0.1rem;
                padding-right: 0.1rem;
                max-width: 100%;
                position: relative;
                display: flex;
                color: inherit;
                align-items: center;
                margin-right: 0.5rem;
                margin-top: 0.15rem;
                overflow: hidden;

                .dxbs-tag-text {
                    white-space: pre;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                > .dxbs-btn-group {
                    white-space: normal;
                    position: relative;
                    margin-left: 0.5em;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 1.5em;
                    height: 1.5em;
                    flex: 0 0 auto;

                    .btn.dx-btn.dxbs-cmd-btn {
                        position: unset;
                        padding: 0;
                        width: auto;
                        height: auto;

                        /* for some Bootstrap themes: */
                        &:focus,
                        &:hover {
                            &:active {
                                box-shadow: none;
                            }

                            z-index: 1;

                            &::before {
                                opacity: 0.2;
                            }
                        }
                    }
                }

                &:last-of-type {
                    max-width: calc(100% - 3.5em);
                }
            }

            .dxbs-tagbox-input:first-child,
            .dxbs-tag .dxbs-tag-text {
                padding-left: 1.1em;
            }

            .dxbs-tagbox-input {
                width: unset;
                height: unset;
                min-height: unset;
                font-size: inherit;
                background: none;
                font-weight: inherit;
                border: none;
                box-shadow: none;
                padding: 0;
                outline: none;
            }
        }
    }

    &.dxbs-edit-clbv .input-group {
        padding-right: 30px;
    }
}

.dxbs-tagbox:not(.dxbs-tagbox-custom) > .form-control .dxbs-tagbox-wrapper .dxbs-tag::before {
    content: " ";
    position: absolute;
    background-color: currentcolor;
    opacity: 0.15;
    border-radius: 1em;
    height: 100%;
    width: 100%;
}
