.dx-image {
    &.dx-image-rotate-90 {
        transform: rotate(90deg);
    }

    &.dx-image-rotate-180 {
        transform: rotate(180deg);
    }

    &.dx-image-rotate-270 {
        transform: rotate(270deg);
    }

    &.dx-image-size-07em {
        width: 0.7em;
        height: 0.7em;
    }

    &.dx-image-size-09em {
        width: 0.9em;
        height: 0.9em;
    }

    &.dx-image-size-1em {
        width: 1em;
        height: 1em;
    }

    &.dx-image-size-1rem {
        width: 1rem;
        height: 1rem;
    }

    &.dx-image-size-10px {
        width: 10px;
        height: 10px;
    }

    &.dx-image-size-12px {
        width: 12px;
        height: 12px;
    }

    &.dx-image-size-14px {
        width: 14px;
        height: 14px;
    }

    &.dx-image-size-16px {
        width: 16px;
        height: 16px;
    }

    &.dx-image-size-18px {
        width: 18px;
        height: 18px;
    }

    &.dx-image-size-24px {
        width: 24px;
        height: 24px;
    }

    &.dx-image-size-32px {
        width: 32px;
        height: 32px;
    }

    &.dx-image-size-48px {
        width: 48px;
        height: 48px;
    }

    &:not(.dx-image-fill) {
        fill: currentColor;
    }
}
