$default-max-dropdown-height: 200px;

.dxbs-combobox {
    display: flex;
}

.dxbs-combobox-dropdown {
    &-hidden {
        visibility: hidden;
    }

    &-shown {
        visibility: visible;
    }

    &.dxbs-editor-dropdown {
        max-height: $default-max-dropdown-height;

        .dxbs-listbox {
            max-height: $default-max-dropdown-height;
            background-color: inherit;

            .dxbs-gridview {
                display: flex;
                min-height: 0;

                > .card {
                    display: flex;

                    > .dxgvHSDC { // stylelint-disable-line selector-class-pattern
                        flex-shrink: 0;
                    }
                }
            }
        }
    }
}
