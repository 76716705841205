.dxbl-button-root {
    .dxbl-button {
        @each $color, $value in $theme-colors {
            &.dxbl-button-#{$color} {
                @if($color == 'secondary' and mixin-exists("dx-button-secondary-custom")) {
                    @include dx-button-secondary-custom();
                } @else if($color == 'light' and mixin-exists("dx-button-light-custom")) {
                    @include dx-button-light-custom();
                } @else if($color == 'dark' and mixin-exists("dx-button-dark-custom")) {
                    @include dx-button-dark-custom();
                } @else {
                    @include button-variant($value, $value);
                }
            }
        }

        @each $color, $value in $theme-colors {
            &.dxbl-button-outline-#{$color} {
                @if($color == 'secondary' and mixin-exists("dx-button-secondary-outline-custom")) {
                    @include dx-button-secondary-outline-custom();
                } @else if($color == 'light' and mixin-exists("dx-button-light-outline-custom")) {
                    @include dx-button-light-outline-custom();
                } @else if($color == 'dark' and mixin-exists("dx-button-dark-outline-custom")) {
                    @include dx-button-dark-outline-custom();
                } @else {
                    @include button-outline-variant($value);
                }
            }
        }

        @each $color, $value in $theme-colors {
            &.dxbl-button-text-#{$color} {
                @if($color == 'secondary' and mixin-exists("dx-button-text-secondary-custom")) {
                    @include dx-button-text-secondary-custom();
                } @else if($color == 'light' and mixin-exists("dx-button-text-light-custom")) {
                    @include dx-button-text-light-custom();
                } @else if($color == 'dark' and mixin-exists("dx-button-text-dark-custom")) {
                    @include dx-button-text-dark-custom();
                } @else {
                    @include button-outline-variant($value);
                }

                border-color: transparent;
            }
        }
    }
}
