.dxbs-preloader-initial {
    visibility: hidden;
}

.dxbs-preloader-container {
    position: absolute !important;
    z-index: 1;
}

.dxbs-preloader {
    height: 16px;
    width: 16px;
    border: 3px transparent solid;
    border-top: 3px currentColor solid;
    border-radius: 50%;
    -webkit-animation: preloader-spin 1s infinite linear;
    animation: preloader-spin 1s infinite linear;
    opacity: 0.5;
}

.dxbs-preloader.dxbs-preloader-lg {
    height: 24px;
    width: 24px;
    border: 4px transparent solid;
    border-top: 4px currentColor solid;
}

.dxbs-preloader:before {
    position: absolute;
    content: "";
    top: -3px;
    left: -3px;
    height: 16px;
    width: 16px;
    opacity: 0.3;
    border: 3px currentColor solid;
    border-top: 3px transparent solid;
    border-radius: 50%;
}

.dxbs-preloader.dxbs-preloader-lg:before {
    top: -4px;
    left: -4px;
    height: 24px;
    width: 24px;
    border: 4px currentColor solid;
    border-top: 4px transparent solid;
}

@-webkit-keyframes preloader-spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes preloader-spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes preloader-fadeOut {
    to {
        opacity: 0;
    }
}

.dxbs-preloader-success {
    animation: preloader-fadeOut 0.4s cubic-bezier(0.55, 0.09, 0.68, 0.53) forwards;
}
