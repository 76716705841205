.dxbs-combobox {
    .dxbs-listbox { // stylelint-disable-line selector-class-pattern
        > .dxbs-gridview { // stylelint-disable-line selector-class-pattern
            .dropdown-item {
                border-color: inherit;
                border-width: 1px;
                border-style: solid;
            }
        }
    }
}
