@mixin dx-button-icon-size($icon-max-size, $button-icon-gap) {
    column-gap: $button-icon-gap;

    > .dx-image {
        max-height: $icon-max-size;
        max-width: $icon-max-size;
    }
}

@mixin dx-button-focused-shadow($shadow) {
    &:focus,
    &.focus {
        box-shadow: $shadow;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        &:focus {
            box-shadow: $shadow;
        }
    }
}
