button.dx-btn-text {
    border-color: transparent;
}

.dx-btn:hover:not(.disabled) {
    cursor: pointer;
}

.dx-btn-flex-cont {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dx-btn,
.btn.dx-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
