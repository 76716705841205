@import "state-decorations";
@import 'navigation/common';

$dx-accordion-header-padding: 0.75rem !default;
$dx-accordion-group-body-padding: 0rem !default;
$dx-accordion-left-indent: 1rem !default;

@mixin _accordion_backdrop_hover {
    @include _backdrop_base();
    opacity: 0.1;
}

@mixin _accordion_backdrop_pressed {
    @include _backdrop_base();
    opacity: 0.2;
}

@mixin _accordion_backdrop_selected {
    @include _backdrop_base();
    opacity: 0.05;
}

@mixin _expand_btn {
    min-height: 1rem;
    min-width: 1rem;
    padding: 0;
    display: inline-flex;
    box-sizing: content-box;
    font-size: inherit;
    
    &,
    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
        font-weight: inherit;
        color: inherit;
    }
}

@mixin _accordion_button_backdrop {
    top: -.25rem;
    left: -.25rem;
    height: calc(100% + .5rem);
    width: calc(100% + .5rem);
    opacity: 0.1;
}

.dxbl-accordion {
    & > nav {
        & > .dxbl-accordion-group {
            display: flex;
            flex-direction: column;

            &:not(:first-child):not(:last-child) {
                border-radius: 0;
            }

            &:not(:first-child) {
                border-top: 0;
            }

            &:first-child:not(:last-child) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            &:last-child:not(:first-child) {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            & > .dxbl-accordion-group-header {
                padding: $dx-accordion-header-padding;
                border-radius: inherit;
                align-items: center;

                & .expand-btn.btn {
                    @include _expand_btn;
                    
                    &:before {
                        @include _accordion_button_backdrop;
                    }
                }
            }
            
            &:not(.dxbl-accordion-filter) > .dxbl-accordion-group-header {
                line-height: 1rem;
            }

            & .dxbl-accordion-item,
            & .dxbl-accordion-group-header .dxbl-group-header-text {
                & > .dxbl-accordion-item-content {
                    display: flex;
                    text-decoration: none;
                    position: relative;
                    transform: scale(1);

                    &:not(.disabled):not(.active) {
                        cursor: pointer;
                        color: inherit;
                    }
                }
            }
            & .dxbl-accordion-group-header .dxbl-group-header-text > .dxbl-accordion-item-content {
                min-height: 1rem;
            }

            & > .dxbl-accordion-group-body {
                &.card-body {
                    padding: $dx-accordion-group-body-padding;
                }

                & .dxbl-accordion-item {
                    --dx-accordion-expand-width: 1rem;

                    & .dxbl-accordion-item-indent {
                        --dx-accordion-indent-calc: var(--dx-accordion-indent, calc(var(--dx-accordion-expand-width) + 0.5rem));
                        padding: 1.875rem calc(var(--dx-accordion-indent-calc) * var(--dx-accordion-group-level, 0) ) 0 0;
                    }

                    & .dxbl-accordion-expand-button-indent {
                        padding: 1.875rem 1.5rem 0 0;
                    }

                    & .dxbl-accordion-item-indent-end {
                        --dx-accordion-indent-calc: var(--dx-accordion-indent, 0.75rem);
                        padding: 1.875rem calc(var(--dx-accordion-indent-calc) * var(--dx-accordion-group-level, 0)) 0 0;
                    }

                    & .dxbl-accordion-item-text-container {
                        font-size: 0.875rem;
                        padding-top:0;
                        padding-bottom:0;
                    }
                }
            }

            & .dxbl-accordion-item {
                & > .dxbl-accordion-item-content {
                    cursor: pointer;
                    padding: 0 0.75rem;
                    align-items: center;

                    &:not(.disabled) {
                        &:hover {
                            &:before {
                                @include _accordion_backdrop_hover;
                            }
                        }

                        &:active {
                            &:before {
                                @include _accordion_backdrop_pressed;
                            }
                        }
                    }

                    & > .expand-btn.btn {
                        @include _expand_btn;
                        margin: 0 0.5rem 0 0;
                        border: 0;
                    }
                }

                &:not(.dxbl-accordion-item-start) > .dxbl-accordion-item-content {
                    
                    & > .expand-btn.btn {
                        margin: 0 0 0 0.5rem;
                    }
                }
            }

            & .dxbl-accordion-item-text-container {
                display: flex;
                flex: 1 1 auto;
                align-items: center;

                &.form-control-sm {
                    padding-left: 0;
                    padding-right: 0;
                    border-radius: unset;
                }

                & > .dxbl-accordion-item-text {
                    flex: 1 1 auto;

                    & + .badge {
                        margin-left: 0.5rem;
                    }
                }

                & > .dx-image:not(:last-child) {
                    margin-right: 0.5rem;
                }
            }
            
            & .dxbl-accordion-group-header,
            & .dxbl-accordion-item-content {
                & > .expand-btn > .dx-image {
                    opacity: 0.54;
                }
            }
        }

        & .dxbl-accordion-filter > .dxbl-accordion-group-header {
            padding: 0.4375rem 0.75rem;
            
            & .dxbl-navigation-filter {
                border: none;
                background: none;
                width: 100%;

                &.dxbl-textbox[server-focused] {
                    box-shadow: none;
                }
                
                &.dxbl-textbox[has-clear-button] .dxbl-input-clear-button {
                    padding: 0;
                    
                    &> .dx-image {
                        fill-opacity: 0.54;
                    }
                    
                    &::before {
                        @include _accordion_button_backdrop;
                    }
                }
            }

            & .dxbs-filter-icon {
                align-self: center;
                flex: 1 0 auto;
                @include navigation-filter-icon();
            }
        }
    }

    @include navigation-filter-mark();
}
