/* Common */

.dxbs-position-relative {
    position: relative;
}

/* Accessibility */
.dxAIFE,
.dxAIFME {
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: polygon(0 0);
    /* clip-path: polygon(0 0); */
}

.dxAIFE {
    height: 1px;
    overflow: hidden;
    position: absolute !important;
}

.dxAIFME {
    position: fixed;
    left: 0;
    top: 0;
}

/* Edit */
.input-group .dxbs-textbox,
.input-group .dxbs-memo,
.input-group .dxbs-spin-edit, /*hidden*/
.input-group .dxbs-dropdown-edit { /*hidden*/
    display: table;
    width: 100%;
}

.input-group .dxbs-textbox > div,
.input-group .dxbs-memo > div,
.input-group .dxbs-spin-edit > div:not(.dxbs-out-of-range-warn):not(.input-group), /*hidden*/
.input-group .dxbs-dropdown-edit > div:not(.input-group-append):not(.input-group-prepend):not(.dxbs-dropdown-area):not(.dxbs-out-of-range-warn):not(.dxbs-ld):not(.dxbs-lp):not(.input-group) { /*hidden*/
    display: table-row;
}

.dxbs-textbox > div,
.dxbs-memo > div,
.dxbs-spin-edit,
.dxbs-spin-edit > div:not(.dxbs-out-of-range-warn),
.dxbs-spin-edit > .input-group > div:not(.dxbs-input-group-append) {
    position: relative;
}

.dxbs-textbox .form-control,
.dxbs-memo .form-control,
.dxbs-spin-edit .form-control,
.dxbs-spin-edit .input-group .form-control,
.dxbs-dropdown-edit .input-group .form-control,
.dxbs-textbox .col-form-label, /*hidden*/
.dxbs-memo .col-form-label, /*hidden*/
.dxbs-spin-edit .col-form-label, /*hidden*/
.dxbs-dropdown-edit .col-form-label { /*hidden*/
    vertical-align: middle;
}

.dxbs-memo.dxbs-edit-clbv > .dxbs-input-group.input-group > .form-control.dxbs-input-group-append,
.dxbs-textbox.dxbs-edit-clbv > .dxbs-input-group.input-group > .form-control.dxbs-input-group-append {
    top: 0;
}

.dxbs-edit-btn.disabled,
.dxbs-dropdown-edit .input-group-append .btn.disabled,
.dxbs-spin-edit .input-group-append .btn.disabled {
    pointer-events: none;
}

.dxbs-edit-btn.inc:hover,
.dxbs-edit-btn.dec:hover {
    opacity: .8;
}

.dxbs-edit-btn:hover {
    cursor: pointer;
}

.dxbs-dropdown-edit.dxbs-combobox .dxbs-listbox.dropdown-menu, /*hidden*/
.dxbs-dropdown-edit.dxbs-tagbox .dxbs-listbox.dropdown-menu {
    padding-top: 0px;
    padding-bottom: 0px;
    overflow-y: auto;
}

.dxbs-listbox > ul > li > div mark,
.dxbs-listbox > ul > li > .form-check mark { /*hidden*/
    padding: 0;
}

.dxbs-listbox > ul > li {
    display: block;
}

.dxbs-listbox > ul > li > div {
    cursor: pointer;
}

.dxbs-listbox.form-control li:not(.list-group-item-primary):not(.active):not(.disabled),
.dxbs-listbox.form-control .list-group-item:not(.active):not(.disabled), /*hidden*/
.dxbs-listbox.form-control .list-group-item > label { /*hidden*/
    color: inherit;
    background: none;
    text-shadow: none;
}

.dxbs-listbox.form-control > ul > li:not(.active):not(.disabled) > div:not(.active):not(.disabled):not(:hover):not(:focus):not(:active) {
    text-shadow: none;
}

.dxbs-listbox.card, /*hidden*/
.dxbs-listbox.list-group,
.dxbs-listbox > .list-group {
    margin-bottom: 0;
}

.dxbs-listbox.form-control .list-group-item:not(.active):not(.disabled):not(:active):not(:hover):not(:focus), /*hidden*/
.dxbs-listbox ul.form-control .list-group-item:not(.active):not(.disabled):not(:active):not(:hover):not(:focus), /*hidden*/
.dxbs-listbox.form-control > ul.list-group {
    background: none;
}

.dxbs-listbox.form-control > ul.list-group {
    border: none;
}

.dxbs-listbox.form-control > ul > li > div,
.dxbs-listbox.form-control > ul > li > div:hover,
.dxbs-listbox.form-control > ul > li > div:focus,
.dxbs-listbox.form-control > ul > li > div:active {
    border-radius: 0;
    border: none;
}

.dxbs-spin-edit > div.input-group,
.dxbs-combobox > div.input-group {
    flex-wrap: nowrap;
}

/* GridView */
.dxbs-gridview {
    display: table;
    width: 100%;
}

.dxbs-gridview > .card .dxbs-table {
    margin-bottom: 0;
}

.dxbs-gridview > .card > .card-body > .card-body {
    padding: 0;
}

.dxbs-gridview > .card .dxbs-table > tbody > tr > td,
.dxbs-gridview > .card .dxbs-table > thead > tr > th {
    vertical-align: middle;
    height: 100%;
}

.dxbs-gridview > .card .dxbs-table > tbody > tr > td.bg-primary .btn-link {
    color: white;
}

.dxbs-gridview > .card .dxbs-table > tbody > tr > td.dxbs-indent-cell {
    border-top-width: 0px;
    border-bottom-width: 0px;
    text-align: center;
}

.dxbs-gridview > .card .dxbs-table > tbody > .dxbs-empty-data-row > td {
    padding: 1em;
    text-align: center;
}

.dxbs-empty-data-row {
    opacity: 0.5;
}

.dxbs-listbox .dxbs-empty-data-row {
    padding: 1em;
    text-align: center;
}

/* non-data-header */
.dxbs-gridview > .card .dxbs-table > thead > tr > th.dxbs-cmd-cell,
.dxbs-gridview > .card .dxbs-table > thead > tr > th.dxbs-select-cell {
    cursor: default;
}

/* command cell buttons */
.dxbs-gridview > .card .dxbs-table > tbody > tr > td.dxbs-cmd-cell > .btn.btn-link,
.dxbs-gridview > .card .dxbs-table > thead > tr > th.dxbs-cmd-cell > .btn.btn-link {
    border: 0;
    padding-top: 0;
    padding-bottom: 0;
    vertical-align: baseline;
}

.dxbs-gridview > .card .dxbs-table > tbody > tr > td mark {
    padding: 0;
}

.dxbs-gridview .dxbs-cmd-cell,
.dxbs-gridview .dxbs-select-cell {
    text-align: center;
    white-space: nowrap;
}

.dxbs-gridview .dxbs-loading {
    display: inline-block;
}

/* Fixed layout */

.dxbs-gridview .dxbs-fixed {
    table-layout: fixed;
}

.dxbs-gridview .dxbs-fixed > thead > tr > th,
.dxbs-gridview .dxbs-fixed > tbody > tr > td {
    overflow: hidden;
    white-space: nowrap;
}


.dxbs-gridview .dxbs-fixed > thead > tr > th {
    text-overflow: ellipsis;
}

.dxbs-gridview .dxbs-fixed .dxbs-dropdown-edit,
.dxbs-gridview .dxbs-fixed .dxbs-spin-edit { /*hidden*/
    position: static !important;
}

/* Scrolling */
.dxbs-gridview > .card > .dxgvHSDC,
.dxbs-gridview > .card > .dxgvCSD {
    border-radius: inherit;
    border-left: 0;
    border-right: 0;
    margin-bottom: 0;
    box-shadow: none;
    box-sizing: content-box;
    display: block;
    position: static;
}

.dxbs-gridview > .card > .dxgvHSDC:first-child,
.dxbs-gridview > .card > .dxgvHSDC + .dxgvCSD:not(.dxbs-grid-hsd),
.dxbs-gridview > .card > .dxgvCSD:first-child {  /*hidden*/
    border-top: 0;
}

/* Borders */
.dxbs-gridview > .card .dxbs-table > thead > tr:first-child > th,
.dxbs-gridview > .card .dxbs-table > thead > tr:first-child > td {
    border-top: 0;
}

.dxbs-gridview .dxbs-nlb,
.dxbs-gridview > .card .dxbs-table > thead > tr > th:first-child,
.dxbs-gridview > .card .dxbs-table > thead > tr > td:first-child {
    border-left-style: none;
}

.dxbs-gridview .dxbs-nrb {
    border-right: 0;
}

.dxbs-gridview > .card .dxbs-table > thead > tr > th,
.dxbs-gridview > .card .dxbs-table > thead > tr > td {
    border-bottom-width: 0;
}

.dxbs-gridview > .card > .dxgvCSD > table {
    border-width: 0;
}

.dxbs-gridview > .card > .dxgvHSDC + .dxgvCSD:not(.dx-scrolling) > table > tbody > tr:first-child > td {
    border-top: 0;
}

.dxbs-gridview.dxbs-vertical-scrollbar-visible:not(.dxbs-fm-details) > .card > .dxgvCSD:not(.dx-scrolling) > table > tbody > tr:last-child > td {
    border-bottom: 0;
}

/* GridView and CardView headers (cells and blocks) */
.dxbs-th.card {
    margin: 0 0.5em 0 0;
    display: inline-block;
    vertical-align: middle;
}

.dxbs-th.card > .card-body {
    padding: .3rem .75rem;
}

.dxbs-gridview > .card .dxbs-table > thead > tr > th,
.dxbs-th.card > .card-body {
    cursor: pointer;
    vertical-align: middle;
}

.dxbs-gridview > .card .dxbs-table > thead > tr.dxbs-filter-row > td,
.dxbs-th.card > .card-body.dxbs-noclick {
    cursor: default;
}

.dxbs-th.card > .card-body > span {
    font-weight: 600;
}

.dxbs-th.card > .card-body > span:not(:last-child) {
    display: table-cell;
    width: 99.9%;
    vertical-align: middle;
}

.dxbs-gridview > .card .dxbs-table > thead > tr > th > button:not(.dxbs-cmd-btn),
.dxbs-th.card > .card-body > button {
    padding: 0 .2rem;
    border-top: none;
    border-bottom: none;
}

.dxbs-fixed-header-content {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    -ms-flex-align: center;
}

.dxbs-fixed-header-content > span {
    flex-grow: 1;
    font-weight: 600;
}

.dxbs-fixed-header-content > button {
    padding: 0 .2rem;
    text-decoration: none;
    border-top: none;
    border-bottom: none;
}

.dxbs-gridview .dxbs-fixed .dxbs-fixed-header-content > span {
    overflow: hidden;
    text-overflow: ellipsis;
}

.dxbs-gridview td.table-active.bg-primary.text-white > .dxbs-checkbox > .custom-control > .custom-control-label {
    mix-blend-mode: screen;
}
/* PopupControl */
.dxbs-modal.modal {
    overflow: auto;
}

.dxbs-modal.modal .drag {
    cursor: default;
}

.dxbs-modal-back {
    visibility: hidden;
}

/* Pager */

.dxbs-grid-pager .pagination {
    margin: 0;
    float: left;
}
.dxbs-spin-edit > div.input-group > div:not(.input-group-append):not(.input-group-prepend):not(.dxbs-out-of-range-warn), .dxbs-dropdown-edit > .input-group > div:not(.input-group-append):not(.input-group-prepend):not(.dxbs-dropdown-area):not(.dxbs-out-of-range-warn):not(.dxbs-ld):not(.dxbs-lp), .dxbs-spin-edit > div.input-group > div:not(.input-group-append):not(.input-group-prepend):not(.dxbs-out-of-range-warn) > .form-control, .dxbs-textbox > div.input-group > div:not(.input-group-append):not(.input-group-prepend), .dxbs-memo > div.input-group > div:not(.input-group-append):not(.input-group-prepend), .dxbs-dropdown-edit > .input-group > div:not(.input-group-append):not(.input-group-prepend):not(.dxbs-dropdown-area):not(.dxbs-out-of-range-warn):not(.dxbs-ld):not(.dxbs-lp) > .form-control {
    flex: 1 1 auto;
    -ms-flex: 1 1 auto;
}

.dxbs-listbox > .list-group > li {
    display: block;
}

.dxbs-listbox > .list-group > li {
    flex: 0 1 auto;
    -ms-flex: 0 0 auto;
}

.dxbs-listbox > ul > li > .form-check.form-check-inline {
    margin-right: 0;
}

.dxbs-listbox > li > .form-check > label {
    display: inline-block;
    position: relative;
}

.dxbs-fast-nav.dropdown-menu,
.dxbs-dropdown-area.dropdown-menu {
    position: absolute;
    margin-bottom: 0px;
    padding: 0px;
}

.dxbs-gridview > .card {
    display: block;
}

.dxbs-gridview .dxbs-th.card > .card-body {
    display: flex;
    display: -ms-flexbox;
    flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    align-items: center;
    -ms-flex-align: center;
}

.dxbs-gridview .dxbs-th.card > .card-body > span:not(:last-child) {
    display: inline-block;
}


.dxbs-grid-pager .pagination {
    float: none;
}
