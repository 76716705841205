@mixin navigation-filter {
    margin-bottom: 1rem;
    width: 100%;
}

@mixin navigation-filter-icon {
    opacity: 0.5;
}

@mixin navigation-filter-mark {
    .dxbl-filter-content {
        background-color: $dx-mark-bg;
        color: $dx-mark-color;
    }
}
