@use "mixins" as editor-mixins;

@import "../dx-blazor.params";

@mixin editor-clear-button-hover-position($padding) {
    &:hover {
        &::before {
            top: $padding;
            bottom: $padding;
            right: $padding;
        }
    }
}

@mixin editor-clear-button {
    .dxbs-editor-clear-btn.btn {
        align-self: center;
        border: 0;
        background-color: transparent;
        position: relative;

        @include editor-mixins.editor-button-sizes();

        &.btn {
            padding-left: 0;
            color: currentcolor;

            @include editor-clear-button-hover-position(editor-mixins.$editor-button-padding);

            &.btn-sm {
                @include editor-clear-button-hover-position(editor-mixins.$editor-button-padding-sm);
            }

            &.btn-lg {
                @include editor-clear-button-hover-position(editor-mixins.$editor-button-padding-lg);
            }
        }

        &:hover {
            background-color: transparent;

            &::before {
                content: "";
                position: absolute;
                z-index: -1;
                left: 0;
                top: editor-mixins.$editor-button-padding-sm;
                bottom: editor-mixins.$editor-button-padding-sm;
                right: editor-mixins.$editor-button-padding-sm;
                background-color: currentcolor;
                opacity: 0.15;
                border-radius: 50%;
            }
        }

        &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
        }

        > .dx-image {
            fill-opacity: 0.6;
        }
    }
}

.input-group > .dxbs-input-group-append {
    @include editor-clear-button();
}
