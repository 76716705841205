/* icons */
.dx-back,
.dx-more,
.dxsc-time-icon,
.dxsc-save-icon,
.dxsc-edit-icon,
.dxsc-delete-icon,
.dxsc-close-icon,
.dxsc-description-icon,
.dxsc-recurrence-icon,
.dxsc-exception-icon,
.dxsc-location-icon,
.dxsc-resource-icon,
.dxsc-status-icon,
.dxsc-label-icon {
    width: 18px;
    height: 18px;
}

.dx-expand {
    width: 18px;
    height: 18px;
}

.dx-apt-recurrence,
.dx-apt-exception {
    width: 14px;
    height: 14px;
}

.dx-settings {
    width: 16px;
    height: 16px;
}

.dx-search {
    width: 24px;
    height: 24px;
}

input.dx-blazor-hide-ms-button::-ms-reveal {
    display: none;
}
