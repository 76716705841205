.dxbs-grid-dragging-target {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1999;
}

.dxbs-grid-move-cursor,
.dxbs-grid-move-cursor a:hover,
.dxbs-grid-move-cursor .dxbs-grid-action,
.dxbs-grid-move-cursor * {
    cursor: move !important; // stylelint-disable-line declaration-no-important
}
