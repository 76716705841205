$dx-white: white !default;
$dx-black: black !default;
$dx-blue: #0d6efd !default;
$dx-green: #198754 !default;
$dx-red: #dc3545 !default;
$dx-yellow: #fc781b !default;

$dx-gray-200: #e9ecef !default;
$dx-primary: $dx-blue !default;

$dx-success: $dx-green !default;
$dx-danger: $dx-red !default;
$dx-warning: $dx-yellow !default;
$dx-info: $dx-blue !default;

$dx-body-color: #212529 !default;
$dx-body-bg: #fff !default;

$dx-box-shadow: 0 .5rem 1rem rgba($dx-black, .15) !default;

$dx-font-size: 0.875rem !default;
$dx-font-size-sm: 0.75rem !default;
$dx-font-size-lg: 1rem !default;

$dx-input-focus-width: .25rem !default;
$dx-input-focus-color-opacity: .25 !default;
$dx-input-focus-color: rgba($dx-primary, $dx-input-focus-color-opacity) !default;
$dx-input-focus-blur: 0 !default;
$dx-input-focus-box-shadow: 0 0 $dx-input-focus-blur $dx-input-focus-width $dx-input-focus-color !default;
$dx-input-focus-border-color: tint-color($dx-primary, 50%) !default;
$dx-input-focus-width: .25rem !default;

$dx-line-height: 1.42858 !default;
$dx-line-height-sm: 1.333334 !default;
$dx-line-height-lg: 1.625 !default;

$dx-line-box-padding-x: 0.625rem !default;
$dx-line-box-padding-y: 0.1875rem !default;

$dx-line-box-padding-x-sm: 0.3125rem !default;
$dx-line-box-padding-y-sm: 0.1875rem !default;

$dx-line-box-padding-x-lg: 0.75rem !default;
$dx-line-box-padding-y-lg: 0.375rem !default;

$dx-border-width: 1px !default;
$dx-border-color: #ced4da !default;
$dx-border-radius-sm: 0.15rem !default;
$dx-border-radius: 0.2rem !default;
$dx-border-radius-lg: 0.3rem !default;

$dx-focus-color: #80bdff !default;
$dx-focus-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !default;

$dx-color-valid: $dx-success !default;
$dx-focus-shadow-valid: 0 0 0 0.2rem rgba($dx-success, 0.25) !default;

$dx-color-invalid: $dx-danger !default;
$dx-focus-shadow-invalid: 0 0 0 0.2rem rgba($dx-danger, 0.25) !default;

$dx-link-color: $dx-blue !default;
$dx-link-hover-color: mix(black, $dx-link-color, 20%);

$dx-icon-size: 1rem !default;
$dx-icon-size-sm: 1rem !default;
$dx-icon-size-lg: 1.125rem !default;

$dx-input-background: $dx-white !default;
$dx-active-background: $dx-primary !default;
$dx-input-border: 1px solid rgba($dx-black, .25) !default;

// Buttons
$dx-button-border-color: $dx-body-color !default;
$dx-button-border-width: $dx-border-width !default;
$dx-button-border-radius-sm: $dx-border-radius-sm !default;
$dx-button-border-radius: $dx-border-radius !default;
$dx-button-border-radius-lg: $dx-border-radius-lg !default;
$dx-button-icon-gap-sm: $dx-font-size-sm * 0.31 !default;
$dx-button-icon-gap: $dx-font-size * 0.51 !default;
$dx-button-icon-gap-lg: $dx-font-size-lg * 0.44 !default;

$dx-button-icon-size: $dx-icon-size !default;
$dx-button-icon-size-sm: $dx-icon-size-sm !default;
$dx-button-icon-size-lg: $dx-icon-size-lg !default;

//Checks

$dx-checkbox-checked-color: $dx-white !default;
$dx-checkbox-checked-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-2 -2 12 12'%3E%3Cpath fill='%23fff' d='M7,1L3,5L1,3L0,4l3,3l5-5L7,1z'/%3E%3C/svg%3E") !default;
$dx-checked-indeterminate-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 12 12' style='enable-background:new 0 0 12 12;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Crect id='_x31_' x='2' y='5' class='st0' width='8' height='2'/%3E%3C/svg%3E%0A") !default;
$dx-checkbox-radio-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$dx-checkbox-checked-color}'/></svg>") !default;

$dx-checkbox-width: 1.25rem !default;
$dx-checkbox-height: 1.25rem !default;
$dx-checkbox-background: $dx-input-background !default;
$dx-checkbox-checked-background: $dx-active-background !default;
$dx-checkbox-border: $dx-input-border !default;
$dx-checkbox-checked-border: $dx-input-focus-border-color !default;
$dx-checkbox-focus-border: $dx-input-focus-border-color !default;
$dx-checkbox-focus-box-shadow: $dx-input-focus-box-shadow !default;
$dx-checkbox-label-color: null !default;
$dx-checkbox-label-cursor: null !default;

// Editors
$dx-input-editor-border-width: $dx-border-width !default;
$dx-input-editor-border-color: $dx-border-color !default;
$dx-input-editor-border: $dx-input-editor-border-width solid $dx-input-editor-border-color !default;
$dx-input-editor-border-radius-sm: $dx-border-radius-sm !default;
$dx-input-editor-border-radius: $dx-border-radius !default;
$dx-input-editor-border-radius-lg: $dx-border-radius-lg !default;
$dx-input-editor-bg: $dx-body-bg !default;
$dx-input-editor-disabled-bg: $dx-gray-200 !default;
$dx-input-editor-color: $dx-body-color !default;

$dx-input-editor-focus-color: $dx-focus-color !default;
$dx-input-editor-focus-shadow: $dx-focus-shadow !default;
$dx-input-editor-color-valid: $dx-color-valid !default;
$dx-input-editor-focus-shadow-valid: $dx-focus-shadow-valid !default;
$dx-input-editor-color-invalid: $dx-color-invalid !default;
$dx-input-editor-focus-shadow-invalid: $dx-focus-shadow-invalid !default;

$dx-input-editor-button-padding-sm: $dx-button-icon-gap-sm !default;
$dx-input-editor-button-padding: $dx-button-icon-gap !default;
$dx-input-editor-button-padding-lg: $dx-button-icon-gap-lg !default;

//Validation
$dx-validation-success: $dx-success !default;
$dx-validation-critical: $dx-danger !default;
$dx-validation-warning: $dx-warning !default;
$dx-validation-info: $dx-info !default;

//Memo
$dx-memo-input-clear-icon-right-padding: 1.5rem !default;
$dx-memo-input-clear-icon-right-padding-sm: 1.5rem !default;
$dx-memo-input-clear-icon-right-padding-lg: 2.5rem !default;
$dx-memo-input-min-height: 4rem !default;
