.dx-pivotgrid {
    tr:last-child > .dxpg-header,
    tbody:not(:last-child) > tr:last-child > td,
    thead:not(:last-child) > tr:last-child > th {
        border-bottom-color: inherit;
    }

    .dxpg-area-column,
    .dxpg-area-row {
        & > .btn-link:not(:hover) {
            text-decoration: none;
        }
    }

    th,
    td {
        &.table-active {
            box-shadow: none;
            background-color: rgba(0,0,0,.075);
        }
    }
}
