.dxbs-listbox {
    .dxbs-preventsel > .dxbs-checkbox {
        padding-left: 0;
        margin-bottom: 0;

        & > input {
            margin-left: 0;
        }
    }

    & > .dxbs-gridview tr {
        border-color: inherit;
    }
}
