.dxbl-memoinput {
    @include dx-font-size();
    @include dx-input-editor-root("dxbl-memoinput");

    border-radius: $dx-input-editor-border-radius;

    .dxbl-memoinput-input {
        @include dx-line-box-size();

        border-radius: $dx-input-editor-border-radius;

        &[has-clear-button],[has-overflow] {
            padding-right: $dx-memo-input-clear-icon-right-padding;
        }
        &:not(.dxbl-memoinput-input-reset-height) {
            min-height: $dx-memo-input-min-height;
        }
        &.dxbl-memoinput-input-disable-resize {
            resize: none;
        }
        &.dxbl-memoinput-input-vertical-resize {
            resize: vertical;
        }
        &.dxbl-memoinput-input-horizontal-resize {
            resize: horizontal;
        }
        &.dxbl-memoinput-input-both-resize {
            resize: both;
        }
    }

    &.dxbl-memoinput-sm {
        @include dx-font-size-sm();

        border-radius: $dx-input-editor-border-radius-sm;

        .dxbl-memoinput-input {
            @include dx-line-box-size-sm();

            border-radius: $dx-input-editor-border-radius-sm;

            &[has-clear-button],[has-overflow] {
                padding-right: $dx-memo-input-clear-icon-right-padding-sm;
            }
        }
    }

    &.dxbl-memoinput-lg {
        @include dx-font-size-lg();

        border-radius: $dx-input-editor-border-radius-lg;

        .dxbl-memoinput-input {
            @include dx-line-box-size-lg();

            border-radius: $dx-input-editor-border-radius-lg;

            &[has-clear-button],[has-overflow] {
                padding-right: $dx-memo-input-clear-icon-right-padding-lg;
            }
        }
    }

    &[has-clear-button] {
        @include dx-input-editor-clear-button-remove-extra-paddings();
    }
}
