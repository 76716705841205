@mixin dx-input-editor-root($root-class-name) {
    display: flex;
    border: $dx-input-editor-border;
    background-color: $dx-input-editor-bg;
    color: $dx-input-editor-color;

    &[server-focused] {
        border-color: $dx-input-editor-focus-color;
        box-shadow: $dx-input-editor-focus-shadow;
        &:not([invalid])[modified] {
            box-shadow: $dx-input-editor-focus-shadow-valid;
        }
        &[invalid] {
            box-shadow: $dx-input-editor-focus-shadow-invalid;
        }
    }
    &:not([invalid])[modified]  {
        border-color: $dx-input-editor-color-valid;
    }
    &[invalid] {
        border-color: $dx-input-editor-color-invalid;
    }

    .#{$root-class-name}-input {
        @include dx-remove-border();

        flex-grow: 1;
        color: $dx-input-editor-color;
        background-color: transparent;
        appearance: none;
        width: 100%;

        &[disabled] {
            background-color: $dx-input-editor-disabled-bg;
            opacity: 0.5;
        }
    }

    [slot='right-buttons']:not([render-style-mode="text"]),
    [slot='default-far-buttons']:not([render-style-mode="text"]) {
        border-left: $dx-input-editor-border;
    }

    [slot='left-buttons']:not([render-style-mode="text"]) {
        border-right: $dx-input-editor-border;
    }

    .dxbl-button-root {
        .dxbl-input-button {
            @include dx-button-focused-shadow(none);

            border: 0;
            border-radius: 0;
        }
    }
}

@mixin dx-input-editor-focused(
    $focus-color: $dx-input-editor-focus-color,
    $focus-shadow: $dx-input-editor-focus-shadow
) {
    &[server-focused] {
        border-color: $focus-color;
        box-shadow: $focus-shadow;
    }
}

@mixin dx-input-editor-utmost-buttons-radius($radius) {
    $button-radius: calc(#{$radius} - #{$dx-input-editor-border-width});
    [slot='left-buttons']:first-of-type {
        .dxbl-input-button {
            border-top-left-radius: $button-radius;
            border-bottom-left-radius: $button-radius;
        }
    }

    [slot='right-buttons']:last-of-type {
        .dxbl-input-button {
            border-top-right-radius: $button-radius;
            border-bottom-right-radius: $button-radius;
        }
    }
}

@mixin dx-input-editor-clear-button-remove-extra-paddings() {
    .dxbl-input-clear-button-root {

        .dxbl-input-clear-button {
            padding-left: 0;
        }

        &:hover {
            .dxbl-input-clear-button::before {
                left: 0;
            }
        }
    }
}

@mixin dx-input-editor-shadow() {
    .form-control {
        box-shadow: $input-box-shadow;
    }
    .form-control:focus {
        box-shadow: $input-box-shadow, $input-focus-box-shadow;
    }
}
