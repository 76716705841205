@use "./mixins/size-management";

.dxbl-radio-root {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;

    &:not(:defined) {
        display: none;
    }
    > .dxbl-radio-input {
        min-width: $dx-checkbox-width;
        width: $dx-checkbox-width;
        min-height: $dx-checkbox-height;
        height: $dx-checkbox-height;
        background-color: $dx-checkbox-background;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border: $dx-checkbox-border;
        border-radius: 50%;
        appearance: none;
        color-adjust: exact;

        &:focus {
            border-color: $dx-checkbox-focus-border;
            outline: 0;
            box-shadow: $dx-checkbox-focus-box-shadow;
        }

        &:checked {
            background-color: $dx-checkbox-checked-background;
            border-color: $dx-checkbox-checked-border;
            background-image: escape-svg($dx-checkbox-radio-image);
        }
    }
    > .dxbl-radio-label {
        color: $dx-checkbox-label-color;
        cursor: $dx-checkbox-label-cursor;
        &.dxbl-checkbox-label-wrap {
            white-space: normal;
        }
        &.dxbl-checkbox-label-nowrap {
            white-space: nowrap;
        }
        &.dxbl-checkbox-label-ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &.dxbl-checkbox-label-left {
            text-align: right;
            padding-right: 0.5rem;
        }
        &.dxbl-checkbox-label-right {
            text-align: left;
            padding-left: 0.5rem;
        }
    }
}

.dxbl-radio-group {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    gap: 0.5rem;
}
