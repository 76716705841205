.dxbl-group {
    & > .dxbl-group-header {
        display: flex;

        & .expand-btn {
            outline: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 0.75rem;
            height: 0.75rem;
            padding: 0.5rem;
            opacity: unset;
            color: inherit;
            background-color: transparent;
            border: 0;
            flex-basis: auto;
            position: relative;
            transform: scale(1);
            text-decoration: none;

            & > .dxbs-expand-image {
                width: 1rem;
                height: 1rem;
            }
        }
        & > .dxbl-group-header-text {
            flex-grow: 1;
            & .expand-btn.btn.invisible {
                display: none;
            }
        }
        &:not(.dxbl-group-header-start) {
            & > .dxbl-group-header-text {
                margin-right: auto;
            }
            & .expand-btn {
                margin: 0 0 0 0.5rem;
            }
        }
        &.dxbl-group-header-start {

            & > .dxbl-group-header-text {
                margin-left: auto;
            }

            & .expand-btn {
                margin: 0 0.5rem 0 0;
            }
        }
    }
    &.transition > .dxbl-group-body {
        transition: height 200ms cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
        will-change: height;
    }
    &:not(.expanded):not(.transition) > .dxbl-group-header {
        border-bottom: none;
    }
    &:not([data-qa-dxbl-loaded]):not(.expanded) > .dxbl-group-body {
        display: none;
    }
}