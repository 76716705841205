//////////
//old.css
.dxbs-tabs ul.nav > li > a:not(.disabled) {
    cursor: pointer;
}


.dxbs-tabs .nav:before,
.dxbs-tabs .nav:after { /*hidden*/
    display: block;
}

.dxbs-tabs span.image {
    display: inline-block;
    position: relative;
    align-self: center;
}

.dxbs-tabs ul.nav > li > a > span.image:not(:last-child) {
    margin-right: 0.5rem;
}

.dxbs-tabs-scrollable {
    display: flex;
    flex-flow: row nowrap;
}

.dxbs-tabs-scrollable .dxbs-tabs-scroll-btn {
    flex: 0 3rem;
    width: 3rem;
    margin-left: calc(-2 * 3rem);
    display: none;
    visibility: hidden;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.dxbs-tabs-scrollable .dxbs-tabs-scroll-btn.prev {
    border-top-right-radius: 0;
}

.dxbs-tabs-scrollable .dxbs-tabs-scroll-btn.next {
    border-top-left-radius: 0;
}

.dxbs-tabs-scrollable .dxbs-tabs-scroll-btn,
.dxbs-tabs-scrollable .dxbs-tabs-scroll-btn:disabled {
    opacity: 0.3;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dxbs-tabs-scrollable.can-scroll-left .dxbs-tabs-scroll-btn.prev,
.dxbs-tabs-scrollable.can-scroll-right .dxbs-tabs-scroll-btn.next {
    opacity: 1;
}

.dxbs-tabs-scrollable.has-overflow .dxbs-tabs-scroll-btn {
    visibility: visible;
}

.dxbs-tabs-scroll-btn + .dxbs-tabs-scroll-btn {
    margin-left: 0;
}

.dxbs-tabs-scrollable.has-overflow .dxbs-tabs-scroll-btn {
    display: inline-flex;
}

.dxbs-tabs-scrollable > .tab-content {
    flex: 1 100%;
}

.dxbs-tabs-scrollable .nav {
    flex: 1 calc(100% - 2 * 3rem);
    flex-wrap: nowrap;
    touch-action: pan-x;
    white-space: nowrap;
}

.dxbs-tabs-scrollable.has-overflow.dxbs-tabs-scrollbuttons > .dxbs-scroll-viewer,
.dxbs-tabs-scrollable:not(.dxbs-loaded).dxbs-tabs-scrollbuttons > .dxbs-scroll-viewer {
    margin-right: calc(2 * 3rem);
}

.dxbs-tabs-scrollable .nav:before {
    content: " ";
    width: 100%;
    position: absolute;
    box-shadow: inherit;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 1;

    border: inherit;
}

.dxbs-tabs-scrollable .nav > .nav-item {
    z-index: 1;
}
//old.css
//////////


.dx-tabs-content {
    flex: 1 0 100%;
    width: 100%;
}

.dx-tabs-content-panel:not(.dxbs-tabs-render-default) > .dx-tabs-content:not([data-dx-tab-loaded]):not(.active),
.dxbs-loaded.dx-tabs-content-panel:not(.dxbs-tabs-render-default) > .dx-tabs-content:not([data-dx-tab-loaded]) {
    position: absolute;
    visibility: hidden;
    left: -10000px
}

.dx-tabs-content-panel {
    display: flex;
    flex-grow: 1;
}

.dxbs-focus-hidden .dxbs-tabs-scroll-btn.btn-secondary:focus:not(:disabled):not(.disabled):not(.active):not(:active) {
    box-shadow: initial!important;
}

.dxbs-tabs-scrollable {
    & .dxbs-tabs-scroll-btn {
        flex: unset;
        width: unset;
        visibility: hidden;
        box-shadow: initial!important;

        &:not(:hover):not(.active):not(:active):not(:focus),
        &:disabled {
            border-color: transparent;
            background: none;
            color: inherit;
        }

        &:focus {
            box-shadow: initial!important;
        }

        &.prev {
            border-top-left-radius: 0;
        }

        &.next {
            border-top-right-radius: inherit;
        }
    }

    & > .dxbs-scroll-viewer > .dxbs-scroll-viewer-hor-scroll-bar {
        display: none;
    }

    &.dxbs-tabs-scrollbuttons {
        position: relative;

        & > .dxbs-scroll-viewer {
            position: static;
        }
    }

    &:not(.dxbs-tabs-scrollbuttons) {
        & > .dxbs-scroll-viewer {
            padding-left: 1.5rem;
        }
    }
}

.dxbs-tabs > .nav-tabs,
.dxbs-tabs > .dxbs-scroll-viewer > .dxbs-scroll-viewer-content > .nav-tabs {
    & > .nav-item > .nav-link {
        display: flex;
    }
}