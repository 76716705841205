@mixin dx-remove-border() {
    box-shadow: none;
    border: 0;
    outline: none;
}

@mixin dx-remove-left-top-right-borders() {
    border-top: 0;
    border-left: 0;
    border-right: 0;
}
