@charset "UTF-8";
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #5f368d;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #28a745;
  --bs-teal: #20c997;
  --bs-cyan: #00a9e6;
  --bs-white: #fff;
  --bs-gray: #888;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #ececec;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #888;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #5f368d;
  --bs-secondary: #888;
  --bs-success: #28a745;
  --bs-info: #00a9e6;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #ececec;
  --bs-dark: #212529;
  --bs-primary-rgb: 95, 54, 141;
  --bs-secondary-rgb: 136, 136, 136;
  --bs-success-rgb: 40, 167, 69;
  --bs-info-rgb: 0, 169, 230;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 236, 236, 236;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 34, 34, 34;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(34, 34, 34, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.34375rem + 1.125vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.1875rem;
  }
}

h2, .h2 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.75rem;
  }
}

h3, .h3 {
  font-size: calc(1.278125rem + 0.3375vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.53125rem;
  }
}

h4, .h4 {
  font-size: calc(1.25625rem + 0.075vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.3125rem;
  }
}

h5, .h5 {
  font-size: 1.09375rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #5f368d;
  text-decoration: underline;
}
a:hover {
  color: #4c2b71;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.15rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #888;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #888;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #888;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(34, 34, 34, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(34, 34, 34, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(34, 34, 34, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #dfd7e8;
  --bs-table-striped-bg: #d6cede;
  --bs-table-striped-color: #222;
  --bs-table-active-bg: #ccc5d4;
  --bs-table-active-color: #222;
  --bs-table-hover-bg: #d1c9d9;
  --bs-table-hover-color: #222;
  color: #222;
  border-color: #ccc5d4;
}

.table-secondary {
  --bs-table-bg: #e7e7e7;
  --bs-table-striped-bg: #dddddd;
  --bs-table-striped-color: #222;
  --bs-table-active-bg: lightgray;
  --bs-table-active-color: #222;
  --bs-table-hover-bg: #d8d8d8;
  --bs-table-hover-color: #222;
  color: #222;
  border-color: lightgray;
}

.table-success {
  --bs-table-bg: #d4edda;
  --bs-table-striped-bg: #cbe3d1;
  --bs-table-striped-color: #222;
  --bs-table-active-bg: #c2d9c8;
  --bs-table-active-color: #222;
  --bs-table-hover-bg: #c7decc;
  --bs-table-hover-color: #222;
  color: #222;
  border-color: #c2d9c8;
}

.table-info {
  --bs-table-bg: #cceefa;
  --bs-table-striped-bg: #c4e4ef;
  --bs-table-striped-color: #222;
  --bs-table-active-bg: #bbdae4;
  --bs-table-active-color: #222;
  --bs-table-hover-bg: #bfdfea;
  --bs-table-hover-color: #222;
  color: #222;
  border-color: #bbdae4;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f4e9c4;
  --bs-table-striped-color: #222;
  --bs-table-active-bg: #e9debc;
  --bs-table-active-color: #222;
  --bs-table-hover-bg: #eee3c0;
  --bs-table-hover-color: #222;
  color: #222;
  border-color: #e9debc;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #edced1;
  --bs-table-striped-color: #222;
  --bs-table-active-bg: #e3c5c8;
  --bs-table-active-color: #222;
  --bs-table-hover-bg: #e8c9cc;
  --bs-table-hover-color: #222;
  color: #222;
  border-color: #e3c5c8;
}

.table-light {
  --bs-table-bg: #ececec;
  --bs-table-striped-bg: #e2e2e2;
  --bs-table-striped-color: #222;
  --bs-table-active-bg: #d8d8d8;
  --bs-table-active-color: #222;
  --bs-table-hover-bg: #dddddd;
  --bs-table-hover-color: #222;
  color: #222;
  border-color: #d8d8d8;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.40625rem + 1px);
  padding-bottom: calc(0.40625rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.65625rem + 1px);
  padding-bottom: calc(0.65625rem + 1px);
  font-size: 1.125rem;
}

.col-form-label-sm {
  padding-top: calc(0.203125rem + 1px);
  padding-bottom: calc(0.203125rem + 1px);
  font-size: 0.8125rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #888;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.40625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #af9bc6;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #888;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.40625rem 0.75rem;
  margin: -0.40625rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.40625rem 0.75rem;
  margin: -0.40625rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.40625rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.40625rem + 2px);
  padding: 0.203125rem 0.5rem;
  font-size: 0.8125rem;
  border-radius: 0.15rem;
}
.form-control-sm::file-selector-button {
  padding: 0.203125rem 0.5rem;
  margin: -0.203125rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.203125rem 0.5rem;
  margin: -0.203125rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.3125rem + 2px);
  padding: 0.65625rem 1rem;
  font-size: 1.125rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 0.65625rem 1rem;
  margin: -0.65625rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.65625rem 1rem;
  margin: -0.65625rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.8125rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.40625rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1.3125rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.40625rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.2rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.2rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.40625rem 2.25rem 0.40625rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #af9bc6;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.203125rem;
  padding-bottom: 0.203125rem;
  padding-left: 0.5rem;
  font-size: 0.8125rem;
  border-radius: 0.15rem;
}

.form-select-lg {
  padding-top: 0.65625rem;
  padding-bottom: 0.65625rem;
  padding-left: 1rem;
  font-size: 1.125rem;
  border-radius: 0.3rem;
}

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(34, 34, 34, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #af9bc6;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.25);
}
.form-check-input:checked {
  background-color: #5f368d;
  border-color: #5f368d;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-2 -2 12 12'%3E%3Cpath fill='%23fff' d='M7,1L3,5L1,3L0,4l3,3l5-5L7,1z'/%3E%3C/svg%3E");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #5f368d;
  border-color: #5f368d;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 12 12' style='enable-background:new 0 0 12 12;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Crect id='_x31_' x='2' y='5' class='st0' width='8' height='2'/%3E%3C/svg%3E%0A");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%2834, 34, 34, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23af9bc6'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(95, 54, 141, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(95, 54, 141, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #5f368d;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #cfc3dd;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #5f368d;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #cfc3dd;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.40625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.65625rem 1rem;
  font-size: 1.125rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.203125rem 0.5rem;
  font-size: 0.8125rem;
  border-radius: 0.15rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.8125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.203125rem) center;
  background-size: calc(0.75em + 0.40625rem) calc(0.75em + 0.40625rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.8125rem);
  background-position: top calc(0.375em + 0.203125rem) right calc(0.375em + 0.203125rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #28a745;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.40625rem) calc(0.75em + 0.40625rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #28a745;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #28a745;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.8125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.203125rem) center;
  background-size: calc(0.75em + 0.40625rem) calc(0.75em + 0.40625rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.8125rem);
  background-position: top calc(0.375em + 0.203125rem) right calc(0.375em + 0.203125rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.40625rem) calc(0.75em + 0.40625rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.40625rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #5f368d;
  border-color: #5f368d;
}
.btn-primary:hover {
  color: #fff;
  background-color: #512e78;
  border-color: #4c2b71;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #512e78;
  border-color: #4c2b71;
  box-shadow: 0 0 0 0.25rem rgba(119, 84, 158, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #4c2b71;
  border-color: #47296a;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(119, 84, 158, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #5f368d;
  border-color: #5f368d;
}

.btn-secondary {
  color: #fff;
  background-color: #888;
  border-color: #888;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #747474;
  border-color: #6d6d6d;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #747474;
  border-color: #6d6d6d;
  box-shadow: 0 0 0 0.25rem rgba(154, 154, 154, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6d6d6d;
  border-color: #666666;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(154, 154, 154, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #888;
  border-color: #888;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #228e3b;
  border-color: #208637;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #228e3b;
  border-color: #208637;
  box-shadow: 0 0 0 0.25rem rgba(72, 180, 97, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #208637;
  border-color: #1e7d34;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(72, 180, 97, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-info {
  color: #fff;
  background-color: #00a9e6;
  border-color: #00a9e6;
}
.btn-info:hover {
  color: #fff;
  background-color: #0090c4;
  border-color: #0087b8;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #fff;
  background-color: #0090c4;
  border-color: #0087b8;
  box-shadow: 0 0 0 0.25rem rgba(38, 182, 234, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #0087b8;
  border-color: #007fad;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 182, 234, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #fff;
  background-color: #00a9e6;
  border-color: #00a9e6;
}

.btn-warning {
  color: #222;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #222;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #222;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(222, 169, 11, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #222;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(222, 169, 11, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #222;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #222;
  background-color: #ececec;
  border-color: #ececec;
}
.btn-light:hover {
  color: #222;
  background-color: #efefef;
  border-color: #eeeeee;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #222;
  background-color: #efefef;
  border-color: #eeeeee;
  box-shadow: 0 0 0 0.25rem rgba(206, 206, 206, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #222;
  background-color: #f0f0f0;
  border-color: #eeeeee;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(206, 206, 206, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #222;
  background-color: #ececec;
  border-color: #ececec;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-primary {
  color: #5f368d;
  border-color: #5f368d;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #5f368d;
  border-color: #5f368d;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #5f368d;
  border-color: #5f368d;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #5f368d;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #888;
  border-color: #888;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #888;
  border-color: #888;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(136, 136, 136, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #888;
  border-color: #888;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(136, 136, 136, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #888;
  background-color: transparent;
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-info {
  color: #00a9e6;
  border-color: #00a9e6;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #00a9e6;
  border-color: #00a9e6;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 169, 230, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #fff;
  background-color: #00a9e6;
  border-color: #00a9e6;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 169, 230, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #00a9e6;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #222;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #222;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #ececec;
  border-color: #ececec;
}
.btn-outline-light:hover {
  color: #222;
  background-color: #ececec;
  border-color: #ececec;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(236, 236, 236, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #222;
  background-color: #ececec;
  border-color: #ececec;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(236, 236, 236, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #ececec;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #212529;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #5f368d;
  text-decoration: underline;
}
.btn-link:hover {
  color: #4c2b71;
}
.btn-link:disabled, .btn-link.disabled {
  color: #888;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.65625rem 1rem;
  font-size: 1.125rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.203125rem 0.5rem;
  font-size: 0.8125rem;
  border-radius: 0.15rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(34, 34, 34, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(34, 34, 34, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #5f368d;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  color: #888;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(34, 34, 34, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #5f368d;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(34, 34, 34, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #5f368d;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #4c2b71;
}
.nav-link.disabled {
  color: #888;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #888;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #5f368d;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.2rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #5f368d;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.125rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.2rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
.navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(34, 34, 34, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(34, 34, 34, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(34, 34, 34, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(34, 34, 34, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(34, 34, 34, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(34, 34, 34, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(34, 34, 34, 0.55);
  border-color: rgba(34, 34, 34, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2834, 34, 34, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(34, 34, 34, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(34, 34, 34, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(34, 34, 34, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1.25rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.5rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(34, 34, 34, 0.03);
  border-bottom: 1px solid rgba(34, 34, 34, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1.25rem;
  background-color: rgba(34, 34, 34, 0.03);
  border-top: 1px solid rgba(34, 34, 34, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.5rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #56317f;
  background-color: #efebf4;
  box-shadow: inset 0 -1px 0 rgba(34, 34, 34, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2356317f'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #af9bc6;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(34, 34, 34, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.2rem - 1px);
  border-top-right-radius: calc(0.2rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.2rem - 1px);
  border-bottom-left-radius: calc(0.2rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #ececec;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #AAA;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #AAA;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #5f368d;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #4c2b71;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #4c2b71;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #5f368d;
  border-color: #5f368d;
}
.page-item.disabled .page-link {
  color: #888;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.2195rem 0.5rem;
  font-size: 0.8125rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.15rem;
  border-bottom-left-radius: 0.15rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.15rem;
  border-bottom-right-radius: 0.15rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 80%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.2rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #392055;
  background-color: #dfd7e8;
  border-color: #cfc3dd;
}
.alert-primary .alert-link {
  color: #2e1a44;
}

.alert-secondary {
  color: #525252;
  background-color: #e7e7e7;
  border-color: #dbdbdb;
}
.alert-secondary .alert-link {
  color: #424242;
}

.alert-success {
  color: #186429;
  background-color: #d4edda;
  border-color: #bfe5c7;
}
.alert-success .alert-link {
  color: #135021;
}

.alert-info {
  color: #00658a;
  background-color: #cceefa;
  border-color: #b3e5f8;
}
.alert-info .alert-link {
  color: #00516e;
}

.alert-warning {
  color: #997404;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #7a5d03;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #8e8e8e;
  background-color: #fbfbfb;
  border-color: #f9f9f9;
}
.alert-light .alert-link {
  color: #727272;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.5rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #5f368d;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #ececec;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.59375rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(34, 34, 34, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #888;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #5f368d;
  border-color: #5f368d;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #392055;
  background-color: #dfd7e8;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #392055;
  background-color: #c9c2d1;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #392055;
  border-color: #392055;
}

.list-group-item-secondary {
  color: #525252;
  background-color: #e7e7e7;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #525252;
  background-color: #d0d0d0;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #525252;
  border-color: #525252;
}

.list-group-item-success {
  color: #186429;
  background-color: #d4edda;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #186429;
  background-color: #bfd5c4;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #186429;
  border-color: #186429;
}

.list-group-item-info {
  color: #00658a;
  background-color: #cceefa;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #00658a;
  background-color: #b8d6e1;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #00658a;
  border-color: #00658a;
}

.list-group-item-warning {
  color: #997404;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #997404;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #997404;
  border-color: #997404;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #8e8e8e;
  background-color: #fbfbfb;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #8e8e8e;
  background-color: #e2e2e2;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #8e8e8e;
  border-color: #8e8e8e;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #222;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23222'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.2rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #222;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(34, 34, 34, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(34, 34, 34, 0.15);
  border-radius: 0.2rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #888;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(34, 34, 34, 0.05);
  border-top-left-radius: calc(0.2rem - 1px);
  border-top-right-radius: calc(0.2rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(34, 34, 34, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #222;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #222;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #222;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #222;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #222;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #222;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(34, 34, 34, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(34, 34, 34, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(34, 34, 34, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(34, 34, 34, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(34, 34, 34, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(34, 34, 34, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #222;
}
.carousel-dark .carousel-caption {
  color: #222;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #222;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(34, 34, 34, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(34, 34, 34, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(34, 34, 34, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(34, 34, 34, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #222 55%, rgba(0, 0, 0, 0.8) 75%, #222 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #5f368d;
}
.link-primary:hover, .link-primary:focus {
  color: #4c2b71;
}

.link-secondary {
  color: #888;
}
.link-secondary:hover, .link-secondary:focus {
  color: #6d6d6d;
}

.link-success {
  color: #28a745;
}
.link-success:hover, .link-success:focus {
  color: #208637;
}

.link-info {
  color: #00a9e6;
}
.link-info:hover, .link-info:focus {
  color: #0087b8;
}

.link-warning {
  color: #ffc107;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #ececec;
}
.link-light:hover, .link-light:focus {
  color: #f0f0f0;
}

.link-dark {
  color: #212529;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(34, 34, 34, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(34, 34, 34, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(34, 34, 34, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #5f368d !important;
}

.border-secondary {
  border-color: #888 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #00a9e6 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #ececec !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.34375rem + 1.125vw) !important;
}

.fs-2 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-3 {
  font-size: calc(1.278125rem + 0.3375vw) !important;
}

.fs-4 {
  font-size: calc(1.25625rem + 0.075vw) !important;
}

.fs-5 {
  font-size: 1.09375rem !important;
}

.fs-6 {
  font-size: 0.875rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #888 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(34, 34, 34, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.2rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.15rem !important;
}

.rounded-2 {
  border-radius: 0.2rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.2rem !important;
  border-top-right-radius: 0.2rem !important;
}

.rounded-end {
  border-top-right-radius: 0.2rem !important;
  border-bottom-right-radius: 0.2rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.2rem !important;
  border-top-left-radius: 0.2rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.1875rem !important;
  }

  .fs-2 {
    font-size: 1.75rem !important;
  }

  .fs-3 {
    font-size: 1.53125rem !important;
  }

  .fs-4 {
    font-size: 1.3125rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
.dxbl-button-root .dxbl-button.dxbl-button-primary {
  color: #fff;
  background-color: #5f368d;
  border-color: #5f368d;
}
.dxbl-button-root .dxbl-button.dxbl-button-primary:hover {
  color: #fff;
  background-color: #512e78;
  border-color: #4c2b71;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-primary, .dxbl-button-root .dxbl-button.dxbl-button-primary:focus {
  color: #fff;
  background-color: #512e78;
  border-color: #4c2b71;
  box-shadow: 0 0 0 0.25rem rgba(119, 84, 158, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-primary, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-primary, .dxbl-button-root .dxbl-button.dxbl-button-primary:active, .dxbl-button-root .dxbl-button.dxbl-button-primary.active, .show > .dxbl-button-root .dxbl-button.dxbl-button-primary.dropdown-toggle {
  color: #fff;
  background-color: #4c2b71;
  border-color: #47296a;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-primary:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-primary:focus, .dxbl-button-root .dxbl-button.dxbl-button-primary:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-primary.active:focus, .show > .dxbl-button-root .dxbl-button.dxbl-button-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(119, 84, 158, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-primary:disabled, .dxbl-button-root .dxbl-button.dxbl-button-primary.disabled {
  color: #fff;
  background-color: #5f368d;
  border-color: #5f368d;
}
.dxbl-button-root .dxbl-button.dxbl-button-secondary {
  color: #fff;
  background-color: #888;
  border-color: #888;
}
.dxbl-button-root .dxbl-button.dxbl-button-secondary:hover {
  color: #fff;
  background-color: #747474;
  border-color: #6d6d6d;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-secondary, .dxbl-button-root .dxbl-button.dxbl-button-secondary:focus {
  color: #fff;
  background-color: #747474;
  border-color: #6d6d6d;
  box-shadow: 0 0 0 0.25rem rgba(154, 154, 154, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-secondary, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-secondary, .dxbl-button-root .dxbl-button.dxbl-button-secondary:active, .dxbl-button-root .dxbl-button.dxbl-button-secondary.active, .show > .dxbl-button-root .dxbl-button.dxbl-button-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6d6d6d;
  border-color: #666666;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-secondary:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-secondary:focus, .dxbl-button-root .dxbl-button.dxbl-button-secondary:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-secondary.active:focus, .show > .dxbl-button-root .dxbl-button.dxbl-button-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(154, 154, 154, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-secondary:disabled, .dxbl-button-root .dxbl-button.dxbl-button-secondary.disabled {
  color: #fff;
  background-color: #888;
  border-color: #888;
}
.dxbl-button-root .dxbl-button.dxbl-button-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.dxbl-button-root .dxbl-button.dxbl-button-success:hover {
  color: #fff;
  background-color: #228e3b;
  border-color: #208637;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-success, .dxbl-button-root .dxbl-button.dxbl-button-success:focus {
  color: #fff;
  background-color: #228e3b;
  border-color: #208637;
  box-shadow: 0 0 0 0.25rem rgba(72, 180, 97, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-success, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-success, .dxbl-button-root .dxbl-button.dxbl-button-success:active, .dxbl-button-root .dxbl-button.dxbl-button-success.active, .show > .dxbl-button-root .dxbl-button.dxbl-button-success.dropdown-toggle {
  color: #fff;
  background-color: #208637;
  border-color: #1e7d34;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-success:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-success:focus, .dxbl-button-root .dxbl-button.dxbl-button-success:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-success.active:focus, .show > .dxbl-button-root .dxbl-button.dxbl-button-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(72, 180, 97, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-success:disabled, .dxbl-button-root .dxbl-button.dxbl-button-success.disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.dxbl-button-root .dxbl-button.dxbl-button-info {
  color: #fff;
  background-color: #00a9e6;
  border-color: #00a9e6;
}
.dxbl-button-root .dxbl-button.dxbl-button-info:hover {
  color: #fff;
  background-color: #0090c4;
  border-color: #0087b8;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-info, .dxbl-button-root .dxbl-button.dxbl-button-info:focus {
  color: #fff;
  background-color: #0090c4;
  border-color: #0087b8;
  box-shadow: 0 0 0 0.25rem rgba(38, 182, 234, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-info, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-info, .dxbl-button-root .dxbl-button.dxbl-button-info:active, .dxbl-button-root .dxbl-button.dxbl-button-info.active, .show > .dxbl-button-root .dxbl-button.dxbl-button-info.dropdown-toggle {
  color: #fff;
  background-color: #0087b8;
  border-color: #007fad;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-info:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-info:focus, .dxbl-button-root .dxbl-button.dxbl-button-info:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-info.active:focus, .show > .dxbl-button-root .dxbl-button.dxbl-button-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 182, 234, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-info:disabled, .dxbl-button-root .dxbl-button.dxbl-button-info.disabled {
  color: #fff;
  background-color: #00a9e6;
  border-color: #00a9e6;
}
.dxbl-button-root .dxbl-button.dxbl-button-warning {
  color: #222;
  background-color: #ffc107;
  border-color: #ffc107;
}
.dxbl-button-root .dxbl-button.dxbl-button-warning:hover {
  color: #222;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-warning, .dxbl-button-root .dxbl-button.dxbl-button-warning:focus {
  color: #222;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(222, 169, 11, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-warning, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-warning, .dxbl-button-root .dxbl-button.dxbl-button-warning:active, .dxbl-button-root .dxbl-button.dxbl-button-warning.active, .show > .dxbl-button-root .dxbl-button.dxbl-button-warning.dropdown-toggle {
  color: #222;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-warning:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-warning:focus, .dxbl-button-root .dxbl-button.dxbl-button-warning:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-warning.active:focus, .show > .dxbl-button-root .dxbl-button.dxbl-button-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(222, 169, 11, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-warning:disabled, .dxbl-button-root .dxbl-button.dxbl-button-warning.disabled {
  color: #222;
  background-color: #ffc107;
  border-color: #ffc107;
}
.dxbl-button-root .dxbl-button.dxbl-button-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.dxbl-button-root .dxbl-button.dxbl-button-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-danger, .dxbl-button-root .dxbl-button.dxbl-button-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-danger, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-danger, .dxbl-button-root .dxbl-button.dxbl-button-danger:active, .dxbl-button-root .dxbl-button.dxbl-button-danger.active, .show > .dxbl-button-root .dxbl-button.dxbl-button-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-danger:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-danger:focus, .dxbl-button-root .dxbl-button.dxbl-button-danger:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-danger.active:focus, .show > .dxbl-button-root .dxbl-button.dxbl-button-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-danger:disabled, .dxbl-button-root .dxbl-button.dxbl-button-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.dxbl-button-root .dxbl-button.dxbl-button-light {
  color: #222;
  background-color: #ececec;
  border-color: #ececec;
}
.dxbl-button-root .dxbl-button.dxbl-button-light:hover {
  color: #222;
  background-color: #efefef;
  border-color: #eeeeee;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-light, .dxbl-button-root .dxbl-button.dxbl-button-light:focus {
  color: #222;
  background-color: #efefef;
  border-color: #eeeeee;
  box-shadow: 0 0 0 0.25rem rgba(206, 206, 206, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-light, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-light, .dxbl-button-root .dxbl-button.dxbl-button-light:active, .dxbl-button-root .dxbl-button.dxbl-button-light.active, .show > .dxbl-button-root .dxbl-button.dxbl-button-light.dropdown-toggle {
  color: #222;
  background-color: #f0f0f0;
  border-color: #eeeeee;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-light:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-light:focus, .dxbl-button-root .dxbl-button.dxbl-button-light:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-light.active:focus, .show > .dxbl-button-root .dxbl-button.dxbl-button-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(206, 206, 206, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-light:disabled, .dxbl-button-root .dxbl-button.dxbl-button-light.disabled {
  color: #222;
  background-color: #ececec;
  border-color: #ececec;
}
.dxbl-button-root .dxbl-button.dxbl-button-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.dxbl-button-root .dxbl-button.dxbl-button-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-dark, .dxbl-button-root .dxbl-button.dxbl-button-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-dark, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-dark, .dxbl-button-root .dxbl-button.dxbl-button-dark:active, .dxbl-button-root .dxbl-button.dxbl-button-dark.active, .show > .dxbl-button-root .dxbl-button.dxbl-button-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-dark:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-dark:focus, .dxbl-button-root .dxbl-button.dxbl-button-dark:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-dark.active:focus, .show > .dxbl-button-root .dxbl-button.dxbl-button-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-dark:disabled, .dxbl-button-root .dxbl-button.dxbl-button-dark.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-primary {
  color: #5f368d;
  border-color: #5f368d;
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-primary:hover {
  color: #fff;
  background-color: #5f368d;
  border-color: #5f368d;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-outline-primary, .dxbl-button-root .dxbl-button.dxbl-button-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-outline-primary, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-outline-primary, .dxbl-button-root .dxbl-button.dxbl-button-outline-primary:active, .dxbl-button-root .dxbl-button.dxbl-button-outline-primary.active, .dxbl-button-root .dxbl-button.dxbl-button-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #5f368d;
  border-color: #5f368d;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-outline-primary:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-outline-primary:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-primary:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-primary.active:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-primary:disabled, .dxbl-button-root .dxbl-button.dxbl-button-outline-primary.disabled {
  color: #5f368d;
  background-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-secondary {
  color: #888;
  border-color: #888;
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-secondary:hover {
  color: #fff;
  background-color: #888;
  border-color: #888;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-outline-secondary, .dxbl-button-root .dxbl-button.dxbl-button-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(136, 136, 136, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-outline-secondary, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-outline-secondary, .dxbl-button-root .dxbl-button.dxbl-button-outline-secondary:active, .dxbl-button-root .dxbl-button.dxbl-button-outline-secondary.active, .dxbl-button-root .dxbl-button.dxbl-button-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #888;
  border-color: #888;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-outline-secondary:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-outline-secondary:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-secondary:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-secondary.active:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(136, 136, 136, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-secondary:disabled, .dxbl-button-root .dxbl-button.dxbl-button-outline-secondary.disabled {
  color: #888;
  background-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-outline-success, .dxbl-button-root .dxbl-button.dxbl-button-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-outline-success, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-outline-success, .dxbl-button-root .dxbl-button.dxbl-button-outline-success:active, .dxbl-button-root .dxbl-button.dxbl-button-outline-success.active, .dxbl-button-root .dxbl-button.dxbl-button-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-outline-success:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-outline-success:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-success:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-success.active:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-success:disabled, .dxbl-button-root .dxbl-button.dxbl-button-outline-success.disabled {
  color: #28a745;
  background-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-info {
  color: #00a9e6;
  border-color: #00a9e6;
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-info:hover {
  color: #fff;
  background-color: #00a9e6;
  border-color: #00a9e6;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-outline-info, .dxbl-button-root .dxbl-button.dxbl-button-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 169, 230, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-outline-info, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-outline-info, .dxbl-button-root .dxbl-button.dxbl-button-outline-info:active, .dxbl-button-root .dxbl-button.dxbl-button-outline-info.active, .dxbl-button-root .dxbl-button.dxbl-button-outline-info.dropdown-toggle.show {
  color: #fff;
  background-color: #00a9e6;
  border-color: #00a9e6;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-outline-info:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-outline-info:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-info:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-info.active:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 169, 230, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-info:disabled, .dxbl-button-root .dxbl-button.dxbl-button-outline-info.disabled {
  color: #00a9e6;
  background-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-warning:hover {
  color: #222;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-outline-warning, .dxbl-button-root .dxbl-button.dxbl-button-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-outline-warning, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-outline-warning, .dxbl-button-root .dxbl-button.dxbl-button-outline-warning:active, .dxbl-button-root .dxbl-button.dxbl-button-outline-warning.active, .dxbl-button-root .dxbl-button.dxbl-button-outline-warning.dropdown-toggle.show {
  color: #222;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-outline-warning:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-outline-warning:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-warning:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-warning.active:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-warning:disabled, .dxbl-button-root .dxbl-button.dxbl-button-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-outline-danger, .dxbl-button-root .dxbl-button.dxbl-button-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-outline-danger, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-outline-danger, .dxbl-button-root .dxbl-button.dxbl-button-outline-danger:active, .dxbl-button-root .dxbl-button.dxbl-button-outline-danger.active, .dxbl-button-root .dxbl-button.dxbl-button-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-outline-danger:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-outline-danger:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-danger:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-danger.active:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-danger:disabled, .dxbl-button-root .dxbl-button.dxbl-button-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-light {
  color: #222;
  border-color: #222;
  border-color: #ececec;
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-light:hover {
  color: #222;
  background-color: #ececec;
  border-color: #ececec;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-outline-light, .dxbl-button-root .dxbl-button.dxbl-button-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(34, 34, 34, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-outline-light, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-outline-light, .dxbl-button-root .dxbl-button.dxbl-button-outline-light:active, .dxbl-button-root .dxbl-button.dxbl-button-outline-light.active, .dxbl-button-root .dxbl-button.dxbl-button-outline-light.dropdown-toggle.show {
  color: #222;
  background-color: #ececec;
  border-color: #ececec;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-outline-light:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-outline-light:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-light:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-light.active:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(34, 34, 34, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-light:disabled, .dxbl-button-root .dxbl-button.dxbl-button-outline-light.disabled {
  color: #222;
  background-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-light:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-light.focus {
  box-shadow: 0 0 0 0.25rem rgba(206, 206, 206, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-light:not(:disabled):not(.disabled):active:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-light:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.25rem rgba(206, 206, 206, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-dark {
  color: #212529;
  border-color: #212529;
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-outline-dark, .dxbl-button-root .dxbl-button.dxbl-button-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-outline-dark, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-outline-dark, .dxbl-button-root .dxbl-button.dxbl-button-outline-dark:active, .dxbl-button-root .dxbl-button.dxbl-button-outline-dark.active, .dxbl-button-root .dxbl-button.dxbl-button-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-outline-dark:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-outline-dark:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-dark:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-dark.active:focus, .dxbl-button-root .dxbl-button.dxbl-button-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-outline-dark:disabled, .dxbl-button-root .dxbl-button.dxbl-button-outline-dark.disabled {
  color: #212529;
  background-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-text-primary {
  color: #5f368d;
  border-color: #5f368d;
  border-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-text-primary:hover {
  color: #fff;
  background-color: #5f368d;
  border-color: #5f368d;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-text-primary, .dxbl-button-root .dxbl-button.dxbl-button-text-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-text-primary, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-text-primary, .dxbl-button-root .dxbl-button.dxbl-button-text-primary:active, .dxbl-button-root .dxbl-button.dxbl-button-text-primary.active, .dxbl-button-root .dxbl-button.dxbl-button-text-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #5f368d;
  border-color: #5f368d;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-text-primary:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-text-primary:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-primary:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-primary.active:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-text-primary:disabled, .dxbl-button-root .dxbl-button.dxbl-button-text-primary.disabled {
  color: #5f368d;
  background-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-text-secondary {
  color: #888;
  border-color: #888;
  border-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-text-secondary:hover {
  color: #fff;
  background-color: #888;
  border-color: #888;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-text-secondary, .dxbl-button-root .dxbl-button.dxbl-button-text-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(136, 136, 136, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-text-secondary, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-text-secondary, .dxbl-button-root .dxbl-button.dxbl-button-text-secondary:active, .dxbl-button-root .dxbl-button.dxbl-button-text-secondary.active, .dxbl-button-root .dxbl-button.dxbl-button-text-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #888;
  border-color: #888;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-text-secondary:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-text-secondary:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-secondary:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-secondary.active:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(136, 136, 136, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-text-secondary:disabled, .dxbl-button-root .dxbl-button.dxbl-button-text-secondary.disabled {
  color: #888;
  background-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-text-success {
  color: #28a745;
  border-color: #28a745;
  border-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-text-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-text-success, .dxbl-button-root .dxbl-button.dxbl-button-text-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-text-success, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-text-success, .dxbl-button-root .dxbl-button.dxbl-button-text-success:active, .dxbl-button-root .dxbl-button.dxbl-button-text-success.active, .dxbl-button-root .dxbl-button.dxbl-button-text-success.dropdown-toggle.show {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-text-success:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-text-success:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-success:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-success.active:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-text-success:disabled, .dxbl-button-root .dxbl-button.dxbl-button-text-success.disabled {
  color: #28a745;
  background-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-text-info {
  color: #00a9e6;
  border-color: #00a9e6;
  border-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-text-info:hover {
  color: #fff;
  background-color: #00a9e6;
  border-color: #00a9e6;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-text-info, .dxbl-button-root .dxbl-button.dxbl-button-text-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 169, 230, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-text-info, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-text-info, .dxbl-button-root .dxbl-button.dxbl-button-text-info:active, .dxbl-button-root .dxbl-button.dxbl-button-text-info.active, .dxbl-button-root .dxbl-button.dxbl-button-text-info.dropdown-toggle.show {
  color: #fff;
  background-color: #00a9e6;
  border-color: #00a9e6;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-text-info:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-text-info:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-info:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-info.active:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 169, 230, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-text-info:disabled, .dxbl-button-root .dxbl-button.dxbl-button-text-info.disabled {
  color: #00a9e6;
  background-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-text-warning {
  color: #ffc107;
  border-color: #ffc107;
  border-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-text-warning:hover {
  color: #222;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-text-warning, .dxbl-button-root .dxbl-button.dxbl-button-text-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-text-warning, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-text-warning, .dxbl-button-root .dxbl-button.dxbl-button-text-warning:active, .dxbl-button-root .dxbl-button.dxbl-button-text-warning.active, .dxbl-button-root .dxbl-button.dxbl-button-text-warning.dropdown-toggle.show {
  color: #222;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-text-warning:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-text-warning:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-warning:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-warning.active:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-text-warning:disabled, .dxbl-button-root .dxbl-button.dxbl-button-text-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-text-danger {
  color: #dc3545;
  border-color: #dc3545;
  border-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-text-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-text-danger, .dxbl-button-root .dxbl-button.dxbl-button-text-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-text-danger, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-text-danger, .dxbl-button-root .dxbl-button.dxbl-button-text-danger:active, .dxbl-button-root .dxbl-button.dxbl-button-text-danger.active, .dxbl-button-root .dxbl-button.dxbl-button-text-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-text-danger:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-text-danger:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-danger:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-danger.active:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-text-danger:disabled, .dxbl-button-root .dxbl-button.dxbl-button-text-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-text-light {
  color: #222;
  border-color: #222;
  border-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-text-light:hover {
  color: #222;
  background-color: #ececec;
  border-color: #ececec;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-text-light, .dxbl-button-root .dxbl-button.dxbl-button-text-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(34, 34, 34, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-text-light, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-text-light, .dxbl-button-root .dxbl-button.dxbl-button-text-light:active, .dxbl-button-root .dxbl-button.dxbl-button-text-light.active, .dxbl-button-root .dxbl-button.dxbl-button-text-light.dropdown-toggle.show {
  color: #222;
  background-color: #ececec;
  border-color: #ececec;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-text-light:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-text-light:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-light:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-light.active:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(34, 34, 34, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-text-light:disabled, .dxbl-button-root .dxbl-button.dxbl-button-text-light.disabled {
  color: #222;
  background-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-text-light:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-light.focus {
  box-shadow: 0 0 0 0.25rem rgba(206, 206, 206, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-text-light:not(:disabled):not(.disabled):active:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-light:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.25rem rgba(206, 206, 206, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-text-dark {
  color: #212529;
  border-color: #212529;
  border-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-text-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:focus + .dxbl-button-root .dxbl-button.dxbl-button-text-dark, .dxbl-button-root .dxbl-button.dxbl-button-text-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-text-dark, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-text-dark, .dxbl-button-root .dxbl-button.dxbl-button-text-dark:active, .dxbl-button-root .dxbl-button.dxbl-button-text-dark.active, .dxbl-button-root .dxbl-button.dxbl-button-text-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:checked + .dxbl-button-root .dxbl-button.dxbl-button-text-dark:focus, .btn-check:active + .dxbl-button-root .dxbl-button.dxbl-button-text-dark:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-dark:active:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-dark.active:focus, .dxbl-button-root .dxbl-button.dxbl-button-text-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.dxbl-button-root .dxbl-button.dxbl-button-text-dark:disabled, .dxbl-button-root .dxbl-button.dxbl-button-text-dark.disabled {
  color: #212529;
  background-color: transparent;
}

.dx-d-none {
  display: none !important;
}

.dx-prevent-selection {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}

.sr-only,
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.dxbs-border-top-0 {
  border-top-width: 0 !important;
}

dxbl-ui-handlers-bridge {
  display: none;
}

.dxbs-preventsel {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

/*iOS click does not work - workaround*/
.dxbs-clickable,
.dxbs-clickable label {
  cursor: pointer;
  pointer-events: auto;
}

.dxbs-non-clickable {
  pointer-events: none;
}

/* icons */
.dx-back,
.dx-more,
.dxsc-time-icon,
.dxsc-save-icon,
.dxsc-edit-icon,
.dxsc-delete-icon,
.dxsc-close-icon,
.dxsc-description-icon,
.dxsc-recurrence-icon,
.dxsc-exception-icon,
.dxsc-location-icon,
.dxsc-resource-icon,
.dxsc-status-icon,
.dxsc-label-icon {
  width: 18px;
  height: 18px;
}

.dx-expand {
  width: 18px;
  height: 18px;
}

.dx-apt-recurrence,
.dx-apt-exception {
  width: 14px;
  height: 14px;
}

.dx-settings {
  width: 16px;
  height: 16px;
}

.dx-search {
  width: 24px;
  height: 24px;
}

input.dx-blazor-hide-ms-button::-ms-reveal {
  display: none;
}

/*legend*/
.dx-blazor-widget.dx-loading .dx-chart-legend {
  visibility: hidden;
}

.dx-chart-legend-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dx-chart-legend-item .custom-control-label {
  justify-content: space-between;
  cursor: pointer;
}

.dx-legend-horizontal.dx-chart-legend .dx-chart-legend-item:not(:last-child) {
  margin-right: 1.25rem;
}

.dx-legend-vertical.dx-chart-legend .dx-chart-legend-item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.dx-chart-legend-icon {
  height: 1rem;
  width: 1rem;
  flex: 0 0 1rem;
  margin-right: 0.25rem;
  position: relative;
  overflow: hidden;
}

.dx-chart-legend-icon ~ * {
  flex: 1 1 auto;
}

.dx-chart-legend-hoverable .dx-chart-legend-item:hover .dx-chart-def-icon:after,
.dx-chart-legend-hoverable .dx-chart-legend-item.active .dx-chart-def-icon:after,
.dx-chart-legend-hoverable .dx-chart-legend-item:focus .dx-chart-def-icon:after {
  position: absolute;
  content: " ";
  border-top: 0.5rem double white;
  border-bottom: 0.5rem double white;
  height: 1.1rem;
  width: 1.5rem;
  opacity: 0.5;
  transform: rotate(-45deg) translate(-0.25rem, -0.25rem);
}

.dx-chart-legend-text {
  white-space: nowrap;
}

.dx-chart-legend .dx-chart-title {
  padding: 0 0 0.5em 0;
  font-size: 1.2em;
  display: block;
}

.dx-chart-with-legend-container {
  align-self: stretch;
  flex: 1 1 auto;
  background-color: inherit;
}

.dx-legend-outside {
  display: flex;
  align-items: center;
}

.dx-legend-inside {
  position: relative;
}

.dx-chart-legend {
  padding: 0.5em 1em;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  align-items: flex-start;
}
.dx-chart-legend.dx-center {
  align-items: center;
}
.dx-chart-legend.dx-legend-horizontal.dx-right {
  align-items: flex-end;
}
.dx-chart-legend .dx-chart-legend-items {
  display: flex;
  flex-flow: row wrap;
}

.dx-legend-outside .dx-chart-legend.dx-legend-horizontal.dx-top, .dx-legend-outside .dx-chart-legend.dx-legend-vertical.dx-center.dx-top {
  margin-bottom: 0.5rem;
}
.dx-legend-outside .dx-chart-legend.dx-legend-vertical.dx-left {
  margin-right: 0.5rem;
}
.dx-legend-outside .dx-chart-legend.dx-legend-vertical.dx-right {
  margin-left: 0.5rem;
}
.dx-legend-outside .dx-chart-legend.dx-legend-vertical.dx-center.dx-bottom, .dx-legend-outside .dx-chart-legend.dx-legend-horizontal.dx-bottom {
  margin-top: 0.5rem;
}
.dx-legend-outside .dx-chart-legend.dx-legend-horizontal.dx-left, .dx-legend-outside .dx-chart-legend.dx-legend-vertical.dx-top:not(.dx-center) {
  align-self: flex-start;
}
.dx-legend-outside .dx-chart-legend.dx-legend-horizontal.dx-right, .dx-legend-outside .dx-chart-legend.dx-legend-vertical.dx-bottom:not(.dx-center) {
  align-self: flex-end;
}

.dx-legend-inside .dx-chart-legend {
  position: absolute;
}
.dx-legend-inside .dx-chart-legend.dx-top {
  top: 0;
}
.dx-legend-inside .dx-chart-legend.dx-bottom {
  bottom: 0;
}
.dx-legend-inside .dx-chart-legend.dx-left {
  left: 0;
}
.dx-legend-inside .dx-chart-legend.dx-right {
  right: 0;
}
.dx-legend-inside .dx-chart-legend.dx-center {
  left: 50%;
  transform: translate(-50%, 0);
}

.dx-legend-horizontal .dx-chart-legend-items {
  flex-direction: row;
}

.dx-legend-vertical .dx-chart-legend-items {
  flex-direction: column;
}

@keyframes dx-rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.dx-chart-title {
  font-size: 1.5em;
  width: 100%;
  text-align: center;
}

.dx-chart-subtitle {
  font-size: 0.8em;
  font-weight: 300;
}

.dx-blazor-widget .dx-chart {
  overflow: hidden;
  flex: 1 1 auto;
  padding: 0 0.75rem;
}

.dx-blazor-widget {
  background-color: unset;
  position: relative;
}

.dx-blazor-widget.dx-loading .dx-chart-with-legend-container {
  position: relative;
}

.dx-blazor-widget .dx-chart-with-legend-container {
  height: 100%;
  width: 100%;
}

.dx-blazor-widget.dx-loading .dx-chart > svg {
  visibility: hidden;
  opacity: 0;
}

.dx-blazor-widget.dx-loading .dx-chart::before {
  content: " ";
  display: block;
  position: absolute;
  top: 1em;
  left: 1em;
  width: calc(100% - 2em);
  height: calc(100% - 2em);
  border-radius: 0.5em;
  opacity: 0.025;
}

.dx-blazor-widget.dx-loading .dx-chart::after {
  content: " ";
  display: block;
  position: absolute;
  top: calc(50% - 2em);
  left: calc(50% - 2em);
  width: 4em;
  height: 4em;
  border-radius: 50%;
  border: 0.2em solid currentColor;
  border-bottom-color: transparent;
  animation: dx-rotating 1s linear infinite;
}

.dx-chart-tooltip.show {
  visibility: visible;
}

.dx-chart-tooltip {
  visibility: hidden;
  display: block;
  z-index: 1;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -100%);
  position: absolute;
  will-change: filter;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.53));
  -webkit-filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.53));
}
.dx-chart-tooltip > .form-control {
  width: initial;
  height: initial;
  padding: 0;
  white-space: nowrap;
  border: 1px solid #d1d1d1;
  border-radius: 3px;
}
.dx-chart-tooltip > .form-control + .dx-tooltip-pointer:after {
  width: 0.6275rem;
  height: 0.6275rem;
  transform-origin: center;
  transform: translate(-50%, -50%) rotate(45deg);
  content: " ";
  background-color: inherit;
  position: absolute;
  border-bottom: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
}
.dx-chart-tooltip > .form-control + .dx-tooltip-pointer {
  top: calc(100% - 0.1px);
  position: absolute;
}
.dx-chart-tooltip > .form-control .dx-tooltip-content.default {
  padding: 0.75rem;
}

/** Blazor PivotGrid **/
.dx-pivotgrid.card {
  user-select: none;
  overflow: auto;
  border-right-width: 0;
  border-bottom-width: 0;
}

.dx-pivotgrid table {
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
}

.dx-pivotgrid table,
.dx-pivotgrid thead,
.dx-pivotgrid tbody,
.dx-pivotgrid tr,
.dx-pivotgrid .table tr td,
.dx-pivotgrid .table tr th,
.dx-pivotgrid .dxpg-container {
  border-color: inherit;
}

.dx-pivotgrid .table td,
.dx-pivotgrid .table th {
  border-style: none;
  border-width: 0;
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.dx-pivotgrid .table thead th.dxpg-area-data {
  border-style: none;
}

.dx-pivotgrid .table thead th.dxpg-area-row {
  vertical-align: bottom;
}

.dx-pivotgrid .table .dxpg-data {
  text-align: right;
}

.dx-pivotgrid .table tr th,
.dx-pivotgrid .table tr td,
.dxpg-total-text {
  white-space: nowrap;
  font-weight: inherit;
  padding: 0.5em 1em;
  vertical-align: top;
  height: 100%;
  position: relative;
}

th[rowspan] .dxpg-total-text {
  position: absolute;
}

.dx-pivotgrid .table tr th.dxpg-area-column {
  padding-left: 0;
}

.dx-pivotgrid .table tr th.dxpg-area-row,
.dx-pivotgrid .table tr th.dxpg-area-data,
.dx-pivotgrid .table tfoot tr td {
  padding-left: 0.5em;
}

.dx-pivotgrid .table tr .dxpg-npc {
  padding: 0;
}

.dx-pivotgrid .dxpg-header .btn {
  color: inherit;
  text-align: left;
  text-decoration: none;
  width: 100%;
  pointer-events: auto;
  display: inline-block;
}

.dx-pivotgrid .dxpg-field-cell .btn {
  color: inherit;
  border-radius: 0;
  border-color: inherit;
  border-style: solid;
  font-weight: 600;
  display: inline-block;
}

.dx-pivotgrid .dxpg-field-cell .btn .dx-image {
  margin-left: 0.5rem;
}

.dx-pivotgrid .dxpg-header .btn .dx-image {
  margin-right: 0.5rem;
}

.dx-pivotgrid .dxpg-npc .btn-link:focus, .dx-pivotgrid .dxpg-npc .btn-link.focus, .dx-pivotgrid .dxpg-npc .btn-link.active, .dx-pivotgrid .dxpg-npc .btn-link:active,
.dx-pivotgrid .dxpg-header .btn-link:focus,
.dx-pivotgrid .dxpg-header .btn-link.focus,
.dx-pivotgrid .dxpg-header .btn-link.active,
.dx-pivotgrid .dxpg-header .btn-link:active,
.dx-pivotgrid .dxpg-field-cell .btn-link:focus,
.dx-pivotgrid .dxpg-field-cell .btn-link.focus,
.dx-pivotgrid .dxpg-field-cell .btn-link.active,
.dx-pivotgrid .dxpg-field-cell .btn-link:active {
  box-shadow: none !important;
}

.dx-pivotgrid .dxpg-field-cell .btn .image {
  margin-left: 0.5rem;
}

.dx-pivotgrid .dxpg-field-cell .btn.inactive {
  cursor: default;
  color: inherit;
  text-decoration: none;
}

.dxpg-total-text {
  width: 100%;
  height: 100%;
  display: inline-block;
}

.dxbs-scheduler {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.dxbs-scheduler .dx-btn.btn-link:focus, .dxbs-scheduler .dx-btn.btn-link.focus, .dxbs-scheduler .dx-btn.btn-link.active, .dxbs-scheduler .dx-btn.btn-link:active {
  box-shadow: none !important;
}
.dxbs-scheduler .dxbs-sc-scroll-container > .dxbs-sc-vertical-view > tbody > tr:nth-last-of-type(2) > .dxbs-sc-hour-cell, .dxbs-scheduler .dxbs-sc-scroll-container > .dxbs-sc-vertical-view > tbody > tr:last-child > td {
  border-bottom-width: 0;
}
.dxbs-scheduler .dxbs-sc-timecells-container > .dxbs-sc-horizontal-view > tbody > tr:last-child > td,
.dxbs-scheduler .dxbs-sc-scroll-container > .dxbs-sc-vertical-view > tbody > tr > .dxbs-sc-gr-sep-vertical {
  border-bottom-width: 0;
}

.dxbs-scheduler .dx-spinner-container {
  display: none;
  z-index: 10000;
  position: absolute;
  width: 100%;
  height: 100%;
}

.dxbs-scheduler.dx-loading .dx-spinner-container {
  display: flex;
}

.dxbs-scheduler.dx-loading .dxsc-main-container {
  opacity: 0.3;
}

.dxbs-scheduler .dxsc-main-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

/* toolbar */
.dxbs-scheduler .dxbs-sc-tb-wrapper {
  flex: 0 0 auto;
  padding: 0.75rem;
}

.dxbs-scheduler .dxbs-sc-toolbar.dxbs-toolbar .dxbs-toolbar-btn > .dx-image,
.dxbs-scheduler .dxbs-sc-toolbar.dxbs-toolbar .dxbs-dropdown-toggle > .dx-image,
.dxbs-scheduler .dxbs-sc-toolbar.dxbs-toolbar .dropdown-item > .dx-image,
.dxbs-toolbar-dropdown.dxbs-sc-toolbar-dropdown .dxbs-toolbar-btn > .dx-image,
.dxbs-toolbar-dropdown.dxbs-sc-toolbar-dropdown .dxbs-dropdown-toggle > .dx-image,
.dxbs-toolbar-dropdown.dxbs-sc-toolbar-dropdown .dropdown-item > .dx-image {
  width: 14px;
  height: 14px;
}
.dxbs-scheduler .dxbs-sc-toolbar.dxbs-toolbar .btn-toolbar > .btn-group:not(.dxbs-ta-ag):nth-child(4),
.dxbs-toolbar-dropdown.dxbs-sc-toolbar-dropdown .btn-toolbar > .btn-group:not(.dxbs-ta-ag):nth-child(4) {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.dxbs-scheduler .dxbs-sc-toolbar.dxbs-toolbar .btn-toolbar .dxbs-ta-title,
.dxbs-toolbar-dropdown.dxbs-sc-toolbar-dropdown .btn-toolbar .dxbs-ta-title {
  display: none;
}
.dxbs-scheduler .dxbs-sc-toolbar.dxbs-toolbar .modal-body > .dxbs-tb-dropdown-item-list,
.dxbs-toolbar-dropdown.dxbs-sc-toolbar-dropdown .modal-body > .dxbs-tb-dropdown-item-list {
  margin: 0;
}
.dxbs-scheduler .dxbs-sc-toolbar.dxbs-toolbar .modal-body > .dxbs-tb-dropdown-item-list .btn-secondary.btn:not(:focus),
.dxbs-toolbar-dropdown.dxbs-sc-toolbar-dropdown .modal-body > .dxbs-tb-dropdown-item-list .btn-secondary.btn:not(:focus) {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2) !important;
}
.dxbs-scheduler .dxbs-sc-toolbar.dxbs-toolbar .btn-group.dxbs-toolbar-group:nth-last-of-type(2),
.dxbs-toolbar-dropdown.dxbs-sc-toolbar-dropdown .btn-group.dxbs-toolbar-group:nth-last-of-type(2) {
  margin-right: 0 !important;
}
.dxbs-scheduler .dxbs-sc-toolbar.dxbs-toolbar .dxbs-toolbar-modal .modal-body,
.dxbs-toolbar-dropdown.dxbs-sc-toolbar-dropdown .dxbs-toolbar-modal .modal-body {
  margin: 0 !important;
}
.dxbs-scheduler .dxbs-sc-toolbar.dxbs-toolbar .dxbs-toolbar-modal .modal-body .dxbs-resource-navigator .dxbs-listbox,
.dxbs-toolbar-dropdown.dxbs-sc-toolbar-dropdown .dxbs-toolbar-modal .modal-body .dxbs-resource-navigator .dxbs-listbox {
  width: auto;
  max-height: 60vh;
}

.dxbs-scheduler .dxbs-sc-has-rn.dxbs-toolbar .btn-toolbar > .btn-group:not(.dxbs-ta-ag):nth-child(6) {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.dxbs-date-navigator .dxbs-calendar {
  display: flex;
  width: 100%;
}

@media (max-width: 375px) {
  .dxbs-date-navigator .calendar-view .card-body {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-height: 320px) {
  .dxbs-date-navigator .calendar-view .card-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .dxbs-toolbar-dialog .dxbs-resource-navigator .dxbs-listbox {
    max-height: 140px !important;
  }
}
.dxbs-date-navigator .dxe-apt-labels-container {
  font-size: 0px;
}

.dxbs-date-navigator .dxe-apt-labels-container > span {
  display: inline-block;
}

.dxbs-date-navigator .dxe-apt-labels-container > span:nth-last-child(n+2) {
  margin-right: 2px;
}

.dxbs-date-navigator .dxe-apt-labels-container {
  height: 5px;
  margin-bottom: -5px;
}

.dxbs-date-navigator .dxe-apt-labels-container > span {
  width: 5px;
  height: 5px;
  margin-top: -2px;
}

.dxbs-date-navigator .dxbs-more-labels-container > .dxbs-h-line {
  width: 5px;
  top: 2px;
  left: 0px;
  border-width: 1px 0 0 0;
  border-style: solid;
}

.dxbs-date-navigator .dxbs-more-labels-container > .dxbs-v-line {
  height: 5px;
  left: 2px;
  border-width: 0 1px 0 0;
  border-style: solid;
}

.dxbs-date-navigator .dxbs-more-labels-container {
  position: relative;
  opacity: 0.7;
}

.dxbs-date-navigator .dxbs-more-labels-container > span.dxbs-h-line,
.dxbs-date-navigator .dxbs-more-labels-container > span.dxbs-v-line {
  position: absolute;
  display: inline-block;
}

.dxbs-resource-navigator .dxbs-textbox-w-icon {
  position: relative;
  display: flex;
  overflow-x: hidden;
}
.dxbs-resource-navigator .dxbs-textbox-w-icon .dxbs-input-group-prepend,
.dxbs-resource-navigator .dxbs-textbox-w-icon .dxbs-input-group-append {
  width: 0;
  padding: 0;
  border: 0;
}
.dxbs-resource-navigator .dxbs-textbox-w-icon .dxbs-editor-input-container {
  flex: 1 1 auto;
}

.dxbs-resource-navigator .dxbs-textbox-w-icon input {
  padding-left: 2.75rem;
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
  padding-right: 0.5rem;
  border-radius: 0;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  background-color: rgba(34, 34, 34, 0.03);
  height: auto !important;
}

.dxbs-resource-navigator .dxbs-textbox-w-icon .dxbs-textbox-icon {
  position: absolute;
  top: 2px;
  left: -2px;
  padding-top: 0.375rem;
  opacity: 0.5;
}

.dxbs-resource-navigator .dxbs-checkbox .dxbs-checkbox-label-right {
  padding-left: 0.5rem;
}

.dxbs-resource-navigator .dxbs-listbox {
  width: 320px;
  max-height: 300px;
  border-radius: 0;
  border-left-width: 0;
  border-right-width: 0;
}
.dxbs-resource-navigator .dxbs-listbox .dxbs-listbox-item {
  padding-left: 1rem !important;
}

.dxbs-resource-navigator .dxbs-rn-footer {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.dxbs-resource-navigator .dxbs-rn-toolbar {
  display: flex;
  justify-content: flex-end;
}
.dxbs-resource-navigator .dxbs-rn-toolbar > button:first-child {
  width: 56px;
}
.dxbs-resource-navigator .dxbs-rn-toolbar > button:last-child {
  margin-left: 8px;
}

/* View */
.dxbs-scheduler .dxbs-sc-horizontal-view,
.dxbs-scheduler .dxbs-sc-vertical-view {
  table-layout: fixed;
}

.dxbs-scheduler .dxbs-sc-horizontal-view > tbody > tr > td:first-child,
.dxbs-scheduler .dxbs-sc-vertical-view > tbody > tr > td:first-child {
  border-left: 0px;
}

.dxbs-scheduler .dxbs-sc-vertical-view > tbody > tr > td {
  border-top: 0px;
}

.dxbs-scheduler .table.dxbs-sc-horizontal-view,
.dxbs-scheduler .table.dxbs-sc-vertical-view {
  border-width: 0;
}

.dxbs-scheduler .table tr > td.dxsc-date-hr:nth-last-child(-2n+2),
.dxbs-scheduler .table tr > td.dxsc-horizontal-rh:nth-last-child(-2n+2),
.dxbs-scheduler .dxbs-sc-vertical-view > tbody > tr > td:last-child,
.dxbs-scheduler .dxbs-sc-horizontal-view > tbody > tr > td:last-child {
  border-right: 0px;
}

.dxbs-scheduler .dxbs-sc-all-day-area:before {
  content: ".";
  visibility: hidden;
}

.dxbs-scheduler .dxsc-v-resource-header,
.dxbs-scheduler .dxbs-sc-resource-hr {
  overflow: hidden;
}

.dxbs-scheduler .dxsc-v-resource-header .dxsc-resource-header-content > span {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
}

.dxbs-scheduler .dxsc-v-resource-header .dxsc-resource-header-content {
  display: inline-flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.dxbs-scheduler .dxbs-sc-date-hr-today > div:first-child {
  position: absolute;
  height: 4px;
  width: 100%;
  top: -1px;
  left: 0;
}

.dxbs-scheduler .dxbs-sc-date-hr-today {
  position: relative;
}

.dxbs-scheduler .dxbs-sc-gr-sep-vertical {
  width: 16px;
  padding: 0;
}

.dxbs-scheduler .dxbs-sc-minute-cell:before {
  content: ".";
  visibility: hidden;
}

.dxbs-scheduler .table tr > td.dxbs-sc-minute-cell {
  border-left: 0px;
  width: 3.5em;
  text-align: center;
  vertical-align: middle;
  font-size: 0.75em;
  white-space: nowrap;
}

.dxbs-scheduler .table tr > td.dxbs-sc-hour-cell {
  border-right: 0px;
  width: 3.5em;
  text-align: right;
  white-space: nowrap;
}

.dxbs-scheduler .dxsc-hour-time-scale.table tr > td.dxbs-sc-hour-cell,
.dxbs-scheduler .dxbs-sc-time-rulers-caption {
  width: 6.13em;
}

.dxbs-scheduler .dxbs-sc-hour-min-text-compact {
  display: none;
}

.dxbs-scheduler .dxbs-sc-date-hr-wrapper {
  display: flex;
  align-items: baseline;
  overflow: hidden;
}
.dxbs-scheduler .dxbs-sc-date-hr-month,
.dxbs-scheduler .dxbs-sc-date-hr-day {
  font-size: 1.25rem;
  margin-right: 0.2225rem;
}
.dxbs-scheduler .dxbs-sc-dayview .dxbs-sc-date-hr {
  padding-left: 0.4725rem;
}

@media (max-width: 560px) {
  .dxbs-scheduler .table tr > td.dxbs-sc-hour-cell {
    width: 1.85em;
  }
  .dxbs-scheduler .dxbs-sc-time-rulers-caption {
    width: 4.48em;
  }
  .dxbs-scheduler .table tr > td.dxbs-sc-hour-cell, .dxbs-scheduler .table tr > td.dxbs-sc-minute-cell, .dxbs-scheduler .table tr > td.dxbs-sc-time-rulers-caption {
    font-size: 0.685rem;
    padding: 0.125rem;
  }
  .dxbs-scheduler .table.dxsc-hour-time-scale tr > td.dxbs-sc-hour-cell,
.dxbs-scheduler .table tr > td.dxbs-sc-time-rulers-caption {
    width: 3em;
  }
  .dxbs-scheduler .table:not(.dxsc-hour-time-scale) tr > td.dxbs-sc-hour-cell, .dxbs-scheduler .table:not(.dxsc-hour-time-scale) tr > td.dxbs-sc-minute-cell {
    width: 1.5em;
  }
  .dxbs-scheduler .dxbs-sc-hour-min-text-compact {
    display: initial;
  }
  .dxbs-scheduler .dxbs-sc-hour-text,
.dxbs-scheduler .dxbs-sc-minute-cell span {
    display: none;
  }
  .dxbs-scheduler .dxbs-sc-dayview .dxbs-sc-date-hr {
    padding-left: 0;
    padding-right: 0;
  }
  .dxbs-scheduler .dxbs-sc-date-hr-wrapper {
    flex-direction: column-reverse;
    align-items: center;
    font-size: 0.7rem;
  }
  .dxbs-scheduler .dxbs-sc-date-hr-wrapper .dxbs-sc-date-hr-month {
    display: none;
  }
  .dxbs-scheduler .dxbs-sc-date-hr-wrapper .dxbs-sc-date-hr-month,
.dxbs-scheduler .dxbs-sc-date-hr-wrapper .dxbs-sc-date-hr-day {
    margin-right: 0;
  }
  .dxbs-scheduler .dxbs-sc-date-hr-wrapper .dxbs-sc-date-hr-day {
    font-size: 0.9rem;
  }
}
.dxbs-scheduler .dxbs-sc-hour-boundary {
  border-style: solid;
}

.dxbs-scheduler .dxbs-sc-time-marker-line {
  width: 100%;
  border-style: dashed;
  border-width: 1px 0 0 0;
}

.dxbs-scheduler .dxbs-sc-time-marker {
  display: flex;
  align-items: center;
  position: absolute;
}

.dxbs-scheduler .dxbs-sc-time-indicator {
  position: absolute;
}

.dxbs-scheduler .dxbs-sc-time-indicator-line {
  width: 100%;
  height: 2px;
}

.dxbs-scheduler .dxbs-sc-time-marker-image {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  border: 2px solid transparent;
  z-index: 100;
  flex-shrink: 0;
}

/* Timeline */
.dxbs-scheduler .dxbs-sc-month,
.dxbs-scheduler .dxbs-sc-timeline {
  height: 100%;
  overflow: hidden;
}

.dxbs-scheduler .dxbs-sc-empty-cell table,
.dxbs-scheduler .dxbs-sc-v-scroll-spacer table,
.dxbs-scheduler .dxbs-sc-horizontal-view,
.dxbs-scheduler .dxbs-sc-resource-headers,
.dxbs-scheduler .dxbs-sc-month .dxbs-sc-headers,
.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-h-scroll-spacer table,
.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-timescale {
  table-layout: fixed;
  margin-bottom: 0;
}

.dxbs-scheduler .dxbs-sc-month .dxbs-sc-headers,
.dxbs-scheduler .dxbs-sc-month .dxbs-sc-horizontal-view,
.dxbs-scheduler .dxbs-sc-month .dxbs-sc-resource-headers,
.dxbs-scheduler .dxbs-sc-timescale,
.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-horizontal-view,
.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-resource-headers {
  border-style: none;
}

.dxbs-scheduler .dxbs-sc-timescale:not(:last-child) > tbody > tr > td,
.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-horizontal-view > tbody > tr > td,
.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-resource-headers > tbody > tr > td {
  border-left-style: none;
  border-bottom-style: none;
}

.dxbs-scheduler .dxbs-sc-month .dxbs-sc-headers > tbody > tr > td:last-child,
.dxbs-scheduler .dxbs-sc-timescale > tbody > tr > td:last-child,
.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-horizontal-view > tbody > tr > td:last-child {
  border-right-style: none;
}

.dxbs-scheduler .dxbs-sc-month .dxbs-sc-headers > tbody > tr > td,
.dxbs-scheduler .dxbs-sc-timescale:last-child > tbody > tr > td {
  border-left-style: none;
}

.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-horizontal-view > tbody > tr:first-child > td {
  padding: 0;
  border-style: none;
}

.dxbs-scheduler .dxbs-sc-month .dxbs-sc-horizontal-view > tbody > tr:first-child > td,
.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-horizontal-view > tbody > tr:nth-child(2) > td {
  border-top-style: none;
}

.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-horizontal-view > tbody > tr:not(:first-child) > td,
.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-resource-headers > tbody > tr > td {
  height: 150px;
}

.dxbs-scheduler .dxbs-sc-resources-container,
.dxbs-scheduler .dxbs-sc-headers-container,
.dxbs-scheduler .dxbs-sc-headers-scroll-area {
  overflow: hidden;
}

.dxbs-scheduler .dxbs-sc-timecells-container {
  position: relative;
}

.dxbs-scheduler .dxbs-sc-timecells-container,
.dxbs-scheduler .dxbs-sc-resources-container {
  height: 100%;
}

.dxbs-scheduler .dxbs-sc-timeline,
.dxbs-scheduler .dxbs-sc-month,
.dxbs-scheduler .dxbs-sc-resources-scroll-area {
  display: flex;
  flex-direction: column;
}

.dxbs-scheduler .dxbs-sc-month > div:last-child,
.dxbs-scheduler .dxbs-sc-timeline > div:last-child {
  height: 100%;
  overflow: hidden;
}

.dxbs-scheduler .dxbs-sc-month > div,
.dxbs-scheduler .dxbs-sc-timeline > div,
.dxbs-scheduler .dxbs-sc-headers-scroll-area {
  display: flex;
}

.dxbs-scheduler .dxbs-sc-h-scroll-spacer,
.dxbs-scheduler .dxbs-sc-v-scroll-spacer {
  flex: 0 0 auto;
  display: none;
}

.dxbs-scheduler .dxbs-sc-h-scroll-spacer table {
  height: 100%;
  border-style: none;
}

.dxbs-scheduler .dxbs-sc-h-scroll-spacer table td {
  padding: 0;
  border-left-style: none;
  border-bottom-style: none;
}

.dxbs-scheduler .dxbs-sc-v-scroll-spacer table {
  height: 100%;
  border-right-style: none;
}

.dxbs-scheduler .dxbs-sc-v-scroll-spacer table td {
  padding: 0;
  border-right-style: none;
}

.dxbs-scheduler .dxbs-sc-empty-cell,
.dxbs-scheduler .dxbs-sc-resources-scroll-area {
  flex: 0 0 auto;
  width: 29px;
}

.dxbs-scheduler .dxbs-sc-empty-cell table {
  height: 100%;
  border-left-style: none;
  border-right-style: none;
  border-bottom-style: none;
}

.dxbs-scheduler .dxbs-sc-empty-cell td {
  padding: 0;
  border-left-style: none;
  border-bottom-style: none;
}

.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-date-hr > div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-headers-container > div {
  position: relative;
}
.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-headers-container > div .dxbs-sc-time-marker-image {
  bottom: -6px;
}
.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-timecells-container > div {
  position: relative;
  height: 100%;
}
.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-timecells-container > div .dxbs-sc-time-marker-image {
  top: -6px;
}
.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-time-marker-image,
.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-time-marker-line {
  display: none;
  position: absolute;
}
.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-time-marker-line {
  width: unset;
  top: 0;
  bottom: 0;
  border-width: 0 1px 0 0;
}

.dxbs-scheduler .dxbs-sc-month .dxbs-sc-time-cell {
  height: 150px;
}

.dxbs-scheduler .dxsc-left-corner {
  width: 33px;
}

.dxbs-scheduler .dxsc-rh-text-wrapper {
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dxbs-scheduler .dxsc-rh-rotated-caption span {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Appointment */
.dxbs-scheduler .dxbs-sc-apt {
  position: absolute;
  font-size: 0.9em;
  z-index: 8;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.dxbs-scheduler .dxbs-sc-apt > div {
  position: relative;
  height: 100%;
}

.dxbs-scheduler .dxbs-sc-apt.dxbs-sc-vertical-apt {
  padding: 2px 2px 1px 2px;
}

.dxbs-scheduler .dxbs-sc-apt.dxbs-sc-horizontal-apt {
  padding: 4px 4px 0px 4px;
}

.dxbs-scheduler .dxbs-sc-apt-container {
  height: 100%;
}

.dxbs-scheduler .dxbs-sc-apt .dxsc-apt-content {
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
}

.dxbs-scheduler .dxbs-sc-apt .dxsc-apt-bg {
  opacity: 0.7;
  padding: 0;
  margin: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.dxbs-scheduler .dxbs-sc-apt.dxbs-apt-selected .dxsc-apt-bg {
  opacity: 1;
}

.dxbs-scheduler .dxbs-apt-hovered .dxsc-apt-bg {
  opacity: 0.7;
}

.dxbs-scheduler .dxbs-sc-apt.dxbs-apt-disable {
  opacity: 0.5;
}

.dxbs-scheduler .dxbs-sc-apt .dxsc-top-handle,
.dxbs-scheduler .dxbs-sc-apt .dxsc-bottom-handle {
  cursor: n-resize;
  height: 5px;
  width: 100%;
  position: absolute;
  z-index: 10;
}

.dxbs-scheduler .dxbs-sc-apt .dxsc-left-handle,
.dxbs-scheduler .dxbs-sc-apt .dxsc-right-handle {
  cursor: e-resize;
  padding: 3px;
  z-index: 10;
  position: absolute;
  height: 100%;
}

.dxbs-scheduler .dxbs-sc-apt .dxsc-left-handle {
  margin-right: -6px;
}

.dxbs-scheduler .dxbs-sc-apt .dxsc-right-handle {
  margin-left: -6px;
  right: 0;
}

.dxbs-scheduler .dxbs-sc-apt .dxsc-bottom-handle {
  bottom: 0;
}

.dxbs-scheduler .dxbs-sc-apt .dxsc-apt-status-container {
  background-color: var(--bs-white);
  box-sizing: border-box;
  border-width: 0;
}

.dxbs-scheduler .dxbs-sc-apt .dxsc-apt-status {
  width: 0.4em;
  height: 100%;
}

.dxbs-scheduler .dxbs-sc-apt .dxsc-apt-status-container:after {
  content: " ";
  position: absolute;
  left: -1px;
  top: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  box-sizing: border-box;
  background-color: transparent;
  border: 1px solid white;
  opacity: 0.4;
  border-radius: inherit;
}

.dxbs-scheduler .dxbs-sc-apt .dxbs-sc-apt-status-container .dxsc-apt-status {
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
}

.dxbs-scheduler .dxbs-sc-apt .dxsc-content-wrapper {
  padding: 0.2em 0.5em;
  width: 100%;
}

.dxbs-scheduler .dxbs-sc-apt-time {
  font-size: 0.7rem;
  font-weight: 300;
  opacity: 0.8;
}

.dxbs-scheduler .dxbs-sc-apt-description {
  font-size: 0.8em;
  opacity: 0.65;
}

.dxbs-scheduler .dxbs-sc-apt-images-container {
  align-self: flex-end;
  padding-right: 0.4em;
  padding-bottom: 0.3em;
}

.dxbs-scheduler .dxbs-sc-apt-compact .dxsc-content-wrapper * {
  display: none;
}

.dxbs-scheduler .dxbs-sc-horizontal-apt .dxbs-sc-apt-compact .dxsc-content-wrapper {
  min-height: 2em;
}

/*Appointment edit dialog*/
.dxbs-appointment-edit-dialog .dxbs-apt-start-time-edit,
.dxbs-appointment-edit-dialog .dxbs-apt-end-time-edit {
  width: 100% !important;
}

.dxbs-appointment-edit-dialog .modal-header {
  align-items: center;
  padding: 0;
}

.dxbs-appointment-edit-dialog .modal-header > a {
  align-self: center;
}

.dxsc-edit-tooltip .popover-body,
.dxbs-appointment-edit-dialog .modal-body {
  padding-left: 0;
  padding-right: 0;
}

.dxsc-edit-tooltip .dx-icon,
.dxbs-appointment-edit-dialog .dx-icon {
  margin-right: 0.75rem;
}

.dxsc-edit-tooltip .dx-icon:not(.dxsc-description-icon),
.dxbs-appointment-edit-dialog .dx-icon:not(.dxsc-description-icon) {
  margin-bottom: 0.38rem;
}

.dxsc-edit-tooltip .dx-icon.dxsc-description-icon,
.dxbs-appointment-edit-dialog .dx-icon.dxsc-description-icon {
  margin-top: 16px;
}

.dxsc-edit-tooltip .dxsc-no-icon,
.dxbs-appointment-edit-dialog .dxsc-no-icon {
  padding-left: 1.89rem;
}

.dxsc-edit-tooltip .dxsc-label-item,
.dxbs-appointment-edit-dialog .dxsc-label-item {
  margin-right: 1.05rem;
}

.dxsc-label-item {
  padding: 4px;
  margin: 0 0.5rem 0.7rem 0.3rem;
  border-radius: 50%;
}

.dx-color-items .dropdown-item {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.dxbs-scheduler-color-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
}
.dxbs-scheduler-color-item .color {
  margin: 0 0.5rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.dxbs-recurrence-model .dxbs-recurrence-name .dxbs-edit-btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.dxbs-recurrence-model .dxbs-recurrence-settings {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dxbs-appointment-edit-dialog.dxbs-popup-scrollable .modal-content,
.dxbs-appointment-edit-dialog.dxbs-popup-scrollable .modal-content .modal-body {
  overflow-y: inherit;
}

.dxsc-edit-tooltip .dxbs-fl > .row {
  align-items: flex-end;
}

.dxbs-appointment-edit-dialog .dxbs-fl > .row {
  align-items: flex-end;
}

.dxbs-appointment-edit-dialog .modal-body .dxbs-fl {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.dxbs-appointment-edit-dialog .modal-body {
  padding-top: 0;
}

.dxbs-appointment-edit-dialog .modal-body .modal-header {
  margin-bottom: 1rem;
}

.dxbs-scheduler .dxsc-week-button-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 33px;
}

.dxbs-scheduler .dxsc-week-button {
  width: 35px;
  height: 35px;
  margin-bottom: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-flow: wrap-reverse;
}

.dx-hint {
  width: max-content;
}

.dxbs-appointment-edit-dialog .modal-header,
.dx-hint .popover-header {
  font-size: 1.4em;
}

.dxbs-appointment-edit-dialog .modal-header > div {
  padding: 0.6rem 1.2rem;
}

.dx-hint .popover-header {
  padding: 0.6rem 1.1rem;
}

.dx-hint .dxsc-subject {
  font-size: 1.1em;
  font-weight: 600;
}

.dxbs-scheduler.dx-hint.dxsc-edit-tooltip {
  min-width: 276px;
  max-width: 450px;
}

@media (max-width: 450px) {
  .dxsc-edit-tooltip {
    min-width: 100vw;
    width: auto;
  }
}
.dxsc-edit-tooltip.modal {
  display: flex;
  justify-content: center;
}
.dxsc-edit-tooltip.modal:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.3;
  z-index: -1;
}
.dxsc-edit-tooltip.modal .modal-dialog {
  align-self: flex-end;
  margin: 0;
  bottom: -50%;
  max-width: initial;
  transition: bottom 0.5s ease;
  -webkit-transition: bottom 0.5s ease;
  -moz-transition: bottom 0.5s ease;
  -khtml-transition: bottom 0.5s ease;
  -o-transition: bottom 0.5s ease;
}
.dxsc-edit-tooltip.modal.show .modal-dialog {
  bottom: 0;
}
.dxsc-edit-tooltip.modal .modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dxbs-appointment-edit-dialog.dxbs-scheduler.dxbs-sc-mobile {
  margin: 0;
  height: 100%;
  width: initial;
  max-width: initial;
}

.dxbs-appointment-edit-dialog .dxsc-caption,
.dxsc-edit-tooltip .dxsc-caption {
  font-size: 0.75rem;
  font-weight: 700;
}

.dxbs-appointment-edit-dialog .dxsc-group-icon,
.dxsc-edit-tooltip .dxsc-group-icon {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

@media (max-width: 991px) {
  .dxbs-appointment-edit-dialog .form-group > div > div:not(.dxsc-no-icon):not(.dxsc-group-icon),
.dxbs-appointment-edit-dialog .form-group > div > .dxbs-dropdown-edit:not(.dxsc-no-icon):not(.dxsc-group-icon),
.dxsc-edit-tooltip .form-group > div > div:not(.dxsc-no-icon):not(.dxsc-group-icon),
.dxsc-edit-tooltip .form-group > div > .dxbs-dropdown-edit:not(.dxsc-no-icon):not(.dxsc-group-icon) {
    padding-left: 0.75rem;
  }
}
.dxbs-appointment-edit-dialog .dxbs-fl .form-group.row > .dxbs-fl-ctrl,
.dxsc-edit-tooltip .dxbs-fl .form-group.row > .dxbs-fl-ctrl {
  display: flex;
}

.dxsc-tooltip.popover {
  max-width: 370px;
}

.dxsc-tooltip .dxsc-label-item {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.dxbs-fl div:not(.dxsc-description-item) > .form-group .dxsc-group-icon > div:first-child {
  display: flex;
  align-items: flex-end;
}

/* Labels & Statuses color */
.dxbs-scheduler .dx-blue-color,
.dxbs-scheduler-blue-color {
  background-color: var(--bs-blue);
}

.dxbs-scheduler .dx-indigo-color,
.dxbs-scheduler-indigo-color {
  background-color: var(--bs-indigo);
}

.dxbs-scheduler .dx-purple-color,
.dxbs-scheduler-purple-color {
  background-color: var(--bs-purple);
}

.dxbs-scheduler .dx-pink-color,
.dxbs-scheduler-pink-color {
  background-color: var(--bs-pink);
}

.dxbs-scheduler .dx-red-color,
.dxbs-scheduler-red-color {
  background-color: var(--bs-red);
}

.dxbs-scheduler .dx-orange-color,
.dxbs-scheduler-orange-color {
  background-color: var(--bs-orange);
}

.dxbs-scheduler .dx-yellow-color,
.dxbs-scheduler-yellow-color {
  background-color: var(--bs-yellow);
}

.dxbs-scheduler .dx-green-color,
.dxbs-scheduler-green-color {
  background-color: var(--bs-green);
}

.dxbs-scheduler .dx-teal-color,
.dxbs-scheduler-teal-color {
  background-color: var(--bs-teal);
}

.dxbs-scheduler .dx-cyan-color,
.dxbs-scheduler-cyan-color {
  background-color: var(--bs-cyan);
}

.dx-hint {
  z-index: 2147483647;
  display: none;
  /*position: absolute;*/
  box-shadow: 0px 2px 24px 0 rgba(0, 0, 0, 0.24);
}

.dx-hint .dxh-callout {
  border-width: 8px;
}

.dx-hint .dxh-callout:after {
  content: "";
  border-width: 7px;
}

.dx-hint.bs-popover-bottom .popover-header::before, .dx-hint.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom: 0px;
}

.dx-hint.bs-popover-bottom .arrow::before, .dx-hint.bs-popover-auto[data-popper-placement^=bottom] .arrow::before {
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.dx-hint.bs-popover-top .arrow::before, .dx-hint.bs-popover-auto[data-popper-placement^=top] .arrow::before {
  border-top-color: rgba(0, 0, 0, 0.1);
}

.dx-hint.bs-popover-left .arrow::before {
  border-left-color: rgba(0, 0, 0, 0.1);
}

.dx-hint.bs-popover-right .arrow::before {
  border-right-color: rgba(0, 0, 0, 0.1);
}

.dx-hint.bs-popover-top .arrow, .dx-hint.bs-popover-auto[data-popper-placement^=top] .arrow,
.dx-hint.bs-popover-bottom .arrow,
.dx-hint.bs-popover-auto[data-popper-placement^=bottom] .arrow {
  left: calc(50% - 0.5rem);
}

.dx-hint.bs-popover-left .arrow,
.dx-hint.bs-popover-right .arrow {
  top: calc(50% - 0.5rem);
}

.dx-hint.bs-popover-center .arrow {
  display: none;
}

.dx-hint .dxh-callout,
.dx-hint .dxh-callout:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

/*top*/
.dx-hint.dxh-top .dxh-callout {
  bottom: -8px;
  left: 50%;
  margin-left: -8px;
  border-bottom-width: 0;
}

.dx-hint.dxh-top .dxh-callout:after {
  bottom: 1px;
  margin-left: -7px;
  content: " ";
  border-bottom-width: 0;
}

.dx-hint.dxh-top .dxh-callout {
  border-top-color: #cecece;
}

.dx-hint.dxh-top .dxh-inner-callout,
.dx-hint.dxh-top .dxh-callout:after {
  border-top-color: #fff;
}

/*right*/
.dx-hint.dxh-right .dxh-callout {
  top: 50%;
  left: -8px;
  margin-top: -8px;
  border-left-width: 0;
}

.dx-hint.dxh-right .dxh-callout:after {
  bottom: -7px;
  left: 1px;
  content: " ";
  border-left-width: 0;
}

.dx-hint.dxh-right .dxh-callout {
  border-right-color: #cecece;
}

.dx-hint.dxh-right .dxh-callout:after {
  border-right-color: #fff;
}

/*bottom*/
.dx-hint.dxh-bottom .dxh-callout {
  top: -8px;
  left: 50%;
  margin-left: -8px;
  border-top-width: 0;
}

.dx-hint.dxh-bottom .dxh-callout:after {
  top: 1px;
  margin-left: -7px;
  content: " ";
  border-top-width: 0;
}

.dx-hint.dxh-bottom .dxh-callout {
  border-bottom-color: #cecece;
}

.dx-hint.dxh-bottom .dxh-callout:after {
  border-bottom-color: #fff;
}

/*left*/
.dx-hint.dxh-left .dxh-callout {
  top: 50%;
  right: -8px;
  margin-top: -8px;
  border-right-width: 0;
}

.dx-hint.dxh-left .dxh-callout:after {
  right: 1px;
  bottom: -7px;
  content: " ";
  border-right-width: 0;
}

.dx-hint.dxh-left .dxh-callout {
  border-left-color: #cecece;
}

.dx-hint.dxh-left .dxh-callout:after {
  border-left-color: #fff;
}

.dxbs-listbox .list-group-item-action:not(.disabled):not(.dxbs-listbox-active):not(.active):hover:before, .dxbs-tree ul.nav > .nav-item > .nav-link:not(.disabled):not(.active):hover:before, .btn.dxbs-cmd-btn:focus:before, .btn.dxbs-cmd-btn:hover:before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.1;
}

.btn.dxbs-cmd-btn:focus:hover:before {
  opacity: 0.2;
}

.roller-container {
  display: inline-flex;
  flex-flow: column nowrap;
  flex: 1 1;
  position: relative;
}

.roller:hover,
.roller:focus {
  color: inherit;
}

.roller-container > span:first-child {
  text-align: center;
  padding: 1rem 0;
  padding-bottom: 0.25rem;
  opacity: 0.5;
}

.roller {
  outline: 0;
  text-decoration: none !important;
  overflow: hidden;
  position: relative;
}
.roller-cancel-btn.btn.dxbs-cmd-btn, .roller-apply-btn.btn.dxbs-cmd-btn {
  padding: 0.3rem;
  color: inherit;
}
.roller:not(:focus):not(:hover) {
  color: inherit;
}

.roller-title {
  font-family: monospace, monospace;
  font-size: 0.8125rem;
  font-weight: 600;
}

.roller-item,
.roller-after {
  text-align: center;
  padding: 0.4rem 0.75rem;
  line-height: 1.5rem;
  will-change: transform, opacity;
  white-space: nowrap;
}

.roller-item,
.roller-item.expander {
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.roller-item.expander {
  height: 0;
  overflow: hidden;
  visibility: hidden;
  padding: 0 1rem;
}

.roller-after {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  transform: translateY(-50%);
  border-color: currentColor;
  cursor: default;
}
.roller-after .roller-item, .roller-after:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  font-weight: 600;
}
.roller-after:after {
  content: " ";
  border-top: 1px solid currentColor;
  border-bottom: 1px solid currentColor;
  opacity: 0.15;
  z-index: 2;
}
.roller-invalid-date .roller-after .roller-item {
  opacity: 0.35;
}

.roller:focus .roller-after::after {
  opacity: 0.3;
}

.rollers-container {
  display: flex;
  flex-flow: row nowrap;
  padding: 0.5rem 0.5rem 2rem;
  min-width: 310px;
}
.dxbs-date-edit .rollers-container.dxbs-time-roller {
  min-width: unset;
  width: 100%;
}
.rollers-container:empty {
  height: calc(3.75rem + 1.5em + 180px);
}

.roller-footer.btn-toolbar.card-footer {
  padding: 0 0.5rem;
  align-items: center;
  justify-content: center;
}
.roller-footer.btn-toolbar.card-footer .dx-btn {
  margin: 0.5em 0;
}

.dxbs-icon-close, .dxbs-icon-apply, .dxbs-caret-left, .dxbs-caret-right, .dxbs-icon-expanded, .dxbs-icon-collapsed, .dxbs-caret-bottom {
  width: inherit;
  height: inherit;
  display: inline-flex;
  flex: 0 0 100%;
  position: relative;
}

.dxbs-caret-left,
.dxbs-caret-right {
  height: 10px;
  width: 10px;
}

.dxbs-icon-close:before,
.dxbs-icon-close:after {
  content: " ";
  position: absolute;
  left: -7%;
  top: 50%;
  width: calc(141% - 2px);
  height: 0;
  border-top: 2px solid currentColor;
}

.dxbs-icon-close:before {
  transform: rotate(45deg) translate(-1px, -0.5px);
}

.dxbs-icon-close:after {
  transform: rotate(-45deg) translate(0.5px, -1px);
}

.dxbs-icon-apply:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 12.5%;
  width: 100%;
  height: 50%;
  border-bottom: 2px solid currentColor;
  border-left: 2px solid currentColor;
  transform: rotate(-45deg) translate(-1px, -1px);
}

.dxbs-caret-left::before,
.dxbs-caret-right:before,
.dxbs-caret-bottom::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 80%;
  height: 80%;
  border-top: 2px solid currentColor;
  border-left: 2px solid currentColor;
  transform: translate(2px, 2px);
}

.dxbs-caret-left {
  transform: rotate(-45deg);
}

.dxbs-caret-right {
  transform: rotate(135deg);
}

.dxbs-caret-bottom {
  transform: rotate(-135deg);
}

.dxbs-icon-expanded::before,
.dxbs-icon-collapsed::before {
  content: " ";
  position: absolute;
  left: 50%;
  top: 50%;
  border-top: 0.3rem solid transparent;
  border-left: 0.3rem solid currentColor;
  border-bottom: 0.3rem solid transparent;
  transform: translate(-30%, -50%);
}

.dxbs-icon-expanded::before {
  transform: rotate(90deg) translate(-85%, 25%);
}

.dxbs-icon-expanded,
.dxbs-icon-collapsed {
  overflow: hidden;
}

.dxbs-listbox .list-group-item-action:not(.disabled):not(.dxbs-listbox-active):not(.active):hover:before, .dxbs-tree ul.nav > .nav-item > .nav-link:not(.disabled):not(.active):hover:before, .btn.dxbs-cmd-btn:focus:before, .btn.dxbs-cmd-btn:hover:before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.1;
}

.btn.dxbs-cmd-btn:focus:hover:before {
  opacity: 0.2;
}

.btn.dxbs-cmd-btn {
  width: 1rem;
  height: 1rem;
  padding: 0.5rem;
  flex: 0 0 1rem;
  font-size: 0;
  display: inline-flex;
  box-sizing: content-box;
  color: inherit !important;
  box-shadow: unset;
}
.btn.dxbs-cmd-btn:not(:focus) {
  color: inherit;
}

.btn.dxbs-cmd-btn:hover:before {
  border-radius: 50%;
}
.btn.dxbs-cmd-btn:focus:before {
  border-radius: 50%;
}
@media (hover: none) {
  .dxbs-cmd-btn {
    color: inherit !important;
  }
  .dxbs-cmd-btn:before {
    opacity: 0 !important;
  }
}
.dxbs-cmd-btn.btn-link:focus, .dxbs-cmd-btn.btn-link.focus, .dxbs-cmd-btn.btn-link.active, .dxbs-cmd-btn.btn-link:active {
  box-shadow: none !important;
}

.dxbs-calendar .btn.dxbs-cmd-btn {
  width: 0.7em;
  height: 0.7em;
  padding: 0.5rem;
  flex: 0 0 0.7em;
}

.dxbs-listbox .list-group-item-action:not(.disabled):not(.dxbs-listbox-active):not(.active):hover:before, .dxbs-tree ul.nav > .nav-item > .nav-link:not(.disabled):not(.active):hover:before, .btn.dxbs-cmd-btn:hover:before, .btn.dxbs-cmd-btn:focus:before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.1;
}

.btn.dxbs-cmd-btn:focus:hover:before {
  opacity: 0.2;
}

.dxbs-tree > .dxbl-navigation-filter {
  margin-bottom: 1rem;
  width: 100%;
}
.dxbs-tree > .dxbl-navigation-filter .dxbs-filter-icon {
  opacity: 0.5;
}
.dxbs-tree .dxbl-filter-content {
  background-color: rgba(95, 54, 141, 0.75);
  color: #fff;
}

.dxbs-tree ul.flex-column {
  /*from _old*/
  flex-wrap: nowrap;
}

.dxbs-tree > ul.nav ul.nav {
  margin: 0.2em 0 0.2em 2em;
}

.dxbs-tree ul.nav > .nav-item > .nav-link {
  display: flex;
  align-items: center;
  padding: 0.125rem 0.625rem 0.125rem 0.125rem;
}
.dxbs-tree ul.nav > .nav-item > .nav-link.no-expand-button {
  padding: 0.325rem 0.625rem;
}
.dxbs-tree ul.nav > .nav-item > .nav-link .image + .treeview-item-text-container {
  padding-left: 0.7em;
}
.dxbs-tree ul.nav > .nav-item > .nav-link:not(.disabled):not(.active) {
  cursor: pointer;
  color: inherit;
  position: relative;
  transform: scale(1);
}
.dxbs-tree ul.nav > .nav-item > .nav-link > span + .badge {
  /*from _old*/
  margin-left: 0.3em;
}
.dxbs-tree ul.nav > .nav-item > .nav-link > span.image:not(:last-child), .dxbs-tree ul.nav > .nav-item > .nav-link > .badge > span.image:not(:last-child), .dxbs-tree ul.nav > .nav-item > .nav-link > span.node-lp:not(:last-child) {
  /*from _old*/
  padding: 0.4em;
  margin: -0.4em;
  margin-right: 0;
}
.dxbs-tree ul.nav > .nav-item > .nav-link > span:not(.image):not(.badge):not(.expand-btn) {
  flex: 1 1 auto;
}

.dxbs-tree ul.nav > .nav-item > .nav-link:not(.disabled):not(.active):hover:before {
  border-radius: inherit;
}

.dxbs-tree .expand-btn.btn {
  display: inline-flex;
  box-sizing: content-box;
  padding: 0.5rem;
  font-size: inherit;
}
.dxbs-tree .expand-btn.btn.btn-link {
  text-decoration: none;
}
.dxbs-tree .expand-btn.btn:not(:last-child) {
  margin-right: 0.25rem;
}
.dxbs-tree .expand-btn.btn, .dxbs-tree .expand-btn.btn > span {
  min-height: 0.7em;
  min-width: 0.7em;
}
.dxbs-tree .expand-btn.btn, .dxbs-tree .expand-btn.btn:hover, .dxbs-tree .expand-btn.btn:focus, .dxbs-tree .expand-btn.btn:active {
  box-shadow: none !important;
  font-weight: inherit;
  color: inherit;
}

.dxbs-progress.disabled > .progress > .progress-bar {
  animation: none;
  -webkit-animation: none;
}

@keyframes dynamic_opacity {
  from {
    opacity: 0.35;
  }
  to {
    opacity: 0.5;
  }
}
.dxbs-gridview tr {
  transition: opacity linear 0.35s;
}
.dxbs-gridview .dxbs-row-loading {
  opacity: 0.15;
}
.dxbs-gridview .dxbs-row-loading td::before {
  content: "|";
  display: inline-flex;
  min-width: 4rem;
  width: 100%;
  background-color: currentColor;
  opacity: 0;
  border-radius: 10rem;
  animation: 1s linear 0.35s infinite alternate both running dynamic_opacity;
}
.dxbs-gridview .dxbs-row-loading .dx-sk-bool::before {
  max-width: 4rem;
}
.dxbs-gridview .dxbs-row-loading .dx-sk-date::before {
  max-width: 8rem;
}
.dxbs-gridview .dxbs-row-loading .dx-sk-number::before {
  min-width: 3rem;
}
.dxbs-gridview .dxbs-row-loading .dx-sk-str.dx-sk-1::before {
  max-width: 50%;
}
.dxbs-gridview .dxbs-row-loading .dx-sk-number.dx-sk-1::before {
  max-width: 3rem;
}
.dxbs-gridview .dxbs-row-loading .dx-sk-str.dx-sk-2::before {
  max-width: 60%;
}
.dxbs-gridview .dxbs-row-loading .dx-sk-number.dx-sk-2::before {
  max-width: 4rem;
}
.dxbs-gridview .dxbs-row-loading .dx-sk-str.dx-sk-3::before {
  max-width: 70%;
}
.dxbs-gridview .dxbs-row-loading .dx-sk-number.dx-sk-3::before {
  max-width: 5rem;
}
.dxbs-gridview .dxbs-row-loading .dx-sk-str.dx-sk-4::before {
  max-width: 80%;
}
.dxbs-gridview .dxbs-row-loading .dx-sk-number.dx-sk-4::before {
  max-width: 6rem;
}
.dxbs-gridview .dxbs-row-loading .dx-sk-str.dx-sk-5::before {
  max-width: 90%;
}
.dxbs-gridview .dxbs-row-loading .dx-sk-number.dx-sk-5::before {
  max-width: 7rem;
}

.dxbs-gridview .dxbs-page-size-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.dxbs-gridview .dxbs-page-size-selector .dxbs-dropdown-edit {
  margin-left: 4px;
  display: inline-block;
}
.dxbs-gridview .dxbs-page-size-selector .col-form-label {
  margin-right: 4px;
}
.dxbs-gridview .dxbs-page-size-selector > .dxbs-combobox > .input-group > div > span.form-control {
  padding-right: 3.4em;
}
.dxbs-gridview .btn-toggle.dxbs-cmd-btn {
  width: unset;
  height: unset;
  padding: 0;
  position: relative;
  z-index: 1;
  color: inherit;
  top: -2px;
  border: 0;
}
.dxbs-gridview .dxbs-toggle-cell {
  vertical-align: top !important;
  /*review and drop all !importants*/
}
.dxbs-gridview .dxbs-data-row > td.table-active {
  background-image: repeating-linear-gradient(0, var(--selection-bg), var(--selection-bg));
}
.dxbs-gridview .dxbs-data-row > .dxbs-toggle-cell {
  vertical-align: middle !important;
  /*review and drop all !importants*/
  border-bottom: 0;
}
.dxbs-gridview .dxbs-toggle-cell, .dxbs-gridview .dxbs-grid-group-cell-offset {
  border-right: 0;
}
.dxbs-gridview .dxbs-toggle-cell + td, .dxbs-gridview .dxbs-grid-group-cell-offset + .dxbs-grid-group-cell-offset {
  border-left: 0;
}
.dxbs-gridview .dxbs-grid-cols-togglebtn {
  min-width: 1.5rem;
  width: var(--button-w, 1.5rem);
}
.dxbs-gridview .dxbs-grid-group-span-row {
  visibility: hidden;
}
.dxbs-gridview .dxbs-grid-group-span-row > td {
  padding: 0;
  border: 0;
}
.dxbs-gridview > .card > .card-body {
  padding: 0.5em;
}
.dxbs-gridview .dxbs-grid-cell-loading::before {
  content: "|";
  visibility: hidden;
  display: inline-flex;
  width: 0;
  font-size: inherit;
  overflow: hidden;
}
.dxbs-gridview .dxbs-select-cell > .custom-control:only-child {
  display: inline-block;
  margin-right: -0.5rem;
}
.dxbs-gridview .dxbs-select-cell > .custom-control:only-child .custom-control-label {
  vertical-align: top;
  cursor: pointer;
}
.dxbs-gridview .dxbs-select-cell > .custom-control:only-child input:disabled ~ .custom-control-label {
  cursor: not-allowed;
}
.dxbs-gridview .dxbs-fixed > thead > tr > th.dxbs-select-cell, .dxbs-gridview .dxbs-fixed > tbody > tr > td.dxbs-select-cell {
  text-overflow: clip;
}
.dxbs-gridview .dxbs-fixed > thead > tr > th.dxbs-select-cell > .custom-control:only-child > .custom-control-input, .dxbs-gridview .dxbs-fixed > tbody > tr > td.dxbs-select-cell > .custom-control:only-child > .custom-control-input {
  left: 0;
  top: 0;
  width: 0;
  height: 0;
}
.dxbs-gridview th.dxbs-grid-checkbox-col {
  text-align: center;
}
.dxbs-gridview > .card .dxbs-table > tbody > tr > td.dx-grid-summary-cell:not(:first-child) {
  border-left-style: hidden;
}
.dxbs-gridview > .card .dxbs-table > tbody > tr > td.dx-grid-summary-cell:not(:last-child) {
  border-right-style: hidden;
}
.dxbs-gridview > .card .dxbs-table > tbody > tr > td.dx-grid-summary-cell {
  font-weight: 700;
  position: relative;
  vertical-align: top;
  /*max-width: 1px;*/
}
.dxbs-gridview > .card .dxbs-table > tbody > tr > td.dx-grid-summary-cell div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
.dxbs-gridview td.dx-grid-summary-cell:before {
  content: "";
  background-color: var(--bs-gray);
  position: absolute;
  opacity: 0.035;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.dxbs-gridview > .card > .dxbs-table > thead > tr:last-child > td, .dxbs-gridview > .card > .dxbs-table > thead > tr:last-child > th, .dxbs-gridview > .card > * > .dxbs-table > thead > tr:last-child > td, .dxbs-gridview > .card > * > .dxbs-table > thead > tr:last-child > th {
  border-bottom-width: 1px;
}
.dxbs-gridview > .card > .table-bordered, .dxbs-gridview > .card > div:not(.dxgvHSDC):not(.dxgvCSD) > .table-bordered {
  border-left-width: 0;
  border-right-width: 0;
}
.dxbs-gridview > .card > .table-bordered > thead, .dxbs-gridview > .card > div:not(.dxgvHSDC):not(.dxgvCSD) > .table-bordered > thead {
  border-color: inherit;
}
.dxbs-gridview > .card > .table-bordered > thead > tr > th:last-child, .dxbs-gridview > .card > .table-bordered > thead > tr > td:last-child, .dxbs-gridview > .card > .table-bordered > tbody > tr > th:last-child, .dxbs-gridview > .card > .table-bordered > tbody > tr > td:last-child, .dxbs-gridview > .card > .table-bordered > tfoot > tr > th:last-child, .dxbs-gridview > .card > .table-bordered > tfoot > tr > td:last-child, .dxbs-gridview > .card > div:not(.dxgvHSDC):not(.dxgvCSD) > .table-bordered > thead > tr > th:last-child, .dxbs-gridview > .card > div:not(.dxgvHSDC):not(.dxgvCSD) > .table-bordered > thead > tr > td:last-child, .dxbs-gridview > .card > div:not(.dxgvHSDC):not(.dxgvCSD) > .table-bordered > tbody > tr > th:last-child, .dxbs-gridview > .card > div:not(.dxgvHSDC):not(.dxgvCSD) > .table-bordered > tbody > tr > td:last-child, .dxbs-gridview > .card > div:not(.dxgvHSDC):not(.dxgvCSD) > .table-bordered > tfoot > tr > th:last-child, .dxbs-gridview > .card > div:not(.dxgvHSDC):not(.dxgvCSD) > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}
.dxbs-gridview > .card > .table-bordered:last-child, .dxbs-gridview > .card > .table-bordered:last-child > tbody:last-child > tr:last-child > td, .dxbs-gridview > .card > div:not(.dxgvHSDC):not(.dxgvCSD) > .table-bordered:last-child, .dxbs-gridview > .card > div:not(.dxgvHSDC):not(.dxgvCSD) > .table-bordered:last-child > tbody:last-child > tr:last-child > td {
  border-bottom-width: 0;
}
.dxbs-gridview > .card > .table-bordered:first-child, .dxbs-gridview > .card > .table-bordered:first-child > thead > tr:first-child > th, .dxbs-gridview > .card > .table-bordered:first-child > tbody:first-child > tr:first-child > td, .dxbs-gridview > .card > div:not(.dxgvHSDC):not(.dxgvCSD):not(.dxbs-grid-gph) > .table-bordered:first-child, .dxbs-gridview > .card > div:not(.dxgvHSDC):not(.dxgvCSD):not(.dxbs-grid-gph) > .table-bordered:first-child > thead > tr:first-child > th, .dxbs-gridview > .card > div:not(.dxgvHSDC):not(.dxgvCSD):not(.dxbs-grid-gph) > .table-bordered:first-child > tbody:first-child > tr:first-child > td {
  border-top-width: 0;
}
.dxbs-gridview > .card, .dxbs-gridview > .card > .card,
.dxbs-gridview > .card .dxbs-grid-edit-form > .dxbs-fl .row > div {
  position: static;
}
.dxbs-gridview > .card > .dxgvHSDC.card:not(:first-child), .dxbs-gridview > .card > .dxgvCSD.card:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dxbs-gridview > .card > .dxgvHSDC.card:not(:last-child), .dxbs-gridview > .card > .dxgvCSD.card:not(:last-child) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dxbs-gridview > .card .dxbs-grid-header-container > .dxbs-grid-fixed-table {
  position: absolute;
}
.dxbs-gridview .dx-btn.btn-link:focus, .dxbs-gridview .dx-btn.btn-link.focus, .dxbs-gridview .dx-btn.btn-link.active, .dxbs-gridview .dx-btn.btn-link:active {
  box-shadow: none !important;
}

.dxbs-table > tbody > tr > td.dx-rowtemplate-padding {
  padding: 0;
}

.dxbs-fixed > tbody > tr > td.dxbs-grid-edit-form {
  overflow: visible;
}

.dxbs-gridview > .card > .card-header {
  padding: 0.375rem !important;
}

@media (max-width: 767px), (hover: none) {
  .dxbs-group-panel-container > .dxbs-group-panel {
    white-space: nowrap;
    overflow-x: hidden;
  }

  .dxbs-group-panel-container {
    display: grid;
  }
}
.dxbs-gridview .dxbs-fixed-header-content .dxbs-sort-order-indicator,
.dxbs-gridview .dxbs-group-panel-container .dxbs-group-panel .dxbs-sort-order-indicator {
  display: inline-block;
  border: 0;
  padding: 0;
  color: inherit;
}

.dxbs-gridview .dxbs-fixed-header-content .dxbs-sort-order-indicator > .dx-image,
.dxbs-gridview .dxbs-group-panel-container .dxbs-group-panel .dxbs-sort-order-indicator > .dx-image {
  display: block;
}

.dxbs-gridview .dxbs-group-panel-container .dxbs-group-panel > .card > .card-body > span[data-toggle=gridview-column-sort],
.dx-dragging-state > .card-body > span[data-toggle=gridview-column-sort],
.dx-dragging-state > .card-body > .dxbs-sort-order-indicator {
  color: inherit;
}

.dxbs-gridview.dxbs-has-vertical-scrollbar:not(.dxbs-vertical-scrollbar-visible) > .card > .dxgvHSDC > table > thead > tr > th:last-child,
.dxbs-gridview.dxbs-has-vertical-scrollbar:not(.dxbs-vertical-scrollbar-visible) > .card > .dxgvCSD > table > tbody > tr > td:last-child {
  border-right-width: 0;
}

.dxbs-gridview > .card > .dxbs-grid-vsd > table > tbody > tr > td.dx-grid-summary-cell {
  position: sticky;
  bottom: 0;
  z-index: inherit;
}

.dxbs-popup-edit-row,
.dxbs-popup-edit-row > td {
  padding: 0;
  background: none;
  outline: none;
  box-shadow: none;
  max-height: 0;
  min-height: 0;
}

.dxbs-popup-edit-row,
.dxbs-popup-edit-row > td,
.dxbs-popup-edit-row + tr,
.dxbs-popup-edit-row + tr > td {
  border-width: 0 !important;
  border-style: solid !important;
}

.dxbs-grid-pager {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.dxbs-grid-pager .pagination {
  margin: 0;
}
.dxbs-grid-pager .pagination > li > button:not(.disabled), .dxbs-grid-pager .pagination > li > div.page-link:not(.disabled) {
  cursor: pointer;
}

.dxbs-gridview .dxbs-editform .dxbs-datagrid-inplace-edit-area {
  overflow: hidden;
  width: 100%;
}
.dxbs-gridview .dxbs-editform .dxbs-datagrid-inplace-edit-area > div {
  padding: 1em 0.5em;
  max-width: var(--component-width);
  will-change: transform;
  transform: translateX(var(--scroll-left));
}

th:focus {
  outline: 0;
}

table.dx-resize thead tr th > button,
table.dx-resize .dxbs-fixed-header-content {
  width: calc(100% - 7px);
}

.dxAndroidMobilePlatform table.dx-resize thead tr th > button,
.dxAndroidMobilePlatform .dxbs-fixed-header-content,
.dxMacOSMobilePlatform table.dx-resize thead tr th > button,
.dxMacOSMobilePlatform .dxbs-fixed-header-content {
  width: calc(100% - 1px);
}

th:focus > button:before,
th:focus .dxbs-fixed-header-content:before {
  content: "";
  position: absolute;
  width: inherit;
  height: 100%;
}

table.dx-resize {
  table-layout: fixed;
}

table.dx-resize th {
  position: relative;
}

table.dx-resize tr th span,
table.dx-resize tr th:not(.dxbs-toggle-cell):not(.dxbs-grid-group-cell-offset) span,
table.dx-resize tr td:not(.dxbs-toggle-cell):not(.dxbs-grid-group-cell-offset):not(.dxbs-grid-edit-form) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dxMacOSMobilePlatform table.dx-resize thead {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

.dxAndroidMobilePlatform .dxColumnResizeAnchor:before,
.dxMacOSMobilePlatform .dxColumnResizeAnchor:before {
  background-color: transparent;
  position: absolute;
  content: "";
  height: 100%;
  width: 21px;
  right: -10px;
}

.dxAndroidMobilePlatform .dxColumnResizeAnchor,
.dxMacOSMobilePlatform .dxColumnResizeAnchor,
.dxAndroidMobilePlatform th:focus .dxColumnResizeAnchor,
.dxMacOSMobilePlatform th:focus .dxColumnResizeAnchor {
  width: 1px;
  opacity: 0;
}

.dxAndroidMobilePlatform th:focus:before,
.dxMacOSMobilePlatform th:focus:before {
  content: none !important;
}

.dxColumnResizeAnchor {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  top: 0;
  right: 0;
  width: 7px;
  height: 100%;
  position: absolute;
  cursor: col-resize;
  z-index: 1;
  opacity: 0;
}

th:focus .dxColumnResizeAnchor {
  top: 3px;
  right: 3px;
  height: calc(100% - 6px);
  box-shadow: 0 0 0 1px var(--dx-accent-shadow-color, rgba(128, 128, 128, 0.25));
  border-radius: 2px;
  opacity: 1;
}

.dxColumnResizeAnchor:active:before {
  background-color: transparent;
  position: absolute;
  content: "";
  height: 100%;
  width: 45px;
  left: -20px;
}

th:focus .dxColumnResizeAnchor::after {
  content: " ";
  width: 3px;
  height: 10px;
  border-left: 1px solid var(--dx-accent-color, var(--bs-primary));
  border-right: 1px solid var(--dx-accent-color, var(--bs-primary));
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}

.table th:focus:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 2px;
  box-shadow: 0 0 0 2px var(--dx-accent-color, var(--bs-primary));
  z-index: 0;
  height: calc(100% - 6px);
  width: calc(100% - 6px);
  transform: translate3d(-0.25rem, -0.25rem, 0);
  top: 7px;
  left: 7px;
  opacity: 0.5;
}

.card-body.dxbs-grid-pager.dx-resize {
  overflow-x: auto;
}

.dxbs-gridview table.dx-resize .dxbs-fixed > thead > tr > th {
  overflow: unset;
}

.dxbs-listbox .list-group-item-action:not(.disabled):not(.dxbs-listbox-active):not(.active):hover:before, .btn.dxbs-cmd-btn:hover:before, .btn.dxbs-cmd-btn:focus:before, .dxbs-tree ul.nav > .nav-item > .nav-link:not(.disabled):not(.active):hover:before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.1;
}

.btn.dxbs-cmd-btn:focus:hover:before {
  opacity: 0.2;
}

.dxbs-listbox .list-group-item-action:not(.disabled):not(.dxbs-listbox-active):not(.active),
.dxbs-listbox-active,
.dxbs-listbox-active.active,
.dxbs-listbox-active.list-group-item-action.active,
.dxbs-listbox-active.list-group-item-action.active:hover {
  position: relative;
  transform: scale(1);
  background: transparent;
  color: inherit;
}

.dxbs-listbox-active:not(tr):before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.1;
}

tr.dx-blazor-listbox-column {
  position: relative;
  will-change: scale;
}

tr.dx-blazor-listbox-column:not(.dropdown-item):hover {
  transform: scale(1);
}

tr.dxbs-listbox-active td:before,
tr.dx-blazor-listbox-column:not(.dropdown-item):not(.dxbs-editor-disabled):not(.dx-blazor-readonly-row):hover td:before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.025;
}

.dxSafari tr.dx-blazor-listbox-column:hover > td {
  position: relative;
}

.dxbs-combobox-hint-text {
  position: absolute;
  opacity: 0.3;
  pointer-events: none;
  z-index: 4;
}

.dxbs-listbox .empty-item .list-group-item-action:before,
.dxbs-listbox .empty-item .custom-control-label:before,
.dxbs-listbox .empty-item.dropdown-item:before {
  content: ".";
  visibility: hidden;
}

.dxbs-listbox li > .list-group-item > .dxbs-checkbox {
  display: inline-block;
  cursor: default;
}
.dxbs-listbox li > .list-group-item > .dxbs-checkbox + span {
  display: inline-block;
  margin-left: 0.25rem;
}

.dx-dropdown-list-container .dxgvHSDC.card {
  border-bottom-left-radius: 0;
}

.dx-dropdown-list-container .dxbs-gridview > div > .dxbs-grid-vsd {
  height: auto;
  max-height: 200px;
}

tr.dx-blazor-listbox-column.dropdown-item {
  display: table-row;
  padding: inherit;
}

.dxbs-listbox .dxbs-gridview > div > .dxbs-grid-vsd {
  height: inherit;
}

.dxbs-listbox > .dxbs-gridview .dxgvHSDC {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dxbs-listbox > .dxbs-gridview .dxgvHSDC th {
  cursor: default !important;
}

.dxbs-listbox > .dxbs-gridview .dxgvCSD {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.has-feedback .dxbs-listbox.form-control,
.dxbs-listbox.form-control,
.dxbs-listbox > ul.form-control {
  height: auto;
  padding: 0;
}

dxbl-thumb {
  cursor: grab;
}

dxbs-thumb-dragging {
  cursor: grabbing;
}

.dxbs-fl .row {
  margin-left: 0;
  margin-right: 0;
}

.dxbs-fl .dxbs-fl-g {
  /*hidden*/
  padding-left: 0;
  padding-right: 0;
}

.dxbs-fl .dxbs-fl-gd,
.dxbs-fl .dxbs-fl-gt {
  margin-top: 1em;
}

.dxbs-fl .dxbs-fl-gd,
.dxbs-fl .dxbs-fl-gt {
  margin-bottom: 1em;
}

.dxbs-fl .dxbs-fl-g > .row,
.dxbs-fl .dxbs-fl-gd > .card > .row,
.dxbs-fl .dxbs-fl-gr + .row:not(.dxbs-fl-gr) {
  /*hidden*/
  padding-top: 1em;
}

.dxbs-fl .form-group.row {
  margin-left: 0;
  margin-right: 0;
}

.dxbs-fl .form-group.row > .dxbs-fl-cpt {
  margin-bottom: 5px;
  align-self: flex-start;
}

.dxbs-fl .form-group.row > .dxbs-fl-cpt:not(:first-child):not(:last-child) {
  /*hidden*/
  padding-right: 0.6em;
  padding-left: 0.6em;
}

.dxbs-fl .form-group.row > .dxbs-fl-cpt:first-child {
  padding-right: 0.6em;
}

.dxbs-fl .form-group.row > .dxbs-fl-cpt:last-child {
  /*hidden*/
  padding-right: 0;
  padding-left: 0.6em;
  text-align: left;
}

@media (min-width: 992px) {
  .dxbs-fl .form-group.row > .col-form-label {
    white-space: nowrap;
  }

  .dxbs-fl:not(.dxbs-fl-calc) .form-group.row > .col-form-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dxbs-fl .form-group.row > .dxbs-fl-ctrl:not(img) {
    width: 100%;
  }
}
.dxbs-fl-calc .form-group > .dxbs-fl-cpt {
  width: auto !important;
  flex: none !important;
}

.dxbs-fl-calc .form-group > .dxbs-fl-ctrl:not(img):not(.dxbs-fl-ctrl-nc) {
  width: 100% !important;
}

.dxbs-fl .dxbs-fl-cpt {
  font-weight: 600;
}

@media (min-width: 992px) {
  .dxbs-fl .form-group.row > .dxbs-fl-ctrl {
    align-self: center;
  }
}
.dxbs-fl .form-group.row {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
}
.dxbs-fl .form-group.row .dxbs-fl-empty-caption {
  display: none;
}

@media (min-width: 992px) {
  .dxbs-fl .form-group.row {
    flex-flow: row nowrap;
  }

  .dxbs-fl .form-group.row > .col-form-label {
    flex: 1 0 auto;
  }
}
.dxbs-fl .form-group.row > .col-form-label {
  margin-bottom: 0;
}

.dxbs-fl .dxbs-fl-gd,
.dxbs-fl .dxbs-fl-gt {
  margin-top: 1rem;
}

.dxbs-fl .dxbs-fl-gd {
  margin-bottom: 1rem;
}

.dxbs-fl .dxbs-fl-g > .row,
.dxbs-fl .dxbs-fl-gd > .card > .row {
  padding-top: 1rem;
}

.dxbs-fl .form-group {
  margin-bottom: 0.5rem;
}

.invalid .form-control.dxbs-btn-group.dxbs-input-group-append,
.valid .form-control.dxbs-btn-group.dxbs-input-group-append {
  border-color: transparent;
}

.dxbs-fl .dxbs-fl-empty-caption {
  vertical-align: top;
}

.row > label.dxbs-fl-cpt,
.row > div.dxbs-fl-ctrl {
  padding-left: unset;
  padding-right: unset;
}

.dxbs-fl-row-break {
  flex-basis: 100%;
  height: 0;
}

.dxbs-fl .form-group.row > .dxbs-fl-cpt {
  width: auto;
  flex: 0 0 auto;
}
.dxbs-fl .form-group.row > .dxbs-fl-ctrl {
  width: auto;
  flex: 1 1 auto;
  min-width: 0;
}

.dxbl-fl-tab-content {
  padding: 0;
  padding-top: 1em;
}

.dxbs-uc .progress {
  height: 1rem;
}

.dxbs-uc .dxuc-file-list-view .progress {
  height: 0.4375rem;
}

.dxbs-uc .progress-bar {
  -webkit-transition: width 0.1s ease;
  -o-transition: width 0.1s ease;
  transition: width 0.1s ease;
}

.dxbs-uc .dxuc-button-container {
  height: 3rem;
}

.dxbs-uc .dxuc-file-extension.badge {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 11px;
  padding: 0.25em 0.4em;
  font-size: 11px;
}

.dxbs-uc > .row > .col > .dxuc-file-view td {
  border: none !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.dxbs-uc > .dxuc-file-list-view .dxuc-file-view td {
  border: none !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dxuc-button-icon-container {
  position: relative;
  z-index: 1;
  color: inherit;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
}

.dxuc-button-icon-container:hover:before {
  content: " ";
  position: absolute;
  z-index: -1;
  background-color: currentColor;
  right: 2px;
  opacity: 0.1;
  border-radius: 100%;
  width: 20px;
  height: 20px;
}

.dxuc-hide,
.dxuc-file-container {
  display: none;
}

@media (min-width: 600px) {
  .dxuc-file-container {
    display: inherit;
  }
}
.dxuc-cancel-icon,
.dxuc-pause-icon,
.dxuc-reload-icon,
.dxuc-continue-icon,
.dxuc-upload-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.dxuc-file-icon {
  width: 42px;
  height: 42px;
}

.column-chooser-elements-container .column-chooser-element-container.freeze.up {
  transform: translateY(calc(1px - 100%));
  transition: transform 0.3s;
  z-index: 1006;
}

.column-chooser-elements-container .column-chooser-element-container.freeze.down {
  transform: translateY(calc(100% - 1px));
  transition: transform 0.3s;
  z-index: 1004;
}

.column-chooser-elements-container .column-chooser-element-container.freeze {
  transform: translateY(0%);
  transition: transform 0.3s;
  z-index: 1005;
  pointer-events: none;
}

.column-chooser-elements-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: inherit;
  color: inherit;
  display: none;
  width: 100%;
  max-height: 60vh;
  border: inherit;
}

.column-chooser-elements-container:focus {
  outline: none;
}

.column-chooser-element-container.page-link {
  margin-left: unset !important;
  line-height: unset !important;
}

.column-chooser-element-container.page-link:focus {
  position: static;
}

.column-chooser-element-container.page-link:hover {
  z-index: auto;
}

.column-chooser-element-container.page-link:focus::before {
  content: " ";
  pointer-events: none;
  user-select: none;
  position: absolute;
  width: 100%;
  height: var(--h, auto);
  left: 0px;
  top: calc(var(--t, auto) - var(--bt, auto));
  box-shadow: inherit;
  z-index: 1001;
}

.column-chooser-element-container.page-link:focus .column-chooser-drag-icon-owner {
  top: var(--t, auto);
  height: calc(var(--h, auto) - var(--bt, auto) * 2);
}

.column-chooser-elements-container:last-child {
  display: block;
}

.column-chooser-header {
  display: flex;
  align-items: center;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

.column-chooser-header {
  justify-content: space-between;
  border: inherit;
  border-bottom-width: 1px !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
}

.column-chooser-element-container {
  max-width: 325px;
}

.column-chooser-element-container.list-group-item {
  border-left: 0;
  border-right: 0;
  background: inherit;
  color: inherit;
  display: flex;
  padding-left: 0.85rem;
  padding-right: 0.85rem;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: inherit;
  border-style: inherit;
}

.column-chooser-element-container.list-group-item.active,
.column-chooser-element-container.list-group-item:hover,
.column-chooser-element-container.list-group-item:focus {
  background: inherit;
  color: inherit;
}

.column-chooser-container .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.column-chooser-element-container.list-group-item:first-child {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-top-color: transparent;
}

.column-chooser-element-container.list-group-item:last-child {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  border-bottom-color: transparent;
}

.column-chooser-elements-container .column-chooser-element-container.in-drag {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-top-color: inherit;
  border-bottom-style: solid;
  position: absolute;
  pointer-events: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1008;
}

.column-chooser-element-checkbox {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  display: block;
  width: 100%;
  margin-bottom: 0;
  cursor: pointer;
}

.column-chooser-elements-container.column-chooser-enable-drag-drop .column-chooser-element-container.list-group-item {
  padding-right: 0;
}
.column-chooser-elements-container.column-chooser-enable-drag-drop .column-chooser-element-container.list-group-item > .column-chooser-element-checkbox {
  padding-right: calc(24px + 18px + 16px);
}

.column-chooser-element-checkbox .dxbs-checkbox > label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  vertical-align: top;
  pointer-events: none;
}

.column-chooser-element-checkbox .dxbs-checkbox > input {
  pointer-events: none;
}

.column-chooser-element-checkbox > .dxbs-checkbox .dxbs-checkbox-label.dxbs-checkbox-label-wrap {
  white-space: nowrap;
}

.column-chooser-caption {
  font-size: 0.925rem;
  font-weight: 600;
  margin-left: 8px;
  padding-right: 24px;
}

.column-chooser-button {
  display: flex;
  align-items: center;
}

.column-chooser-button-icon {
  width: 16px;
  height: 16px;
}

.column-chooser-drag-icon {
  width: 18px;
  height: 18px;
}

.column-chooser-drag-icon-owner {
  display: flex;
  align-items: center;
  position: absolute;
  cursor: move;
  width: calc(18px + 16px);
  height: 100%;
  justify-content: center;
  align-self: center;
  right: 0;
  top: 0;
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}

.column-chooser-exit-icon {
  width: 18px;
  height: 18px;
}

.column-chooser-exit-button {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.dxbs-toolbar-dropdown.border-inherit > .modal-body {
  background: inherit;
  color: inherit;
  border: inherit;
  border-width: 0;
}

.dxbs-toolbar-dropdown.border-inherit > .modal-body > .column-chooser-elements-container {
  background: inherit;
  color: inherit;
  border: inherit;
}

.grid-toolbar.card-header {
  padding: 6px;
}

.dxbs-toolbar-dropdown.dxbs-toolbar-modal .column-chooser-element-container {
  max-width: 100%;
}

.dxbs-toolbar-dropdown.dxbs-toolbar-modal .column-chooser-element-checkbox {
  margin-left: 0;
}

.dxbs-toolbar-dropdown.dxbs-toolbar-modal .column-chooser-drag-icon {
  margin-left: 0;
  margin-right: 0;
}

.dxbs-toolbar-dropdown.dxbs-toolbar-modal .column-chooser-elements-container {
  overflow: unset;
  -webkit-overflow-scrolling: unset;
  max-height: none;
}

.grid-header-template {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: inherit;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.grid-header-template + .dxbs-table {
  border-top-width: 0;
}

.no-item-text .dxbs-toolbar-group .column-chooser-button-label {
  display: none;
}

.column-chooser-element-container.list-group-item + .list-group-item {
  border-top-width: 0;
}

.dxIE .page-link.column-chooser-element-container:focus {
  position: relative;
}

.dxbs-focus-hidden .dxbs-edit-btn.inc:focus,
.dxbs-focus-hidden .dxbs-clear-btn:focus,
.dxbs-focus-hidden .dxbs-edit-btn.dec:focus,
.dxbs-combobox .dxbs-focus-hidden .dxbs-dropdown-toggle:focus,
.dxbs-date-edit.dxbs-focus-hidden .dxbs-dropdown-toggle:focus,
.dxbs-calendar.card .dxbs-focus-hidden .dxbs-month-title:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: transparent;
}

.dxbs-focus-hidden .dxbs-clear-btn:focus, [data-toggle=gridview-column-sort]:focus {
  outline: 0;
}

.dxbs-focus-hidden .dxbs-clear-btn {
  background: none;
}

.dxbs-spin-edit[use-adaptive-layout] .input-group .dxbs-edit-btn.dec, .dxbs-spin-edit[use-adaptive-layout] .input-group .dxbs-edit-btn.inc {
  border-bottom: none;
  border-top: none;
  border-right: none;
  height: 100%;
}
.dxbs-spin-edit[use-adaptive-layout] .dxbs-edit-clbv input.form-control {
  padding-right: 8em;
}
.dxbs-spin-edit[use-adaptive-layout] .dxbs-spin-btn-group {
  display: flex;
  flex-direction: row-reverse;
}
.dxbs-spin-edit[use-adaptive-layout] .dxbs-spin-btn-group .dxbs-editor-clear-btn {
  order: 1;
}
.dxbs-spin-edit:not([use-adaptive-layout]) .dxbs-spin-btn-group .dxbs-edit-btn {
  display: flex;
  flex: 1 1 auto;
  padding-top: 0;
  padding-bottom: 0;
  height: 50%;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  margin-top: 0;
}
.dxbs-spin-edit:not([use-adaptive-layout]) .dxbs-spin-btn-group .dxbs-edit-btn.dec {
  position: absolute;
  right: 0;
  bottom: 0;
  width: auto;
  border-bottom: none;
}
.dxbs-spin-edit:not([use-adaptive-layout]) .dxbs-spin-btn-group .dxbs-edit-btn.inc {
  border-bottom: 0;
  border-bottom: 0px;
  border-bottom-right-radius: 0px;
}
.dxbs-spin-edit:not([data-qa-dxbl-loaded]) .dxbs-spin-btn-group {
  visibility: hidden;
}
.dxbs-spin-edit .inc.dxbs-edit-btn + .dec.dxbs-edit-btn {
  margin-left: 0;
  border-top: 0px;
  border-top-right-radius: 0;
}
.dxbs-spin-edit .dxbs-clickable.dxbs-edit-btn {
  width: 100%;
}

.dx-blazor-snackbar-container {
  z-index: 30001;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  margin: 0;
  transition: visibility 0s linear 0.2s, opacity 0.2s linear;
  right: 0.5rem;
  left: 0.5rem;
  bottom: 0.5rem;
}
@media (min-width: 576px) {
  .dx-blazor-snackbar-container {
    width: 580px;
    padding: 0 24px;
    left: calc(50% - 290px);
    right: unset;
    bottom: 1rem;
  }
}
.dx-blazor-snackbar-container.dx-blazor-snackbar-container-shown {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.dx-blazor-snackbar-container .dx-blazor-snackbar {
  padding: 0.5rem;
  margin: 0;
  width: 100%;
  opacity: 0.75;
}

.dxbs-context-menu > .modal-body > ul, .dxbs-context-menu-submenu > .modal-body > ul {
  margin: 0;
  width: 100%;
}
.dxbs-context-menu > .modal-body .dropdown-divider, .dxbs-context-menu-submenu > .modal-body .dropdown-divider {
  margin: 0;
}
.dxbs-context-menu > .modal-body .dropdown-divider:first-child, .dxbs-context-menu-submenu > .modal-body .dropdown-divider:first-child {
  display: none;
}

.dxbs-context-menu-item.dropdown-item {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding-left: 8px;
}
.has-icons > li > .dxbs-context-menu-item.dropdown-item .dxbs-context-menu-item-text {
  display: inline-block;
  padding-left: 24px;
}
.has-icons > li > .dxbs-context-menu-item.dropdown-item.has-icon .dxbs-context-menu-item-text {
  padding-left: 8px;
}
.dxbs-context-menu-item.dropdown-item .dx-popout-image {
  position: absolute;
  height: 1rem;
  width: 1rem;
  top: calc(50% - 0.5rem);
  right: 0.25rem;
}
.dxbs-context-menu-item.dropdown-item .dxbs-context-menu-item-icon {
  padding: 0;
  display: inline-flex;
}
.dxbs-context-menu-item.dropdown-item img.dxbs-context-menu-item-icon {
  height: 16px;
  width: 16px;
}

.dxbs-context-menu-submenu > .modal-body, .dxbs-context-menu > .modal-body, .dxbl-dropdown-dialog > .modal-body {
  padding: 0;
}
.dxbs-context-menu > .modal-body, .dxbs-context-menu-submenu > .modal-body {
  margin-bottom: 0;
}

.dxbs-memo .form-control.dxbs-vertical-resize {
  resize: vertical;
}

.dxbs-memo .form-control.dxbs-horizontal-resize {
  resize: horizontal;
}

.dxbs-memo .form-control.dxbs-disable-resize {
  resize: none;
}

.dxbs-memo .form-control.dxbs-enable-resize {
  resize: both;
}

.dxbs-memo .form-control:not(.dxbs-disable-mh) {
  min-height: 4em;
}

.dxbs-memo .form-control {
  max-width: 100%;
}

.dxbs-memo > .input-group > .input-group-append.form-control {
  height: 100%;
}

.dxbs-textbox.dxbs-edit-clbv input.form-control {
  padding-right: 2.571em;
}

.dxbs-edit-clbv textarea.form-control {
  padding-right: calc(15px + 2.2em);
}

.dxbs-memo .dxbs-reset-width {
  width: min-content;
}

.dx-gridlayout {
  width: 100%;
  height: 100%;
}

.dx-gridlayout > .dx-gridlayout-root {
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;
  display: grid;
  display: -ms-grid;
}

.dx-stacklayout > .dx-stacklayout-root {
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;
  display: grid;
  display: -ms-grid;
}

.btn.dxbs-cmd-btn:hover:before, .btn.dxbs-cmd-btn:focus:before, .dxbs-tree ul.nav > .nav-item > .nav-link:not(.disabled):not(.active):hover:before, .dxbs-listbox .list-group-item-action:not(.disabled):not(.dxbs-listbox-active):not(.active):hover:before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.1;
}

.btn.dxbs-cmd-btn:focus:hover:before {
  opacity: 0.2;
}

dxbl-menu > ul,
dxbl-menu-item,
dxbl-menu > .nav {
  width: 100%;
}

dxbl-menu {
  display: flex;
  flex-direction: column;
}
dxbl-menu.dxbs-menu > .dx-menu-title {
  align-self: stretch;
}

dxbl-menu-item {
  display: flex;
}

dxbl-menu-item {
  height: 100%;
}

.dxbs-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.dxbs-menu ul:not(.dx-menu-nav-dropdown) span.dx-menu-item-text {
  white-space: nowrap;
}
.dxbs-menu ul.dx-menu-nav-dropdown {
  display: block;
}
.dxbs-menu .dropdown-menu.dx-dropdown-menu {
  animation: fadeIn 0.175s;
  padding: 0;
  overflow: visible;
  display: block;
  margin: 0;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
  top: unset;
  bottom: unset;
  left: unset;
  right: unset;
}
.dxbs-menu .dx-menu-bar {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.dxbs-menu .dx-menu-bar.dx-menu-loading {
  visibility: hidden;
  width: 1px;
}
.dxbs-menu .dx-menu-bar.horizontal {
  height: 100%;
}
.dxbs-menu .dx-menu-bar > ul {
  display: flex;
  flex: 1 1 auto;
}
.dxbs-menu .dx-menu-bar > ul.dx-menu-loading {
  visibility: hidden;
  width: 1px;
  flex: 0 1 auto;
}
.dxbs-menu .dx-menu-bar > ul > .dx-menu-item {
  position: static;
}
.dxbs-menu .dx-menu-bar.vertical {
  flex-direction: column;
  height: inherit;
}
.dxbs-menu .dx-menu-bar.vertical .dx-menu-title {
  align-self: start;
}
.dxbs-menu .dx-menu-title {
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.3rem 1rem;
  align-self: center;
  white-space: nowrap;
}
.dxbs-menu .dx-menu-title.nav-link {
  display: flex;
  transform: scale(1);
  background: none;
  color: inherit;
}
.dxbs-menu .dx-menu-title.nav-link:active:before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.2;
}
.dxbs-menu .dx-menu-title.nav-link:hover:before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.1;
}
.dxbs-menu .dx-menu-title .dx-menu-back {
  width: 18px;
  height: 18px;
  align-self: center;
  margin-right: 0.75rem;
}
.dxbs-menu div.nav {
  display: block;
  position: relative;
}
.dxbs-menu div.nav .dx-nav-menu-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dxbs-menu div.nav .dx-nav-menu-bar .dx-menu-hamburger {
  width: 18px;
  height: 18px;
}
.dxbs-menu div.nav .dx-nav-menu-bar.left {
  justify-content: flex-end;
  flex-direction: row-reverse;
}
.dxbs-menu div.nav .dx-nav-menu-bar.right {
  justify-content: space-between;
}
.dxbs-menu div.nav .dx-nav-menu-bar.reverse {
  flex-direction: row-reverse;
  justify-content: space-between;
}
.dxbs-menu div.nav .dx-nav-menu-bar > a.nav-link {
  display: flex;
  transform: scale(1);
  outline: none;
  background: none;
  color: inherit;
  padding: 0.6rem;
}
.dxbs-menu div.nav .dx-nav-menu-bar > a.nav-link.selected:before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.05;
}
.dxbs-menu div.nav .dx-nav-menu-bar > a.nav-link:active:before, .dxbs-menu div.nav .dx-nav-menu-bar > a.nav-link:active.selected:before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.2;
}
.dxbs-menu div.nav .dx-nav-menu-bar > a.nav-link:hover:before, .dxbs-menu div.nav .dx-nav-menu-bar > a.nav-link:focus:before, .dxbs-menu div.nav .dx-nav-menu-bar > a.nav-link:hover.selected:before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.1;
}
.dxbs-menu div.nav .dropdown-menu {
  display: block;
  position: absolute;
  z-index: 100;
  width: 100%;
  height: auto;
  margin: 0;
}
.dxbs-menu div.nav .dropdown-menu.dx-dropdown-menu.top-radius-0 {
  border-radius: 0 0 0.25rem 0.25rem;
}
.dxbs-menu div.nav .dropdown-menu.dx-dropdown-menu.bottom-radius-0 {
  border-radius: 0.25rem 0.25rem 0 0;
}
.dxbs-menu .dx-menu-item {
  color: inherit;
}
.dxbs-menu .dx-menu-item.dx-menu-dropdown-item {
  display: flex;
}
.dxbs-menu .dx-menu-item > dxbl-menu-item > a {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  transform: scale(1);
  outline: none;
  background: none;
  color: inherit;
  height: auto;
}
.dxbs-menu .dx-menu-item > dxbl-menu-item > a.dx-menu-horizontal-item {
  height: 100%;
}
.dxbs-menu .dx-menu-item > dxbl-menu-item > a.disabled {
  opacity: 0.5;
}
.dxbs-menu .dx-menu-item > dxbl-menu-item > a .dx-menu-item-image + .dx-menu-item-text-container,
.dxbs-menu .dx-menu-item > dxbl-menu-item > a img + .dx-menu-item-text-container {
  padding-left: 0.7em;
}
.dxbs-menu .dx-menu-item > dxbl-menu-item > a:not(disabled).selected:not(.mute-selection):before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.05;
}
.dxbs-menu .dx-menu-item > dxbl-menu-item > a:not(disabled):active:before, .dxbs-menu .dx-menu-item > dxbl-menu-item > a:not(disabled):active.selected:not(.mute-selection):before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.2;
}
.dxbs-menu .dx-menu-item > dxbl-menu-item > a:not(disabled):hover:before, .dxbs-menu .dx-menu-item > dxbl-menu-item > a:not(disabled):focus:before, .dxbs-menu .dx-menu-item > dxbl-menu-item > a:not(disabled):hover.selected:not(.mute-selection):before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.1;
}
.dxbs-menu .dx-menu-item > dxbl-menu-item > a:focus:not(:hover):not(:active):not(.selected):before {
  content: none;
}
.dxbs-menu .dx-menu-item > dxbl-menu-item > a div.dx-menu-text-hidden {
  display: none;
  overflow: hidden;
  width: 0;
  padding: 0;
}
.dxbs-menu .dx-menu-item .dropdown-toggle {
  margin-left: auto;
  padding-left: 0.6rem;
}
.dxbs-menu .dx-menu-item .dropdown-toggle.right:after {
  transform: rotate(-90deg);
  position: relative;
  left: 0.4rem;
}
.dxbs-menu .dx-menu-item div.dx-menu-item-space {
  display: inline-block;
  width: 1rem;
  flex: 0 0 auto;
}
.dxbs-menu .separator {
  overflow: hidden;
  background-color: currentColor;
  opacity: 0.2;
  flex: 0 0 auto;
}
.dxbs-menu .horizontal > .nav > .separator {
  width: 1px;
}
.dxbs-menu .vertical > .nav > .separator {
  height: 1px;
  margin: 0.5rem 0;
}
.dxbs-menu .dropdown-menu > ul > .separator {
  height: 1px;
  margin: 0.5rem 0;
}
.dxbs-menu .dx-menu-spaceitem {
  flex: 1;
}
.dxbs-menu .item-stretch {
  flex: 1 1 auto;
}
.dxbs-menu .item-stretch > .dropdown-toggle {
  margin-left: 0px;
}
.dxbs-menu .dx-menu-bar .item-position-start {
  justify-content: flex-start;
}
.dxbs-menu .dx-menu-bar .item-position-end {
  justify-content: flex-end;
}
.dxbs-menu .dx-menu-bar .item-position-center {
  justify-content: center;
}
.dxbs-menu .vertical .item-position-start {
  justify-content: initial;
}
.dxbs-menu .vertical .item-position-end {
  justify-content: initial;
}
.dxbs-menu .vertical .item-position-center {
  justify-content: initial;
}
.dxbs-menu .vertical > .nav > .item-stretch > .nav-link {
  height: 100%;
}
.dxbs-menu:not([data-dx-menu-loaded]) > .nav, .dxbs-menu:not([data-dx-menu-loaded]) > ul, .dxbs-menu:not([data-dx-menu-loaded]) > .dx-menu-title {
  opacity: 0;
}
.dxbs-menu:not([data-dx-menu-loaded]) > .dx-menu-bar {
  opacity: 0;
}
.dxbs-menu:not([data-dx-menu-loaded]) > .dx-menu-bar > .nav {
  flex-wrap: nowrap;
}
.dxbs-menu:not([data-dx-menu-mobile]) > .nav, .dxbs-menu:not([data-dx-menu-mobile]) > ul, .dxbs-menu:not([data-dx-menu-mobile]) > .dx-menu-title {
  display: none;
}
.dxbs-menu[data-dx-menu-mobile] > .dx-menu-bar {
  display: none;
}

.dxbs-menu ul.nav > li > dxbl-menu-item > a:not(.disabled), .dxbs-menu ul.dx-dropdown-menu > li > .dropdown-item:not(.disabled) {
  cursor: pointer;
}
.dxbs-menu ul.nav.nav-justified > li.separator {
  flex: 0 0;
  -ms-flex: 0 0;
  padding: 0 0.4em;
}
.dxbs-menu ul.flex-column > li > dxbl-menu-item > a > span.popout, .dxbs-menu ul.dx-dropdown-menu > li > dxbl-menu-item > .dropdown-item > span.popout {
  top: 0;
  bottom: 0;
}
.dxbs-menu ul > li > dxbl-menu-item > a > .badge {
  margin-left: 0.4em;
}
.dxbs-menu .dxbs-navbar {
  padding: 0 1em;
}

.dxbs-preloader-initial {
  visibility: hidden;
}

.dxbs-preloader-container {
  position: absolute !important;
  z-index: 1;
}

.dxbs-preloader {
  height: 16px;
  width: 16px;
  border: 3px transparent solid;
  border-top: 3px currentColor solid;
  border-radius: 50%;
  -webkit-animation: preloader-spin 1s infinite linear;
  animation: preloader-spin 1s infinite linear;
  opacity: 0.5;
}

.dxbs-preloader.dxbs-preloader-lg {
  height: 24px;
  width: 24px;
  border: 4px transparent solid;
  border-top: 4px currentColor solid;
}

.dxbs-preloader:before {
  position: absolute;
  content: "";
  top: -3px;
  left: -3px;
  height: 16px;
  width: 16px;
  opacity: 0.3;
  border: 3px currentColor solid;
  border-top: 3px transparent solid;
  border-radius: 50%;
}

.dxbs-preloader.dxbs-preloader-lg:before {
  top: -4px;
  left: -4px;
  height: 24px;
  width: 24px;
  border: 4px currentColor solid;
  border-top: 4px transparent solid;
}

@-webkit-keyframes preloader-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes preloader-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes preloader-fadeOut {
  to {
    opacity: 0;
  }
}
.dxbs-preloader-success {
  animation: preloader-fadeOut 0.4s cubic-bezier(0.55, 0.09, 0.68, 0.53) forwards;
}

.dxbs-tabs ul.nav > li > a:not(.disabled) {
  cursor: pointer;
}

.dxbs-tabs .nav:before,
.dxbs-tabs .nav:after {
  /*hidden*/
  display: block;
}

.dxbs-tabs span.image {
  display: inline-block;
  position: relative;
  align-self: center;
}

.dxbs-tabs ul.nav > li > a > span.image:not(:last-child) {
  margin-right: 0.5rem;
}

.dxbs-tabs-scrollable {
  display: flex;
  flex-flow: row nowrap;
}

.dxbs-tabs-scrollable .dxbs-tabs-scroll-btn {
  flex: 0 3rem;
  width: 3rem;
  margin-left: -6rem;
  display: none;
  visibility: hidden;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dxbs-tabs-scrollable .dxbs-tabs-scroll-btn.prev {
  border-top-right-radius: 0;
}

.dxbs-tabs-scrollable .dxbs-tabs-scroll-btn.next {
  border-top-left-radius: 0;
}

.dxbs-tabs-scrollable .dxbs-tabs-scroll-btn,
.dxbs-tabs-scrollable .dxbs-tabs-scroll-btn:disabled {
  opacity: 0.3;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dxbs-tabs-scrollable.can-scroll-left .dxbs-tabs-scroll-btn.prev,
.dxbs-tabs-scrollable.can-scroll-right .dxbs-tabs-scroll-btn.next {
  opacity: 1;
}

.dxbs-tabs-scrollable.has-overflow .dxbs-tabs-scroll-btn {
  visibility: visible;
}

.dxbs-tabs-scroll-btn + .dxbs-tabs-scroll-btn {
  margin-left: 0;
}

.dxbs-tabs-scrollable.has-overflow .dxbs-tabs-scroll-btn {
  display: inline-flex;
}

.dxbs-tabs-scrollable > .tab-content {
  flex: 1 100%;
}

.dxbs-tabs-scrollable .nav {
  flex: 1 calc(100% - 6rem);
  flex-wrap: nowrap;
  touch-action: pan-x;
  white-space: nowrap;
}

.dxbs-tabs-scrollable.has-overflow.dxbs-tabs-scrollbuttons > .dxbs-scroll-viewer,
.dxbs-tabs-scrollable:not(.dxbs-loaded).dxbs-tabs-scrollbuttons > .dxbs-scroll-viewer {
  margin-right: 6rem;
}

.dxbs-tabs-scrollable .nav:before {
  content: " ";
  width: 100%;
  position: absolute;
  box-shadow: inherit;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 1;
  border: inherit;
}

.dxbs-tabs-scrollable .nav > .nav-item {
  z-index: 1;
}

.dx-tabs-content {
  flex: 1 0 100%;
  width: 100%;
}

.dx-tabs-content-panel:not(.dxbs-tabs-render-default) > .dx-tabs-content:not([data-dx-tab-loaded]):not(.active),
.dxbs-loaded.dx-tabs-content-panel:not(.dxbs-tabs-render-default) > .dx-tabs-content:not([data-dx-tab-loaded]) {
  position: absolute;
  visibility: hidden;
  left: -10000px;
}

.dx-tabs-content-panel {
  display: flex;
  flex-grow: 1;
}

.dxbs-focus-hidden .dxbs-tabs-scroll-btn.btn-secondary:focus:not(:disabled):not(.disabled):not(.active):not(:active) {
  box-shadow: initial !important;
}

.dxbs-tabs-scrollable .dxbs-tabs-scroll-btn {
  flex: unset;
  width: unset;
  visibility: hidden;
  box-shadow: initial !important;
}
.dxbs-tabs-scrollable .dxbs-tabs-scroll-btn:not(:hover):not(.active):not(:active):not(:focus), .dxbs-tabs-scrollable .dxbs-tabs-scroll-btn:disabled {
  border-color: transparent;
  background: none;
  color: inherit;
}
.dxbs-tabs-scrollable .dxbs-tabs-scroll-btn:focus {
  box-shadow: initial !important;
}
.dxbs-tabs-scrollable .dxbs-tabs-scroll-btn.prev {
  border-top-left-radius: 0;
}
.dxbs-tabs-scrollable .dxbs-tabs-scroll-btn.next {
  border-top-right-radius: inherit;
}
.dxbs-tabs-scrollable > .dxbs-scroll-viewer > .dxbs-scroll-viewer-hor-scroll-bar {
  display: none;
}
.dxbs-tabs-scrollable.dxbs-tabs-scrollbuttons {
  position: relative;
}
.dxbs-tabs-scrollable.dxbs-tabs-scrollbuttons > .dxbs-scroll-viewer {
  position: static;
}
.dxbs-tabs-scrollable:not(.dxbs-tabs-scrollbuttons) > .dxbs-scroll-viewer {
  padding-left: 1.5rem;
}

.dxbs-tabs > .nav-tabs > .nav-item > .nav-link,
.dxbs-tabs > .dxbs-scroll-viewer > .dxbs-scroll-viewer-content > .nav-tabs > .nav-item > .nav-link {
  display: flex;
}

.dx-blazor-colorpicker {
  padding: 0.5rem;
  position: relative;
}
.dx-blazor-colorpicker .dx-blazor-colorpicker-custom-color-area {
  margin-top: 0.5rem;
}
.dx-blazor-colorpicker .dx-blazor-colorpicker-custom-color-area .btn {
  width: 100%;
}
.dx-blazor-colorpicker .dx-blazor-colorpicker-custom-color-area .dx-blazor-colorpicker-table {
  margin-top: 0.5rem;
}

.dx-blazor-colorpicker-automatic-container {
  display: flex;
  padding-bottom: 0.5rem;
  cursor: pointer;
}
.dx-blazor-colorpicker-automatic-container .dx-blazor-colorpicker-cell {
  flex-shrink: 0;
}

.dx-blazor-colorpicker-automatic-caption {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dx-blazor-colorpicker-table {
  display: flex;
  flex-direction: column;
}

.dx-blazor-colorpicker-row {
  display: flex;
}
.dx-blazor-colorpicker-row:not(:last-child) {
  margin-bottom: 0.25rem;
}

.dx-blazor-colorpicker-cell {
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
}
.dx-blazor-colorpicker-cell:not(:last-child) {
  margin-right: 0.25rem;
}
.dx-blazor-colorpicker-cell.dx-balzor-colorpicker-selected-color {
  outline: 2px solid var(--primary);
  outline-offset: -1px;
}
.dx-blazor-colorpicker-cell:hover {
  outline: 2px solid var(--primary);
  outline-offset: -1px;
}

.dx-blazor-colorpicker-pallete {
  width: 200px;
  height: 200px;
  position: relative;
  cursor: crosshair;
}

.dx-blazor-colorpicker-pallete-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dx-blazor-colorpicker-pallete-gradient-white {
  background-repeat: no-repeat;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), to(white));
  background-image: linear-gradient(270deg, rgba(255, 255, 255, 0), #fff);
}

.dx-blazor-colorpicker-pallete-gradient-black {
  background-repeat: no-repeat;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(black));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), #000);
}

.dx-blazor-colorpicker-hue-scale-wrapper {
  height: 200px;
  width: 30px;
  margin-left: 0.5rem;
  position: relative;
}

.dx-blazor-colorpicker-hue-scale {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-image: -webkit-gradient(linear, left bottom, left top, from(red), color-stop(17%, #ff0), color-stop(33%, #0f0), color-stop(50%, #0ff), color-stop(67%, #00f), color-stop(83%, #f0f), to(red));
  background-image: linear-gradient(0deg, red 0, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, red 100%);
}

.dx-blazor-colorpicker-custom-color {
  display: flex;
}

.dx-blazor-colorpicker-hue-selection-rect {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 0.65rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.35);
  border: 2px solid white;
  z-index: 1;
}

.dx-blazor-colorpicker-color-selection {
  position: absolute;
  width: 1rem;
  height: 1rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.35);
  border: 2px solid white;
  border-radius: 50%;
  z-index: 1;
}

.dx-blazor-colorpicker-editors-container {
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
}
.dx-blazor-colorpicker-editors-container .dxbs-textbox:first-child {
  padding-bottom: 0.35rem;
}

.dx-blazor-colorpicker-btn-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5rem;
}
.dx-blazor-colorpicker-btn-container .btn:first-child {
  margin-right: 0.25rem;
}

.dx-image.dx-image-rotate-90 {
  transform: rotate(90deg);
}
.dx-image.dx-image-rotate-180 {
  transform: rotate(180deg);
}
.dx-image.dx-image-rotate-270 {
  transform: rotate(270deg);
}
.dx-image.dx-image-size-07em {
  width: 0.7em;
  height: 0.7em;
}
.dx-image.dx-image-size-09em {
  width: 0.9em;
  height: 0.9em;
}
.dx-image.dx-image-size-1em {
  width: 1em;
  height: 1em;
}
.dx-image.dx-image-size-1rem {
  width: 1rem;
  height: 1rem;
}
.dx-image.dx-image-size-10px {
  width: 10px;
  height: 10px;
}
.dx-image.dx-image-size-12px {
  width: 12px;
  height: 12px;
}
.dx-image.dx-image-size-14px {
  width: 14px;
  height: 14px;
}
.dx-image.dx-image-size-16px {
  width: 16px;
  height: 16px;
}
.dx-image.dx-image-size-18px {
  width: 18px;
  height: 18px;
}
.dx-image.dx-image-size-24px {
  width: 24px;
  height: 24px;
}
.dx-image.dx-image-size-32px {
  width: 32px;
  height: 32px;
}
.dx-image.dx-image-size-48px {
  width: 48px;
  height: 48px;
}
.dx-image:not(.dx-image-fill) {
  fill: currentColor;
}

/* Common */
.dxbs-position-relative {
  position: relative;
}

/* Accessibility */
.dxAIFE,
.dxAIFME {
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: polygon(0 0);
  /* clip-path: polygon(0 0); */
}

.dxAIFE {
  height: 1px;
  overflow: hidden;
  position: absolute !important;
}

.dxAIFME {
  position: fixed;
  left: 0;
  top: 0;
}

/* Edit */
.input-group .dxbs-textbox,
.input-group .dxbs-memo,
.input-group .dxbs-spin-edit,
.input-group .dxbs-dropdown-edit {
  /*hidden*/
  display: table;
  width: 100%;
}

.input-group .dxbs-textbox > div,
.input-group .dxbs-memo > div,
.input-group .dxbs-spin-edit > div:not(.dxbs-out-of-range-warn):not(.input-group),
.input-group .dxbs-dropdown-edit > div:not(.input-group-append):not(.input-group-prepend):not(.dxbs-dropdown-area):not(.dxbs-out-of-range-warn):not(.dxbs-ld):not(.dxbs-lp):not(.input-group) {
  /*hidden*/
  display: table-row;
}

.dxbs-textbox > div,
.dxbs-memo > div,
.dxbs-spin-edit,
.dxbs-spin-edit > div:not(.dxbs-out-of-range-warn),
.dxbs-spin-edit > .input-group > div:not(.dxbs-input-group-append) {
  position: relative;
}

.dxbs-textbox .form-control,
.dxbs-memo .form-control,
.dxbs-spin-edit .form-control,
.dxbs-spin-edit .input-group .form-control,
.dxbs-dropdown-edit .input-group .form-control,
.dxbs-textbox .col-form-label,
.dxbs-memo .col-form-label,
.dxbs-spin-edit .col-form-label,
.dxbs-dropdown-edit .col-form-label {
  /*hidden*/
  vertical-align: middle;
}

.dxbs-memo.dxbs-edit-clbv > .dxbs-input-group.input-group > .form-control.dxbs-input-group-append,
.dxbs-textbox.dxbs-edit-clbv > .dxbs-input-group.input-group > .form-control.dxbs-input-group-append {
  top: 0;
}

.dxbs-edit-btn.disabled,
.dxbs-dropdown-edit .input-group-append .btn.disabled,
.dxbs-spin-edit .input-group-append .btn.disabled {
  pointer-events: none;
}

.dxbs-edit-btn.inc:hover,
.dxbs-edit-btn.dec:hover {
  opacity: 0.8;
}

.dxbs-edit-btn:hover {
  cursor: pointer;
}

.dxbs-dropdown-edit.dxbs-combobox .dxbs-listbox.dropdown-menu,
.dxbs-dropdown-edit.dxbs-tagbox .dxbs-listbox.dropdown-menu {
  padding-top: 0px;
  padding-bottom: 0px;
  overflow-y: auto;
}

.dxbs-listbox > ul > li > div mark, .dxbs-listbox > ul > li > div .mark,
.dxbs-listbox > ul > li > .form-check mark,
.dxbs-listbox > ul > li > .form-check .mark {
  /*hidden*/
  padding: 0;
}

.dxbs-listbox > ul > li {
  display: block;
}

.dxbs-listbox > ul > li > div {
  cursor: pointer;
}

.dxbs-listbox.form-control li:not(.list-group-item-primary):not(.active):not(.disabled),
.dxbs-listbox.form-control .list-group-item:not(.active):not(.disabled),
.dxbs-listbox.form-control .list-group-item > label {
  /*hidden*/
  color: inherit;
  background: none;
  text-shadow: none;
}

.dxbs-listbox.form-control > ul > li:not(.active):not(.disabled) > div:not(.active):not(.disabled):not(:hover):not(:focus):not(:active) {
  text-shadow: none;
}

.dxbs-listbox.card,
.dxbs-listbox.list-group,
.dxbs-listbox > .list-group {
  margin-bottom: 0;
}

.dxbs-listbox.form-control .list-group-item:not(.active):not(.disabled):not(:active):not(:hover):not(:focus),
.dxbs-listbox ul.form-control .list-group-item:not(.active):not(.disabled):not(:active):not(:hover):not(:focus),
.dxbs-listbox.form-control > ul.list-group {
  background: none;
}

.dxbs-listbox.form-control > ul.list-group {
  border: none;
}

.dxbs-listbox.form-control > ul > li > div,
.dxbs-listbox.form-control > ul > li > div:hover,
.dxbs-listbox.form-control > ul > li > div:focus,
.dxbs-listbox.form-control > ul > li > div:active {
  border-radius: 0;
  border: none;
}

.dxbs-spin-edit > div.input-group,
.dxbs-combobox > div.input-group {
  flex-wrap: nowrap;
}

/* GridView */
.dxbs-gridview {
  display: table;
  width: 100%;
}

.dxbs-gridview > .card .dxbs-table {
  margin-bottom: 0;
}

.dxbs-gridview > .card > .card-body > .card-body {
  padding: 0;
}

.dxbs-gridview > .card .dxbs-table > tbody > tr > td,
.dxbs-gridview > .card .dxbs-table > thead > tr > th {
  vertical-align: middle;
  height: 100%;
}

.dxbs-gridview > .card .dxbs-table > tbody > tr > td.bg-primary .btn-link {
  color: white;
}

.dxbs-gridview > .card .dxbs-table > tbody > tr > td.dxbs-indent-cell {
  border-top-width: 0px;
  border-bottom-width: 0px;
  text-align: center;
}

.dxbs-gridview > .card .dxbs-table > tbody > .dxbs-empty-data-row > td {
  padding: 1em;
  text-align: center;
}

.dxbs-empty-data-row {
  opacity: 0.5;
}

.dxbs-listbox .dxbs-empty-data-row {
  padding: 1em;
  text-align: center;
}

/* non-data-header */
.dxbs-gridview > .card .dxbs-table > thead > tr > th.dxbs-cmd-cell,
.dxbs-gridview > .card .dxbs-table > thead > tr > th.dxbs-select-cell {
  cursor: default;
}

/* command cell buttons */
.dxbs-gridview > .card .dxbs-table > tbody > tr > td.dxbs-cmd-cell > .btn.btn-link,
.dxbs-gridview > .card .dxbs-table > thead > tr > th.dxbs-cmd-cell > .btn.btn-link {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: baseline;
}

.dxbs-gridview > .card .dxbs-table > tbody > tr > td mark, .dxbs-gridview > .card .dxbs-table > tbody > tr > td .mark {
  padding: 0;
}

.dxbs-gridview .dxbs-cmd-cell,
.dxbs-gridview .dxbs-select-cell {
  text-align: center;
  white-space: nowrap;
}

.dxbs-gridview .dxbs-loading {
  display: inline-block;
}

/* Fixed layout */
.dxbs-gridview .dxbs-fixed {
  table-layout: fixed;
}

.dxbs-gridview .dxbs-fixed > thead > tr > th,
.dxbs-gridview .dxbs-fixed > tbody > tr > td {
  overflow: hidden;
  white-space: nowrap;
}

.dxbs-gridview .dxbs-fixed > thead > tr > th {
  text-overflow: ellipsis;
}

.dxbs-gridview .dxbs-fixed .dxbs-dropdown-edit,
.dxbs-gridview .dxbs-fixed .dxbs-spin-edit {
  /*hidden*/
  position: static !important;
}

/* Scrolling */
.dxbs-gridview > .card > .dxgvHSDC,
.dxbs-gridview > .card > .dxgvCSD {
  border-radius: inherit;
  border-left: 0;
  border-right: 0;
  margin-bottom: 0;
  box-shadow: none;
  box-sizing: content-box;
  display: block;
  position: static;
}

.dxbs-gridview > .card > .dxgvHSDC:first-child,
.dxbs-gridview > .card > .dxgvHSDC + .dxgvCSD:not(.dxbs-grid-hsd),
.dxbs-gridview > .card > .dxgvCSD:first-child {
  /*hidden*/
  border-top: 0;
}

/* Borders */
.dxbs-gridview > .card .dxbs-table > thead > tr:first-child > th,
.dxbs-gridview > .card .dxbs-table > thead > tr:first-child > td {
  border-top: 0;
}

.dxbs-gridview .dxbs-nlb,
.dxbs-gridview > .card .dxbs-table > thead > tr > th:first-child,
.dxbs-gridview > .card .dxbs-table > thead > tr > td:first-child {
  border-left-style: none;
}

.dxbs-gridview .dxbs-nrb {
  border-right: 0;
}

.dxbs-gridview > .card .dxbs-table > thead > tr > th,
.dxbs-gridview > .card .dxbs-table > thead > tr > td {
  border-bottom-width: 0;
}

.dxbs-gridview > .card > .dxgvCSD > table {
  border-width: 0;
}

.dxbs-gridview > .card > .dxgvHSDC + .dxgvCSD:not(.dx-scrolling) > table > tbody > tr:first-child > td {
  border-top: 0;
}

.dxbs-gridview.dxbs-vertical-scrollbar-visible:not(.dxbs-fm-details) > .card > .dxgvCSD:not(.dx-scrolling) > table > tbody > tr:last-child > td {
  border-bottom: 0;
}

/* GridView and CardView headers (cells and blocks) */
.dxbs-th.card {
  margin: 0 0.5em 0 0;
  display: inline-block;
  vertical-align: middle;
}

.dxbs-th.card > .card-body {
  padding: 0.3rem 0.75rem;
}

.dxbs-gridview > .card .dxbs-table > thead > tr > th,
.dxbs-th.card > .card-body {
  cursor: pointer;
  vertical-align: middle;
}

.dxbs-gridview > .card .dxbs-table > thead > tr.dxbs-filter-row > td,
.dxbs-th.card > .card-body.dxbs-noclick {
  cursor: default;
}

.dxbs-th.card > .card-body > span {
  font-weight: 600;
}

.dxbs-th.card > .card-body > span:not(:last-child) {
  display: table-cell;
  width: 99.9%;
  vertical-align: middle;
}

.dxbs-gridview > .card .dxbs-table > thead > tr > th > button:not(.dxbs-cmd-btn),
.dxbs-th.card > .card-body > button {
  padding: 0 0.2rem;
  border-top: none;
  border-bottom: none;
}

.dxbs-fixed-header-content {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -ms-flex-align: center;
}

.dxbs-fixed-header-content > span {
  flex-grow: 1;
  font-weight: 600;
}

.dxbs-fixed-header-content > button {
  padding: 0 0.2rem;
  text-decoration: none;
  border-top: none;
  border-bottom: none;
}

.dxbs-gridview .dxbs-fixed .dxbs-fixed-header-content > span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dxbs-gridview td.table-active.bg-primary.text-white > .dxbs-checkbox > .custom-control > .custom-control-label {
  mix-blend-mode: screen;
}

/* PopupControl */
.dxbs-modal.modal {
  overflow: auto;
}

.dxbs-modal.modal .drag {
  cursor: default;
}

.dxbs-modal-back {
  visibility: hidden;
}

/* Pager */
.dxbs-grid-pager .pagination {
  margin: 0;
  float: left;
}

.dxbs-spin-edit > div.input-group > div:not(.input-group-append):not(.input-group-prepend):not(.dxbs-out-of-range-warn), .dxbs-dropdown-edit > .input-group > div:not(.input-group-append):not(.input-group-prepend):not(.dxbs-dropdown-area):not(.dxbs-out-of-range-warn):not(.dxbs-ld):not(.dxbs-lp), .dxbs-spin-edit > div.input-group > div:not(.input-group-append):not(.input-group-prepend):not(.dxbs-out-of-range-warn) > .form-control, .dxbs-textbox > div.input-group > div:not(.input-group-append):not(.input-group-prepend), .dxbs-memo > div.input-group > div:not(.input-group-append):not(.input-group-prepend), .dxbs-dropdown-edit > .input-group > div:not(.input-group-append):not(.input-group-prepend):not(.dxbs-dropdown-area):not(.dxbs-out-of-range-warn):not(.dxbs-ld):not(.dxbs-lp) > .form-control {
  flex: 1 1 auto;
  -ms-flex: 1 1 auto;
}

.dxbs-listbox > .list-group > li {
  display: block;
}

.dxbs-listbox > .list-group > li {
  flex: 0 1 auto;
  -ms-flex: 0 0 auto;
}

.dxbs-listbox > ul > li > .form-check.form-check-inline {
  margin-right: 0;
}

.dxbs-listbox > li > .form-check > label {
  display: inline-block;
  position: relative;
}

.dxbs-fast-nav.dropdown-menu,
.dxbs-dropdown-area.dropdown-menu {
  position: absolute;
  margin-bottom: 0px;
  padding: 0px;
}

.dxbs-gridview > .card {
  display: block;
}

.dxbs-gridview .dxbs-th.card > .card-body {
  display: flex;
  display: -ms-flexbox;
  flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  align-items: center;
  -ms-flex-align: center;
}

.dxbs-gridview .dxbs-th.card > .card-body > span:not(:last-child) {
  display: inline-block;
}

.dxbs-grid-pager .pagination {
  float: none;
}

/* Blazor */
/** Blazor Data Grid **/
.dxbs-fixed-header-content > span:hover,
.dxbs-fixed-header-content > span:focus {
  text-decoration: underline;
}

.dxbs-fixed-header-content > button.btn.btn-link,
td.dxbs-cmd-cell > button.btn.btn-link,
th.dxbs-cmd-cell > button.btn.btn-link {
  font-size: inherit;
}

th.dxbs-cmd-cell > button.btn.btn-link {
  font-weight: 600;
}

/* Virtual Scrolling */
.dxbs-gridview > div > .dxbs-grid-vsd {
  height: 200px;
  overflow-x: hidden;
}

.dxbs-gridview.dxbs-has-vertical-scrollbar.dxbs-vertical-scrollbar-visible > .card > .dxgvHSDC.dxbs-scrollbar-padding {
  padding-right: var(--dx-scrollbar-width, 17px);
}

.dxgvHSDC > .dxbs-table.table-bordered {
  border-width: 0;
}

.dxgvCSD {
  -webkit-overflow-scrolling: touch;
}

/* There is not pager */
.dxbs-gridview > .card > .dxgvHSDC + .dxgvCSD {
  border-bottom: none;
}

.dxbs-gridview > .card > .dxgvCSD + .card-body {
  border-color: inherit;
  border-width: 0;
  border-style: inherit;
  border-top-width: inherit;
}

.dxbs-table > tbody > tr.dxbs-grid-first-level-group > td.dxbs-toggle-cell,
table.dxbs-table:not(.dxbs-grouped) > tbody > tr > td:first-child:not(.dxbs-gridview-row-details) {
  border-left: 0;
}

/* command cell buttons */
.dxbs-gridview > .card .dxbs-table > tbody > tr > td.dxbs-cmd-cell > span > .btn.btn-link,
.dxbs-gridview > .card .dxbs-table > tbody > tr > td.dxbs-cmd-cell > span > .dxgBCTC > .btn.btn-link,
.dxbs-gridview > .card .dxbs-table > thead > tr > th.dxbs-cmd-cell > span > .btn.btn-link {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
}

/* Column Head Dragging */
.dx-dragging-state {
  position: absolute !important;
  z-index: 20000;
  will-change: transform;
  /*animation: translating 4s linear infinite;*/
  visibility: hidden;
  cursor: move;
  -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
  justify-content: center;
  white-space: nowrap;
}

/*hidden*/
.dxbs-th.dx-dragging-state > .card-body {
  display: flex;
  flex-flow: row nowrap;
}

.dxgv-target-marks {
  position: absolute !important;
  z-index: 19999;
  display: flex;
  flex-flow: column;
  transform: translateX(-50%);
}

.dxgv-target-marks > .dx-image.dx-image-rotate-180 {
  transform: translateY(-100%) rotate(180deg);
  top: 2px;
}

.dxgv-target-marks > .dx-image {
  transform: translateY(100%);
  position: relative;
  display: inline-block;
  line-height: 1;
}

.dxbs-group-panel-empty {
  border-color: transparent !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

/* Column Grouping */
/*lack*/
.dxbs-header-indent {
  border-left-color: transparent !important;
}

/*lack*/
.dxbs-header-indent:not(.dxbs-header-indent-last) {
  border-right-color: transparent !important;
}

/*lack*/
.dxgvArm > .dxbs-header-indent {
  font-size: 0;
}

/*lack*/
.dxgvArm > .dxbs-header-indent > .invisible {
  height: 0;
}

/** Blazor ComboBox **/
.dx-dropdown-list-container > .dropdown-menu {
  padding: 0;
}

/*hidden*/
.dx-listbox .input-group-append > .dropdown-menu > table {
  margin-bottom: 0;
}

/*hidden*/
.dx-listbox .input-group-append > .dropdown-menu > table > tbody tr:first-child > td {
  border-top: none;
}

.dx-listbox-scrollable,
.dxbs-listbox.dxbs-grid-vsd.form-control {
  overflow-x: hidden;
  overflow-y: auto;
}

.dxbs-listbox.dxbs-grid-vsd.form-control {
  display: block;
}

/*lack*/
.dx-lb-datagrid > .card {
  border: 0;
}

/*lack*/
.dx-lb-datagrid > .card > table > tbody > tr:last-child > td {
  border-bottom: 0;
}

/*hidden*/
div.dxbs-dm > .dxbs-listbox,
div.dxbs-dm.dxbs-listbox > ul,
.dxbs-listbox.dxbs-grid-vsd.form-control > ul {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-bottom: 0;
}

/*lack*/
.dx-lb-datagrid > .card > table.dxbs-table > tbody > tr.dropdown-item {
  padding: 0;
}

/* Common for Blazor */
.dxbs-border-radius-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.dx-border-radius-inherit {
  border-radius: inherit !important;
}

.dx-border-radius-left-inherit {
  border-top-left-radius: inherit !important;
  border-bottom-left-radius: inherit !important;
}

.dx-border-radius-right-inherit {
  border-top-right-radius: inherit !important;
  border-bottom-right-radius: inherit !important;
}

.dx-border-radius-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn.dxbs-cmd-btn:hover:before, .btn.dxbs-cmd-btn:focus:before, .dxbs-tree ul.nav > .nav-item > .nav-link:not(.disabled):not(.active):hover:before, .dxbs-listbox .list-group-item-action:not(.disabled):not(.dxbs-listbox-active):not(.active):hover:before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.1;
}

.btn.dxbs-cmd-btn:focus:hover:before {
  opacity: 0.2;
}

.dxbs-gridview .dxbs-loading.dxbs-toolbar,
.dxbs-toolbar {
  display: flex;
}
.dxbs-gridview .dxbs-loading.dxbs-toolbar .dxbs-tb-flex1,
.dxbs-toolbar .dxbs-tb-flex1 {
  flex: 1;
}

.dxbs-toolbar > .btn-toolbar > .btn-group > .btn-group[data-toggle=toolbar-dropdownmode] > .dropdown-toggle::after {
  margin-left: 0;
}

.dxbs-toolbar:not(.dxbs-ta-nib) > .btn-toolbar > .btn-group:not(:first-child):not(.dxbs-tb-begin-group) {
  margin-left: 0.5rem;
}

.dxbs-toolbar .dxbs-toolbar-btn.btn.dxbs-ta-nib:not(:focus),
.dxbs-toolbar .dxbs-toolbar-btn > .btn.dxbs-ta-nib:not(:focus),
.dxbs-toolbar-dropdown .dropdown-item.btn:not(:focus),
.dxbs-toolbar.dxbs-ta-nib .dxbs-ta-ag > .btn:not(:focus) {
  box-shadow: none;
  border-color: transparent;
}

/*Layout*/
.dxbs-toolbar,
.dxbs-toolbar > .btn-toolbar {
  user-select: none;
  display: flex;
  flex-wrap: nowrap;
}

.dxbs-toolbar-adaptive {
  position: relative;
  flex: 1 1 auto;
  justify-content: flex-start;
  transition: height ease 0.3s;
  will-change: height;
  white-space: nowrap;
}

.dxbs-toolbar-adaptive > .btn-toolbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  padding: inherit;
  justify-content: inherit;
  align-items: flex-start;
}

.dxbs-toolbar-adaptive > .btn-toolbar > div,
.dxbs-toolbar-adaptive > .btn-toolbar > div > .btn,
.dxbs-toolbar-adaptive > .btn-toolbar > div > .btn-group {
  display: inline-flex;
  align-self: stretch;
}

.dxbs-ta-title {
  display: inline-flex;
  align-items: center;
  padding: 0 1rem;
}

.dxbs-toolbar .dxbs-toolbar-btn .image + span,
.dxbs-toolbar .dxbs-ta-ag .image + span,
.dxbs-toolbar .dxbs-toolbar-btn .dxbs-check.image,
.dxbs-toolbar-dropdown-item .image + span {
  margin-left: 0.4em;
}

.dxbs-toolbar-adaptive .btn.dropdown-toggle > .image:only-child {
  margin-right: 0.4em;
}

.dxbs-toolbar-btn > .dropdown-toggle:not(.dropdown-toggle-split)::after,
.dxbs-toolbar .dxbs-ta-ag > .dropdown-toggle:after {
  margin-left: 0.4em;
}

.dxbs-toolbar-adaptive > .no-item-text.btn-toolbar > .btn-group > .btn > .image:not(:only-child),
.dxbs-toolbar-adaptive > .no-item-text.btn-toolbar > .btn-group > .btn-group > .btn > .image:not(:only-child) {
  margin: 0;
}

.dxbs-toolbar-adaptive > .no-item-text.btn-toolbar > .btn-group > .btn > .image + span:not(.popout),
.dxbs-toolbar-adaptive > .no-item-text.btn-toolbar > .btn-group > .btn-group > .btn > .image + span:not(.popout) {
  display: none;
}

.dxbs-toolbar-dropdown .dropdown-item.dropdown-toggle:after {
  margin-left: auto;
}

.dxbs-toolbar-dropdown .dropdown-item.dropdown-toggle,
.dxbs-toolbar-dropdown .dropdown-item + .dropdown-toggle {
  padding-right: 0.75rem;
}

.dxbs-toolbar-dropdown .dropdown-item + .dropdown-toggle {
  padding-left: 0.75rem;
}

.dxbs-toolbar-btn > .btn.dropdown-toggle::before {
  border: none;
}

.dxbs-toolbar-btn > .btn.dropdown-toggle::after,
.dxbs-toolbar.dxbs-ta-nib .dxbs-ta-ag > .btn:after {
  opacity: 1;
  width: auto;
  height: auto;
  left: auto;
  top: auto;
  position: static;
}

/*states*/
.dxbs-toolbar-adaptive .dxbs-check.image {
  visibility: hidden;
}

.dxbs-toolbar-adaptive .checked > span:not(.image) {
  font-weight: 600;
}

/*no background mode*/
.dxbs-toolbar .dxbs-toolbar-btn.btn.dxbs-ta-nib,
.dxbs-toolbar .dxbs-toolbar-btn > .btn.dxbs-ta-nib,
.dxbs-toolbar.dxbs-ta-nib .dxbs-ta-ag > .btn {
  border-color: transparent;
  border-radius: 3px;
  margin-left: 0px;
}

.dx-toolbar-split-btn > .btn.dxbs-ta-nib.active.dx-toolbar-split-dropdown-btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.dx-toolbar-split-btn > .btn.dxbs-ta-nib.active.dx-toolbar-split-main-btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.dxbs-toolbar-adaptive.dxbs-ta-nib > .btn-toolbar > .btn-group:not(:first-child):not(.dxbs-tb-begin-group):before,
.dxbs-toolbar-adaptive.dxbs-ta-nib > .btn-toolbar > .dxbs-toolbar-group:not(:first-child):not(.dxbs-tb-begin-group):before {
  content: " ";
  display: inline-flex;
  border-left: 1px solid currentColor;
  height: calc(100% - 0.25rem);
  opacity: 0.2;
  margin-right: -1px;
  transform: translateX(0.5rem);
  align-self: center;
}

.dxbs-toolbar-adaptive.dxbs-ta-nib > .btn-toolbar > .btn-group:not(:first-child):not(.dxbs-tb-begin-group) > :first-child,
.dxbs-toolbar-adaptive.dxbs-ta-nib > .btn-toolbar > .btn-group:not(:first-child):not(.dxbs-tb-begin-group) > .dx-toolbar-first-visible-child,
.dxbs-toolbar-adaptive.dxbs-ta-nib > .btn-toolbar > .dxbs-toolbar-group:not(:first-child):not(.dxbs-tb-begin-group) > :first-child,
.dxbs-toolbar-adaptive.dxbs-ta-nib > .btn-toolbar > .dxbs-toolbar-group:not(:first-child):not(.dxbs-tb-begin-group) > .dx-toolbar-first-visible-child {
  margin-left: 1rem;
}

.dxbs-toolbar-adaptive.dxbs-ta-nib > .btn-toolbar > .dxbs-ta-title + .btn-group > :first-child {
  margin-left: 0;
}

.dxbs-toolbar-adaptive.dxbs-ta-nib > .btn-toolbar > .dxbs-ta-title + .btn-group:before {
  display: none;
}

.dxbs-toolbar .dxbs-toolbar-btn.btn.dxbs-ta-nib:not(.dropdown-toggle)::after,
.dxbs-toolbar .dxbs-toolbar-btn > .btn.dxbs-ta-nib:not(.dropdown-toggle)::after,
.dxbs-toolbar .dxbs-toolbar-btn.btn.dxbs-ta-nib::before,
.dxbs-toolbar .dxbs-toolbar-btn > .btn.dxbs-ta-nib::before,
.dxbs-toolbar.dxbs-ta-nib .dxbs-ta-ag.dx-hide-dropdown-mark > .btn:after {
  opacity: 0;
}

.dxbs-toolbar.dxbs-ta-nib .dxbs-ta-ag > .btn:before {
  display: none;
}

.dxbs-toolbar.dxbs-ta-nib .dxbs-ta-ag > .btn:not(:hover):not(:active):not(.active),
.dxbs-toolbar .dxbs-toolbar-btn.btn.dxbs-ta-nib:not(:hover):not(:active):not(.active),
.dxbs-toolbar .dxbs-toolbar-btn > .btn.dxbs-ta-nib:not(:hover):not(:active):not(.active),
.dxbs-toolbar .dxbs-toolbar-btn.btn.dxbs-ta-nib:not(.active):disabled,
.dxbs-toolbar .dxbs-toolbar-btn > .btn.dxbs-ta-nib:not(.active):disabled {
  background: none;
  color: inherit;
}

/*before initialized*/
.dxbs-toolbar-adaptive.dxbs-loading > .btn-toolbar {
  position: relative;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.dxbs-toolbar-adaptive.dxbs-loading.dxbs-ta-nib > .btn-toolbar > .btn-group:before {
  display: none;
}

.dxbs-toolbar-adaptive.dxbs-loading > .btn-toolbar > .btn-group:not(.dxbs-ta-ag) {
  visibility: hidden;
  width: 1px;
}

.dxbs-toolbar-adaptive.dxbs-loading:not(.dx-r-toolbar) > .btn-toolbar > .btn-group:last-child > .btn:first-child {
  visibility: visible !important;
  position: static !important;
  opacity: 0.3;
  cursor: default;
}

/*overrides to refactor*/
.dxbs-toolbar-adaptive .dxbs-icon {
  display: inline-flex;
  line-height: unset;
  position: static;
  overflow: hidden;
}

/*toolbar button*/
.dxbs-toolbar-btn.btn,
.dxbs-toolbar-btn > .btn,
.dxbs-toolbar .dxbs-ta-ag > .btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.dxbs-toolbar-btn.btn.disabled,
.dxbs-toolbar-btn > .btn.disabled,
.dxbs-toolbar .dxbs-ta-ag > .btn.disabled {
  pointer-events: auto;
  cursor: default;
}

.dxbs-toolbar-dropdown-item > dxbl-toolbar-menu-item > .dropdown-item {
  display: inline-flex;
  justify-content: start;
  align-items: center;
}

.dxbs-toolbar-dropdown-item:not(.dx-toolbar-split-btn) > dxbl-toolbar-menu-item > .dropdown-item {
  padding-right: 2rem;
}

.dxbs-toolbar > .btn-toolbar.dxbs-focus-hidden .dxbs-toolbar-btn > .btn:focus, .dxbs-toolbar > .btn-toolbar.dxbs-focus-hidden .dxbs-toolbar-btn.btn:focus, .dxbs-toolbar.dxbs-ta-nib .dxbs-toolbar-group, .dxbs-toolbar.dxbs-ta-nib .dxbs-ta-ag, .dxbs-toolbar > .btn-toolbar.dxbs-focus-hidden .dxbs-ta-ag > .btn:focus, .dxbs-toolbar > .dxbs-loading.dxbs-toolbar .dxbs-ta-ag, .dxbs-toolbar.dxbs-ta-nib .dxbs-toolbar-group.dxbs-toolbar-group-lg > .btn-group {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.dxbs-toolbar-dropdown-item > dxbl-toolbar-menu-item > .dropdown-item:focus {
  outline: none;
}

.dxbs-toolbar-dropdown-item.dropdown-item-text {
  padding: 0;
  display: flex;
}

.dxbs-focus-hidden .dxbs-toolbar-dropdown-item > dxbl-toolbar-menu-item > .dropdown-item:focus:not(:hover):not(:active):not(.active) {
  background: none;
  color: inherit;
}

.tb-back-btn.btn.dxbs-cmd-btn, .tb-cancel-btn.btn.dxbs-cmd-btn {
  padding: calc(0.3rem - 1px);
  color: inherit;
  width: auto;
  height: auto;
  flex: 0 0 auto;
}

.tb-hidden-btn {
  visibility: hidden;
}

.dx-hide-dropdown-mark.dropdown-toggle::after {
  content: none;
}

.dxbs-visually-hidden {
  visibility: hidden;
  transform: translatex(-100000px) !important;
}

/* item spacing large */
.dxbs-toolbar-adaptive > .no-item-text.btn-toolbar > .dxbs-toolbar-group.dxbs-toolbar-group-lg > .btn-group > .btn > .image:not(:only-child), .dxbs-toolbar-adaptive > .no-item-text.btn-toolbar > .dxbs-toolbar-group.dxbs-toolbar-group-lg > .btn-group > .btn-group > .btn > .image:not(:only-child) {
  margin: 0;
}
.dxbs-toolbar-adaptive > .no-item-text.btn-toolbar > .dxbs-toolbar-group.dxbs-toolbar-group-lg > .btn-group > .btn > .image + span:not(.popout), .dxbs-toolbar-adaptive > .no-item-text.btn-toolbar > .dxbs-toolbar-group.dxbs-toolbar-group-lg > .btn-group > .btn-group > .btn > .image + span:not(.popout) {
  display: none;
}

.dxbs-toolbar > .btn-toolbar > .dxbs-toolbar-group.dxbs-toolbar-group-lg > .btn-group + .btn-group {
  margin-left: 0.5rem;
}
.dxbs-toolbar > .btn-toolbar > .dxbs-toolbar-group.dxbs-toolbar-group-lg + .dxbs-toolbar-group:not(.btn-group), .dxbs-toolbar > .btn-toolbar > .dxbs-toolbar-group.dxbs-toolbar-group-lg + .dxbs-tb-flex1 + .dxbs-toolbar-group:not(.btn-group), .dxbs-toolbar > .btn-toolbar > .dxbs-toolbar-group.dxbs-toolbar-group-lg + .dxbs-ta-ag, .dxbs-toolbar > .btn-toolbar > .dxbs-toolbar-group.dxbs-toolbar-group-lg + .dxbs-tb-flex1 + .dxbs-ta-ag {
  margin-left: 1rem;
}

/* toolbar-edit-item */
.dx-r-toolbar.dxbs-toolbar.dxbs-ta-nib > .btn-toolbar > .btn-group > :not(:first-child) {
  margin-left: 0.5rem;
}

.dx-r-toolbar.dxbs-toolbar:not(.dxbs-ta-nib) > .btn-toolbar > .dx-toolbar-edit:not(:last-child) {
  margin-right: 0.5rem;
}

.dx-r-toolbar.dxbs-toolbar.dxbs-ta-nib > .btn-toolbar > .dx-toolbar-edit:not(:first-child):not(.dxbs-tb-begin-group) {
  margin-left: 1rem;
}

.dx-r-toolbar.dxbs-toolbar.dxbs-ta-nib.dxbs-loading .dx-toolbar-edit .dxbs-checkbox.form-control,
.dxbs-toolbar-dropdown .dxbs-checkbox.form-control {
  width: auto;
}

.dx-r-toolbar.dxbs-toolbar.dxbs-ta-nib .dx-toolbar-edit .dxbs-checkbox.form-control,
.dxbs-toolbar-dropdown .dx-toolbar-edit .dxbs-checkbox.form-control {
  background: none;
  border-color: transparent;
  color: currentColor;
}

.dxbs-toolbar-dropdown .dx-toolbar-edit .dxbs-combobox button:not(:hover):not(:active):not(:focus) {
  background: none !important;
  color: inherit !important;
}
.dxbs-toolbar-dropdown .dx-toolbar-edit .dxbs-combobox .dxbs-input-group-append {
  color: inherit;
}
.dxbs-toolbar-dropdown .dx-toolbar-edit .dxbs-combobox input {
  background-color: transparent !important;
  color: inherit;
}

.dx-r-toolbar.dxbs-toolbar.dxbs-ta-nib .dx-toolbar-edit .dxbs-combobox input,
.dxbs-toolbar-dropdown .dx-toolbar-edit .dxbs-combobox input {
  border: none;
  box-shadow: none;
}
.dx-r-toolbar.dxbs-toolbar.dxbs-ta-nib .dx-toolbar-edit .dxbs-combobox .dxbs-editor-dropdown-button,
.dxbs-toolbar-dropdown .dx-toolbar-edit .dxbs-combobox .dxbs-editor-dropdown-button {
  border-left: none;
}
.dx-r-toolbar.dxbs-toolbar.dxbs-ta-nib .dx-toolbar-edit .dxbs-combobox:focus-within .input-group,
.dxbs-toolbar-dropdown .dx-toolbar-edit .dxbs-combobox:focus-within .input-group {
  z-index: 1;
}

.dxbs-toolbar .dx-toolbar-edit .dxbs-combobox input[readonly],
.dxbs-toolbar-dropdown .dx-toolbar-edit .dxbs-combobox input[readonly] {
  cursor: pointer;
}

.dxbs-toolbar-dropdown-item.dx-toolbar-edit .dxbs-combobox .btn.dxbs-editor-dropdown-button {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.dx-toolbar-edit > dxbl-toolbar-menu-item > div,
.dx-toolbar-edit > div {
  padding-right: 0.5rem;
  display: inline-flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 400;
  width: 100%;
  height: 100%;
  white-space: nowrap;
}
.dx-toolbar-edit > dxbl-toolbar-menu-item > div > .image,
.dx-toolbar-edit > div > .image {
  flex-shrink: 0;
}
.dx-toolbar-edit > dxbl-toolbar-menu-item > div > .dxbs-combobox:not(:first-child), .dx-toolbar-edit > dxbl-toolbar-menu-item > div > .dxbs-spin-edit:not(:first-child), .dx-toolbar-edit > dxbl-toolbar-menu-item > div > .dxbs-checkbox:not(:first-child),
.dx-toolbar-edit > div > .dxbs-combobox:not(:first-child),
.dx-toolbar-edit > div > .dxbs-spin-edit:not(:first-child),
.dx-toolbar-edit > div > .dxbs-checkbox:not(:first-child) {
  margin-left: 0.4em;
}
.dx-toolbar-edit > dxbl-toolbar-menu-item > div > .dxbs-combobox:not(:first-child):before, .dx-toolbar-edit > dxbl-toolbar-menu-item > div > .dxbs-spin-edit:not(:first-child):before, .dx-toolbar-edit > dxbl-toolbar-menu-item > div > .dxbs-checkbox:not(:first-child):before,
.dx-toolbar-edit > div > .dxbs-combobox:not(:first-child):before,
.dx-toolbar-edit > div > .dxbs-spin-edit:not(:first-child):before,
.dx-toolbar-edit > div > .dxbs-checkbox:not(:first-child):before {
  content: "";
  margin-left: 0.4em;
}

.dxbs-toolbar-dropdown-item.dx-toolbar-edit + .dxbs-toolbar-dropdown-item:not(.dx-toolbar-edit),
.dxbs-toolbar-dropdown-item:not(.dx-toolbar-edit) + .dxbs-toolbar-dropdown-item.dx-toolbar-edit,
.dxbs-toolbar-dropdown.dxbs-toolbar-modal .dxbs-toolbar-dropdown-item.dx-toolbar-edit:first-child {
  padding-top: 0.25rem;
}

.dxbs-toolbar-dropdown-item.dx-toolbar-edit > dxbl-toolbar-menu-item > div > .dxbs-combobox, .dxbs-toolbar-dropdown-item.dx-toolbar-edit > dxbl-toolbar-menu-item > div > .dxbs-spin-edit, .dxbs-toolbar-dropdown-item.dx-toolbar-edit > dxbl-toolbar-menu-item > div > .dxbs-checkbox {
  margin-left: auto;
}

.dxbs-focus-hidden .dxbs-toolbar-dropdown-item.dx-toolbar-edit > dxbl-toolbar-menu-item > div > .dxbs-combobox input:focus {
  box-shadow: none;
}

.dx-r-toolbar.dxbs-toolbar.dxbs-ta-nib .dxbs-ta-ag > .btn:before {
  display: block;
  border-top: none;
  border-right: none;
  border-left: none;
}

.dxbs-toolbar.dxbs-ta-nib.dx-r-toolbar > .btn-toolbar > .btn-group .dxbs-toolbar-btn.btn,
.dxbs-toolbar.dxbs-ta-nib.dx-r-toolbar > .btn-toolbar > .btn-group .dxbs-toolbar-btn > .btn,
.dxbs-toolbar.dxbs-ta-nib.dx-r-toolbar .dxbs-ta-ag > .btn,
.dxbs-toolbar-dropdown-item > dxbl-toolbar-menu-item > .dropdown-item:not(:disabled) {
  background: none;
  color: currentColor;
  border-left-width: 0;
  border-right-width: 0;
}
.dxbs-toolbar.dxbs-ta-nib.dx-r-toolbar > .btn-toolbar > .btn-group .dxbs-toolbar-btn.btn:before,
.dxbs-toolbar.dxbs-ta-nib.dx-r-toolbar > .btn-toolbar > .btn-group .dxbs-toolbar-btn > .btn:before,
.dxbs-toolbar.dxbs-ta-nib.dx-r-toolbar .dxbs-ta-ag > .btn:before,
.dxbs-toolbar-dropdown-item > dxbl-toolbar-menu-item > .dropdown-item:not(:disabled):before {
  z-index: 1 !important;
  border-radius: 3px;
  pointer-events: none;
  margin-left: unset;
}
.dxbs-toolbar.dxbs-ta-nib.dx-r-toolbar > .btn-toolbar > .btn-group .dxbs-toolbar-btn.btn:hover:not(:disabled):not(.active):not(:active):before,
.dxbs-toolbar.dxbs-ta-nib.dx-r-toolbar > .btn-toolbar > .btn-group .dxbs-toolbar-btn > .btn:hover:not(:disabled):not(.active):not(:active):before,
.dxbs-toolbar.dxbs-ta-nib.dx-r-toolbar .dxbs-ta-ag > .btn:hover:not(:disabled):not(.active):not(:active):before,
.dxbs-toolbar-dropdown-item > dxbl-toolbar-menu-item > .dropdown-item:not(:disabled):hover:not(:disabled):not(.active):not(:active):before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.05;
}
.dxbs-toolbar.dxbs-ta-nib.dx-r-toolbar > .btn-toolbar > .btn-group .dxbs-toolbar-btn.btn:active:not(:disabled):before,
.dxbs-toolbar.dxbs-ta-nib.dx-r-toolbar > .btn-toolbar > .btn-group .dxbs-toolbar-btn > .btn:active:not(:disabled):before,
.dxbs-toolbar.dxbs-ta-nib.dx-r-toolbar .dxbs-ta-ag > .btn:active:not(:disabled):before,
.dxbs-toolbar-dropdown-item > dxbl-toolbar-menu-item > .dropdown-item:not(:disabled):active:not(:disabled):before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.2;
}
.dxbs-toolbar.dxbs-ta-nib.dx-r-toolbar > .btn-toolbar > .btn-group .dxbs-toolbar-btn.btn.active:before,
.dxbs-toolbar.dxbs-ta-nib.dx-r-toolbar > .btn-toolbar > .btn-group .dxbs-toolbar-btn > .btn.active:before,
.dxbs-toolbar.dxbs-ta-nib.dx-r-toolbar .dxbs-ta-ag > .btn.active:before,
.dxbs-toolbar-dropdown-item > dxbl-toolbar-menu-item > .dropdown-item:not(:disabled).active:before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.1;
}

.dxbs-toolbar-dropdown-item > dxbl-toolbar-menu-item > .dropdown-item:not(:disabled):before {
  border-radius: 0;
}

.dx-toolbar-edit.dx-toolbar-coloredit > .btn:first-child > .image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.dx-toolbar-edit.dx-toolbar-coloredit > .btn:first-child > .image-container:not(.image-h):after {
  content: " ";
  height: 2px;
  background-color: var(--back-color);
  min-width: 16px;
  opacity: 1;
  position: absolute;
  bottom: 0;
}

.dx-toolbar-edit.dx-toolbar-coloredit > .btn > .image-container.image-h,
.dxbs-toolbar-dropdown-item.dx-toolbar-coloredit > dxbl-toolbar-menu-item > .dropdown-item > .image-container {
  position: relative;
  overflow: hidden;
  content: " ";
  background-color: var(--back-color);
  width: 17px;
  height: 17px;
  border: 1px solid currentColor;
}

.dx-toolbar-edit.dx-toolbar-coloredit > .btn > .image-container.image-h.dx-toolbar-coloredit-empty:before,
.dxbs-toolbar-dropdown-item.dx-toolbar-coloredit > dxbl-toolbar-menu-item > .dropdown-item > .image-container.dx-toolbar-coloredit-empty:before {
  content: "";
  position: absolute;
  top: 0;
  left: calc(-50% + 1px);
  right: 0;
  transform: rotate(-45deg) translateY(-1px);
  transform-origin: 100% 0;
  border-top: 2px solid red;
}

.dxbs-toolbar:not(.dx-r-toolbar) > .btn-toolbar > .btn-group > .btn-group.dxbs-ta-ag {
  display: none;
}

.dxbs-loaded.dx-r-toolbar .block-g.item-h ~ .block-g > .block-i.dxbs-ta-ag,
.dxbs-loaded.dx-r-toolbar .block-g.item-h > .block-i.dxbs-ta-ag {
  display: inline-flex;
}

.dxbs-loaded.dx-r-toolbar > .btn-toolbar > .btn-group > .btn.item-text-h > .image:not(:only-child),
.dxbs-loaded.dx-r-toolbar > .btn-toolbar > .btn-group > .btn-group.item-text-h > .btn > .image:not(:only-child) {
  margin-right: 0;
}

.dxbs-loaded.dx-r-toolbar > .btn-toolbar .block-g > .block-i.item-text-h:not(.dxbs-ta-ag):not(:only-child) > .image:not(:only-child),
.dxbs-loaded.dx-r-toolbar > .btn-toolbar .block-g > .block-i.item-text-h:not(.dxbs-ta-ag):not(:only-child) > .btn > .image:not(:only-child),
.dxbs-loaded.dx-r-toolbar > .btn-toolbar.item-text-h .block-g > .block-i:not(.dxbs-ta-ag):not(:only-child) > .image:not(:only-child),
.dxbs-loaded.dx-r-toolbar > .btn-toolbar.item-text-h .block-g > .block-i:not(.dxbs-ta-ag):not(:only-child) > .btn > .image:not(:only-child) {
  margin-right: 0.4em;
}

.dxbs-loaded.dx-r-toolbar .item-h .block-g.item-a + .dxbs-tb-flex1 ~ .block-g > .block-i > .btn > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar .item-h .block-g.item-a ~ .block-g > .block-i > .btn > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar .block-g.item-text-h + .dxbs-tb-flex1 ~ .block-g > .block-i:not(.dxbs-ta-ag) > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar .block-g.item-text-h + .dxbs-tb-flex1 ~ .block-g > .block-i:not(.dxbs-ta-ag) > .btn > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar .block-g.item-text-h ~ .block-g > .block-i:not(.dxbs-ta-ag) > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar .block-g.item-text-h ~ .block-g > .block-i:not(.dxbs-ta-ag) > .btn > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar > .btn-toolbar.item-text-h .block-g > .block-i:not(.dxbs-ta-ag):not(:only-child) > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar > .btn-toolbar.item-text-h .block-g > .block-i:not(.dxbs-ta-ag):not(:only-child) > .btn > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar .block-g > .block-i.item-text-h ~ .block-i:not(.dxbs-ta-ag) > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar .block-g > .block-i.item-text-h ~ .block-i:not(.dxbs-ta-ag) > .btn > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar > .btn-toolbar > .btn-group > .btn.item-text-h > .image + span,
.dxbs-loaded.dx-r-toolbar > .btn-toolbar > .btn-group > .btn-group.item-text-h > .btn > .image + span {
  display: none;
}

.dx-r-toolbar.dxbs-toolbar {
  position: static;
}

.dx-r-toolbar.dxbs-toolbar > .btn-toolbar {
  position: static;
  padding: unset;
  flex: 1;
}

.dx-r-toolbar.dxbs-toolbar.dxbs-loading > .btn-toolbar {
  position: static;
  overflow: unset;
}

.dx-r-toolbar.dxbs-toolbar.dxbs-loading > .btn-toolbar > .btn-group:not(.dxbs-ta-ag) {
  visibility: unset;
  width: auto;
}

.dxbs-toolbar .ta-hidden-item:not(.preview-image),
.dxbs-loaded.dxbs-toolbar .dxbs-ta-ag .preview-image.ta-hidden-item,
.dxbs-loaded.dxbs-toolbar .dxbs-ta-ag .preview-image.ta-hidden-item + .text {
  visibility: hidden !important;
  position: absolute !important;
  top: -10000px !important;
  left: -10000px !important;
}

.dxbs-loaded.dxbs-toolbar .item-h .block-g.item-a > .block-i > .btn > span:not(.image) {
  display: none;
}

.dx-r-toolbar > .btn-toolbar > .btn-group > .btn-group.dxbs-ta-ag > .btn:not(:active):not(.active):not(:hover):before {
  opacity: 0;
}

.dx-r-toolbar .btn-group {
  position: static;
}

.dxbs-toolbar.dxbs-toolbar.dx-r-toolbar .btn-group.dx-toolbar-split-btn:not(.disabled):hover > button.dx-toolbar-split-subbtn:not(.active):not(:active):before, .dxbs-toolbar.dxbs-toolbar.dx-r-toolbar .btn-group.dx-toolbar-split-btn:not(.disabled):hover > a.dx-toolbar-split-subbtn:not(.active):not(:active):before,
.dxbs-toolbar-dropdown .dxbs-toolbar-dropdown-item.dx-toolbar-split-btn:not(.disabled) > dxbl-toolbar-menu-item:hover > button.dx-toolbar-split-subbtn:not(.active):not(:active):before,
.dxbs-toolbar-dropdown .dxbs-toolbar-dropdown-item.dx-toolbar-split-btn:not(.disabled) > dxbl-toolbar-menu-item:hover > a.dx-toolbar-split-subbtn:not(.active):not(:active):before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.05;
  z-index: 1 !important;
}
.dxbs-toolbar.dxbs-toolbar.dx-r-toolbar .btn-group.dx-toolbar-split-btn:not(.disabled):hover > button.dx-toolbar-split-subbtn:hover, .dxbs-toolbar.dxbs-toolbar.dx-r-toolbar .btn-group.dx-toolbar-split-btn:not(.disabled):hover > a.dx-toolbar-split-subbtn:hover,
.dxbs-toolbar-dropdown .dxbs-toolbar-dropdown-item.dx-toolbar-split-btn:not(.disabled) > dxbl-toolbar-menu-item:hover > button.dx-toolbar-split-subbtn:hover,
.dxbs-toolbar-dropdown .dxbs-toolbar-dropdown-item.dx-toolbar-split-btn:not(.disabled) > dxbl-toolbar-menu-item:hover > a.dx-toolbar-split-subbtn:hover {
  background-color: unset;
}
.dxbs-toolbar.dxbs-toolbar.dx-r-toolbar .btn-group.dx-toolbar-split-btn:not(.disabled):hover > button.dx-toolbar-split-subbtn.dx-toolbar-split-dropdown-btn:hover:not(:disabled):not(.active):before, .dxbs-toolbar.dxbs-toolbar.dx-r-toolbar .btn-group.dx-toolbar-split-btn:not(.disabled):hover > a.dx-toolbar-split-subbtn.dx-toolbar-split-dropdown-btn:hover:not(:disabled):not(.active):before,
.dxbs-toolbar-dropdown .dxbs-toolbar-dropdown-item.dx-toolbar-split-btn:not(.disabled) > dxbl-toolbar-menu-item:hover > button.dx-toolbar-split-subbtn.dx-toolbar-split-dropdown-btn:hover:not(:disabled):not(.active):before,
.dxbs-toolbar-dropdown .dxbs-toolbar-dropdown-item.dx-toolbar-split-btn:not(.disabled) > dxbl-toolbar-menu-item:hover > a.dx-toolbar-split-subbtn.dx-toolbar-split-dropdown-btn:hover:not(:disabled):not(.active):before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.2;
}
.dxbs-toolbar.dxbs-toolbar.dx-r-toolbar .btn-group.dx-toolbar-split-btn:not(.disabled) > .btn.dx-toolbar-split-dropdown-btn:before,
.dxbs-toolbar-dropdown .dxbs-toolbar-dropdown-item.dx-toolbar-split-btn:not(.disabled) > dxbl-toolbar-menu-item > .btn.dx-toolbar-split-dropdown-btn:before {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.dxbs-toolbar.dxbs-toolbar.dx-r-toolbar .btn-group.dx-toolbar-split-btn:not(.disabled) > .btn.dx-toolbar-split-main-btn:before,
.dxbs-toolbar-dropdown .dxbs-toolbar-dropdown-item.dx-toolbar-split-btn:not(.disabled) > dxbl-toolbar-menu-item > .btn.dx-toolbar-split-main-btn:before {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.dxbs-dropdown-toggle.dropdown-toggle::after {
  display: flex;
  border-top: 0.36em solid;
  border-right: 0.36em solid transparent;
  border-left: 0.36em solid transparent;
  margin-left: 0px;
}

.btn-sm > .dxbs-dropdown-toggle.dropdown-toggle::after, .btn-group-sm > .btn > .dxbs-dropdown-toggle.dropdown-toggle::after,
.btn-sm.dxbs-dropdown-toggle.dropdown-toggle::after,
.btn-group-sm > .dxbs-dropdown-toggle.dropdown-toggle.btn::after {
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.btn-lg > .dxbs-dropdown-toggle.dropdown-toggle::after, .btn-group-lg > .btn > .dxbs-dropdown-toggle.dropdown-toggle::after,
.btn-lg.dxbs-dropdown-toggle.dropdown-toggle::after,
.btn-group-lg > .dxbs-dropdown-toggle.dropdown-toggle.btn::after {
  border-top: 6px solid;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}

.dxbs-toolbar-dropdown dxbl-toolbar-menu-item {
  width: 100%;
}
.dxbs-toolbar-dropdown .dxbs-toolbar-dropdown-item.dropdown-item-text > dxbl-toolbar-menu-item {
  display: flex;
}
.dxbs-toolbar-dropdown.dropdown-menu {
  min-width: 10rem;
}
.dxbs-toolbar-dropdown > .modal-content > .modal-body, .dxbs-toolbar-dropdown > .modal-body {
  padding: 0;
}
.dxbs-toolbar-dropdown > .modal-content > .modal-body > .dxbs-tb-dropdown-item-list, .dxbs-toolbar-dropdown > .modal-body > .dxbs-tb-dropdown-item-list {
  list-style: none;
  padding: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
}
.dxbs-toolbar-dropdown > .modal-content > .modal-body > .dxbs-tb-dropdown-item-list > .dropdown-divider, .dxbs-toolbar-dropdown > .modal-body > .dxbs-tb-dropdown-item-list > .dropdown-divider {
  margin: 0;
}
.dxbs-toolbar-dropdown > .modal-content > .modal-body > .dxbs-tb-dropdown-item-list > .dropdown-divider:first-child, .dxbs-toolbar-dropdown > .modal-body > .dxbs-tb-dropdown-item-list > .dropdown-divider:first-child {
  display: none;
}
.dxbs-toolbar-dropdown > .modal-content > .modal-body > .dxbs-tb-dropdown-item-list dxbl-toolbar-menu-item > .dropdown-item:first-child > span.popout, .dxbs-toolbar-dropdown > .modal-body > .dxbs-tb-dropdown-item-list dxbl-toolbar-menu-item > .dropdown-item:first-child > span.popout {
  right: 0;
  position: absolute;
  padding-left: 1rem;
  padding-right: 1rem;
}
.dxbs-toolbar-dropdown > .modal-content > .modal-body > .dxbs-tb-dropdown-item-list dxbl-toolbar-menu-item > .dropdown-item:not(:first-child), .dxbs-toolbar-dropdown > .modal-body > .dxbs-tb-dropdown-item-list dxbl-toolbar-menu-item > .dropdown-item:not(:first-child) {
  width: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
.dxbs-toolbar-dropdown.dxbs-popup.dxbs-toolbar-modal.dxbs-toolbar-dialog {
  max-height: 75%;
}
.dxbs-toolbar-dropdown.dxbs-popup.dxbs-toolbar-modal.dxbs-toolbar-bottomsheet {
  height: 100%;
  position: fixed;
  transition: top 0.5s ease;
  -webkit-transition: top 0.5s ease;
  -moz-transition: top 0.5s ease;
  -khtml-transition: top 0.5s ease;
  -o-transition: top 0.5s ease;
}
.dxbs-toolbar-dropdown.dxbs-popup.dxbs-toolbar-modal.dxbs-toolbar-bottomsheet:not(show) {
  top: 100%;
}
.dxbs-toolbar-dropdown.dxbs-popup.dxbs-toolbar-modal.dxbs-toolbar-bottomsheet.topVertical.show {
  top: 58%;
  max-height: 42%;
}
.dxbs-toolbar-dropdown.dxbs-popup.dxbs-toolbar-modal.dxbs-toolbar-bottomsheet.topHorizontal.show {
  top: 50%;
  max-height: 50%;
}
.dxbs-toolbar-dropdown.dxbs-popup.dxbs-toolbar-modal.dxbs-toolbar-bottomsheet > .modal-content {
  height: 100%;
  border-radius: 12px 12px 0 0;
}
.dxbs-toolbar-dropdown.dxbs-popup.dxbs-toolbar-modal.dxbs-toolbar-bottomsheet > .modal-content > .modal-header {
  border-radius: inherit;
}
.dxbs-toolbar-dropdown.dxbs-popup.dxbs-toolbar-modal dxbl-toolbar-menu-item > .dropdown-item {
  height: calc(1.5em + 13px + 2px);
  padding-top: 6.5px;
  padding-bottom: 6.5px;
}

dxbl-modal > .dxbs-modal > .dxbs-popup.dxbs-toolbar-dropdown.dxbs-toolbar-bottomsheet {
  width: 100%;
  margin: 0 auto;
  left: 0;
  right: 0;
}

@media (min-width: 576px) {
  .dxbs-toolbar-dropdown.modal-dialog {
    max-width: 580px;
  }
}

.dxbs-toolbar-dropdown.dxbs-toolbar-bottomsheet.modal-dialog .modal:before {
  display: none;
}
.dxbs-toolbar-dropdown.dxbs-toolbar-bottomsheet.modal-dialog .modal > .modal-dialog {
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
}
.dxbs-toolbar-dropdown.dxbs-toolbar-bottomsheet.modal-dialog .modal > .modal-dialog > .modal-content {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-width: 0;
  border-radius: 12px 12px 0 0;
}
.dxbs-toolbar-dropdown.dxbs-toolbar-bottomsheet.modal-dialog .modal > .modal-dialog > .modal-content > .modal-header {
  border-radius: inherit;
}
@media (min-width: 576px) {
  .dxbs-toolbar-dropdown.dxbs-toolbar-bottomsheet.modal-dialog .modal > .modal-dialog {
    max-width: 580px;
  }
}

.dxbs-context-menu > .modal-body > ul, .dxbs-context-menu-submenu > .modal-body > ul {
  margin: 0;
  width: 100%;
}
.dxbs-context-menu > .modal-body .dropdown-divider, .dxbs-context-menu-submenu > .modal-body .dropdown-divider {
  margin: 0;
}
.dxbs-context-menu > .modal-body .dropdown-divider:first-child, .dxbs-context-menu-submenu > .modal-body .dropdown-divider:first-child {
  display: none;
}

.dxbs-context-menu-item.dropdown-item {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding-left: 8px;
}
.has-icons > li > .dxbs-context-menu-item.dropdown-item .dxbs-context-menu-item-text {
  display: inline-block;
  padding-left: 24px;
}
.has-icons > li > .dxbs-context-menu-item.dropdown-item.has-icon .dxbs-context-menu-item-text {
  padding-left: 8px;
}
.dxbs-context-menu-item.dropdown-item .dx-popout-image {
  position: absolute;
  height: 1rem;
  width: 1rem;
  top: calc(50% - 0.5rem);
  right: 0.25rem;
}
.dxbs-context-menu-item.dropdown-item .dxbs-context-menu-item-icon {
  padding: 0;
  display: inline-flex;
}
.dxbs-context-menu-item.dropdown-item img.dxbs-context-menu-item-icon {
  height: 16px;
  width: 16px;
}

.dxbs-context-menu-submenu > .modal-body, .dxbs-context-menu > .modal-body, .dxbl-dropdown-dialog > .modal-body {
  padding: 0;
}
.dxbs-context-menu > .modal-body, .dxbs-context-menu-submenu > .modal-body {
  margin-bottom: 0;
}

.dxbs-context-menu-dropdown.dxbs-context-menu {
  min-width: 10rem;
}
.dxbs-context-menu-dropdown.dxbs-context-menu-submenu {
  min-width: 10rem;
}

button.dx-btn-text {
  border-color: transparent;
}

.dx-btn:hover:not(.disabled) {
  cursor: pointer;
}

.dx-btn-flex-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dx-btn,
.btn.dx-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.dxbs-editor-disabled {
  opacity: 0.5;
}

.invalid .dxbs-input-group input.form-control,
.invalid .dxbs-input-group textarea.form-control, .invalid.dxbs-tagbox .form-control {
  border-color: var(--red, var(--bs-red));
}

.valid.modified .dxbs-input-group input.form-control,
.valid.modified .dxbs-input-group textarea.form-control, .valid.modified.dxbs-tagbox .form-control {
  border-color: var(--green, var(--bs-green));
}

.dxbs-textbox.valid.modified, .dxbs-textbox.invalid,
.dxbs-combobox.valid.modified,
.dxbs-combobox.invalid,
.dxbs-spin-edit.valid.modified,
.dxbs-spin-edit.invalid,
.dxbs-memo.valid.modified,
.dxbs-memo.invalid,
.dxbs-date-time-edit.valid.modified,
.dxbs-date-time-edit.invalid,
.dxbs-tagbox.valid.modified,
.dxbs-tagbox.invalid,
.dxbs-listbox.valid.modified,
.dxbs-listbox.invalid,
.dxbs-calendar.valid.modified,
.dxbs-calendar.invalid,
.dxbs-checkbox.valid.modified,
.dxbs-checkbox.invalid {
  outline: none;
}

.dxbs-input-group.input-group > .dxbs-editor-input-container:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
}
.dxbs-input-group.input-group input.form-control {
  padding-left: 0.5rem;
}
.dxbs-input-group.input-group input.form-control-sm {
  padding-left: 0.375rem;
}
.dxbs-input-group.input-group input.form-control-lg {
  padding-left: 0.75rem;
}

.dxbs-editor-dropdown .dxbs-editor-dropdown-header {
  padding: 0;
  display: flex;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.dxbs-editor-dropdown .dxbs-editor-dropdown-body {
  padding: 0;
}
.dxbs-editor-dropdown .dxbs-editor-dropdown-body > * {
  width: 100%;
  border: none;
}

dxbl-dropdown.dxbs-dropdown > .dxbs-dropdown-dialog.dxbs-editor-dropdown {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}

div.dxbs-btn-group.dxbs-input-group-append > .btn.dx-btn:not(.dxbs-editor-clear-btn), div.dxbs-btn-group.dxbs-input-group-prepend > .btn.dx-btn {
  padding: 0.45rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
div.dxbs-btn-group.dxbs-input-group-append > .btn.dx-btn:not(.dxbs-editor-clear-btn).btn, div.dxbs-btn-group.dxbs-input-group-prepend > .btn.dx-btn.btn {
  border-top: none;
  border-bottom: none;
}
div.dxbs-btn-group.dxbs-input-group-append > .btn.dx-btn:not(.dxbs-editor-clear-btn).btn-sm, div.btn-group-sm.dxbs-btn-group.dxbs-input-group-append > .btn.dx-btn:not(.dxbs-editor-clear-btn), div.dxbs-btn-group.dxbs-input-group-prepend > .btn.dx-btn.btn-sm, div.btn-group-sm.dxbs-btn-group.dxbs-input-group-prepend > .btn.dx-btn {
  padding: 0.25rem;
}
div.dxbs-btn-group.dxbs-input-group-append > .btn.dx-btn:not(.dxbs-editor-clear-btn).btn-lg, div.btn-group-lg.dxbs-btn-group.dxbs-input-group-append > .btn.dx-btn:not(.dxbs-editor-clear-btn), div.dxbs-btn-group.dxbs-input-group-prepend > .btn.dx-btn.btn-lg, div.btn-group-lg.dxbs-btn-group.dxbs-input-group-prepend > .btn.dx-btn {
  padding: 0.5rem;
}
div.dxbs-btn-group.dxbs-input-group-append > .btn.dx-btn:not(.dxbs-editor-clear-btn).btn-lg > .dx-image, div.btn-group-lg.dxbs-btn-group.dxbs-input-group-append > .btn.dx-btn:not(.dxbs-editor-clear-btn) > .dx-image, div.dxbs-btn-group.dxbs-input-group-prepend > .btn.dx-btn.btn-lg > .dx-image, div.btn-group-lg.dxbs-btn-group.dxbs-input-group-prepend > .btn.dx-btn > .dx-image {
  width: 2rem;
  height: 2rem;
}
div.dxbs-btn-group.dxbs-input-group-append > .btn.dx-btn:not(.dxbs-editor-clear-btn) > .dx-image, div.dxbs-btn-group.dxbs-input-group-prepend > .btn.dx-btn > .dx-image {
  width: 1rem;
  height: 1rem;
}
div.dxbs-btn-group.dxbs-input-group-append > .btn.dx-btn:not(.dxbs-editor-clear-btn) > .dx-image > use, div.dxbs-btn-group.dxbs-input-group-prepend > .btn.dx-btn > .dx-image > use {
  fill: currentcolor;
  opacity: 0.85;
}
div.dxbs-btn-group.dxbs-input-group-prepend > .btn.dx-btn {
  border-left: 0;
}
div.dxbs-btn-group.dxbs-input-group-append > .btn.dx-btn {
  border-right: 0;
  margin: 0;
}

div.dxbs-btn-group.dxbs-input-group-append > button.btn.btn-secondary.dxbs-edit-btn {
  border-radius: 0;
}

.input-group > .form-control.dxbs-input-group-prepend.dxbs-btn-group, .input-group > .form-control.dxbs-input-group-append.dxbs-btn-group {
  padding: 0;
  position: absolute;
  display: flex;
  z-index: 0;
  height: 100%;
  width: auto;
  border-color: transparent;
  background: none;
  box-shadow: none;
  overflow: hidden;
}
.input-group > .form-control.dxbs-input-group-prepend .btn.dx-btn, .input-group > .form-control.dxbs-input-group-append .btn.dx-btn {
  border-radius: 0;
}

.dxbs-input-group-prepend {
  margin-left: 1px;
}
.dxbs-input-group-prepend.form-control {
  left: 0;
  border-right: none;
  border-left: none;
}

.form-control.dxbs-input-group-append {
  right: 0;
  border-left: none;
}

.dxbs-calendar.card {
  display: inline-flex;
}
.dxbs-calendar[disabled] .dxbs-calendar-table td, .dxbs-calendar[readonly] .dxbs-calendar-table td {
  cursor: default;
}
.dxbs-calendar[disabled] .dxbs-calendar-footer .btn, .dxbs-calendar[readonly] .dxbs-calendar-footer .btn {
  opacity: 0.25;
}
.dxbs-calendar[disabled] .dxbs-calendar-header .dxbs-calendar-header-title-btn {
  opacity: 0.25;
}
.dxbs-calendar[disabled] .dxbs-calendar-table {
  opacity: 0.5;
}
.dxbs-calendar[readonly] .dxbs-calendar-header .dxbs-calendar-header-title-btn {
  opacity: 1;
}
.dxbs-calendar:not([disabled]):not([readonly]).invalid {
  border-color: var(--red, var(--bs-red));
}
.dxbs-calendar:not([disabled]):not([readonly]).valid.modified {
  border-color: var(--green, var(--bs-green));
}
.dxbs-calendar:not([disabled]):not([readonly]) .dxbs-calendar-header .dxbs-calendar-header-btn:not([disbled]):hover {
  background-color: var(--dx-calendar-header-hover-color, rgba(0, 0, 0, 0.1));
}
.dxbs-calendar:not([disabled]):not([readonly]) .dxbs-calendar-header .dxbs-calendar-header-title-btn:hover {
  background-color: var(--dx-calendar-header-hover-color, rgba(0, 0, 0, 0.1));
}
.dxbs-calendar:not([disabled]):not([readonly]) .dxbs-calendar-table td:not(.dxbs-calendar-disabled-item):not(.dxbs-calendar-table-week-number) {
  cursor: pointer;
}
.dxbs-calendar:not([disabled]):not([readonly]) .dxbs-calendar-table td:not(.dxbs-calendar-disabled-item):not(.dxbs-calendar-table-today):not(.dxbs-calendar-table-week-number):active {
  background-color: var(--dx-calendar-table-active-color, rgba(0, 0, 0, 0.2));
}
.dxbs-calendar:not([disabled]):not([readonly]) .dxbs-calendar-table td:not(.dxbs-calendar-disabled-item):not(.dxbs-calendar-table-today):not(.dxbs-calendar-table-week-number):not(.dxbs-calendar-selected-item):not(.dxbs-calendar-table-week-number):hover {
  background-color: var(--dx-calendar-table-hover-color, rgba(0, 0, 0, 0.05));
}
.dxbs-calendar:not([disabled]):not([readonly]) .dxbs-calendar-table td:not(.dxbs-calendar-disabled-item):not(.dxbs-calendar-table-today):not(.dxbs-calendar-table-week-number):not(.dxbs-calendar-selected-item):not(.dxbs-calendar-table-week-number):hover.dxbs-calendar-selected-range {
  background-color: var(--dx-calendar-table-active-color, rgba(0, 0, 0, 0.2));
}
.dxbs-calendar:not([disabled]):not([readonly]) .dxbs-calendar-table .dxbs-calendar-selected-item:hover {
  background-color: var(--dx-calendar-table-active-color, rgba(0, 0, 0, 0.2));
}
.dxbs-calendar:not([disabled]):not([readonly]) .dxbs-calendar-table .dxbs-calendar-selected-range {
  background-color: var(--dx-calendar-table-active-color, rgba(0, 0, 0, 0.2));
}
.dxbs-calendar .dxbs-calendar-header.btn-group {
  padding: 0;
  /* stylelint-disable declaration-block-no-redundant-longhand-properties */
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  /* stylelint-enable declaration-block-no-redundant-longhand-properties */
}
.dxbs-calendar .dxbs-calendar-header.btn-group.card-header {
  box-shadow: none;
}
.dxbs-calendar .dxbs-calendar-header.btn-group .dxbs-calendar-header-btn {
  border: 0;
  flex-grow: 0;
  color: currentcolor;
  box-shadow: none;
}
.dxbs-calendar .dxbs-calendar-header.btn-group .dxbs-calendar-header-btn[disabled] {
  opacity: 0.25;
}
.dxbs-calendar .dxbs-calendar-header.btn-group .dxbs-calendar-header-title-btn {
  flex-grow: 1;
  font-weight: 600;
  box-shadow: none;
}
.dxbs-calendar .dxbs-calendar-table-container {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
}
.dxbs-calendar .dxbs-calendar-table-container .dxbs-calendar-table {
  border: 0;
  margin: 0;
  width: unset;
  border-collapse: separate;
  border-spacing: 0;
}
.dxbs-calendar .dxbs-calendar-table-container .dxbs-calendar-table td {
  vertical-align: middle;
}
.dxbs-calendar .dxbs-calendar-table-container .dxbs-calendar-table .dxbs-calendar-selected-item {
  background-color: var(--dx-calendar-table-selection-color, rgba(0, 0, 0, 0.1));
}
.dxbs-calendar .dxbs-calendar-table-container .dxbs-calendar-table .dxbs-calendar-disabled-item {
  opacity: 0.5;
  cursor: default;
}
.dxbs-calendar .dxbs-calendar-table-container .dxbs-calendar-table.table-sm td,
.dxbs-calendar .dxbs-calendar-table-container .dxbs-calendar-table.table-sm th {
  padding: 0.25rem;
}
.dxbs-calendar .dxbs-calendar-table-container .dxbs-calendar-table th,
.dxbs-calendar .dxbs-calendar-table-container .dxbs-calendar-table td {
  border-color: transparent;
}
.dxbs-calendar .dxbs-calendar-table-container .dxbs-calendar-table .dxbs-calendar-table-today {
  outline: solid 1px var(--bs-primary);
  outline-offset: -1px;
}
.dxbs-calendar .dxbs-calendar-table-container .dxbs-calendar-table .dxbs-calendar-table-week-number {
  opacity: 0.6;
  text-align: right;
  cursor: default;
}
.dxbs-calendar .dxbs-calendar-table-container .dxbs-calendar-table .dxbs-calendar-table-days-of-week th {
  opacity: 0.5;
  font-family: monospace;
}
.dxbs-calendar .dxbs-calendar-footer {
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;
}
.dxbs-calendar .dxbs-calendar-footer .btn + .btn {
  margin-left: 0.5rem;
}

.dxbs-combobox {
  display: flex;
}

.dxbs-combobox-dropdown-hidden {
  visibility: hidden;
}
.dxbs-combobox-dropdown-shown {
  visibility: visible;
}
.dxbs-combobox-dropdown.dxbs-editor-dropdown {
  max-height: 200px;
}
.dxbs-combobox-dropdown.dxbs-editor-dropdown .dxbs-listbox {
  max-height: 200px;
  background-color: inherit;
}
.dxbs-combobox-dropdown.dxbs-editor-dropdown .dxbs-listbox .dxbs-gridview {
  display: flex;
  min-height: 0;
}
.dxbs-combobox-dropdown.dxbs-editor-dropdown .dxbs-listbox .dxbs-gridview > .card {
  display: flex;
}
.dxbs-combobox-dropdown.dxbs-editor-dropdown .dxbs-listbox .dxbs-gridview > .card > .dxgvHSDC {
  flex-shrink: 0;
}

.dxbs-checkbox {
  display: flex;
  align-items: center;
}
.dxbs-checkbox .custom-control {
  z-index: auto;
  height: 1.28125rem;
  min-height: 1.28125rem;
}
.dxbs-checkbox .custom-control.custom-radio, .dxbs-checkbox .custom-control.custom-checkbox {
  padding-left: 0;
  width: 1.25rem;
  min-width: 1.25rem;
}
.dxbs-checkbox .custom-control.custom-radio .custom-control-label::before, .dxbs-checkbox .custom-control.custom-checkbox .custom-control-label::before {
  position: relative;
  left: 0;
}
.dxbs-checkbox .custom-control.custom-radio .custom-control-label::after, .dxbs-checkbox .custom-control.custom-checkbox .custom-control-label::after {
  left: 0;
}
.dxbs-checkbox .custom-control.custom-switch {
  width: 2.375rem;
  min-width: 2.375rem;
}
.dxbs-checkbox .custom-control > .custom-control-input {
  width: 100%;
  height: 100%;
}
.dxbs-checkbox .custom-control:not(.custom-switch) > .custom-control-label::before {
  top: 0;
  width: 1.25rem;
  min-width: 1.25rem;
  height: 1.25rem;
  min-height: 1.25rem;
}
.dxbs-checkbox .custom-control:not(.custom-switch) > .custom-control-label::after {
  top: 0;
  width: 1.25rem;
  min-width: 1.25rem;
  height: 1.25rem;
  min-height: 1.25rem;
}
.dxbs-checkbox .custom-control > .form-check-input {
  margin-top: unset;
}
.dxbs-checkbox .dxbs-checkbox-label.dxbs-checkbox-label-wrap {
  white-space: normal;
}
.dxbs-checkbox .dxbs-checkbox-label.dxbs-checkbox-label-nowrap {
  white-space: nowrap;
}
.dxbs-checkbox .dxbs-checkbox-label.dxbs-checkbox-label-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dxbs-checkbox .dxbs-checkbox-label.dxbs-checkbox-label-left {
  text-align: right;
  padding-right: 0.5rem;
}
.dxbs-checkbox .dxbs-checkbox-label.dxbs-checkbox-label-right {
  text-align: left;
  padding-left: 0.5rem;
}
.dxbs-checkbox.dxbs-checkbox-align-left {
  justify-content: flex-start;
}
.dxbs-checkbox.dxbs-checkbox-align-right {
  justify-content: flex-end;
}
.dxbs-checkbox.dxbs-checkbox-align-center {
  justify-content: center;
}
.dxbs-checkbox.dxbs-checkbox-space-between {
  justify-content: space-between;
}
.dxbs-checkbox.dxbs-checkbox-space-around {
  justify-content: space-around;
}
.dxbs-checkbox.invalid .custom-control-input ~ .custom-control-label::before {
  border-color: var(--red, var(--bs-red));
}
.dxbs-checkbox.valid.modified .custom-control-input ~ .custom-control-label::before {
  border-color: var(--green, var(--bs-green));
}
.dxbs-checkbox.valid.modified .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--green, var(--bs-green));
}

.input-group > .dxbs-input-group-append .dxbs-editor-clear-btn.btn {
  align-self: center;
  border: 0;
  background-color: transparent;
  position: relative;
  padding: 0.45rem;
}
.input-group > .dxbs-input-group-append .dxbs-editor-clear-btn.btn.btn {
  border-top: none;
  border-bottom: none;
}
.input-group > .dxbs-input-group-append .dxbs-editor-clear-btn.btn.btn-sm, .input-group > .dxbs-input-group-append .btn-group-sm > .dxbs-editor-clear-btn.btn {
  padding: 0.25rem;
}
.input-group > .dxbs-input-group-append .dxbs-editor-clear-btn.btn.btn-lg, .input-group > .dxbs-input-group-append .btn-group-lg > .dxbs-editor-clear-btn.btn {
  padding: 0.5rem;
}
.input-group > .dxbs-input-group-append .dxbs-editor-clear-btn.btn.btn-lg > .dx-image, .input-group > .dxbs-input-group-append .btn-group-lg > .dxbs-editor-clear-btn.btn > .dx-image {
  width: 2rem;
  height: 2rem;
}
.input-group > .dxbs-input-group-append .dxbs-editor-clear-btn.btn > .dx-image {
  width: 1rem;
  height: 1rem;
}
.input-group > .dxbs-input-group-append .dxbs-editor-clear-btn.btn > .dx-image > use {
  fill: currentcolor;
  opacity: 0.85;
}
.input-group > .dxbs-input-group-append .dxbs-editor-clear-btn.btn.btn {
  padding-left: 0;
  color: currentcolor;
}
.input-group > .dxbs-input-group-append .dxbs-editor-clear-btn.btn.btn:hover::before {
  top: 0.45rem;
  bottom: 0.45rem;
  right: 0.45rem;
}
.input-group > .dxbs-input-group-append .dxbs-editor-clear-btn.btn.btn.btn-sm:hover::before, .input-group > .dxbs-input-group-append .btn-group-sm > .dxbs-editor-clear-btn.btn.btn:hover::before {
  top: 0.25rem;
  bottom: 0.25rem;
  right: 0.25rem;
}
.input-group > .dxbs-input-group-append .dxbs-editor-clear-btn.btn.btn.btn-lg:hover::before, .input-group > .dxbs-input-group-append .btn-group-lg > .dxbs-editor-clear-btn.btn.btn:hover::before {
  top: 0.5rem;
  bottom: 0.5rem;
  right: 0.5rem;
}
.input-group > .dxbs-input-group-append .dxbs-editor-clear-btn.btn:hover {
  background-color: transparent;
}
.input-group > .dxbs-input-group-append .dxbs-editor-clear-btn.btn:hover::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0.25rem;
  bottom: 0.25rem;
  right: 0.25rem;
  background-color: currentcolor;
  opacity: 0.15;
  border-radius: 50%;
}
.input-group > .dxbs-input-group-append .dxbs-editor-clear-btn.btn:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.input-group > .dxbs-input-group-append .dxbs-editor-clear-btn.btn > .dx-image {
  fill-opacity: 0.6;
}

.dxbs-date-time-edit {
  display: flex;
}

.dxbs-date-time-edit-dropdown.dropdown-menu {
  text-align: center;
}
.dxbs-date-time-edit-dropdown .dxbs-date-time-edit-dropdown-title {
  justify-content: center;
  display: flex;
  align-self: center;
  font-size: 0.9rem;
  font-weight: 600;
  width: 100%;
  margin: 0;
}
.dxbs-date-time-edit-dropdown .dxbs-date-time-edit-dropdown-cancel,
.dxbs-date-time-edit-dropdown .dxbs-date-time-edit-dropdown-apply {
  width: 16px;
  height: 16px;
  border: 0;
  position: relative;
}
.dxbs-date-time-edit-dropdown .dxbs-date-time-edit-dropdown-cancel:hover::before {
  /* stylelint-disable declaration-block-no-redundant-longhand-properties */
  border-top-left-radius: inherit;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  /* stylelint-enable declaration-block-no-redundant-longhand-properties */
}
.dxbs-date-time-edit-dropdown .dxbs-date-time-edit-dropdown-apply:hover::before {
  /* stylelint-disable declaration-block-no-redundant-longhand-properties */
  border-top-right-radius: inherit;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  /* stylelint-enable declaration-block-no-redundant-longhand-properties */
}
.dxbs-date-time-edit-dropdown .dxbs-date-time-edit-dropdown-cancel {
  margin-right: 0.5rem;
}
.dxbs-date-time-edit-dropdown .dxbs-date-time-edit-dropdown-apply {
  margin-left: 0.5rem;
}
.dxbs-date-time-edit-dropdown .dxbs-date-time-edit-dropdown-tabs .dxbs-date-time-edit-dropdown-tabs-date,
.dxbs-date-time-edit-dropdown .dxbs-date-time-edit-dropdown-tabs .dxbs-date-time-edit-dropdown-tabs-time {
  flex-grow: 1;
  text-align: center;
}
.dxbs-date-time-edit-dropdown .dxbs-date-time-edit-dropdown-tabs .dxbs-date-time-edit-dropdown-tabs-date .nav-link,
.dxbs-date-time-edit-dropdown .dxbs-date-time-edit-dropdown-tabs .dxbs-date-time-edit-dropdown-tabs-time .nav-link {
  border-radius: 0;
  border-top: 0;
  justify-content: center;
}
.dxbs-date-time-edit-dropdown .dxbs-date-time-edit-dropdown-tabs .dxbs-date-time-edit-dropdown-tabs-date .nav-link {
  border-left: 0;
}
.dxbs-date-time-edit-dropdown .dxbs-date-time-edit-dropdown-tabs .dxbs-date-time-edit-dropdown-tabs-time .nav-link {
  border-right: 0;
}
.dxbs-date-time-edit-dropdown .dx-tabs-content {
  display: flex;
  flex-direction: column;
}
.dxbs-date-time-edit-dropdown .dx-tabs-content .dxbs-date-time-edit-dropdown-calendar {
  border: 0;
}
.dxbs-date-time-edit-dropdown .dx-tabs-content .dxbs-date-time-edit-dropdown-calendar .dxbs-date-time-edit-dropdown-calendar-header {
  background: none;
  padding-top: 0.4rem;
  border: 0;
}

.dxbs-listbox {
  display: flex;
  flex-direction: column;
}
.dxbs-listbox.dxbs-listbox-single-column {
  overflow-y: auto;
}
.dxbs-listbox.dxbs-listbox-single-column > div {
  display: flex;
  flex-shrink: 0;
}
.dxbs-listbox.dxbs-listbox-single-column > ul {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;
}
.dxbs-listbox.dxbs-listbox-single-column > ul.form-control {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dxbs-listbox.dxbs-listbox-single-column > ul .dxbs-listbox-item {
  display: flex;
  align-items: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.dxbs-listbox.dxbs-listbox-single-column > ul .dxbs-listbox-item > dxbl-check {
  padding-right: 0.5rem;
}
.dxbs-listbox.dxbs-listbox-single-column > ul .dxbs-listbox-item,
.dxbs-listbox.dxbs-listbox-single-column > ul .dropdown-item {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.dxbs-listbox.dxbs-listbox-single-column > ul li.dxbs-row-loading::before {
  content: "|";
  display: inline-flex;
  min-width: 4rem;
  width: 100%;
  background-color: currentcolor;
  opacity: 0;
  border-radius: 10rem;
  animation: 1s linear 0.35s infinite alternate both running dynamic_opacity;
}
.dxbs-listbox.dxbs-listbox-multi-column .dxbs-grid-vsd {
  position: relative;
}
.dxbs-listbox.invalid {
  border-color: var(--red, var(--bs-red));
}
.dxbs-listbox.valid.modified {
  border-color: var(--green, var(--bs-green));
}

.dxbs-spin-edit {
  display: flex;
}
.dxbs-spin-edit .dxbs-spin-edit-button {
  padding: 0.45rem;
}
.dxbs-spin-edit .dxbs-spin-edit-button.btn {
  border-top: none;
  border-bottom: none;
}
.dxbs-spin-edit .dxbs-spin-edit-button.btn-sm, .dxbs-spin-edit .btn-group-sm > .dxbs-spin-edit-button.btn {
  padding: 0.25rem;
}
.dxbs-spin-edit .dxbs-spin-edit-button.btn-lg, .dxbs-spin-edit .btn-group-lg > .dxbs-spin-edit-button.btn {
  padding: 0.5rem;
}
.dxbs-spin-edit .dxbs-spin-edit-button.btn-lg > .dx-image, .dxbs-spin-edit .btn-group-lg > .dxbs-spin-edit-button.btn > .dx-image {
  width: 2rem;
  height: 2rem;
}
.dxbs-spin-edit .dxbs-spin-edit-button > .dx-image {
  width: 1rem;
  height: 1rem;
}
.dxbs-spin-edit .dxbs-spin-edit-button > .dx-image > use {
  fill: currentcolor;
  opacity: 0.85;
}
.dxbs-spin-edit .dxbs-spin-edit-button.inc > .dx-image {
  transform: rotate(180deg);
}

.dxbs-textbox > .dxbs-input-group.input-group > .dxbs-editor-input-container:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
}

.dxbs-tagbox {
  display: flex;
}
.dxbs-tagbox > .form-control {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0.2rem;
  height: inherit;
  width: 100%;
  overflow: hidden;
  background-color: inherit;
  color: inherit;
  display: flex;
  box-shadow: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
}
.dxbs-tagbox > .form-control .dxbs-input-group-append {
  top: 0;
}
.dxbs-tagbox > .form-control .dxbs-tagbox-wrapper {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: stretch;
  overflow: hidden;
}
.dxbs-tagbox > .form-control .dxbs-tagbox-wrapper .dxbs-tag {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  padding-right: 0.1rem;
  max-width: 100%;
  position: relative;
  display: flex;
  color: inherit;
  align-items: center;
  margin-right: 0.5rem;
  margin-top: 0.15rem;
  overflow: hidden;
}
.dxbs-tagbox > .form-control .dxbs-tagbox-wrapper .dxbs-tag .dxbs-tag-text {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dxbs-tagbox > .form-control .dxbs-tagbox-wrapper .dxbs-tag > .dxbs-btn-group {
  white-space: normal;
  position: relative;
  margin-left: 0.5em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.5em;
  height: 1.5em;
  flex: 0 0 auto;
}
.dxbs-tagbox > .form-control .dxbs-tagbox-wrapper .dxbs-tag > .dxbs-btn-group .btn.dx-btn.dxbs-cmd-btn {
  position: unset;
  padding: 0;
  width: auto;
  height: auto;
  /* for some Bootstrap themes: */
}
.dxbs-tagbox > .form-control .dxbs-tagbox-wrapper .dxbs-tag > .dxbs-btn-group .btn.dx-btn.dxbs-cmd-btn:focus, .dxbs-tagbox > .form-control .dxbs-tagbox-wrapper .dxbs-tag > .dxbs-btn-group .btn.dx-btn.dxbs-cmd-btn:hover {
  z-index: 1;
}
.dxbs-tagbox > .form-control .dxbs-tagbox-wrapper .dxbs-tag > .dxbs-btn-group .btn.dx-btn.dxbs-cmd-btn:focus:active, .dxbs-tagbox > .form-control .dxbs-tagbox-wrapper .dxbs-tag > .dxbs-btn-group .btn.dx-btn.dxbs-cmd-btn:hover:active {
  box-shadow: none;
}
.dxbs-tagbox > .form-control .dxbs-tagbox-wrapper .dxbs-tag > .dxbs-btn-group .btn.dx-btn.dxbs-cmd-btn:focus::before, .dxbs-tagbox > .form-control .dxbs-tagbox-wrapper .dxbs-tag > .dxbs-btn-group .btn.dx-btn.dxbs-cmd-btn:hover::before {
  opacity: 0.2;
}
.dxbs-tagbox > .form-control .dxbs-tagbox-wrapper .dxbs-tag:last-of-type {
  max-width: calc(100% - 3.5em);
}
.dxbs-tagbox > .form-control .dxbs-tagbox-wrapper .dxbs-tagbox-input:first-child,
.dxbs-tagbox > .form-control .dxbs-tagbox-wrapper .dxbs-tag .dxbs-tag-text {
  padding-left: 1.1em;
}
.dxbs-tagbox > .form-control .dxbs-tagbox-wrapper .dxbs-tagbox-input {
  width: unset;
  height: unset;
  min-height: unset;
  font-size: inherit;
  background: none;
  font-weight: inherit;
  border: none;
  box-shadow: none;
  padding: 0;
  outline: none;
}
.dxbs-tagbox.dxbs-edit-clbv .input-group {
  padding-right: 30px;
}

.dxbs-tagbox:not(.dxbs-tagbox-custom) > .form-control .dxbs-tagbox-wrapper .dxbs-tag::before {
  content: " ";
  position: absolute;
  background-color: currentcolor;
  opacity: 0.15;
  border-radius: 1em;
  height: 100%;
  width: 100%;
}

.dxbs-filemanager {
  width: 100%;
  font-family: "Segoe UI", sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(34, 34, 34, 0.125);
  /* toolbar */
}
.dxbs-filemanager:focus {
  outline: none;
}
.dxbs-filemanager .dxbs-filemanager-toolbar {
  flex: 1 0 auto;
  border-bottom: 1px solid rgba(34, 34, 34, 0.125);
  /* TODO: change color to some constant like #dee2e6 */
}
.dxbs-filemanager .dxbs-filemanager-toolbar .dxbs-toolbar-group {
  box-shadow: none;
}
.dxbs-filemanager .dxbs-filemanager-toolbar .dxbs-toolbar-group .dxbs-icon-toolbar {
  display: inline-block;
  text-align: center;
  width: 1rem;
  position: relative;
}
.dxbs-filemanager .dxbs-filemanager-toolbar .dxbs-toolbar-group .dxbs-toolbar-btn > .dx-image,
.dxbs-filemanager .dxbs-filemanager-toolbar .dxbs-toolbar-group .dxbs-dropdown-toggle > .dx-image,
.dxbs-filemanager .dxbs-filemanager-toolbar .dxbs-toolbar-group .dropdown-item > .dx-image {
  width: 18px;
  height: 18px;
}
.dxbs-filemanager .dxbs-filemanager-toolbar .dxbs-toolbar-group .btn {
  background-color: transparent;
  border: none;
}
.dxbs-filemanager .dxbs-filemanager-toolbar .dxbs-toolbar-group .dxbs-toolbar-btn {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.dxbs-filemanager .dxbs-filemanager-toolbar .dxbs-toolbar-group .dxbs-toolbar-btn:first-child {
  margin-left: 0.45rem;
  margin-right: 0;
}
.dxbs-filemanager .dxbs-filemanager-toolbar .dxbs-toolbar-group .dxbs-toolbar-btn:last-child {
  margin-left: 0.85rem;
  margin-right: 0.65rem;
}
.dxbs-filemanager .dxbs-filemanager-container {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  overflow: hidden;
  height: 100%;
  /* treeView (part 1/2) is out of main hierarchy because it appeasr in both file manager and popups */
  /* itemsView */
}
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-dirs-wrapper {
  position: relative;
  flex: 0 0 auto;
  padding-top: 10px;
  max-width: 100%;
  height: 100%;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.15);
  /* splitter */
}
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-dirs-wrapper > .dxbs-splitter-wrapper {
  height: 100%;
  position: absolute;
  z-index: 998;
  top: 0;
  right: 0;
}
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-dirs-wrapper > .dxbs-splitter-wrapper .dxbs-splitter {
  height: 100%;
  display: block;
  cursor: col-resize;
  z-index: 999;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  transform: translateX(3px);
  box-sizing: content-box;
  background-clip: content-box;
}
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-dirs-wrapper > .dxbs-splitter-wrapper .dxbs-splitter.dxbs-splitter-transparent {
  z-index: 998;
  background-color: transparent;
}
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-items-panel {
  height: 100%;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
  /* thumbnailsView */
}
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-items-panel .dxbl-filemanager-thumbnails-scroll {
  height: 100%;
}
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-items-panel .dxbl-filemanager-thumbnails-scroll .dxbl-filemanager-thumbnails-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 0.5rem 1rem;
}
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-items-panel .dxbl-filemanager-thumbnails-scroll .dxbl-filemanager-thumbnails-container div.dxbs-filemanager-thumbnail-item {
  width: 96px;
  padding: 0.7rem 1rem 1rem;
  margin: 8px 7px 16px;
  border: none;
  border-radius: 0.2rem;
  z-index: 0;
  font-family: inherit;
  font-weight: inherit;
  flex: 0 0 auto;
  position: relative;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-items-panel .dxbl-filemanager-thumbnails-scroll .dxbl-filemanager-thumbnails-container div.dxbs-filemanager-thumbnail-item:hover::before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentcolor;
  left: 0;
  top: 0;
  opacity: 0.1;
}
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-items-panel .dxbl-filemanager-thumbnails-scroll .dxbl-filemanager-thumbnails-container div.dxbs-filemanager-thumbnail-item > span {
  white-space: nowrap;
  -webkit-touch-callout: none;
  /* iOS Safari */
  user-select: none;
  /* Safari, Old versions of Firefox, Internet Explorer/Edge */
}
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-items-panel .dxbl-filemanager-thumbnails-scroll .dxbl-filemanager-thumbnails-container div.dxbs-filemanager-thumbnail-item > .dxbl-icon-thumbnail.dx-image {
  display: inline-block;
  width: 48px;
  height: 48px;
}

/* treeView (part 2/2) is out of main hierarchy because it renders in both file manager and popups */
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-dirs-wrapper > .dxbs-filemanager-dirs-panel,
.dxbs-popup .dxbl-filemanager-popup-content > .dxbs-filemanager-dirs-panel {
  height: 100%;
}
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-dirs-wrapper > .dxbs-filemanager-dirs-panel > nav > .dxbs-tree-wraper,
.dxbs-popup .dxbl-filemanager-popup-content > .dxbs-filemanager-dirs-panel > nav > .dxbs-tree-wraper {
  padding-right: 1rem;
}
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-dirs-wrapper > .dxbs-filemanager-dirs-panel .dxbs-filemanager-treeview-container,
.dxbs-popup .dxbl-filemanager-popup-content > .dxbs-filemanager-dirs-panel .dxbs-filemanager-treeview-container {
  height: 100%;
}
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-dirs-wrapper > .dxbs-filemanager-dirs-panel .dxbs-filemanager-treeview-container .dxbs-filemanager-treeview,
.dxbs-popup .dxbl-filemanager-popup-content > .dxbs-filemanager-dirs-panel .dxbs-filemanager-treeview-container .dxbs-filemanager-treeview {
  height: 100%;
  width: 100%;
}
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-dirs-wrapper > .dxbs-filemanager-dirs-panel .dxbs-filemanager-treeview-container .dxbs-filemanager-treeview .nav-link,
.dxbs-popup .dxbl-filemanager-popup-content > .dxbs-filemanager-dirs-panel .dxbs-filemanager-treeview-container .dxbs-filemanager-treeview .nav-link {
  border-radius: 0;
}
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-dirs-wrapper > .dxbs-filemanager-dirs-panel .dxbs-filemanager-treeview-container .dxbs-filemanager-treeview .nav-link .dxbs-filemanager-navpane-item,
.dxbs-popup .dxbl-filemanager-popup-content > .dxbs-filemanager-dirs-panel .dxbs-filemanager-treeview-container .dxbs-filemanager-treeview .nav-link .dxbs-filemanager-navpane-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-dirs-wrapper > .dxbs-filemanager-dirs-panel .dxbs-filemanager-treeview-container .dxbs-filemanager-treeview .nav-link .dxbs-filemanager-navpane-item > .dx-image,
.dxbs-popup .dxbl-filemanager-popup-content > .dxbs-filemanager-dirs-panel .dxbs-filemanager-treeview-container .dxbs-filemanager-treeview .nav-link .dxbs-filemanager-navpane-item > .dx-image {
  flex: 0 auto;
  width: 24px;
  height: 24px;
}
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-dirs-wrapper > .dxbs-filemanager-dirs-panel .dxbs-filemanager-treeview-container .dxbs-filemanager-treeview .nav-link .dxbs-filemanager-navpane-item > span,
.dxbs-popup .dxbl-filemanager-popup-content > .dxbs-filemanager-dirs-panel .dxbs-filemanager-treeview-container .dxbs-filemanager-treeview .nav-link .dxbs-filemanager-navpane-item > span {
  padding-left: 1rem;
  flex: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* TODO: wish it was out-of-the-box feature of the dropdown */
.dxbs-toolbar-dropdown.dropdown-menu.dxbs-filemanager-toolbar-dropdown {
  min-width: unset;
}
.dxbs-toolbar-dropdown.dropdown-menu.dxbs-filemanager-toolbar-dropdown > .dxbs-dropdown-body > .dxbs-tb-dropdown-item-list {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  /* TODO: .dxbs-toolbar-dropdown-item:not(.dx-toolbar-split-btn) > .dxbl-toolbar-menu-item > .dropdown-item */
}
.dxbs-toolbar-dropdown.dropdown-menu.dxbs-filemanager-toolbar-dropdown > .dxbs-dropdown-body > .dxbs-tb-dropdown-item-list .dxbs-toolbar-dropdown-item > .dxbl-toolbar-menu-item > .dropdown-item {
  padding-right: 1rem;
}
.dxbs-toolbar-dropdown.dropdown-menu.dxbs-filemanager-toolbar-dropdown .dxbs-toolbar-btn > .dx-image,
.dxbs-toolbar-dropdown.dropdown-menu.dxbs-filemanager-toolbar-dropdown .dxbs-dropdown-toggle > .dx-image,
.dxbs-toolbar-dropdown.dropdown-menu.dxbs-filemanager-toolbar-dropdown .dropdown-item > .dx-image {
  width: 18px;
  height: 18px;
}

/* dialogs */
.dxbs-popup > .dxbs-modal-content > .dxbs-modal-body.dxbl-filemanager-popup-content {
  min-height: 300px;
  max-height: 350px;
  overflow-y: auto;
}
.dxbs-popup > .dxbs-modal-content > .dxbs-modal-body.dxbl-filemanager-popup-content.dxbl-filemanager-delete-popup-content {
  min-height: unset;
}
.dxbs-popup > .dxbs-modal-content > .dxbs-modal-body.dxbl-filemanager-popup-content .dxbs-grid .dxbs-grid-header-row {
  display: none;
}
.dxbs-popup > .dxbs-modal-content > .dxbs-modal-body.dxbl-filemanager-popup-content .dxbs-grid td,
.dxbs-popup > .dxbs-modal-content > .dxbs-modal-body.dxbl-filemanager-popup-content .dxbs-grid > .card {
  border: none;
}
.dxbs-popup > .dxbs-modal-content > .dxbs-modal-body.dxbl-filemanager-popup-content .dxbs-grid .dxbs-filemanager-file-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.dxbs-popup > .dxbs-modal-content > .dxbs-modal-body.dxbl-filemanager-popup-content .dxbs-grid .dxbs-filemanager-file-item > .dx-image {
  flex: 0 auto;
  width: 24px;
  height: 24px;
}
.dxbs-popup > .dxbs-modal-content > .dxbs-modal-body.dxbl-filemanager-popup-content .dxbs-grid .dxbs-filemanager-file-item > span {
  padding-left: 1rem;
  flex: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dxbs-popup > .dxbs-modal-content > .dxbs-modal-body.dxbl-filemanager-popup-content .dxbs-grid td {
  position: relative;
  z-index: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.dxbs-popup > .dxbs-modal-content > .dxbs-modal-body.dxbl-filemanager-popup-content .dxbs-grid td:hover::before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentcolor;
  left: 0;
  top: 0;
  opacity: 0.1;
}
.dxbs-popup > .dxbs-modal-content > .dxbl-filemanager-popup-footer {
  padding: 0.25rem 0.75rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.dxbs-popup > .dxbs-modal-content > .dxbl-filemanager-popup-footer > * {
  margin: 0.25rem;
}

.dxbs-grid-dragging-target {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1999;
}

.dxbs-grid-move-cursor,
.dxbs-grid-move-cursor a:hover,
.dxbs-grid-move-cursor .dxbs-grid-action,
.dxbs-grid-move-cursor * {
  cursor: move !important;
}

.dxbs-scroll-viewer {
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.dxbs-scroll-viewer-content {
  overflow: auto;
  width: 100%;
  scrollbar-width: none;
  scrollbar-height: none;
}

.dxbs-scroll-viewer-content::-webkit-scrollbar {
  display: none;
}

.dxbs-scroll-viewer-vert-scroll-bar,
.dxbs-scroll-viewer-hor-scroll-bar {
  bottom: 0;
  right: 0;
  position: absolute;
  display: none;
  align-items: flex-end;
  z-index: 1;
  background-color: transparent;
}
.dxbs-scroll-viewer-vert-scroll-bar.active,
.dxbs-scroll-viewer-hor-scroll-bar.active {
  display: flex;
}
.dxbs-scroll-viewer-vert-scroll-bar > .dxbs-scroll-viewer-scroll-thumb,
.dxbs-scroll-viewer-hor-scroll-bar > .dxbs-scroll-viewer-scroll-thumb {
  background-color: currentcolor;
  opacity: 0.5;
  border-radius: 0.1rem;
  cursor: default;
}

.dxbs-scroll-viewer-vert-scroll-bar {
  top: 0;
  width: 0.5rem;
  flex-direction: column;
  transition: width 0.3s;
}
.dxbs-scroll-viewer-vert-scroll-bar > .dxbs-scroll-viewer-scroll-thumb {
  width: 0.2rem;
  transition: width 0.3s;
}
.dxbs-scroll-viewer-vert-scroll-bar > .dxbs-scroll-viewer-scroll-thumb[data-qa-dxbl-dragging] {
  opacity: 0.75;
  width: 0.5rem;
  border-radius: 0.75rem;
}

.dxbs-scroll-viewer-hor-scroll-bar {
  left: 0;
  height: 0.5rem;
  flex-direction: row;
  transition: height 0.3s;
}
.dxbs-scroll-viewer-hor-scroll-bar > .dxbs-scroll-viewer-scroll-thumb {
  height: 0.2rem;
  transition: height 0.3s;
}
.dxbs-scroll-viewer-hor-scroll-bar > .dxbs-scroll-viewer-scroll-thumb[data-qa-dxbl-dragging] {
  opacity: 0.75;
  height: 0.5rem;
  border-radius: 0.75rem;
}

.dxbs-scroll-viewer-vert-scroll-bar:hover > .dxbs-scroll-viewer-scroll-thumb {
  opacity: 0.75;
  width: 0.5rem;
  border-radius: 0.75rem;
}

.dxbs-scroll-viewer-hor-scroll-bar:hover > .dxbs-scroll-viewer-scroll-thumb {
  opacity: 0.75;
  height: 0.5rem;
  border-radius: 0.75rem;
}

dxbl-modal > .dxbs-modal > .dxbs-popup.dxbs-sidepanel-dialog.dxbs-grid-column-chooser-dialog {
  width: 100vw;
  margin: 0;
}
dxbl-modal > .dxbs-modal > .dxbs-popup.dxbs-sidepanel-dialog.dxbs-grid-column-chooser-dialog .dxbs-popup-header-button {
  margin-right: 0.25rem;
}

.dxbs-grid-column-chooser-dialog.dxbs-dropdown-dialog .dxbs-dropdown-header {
  white-space: nowrap;
}
.dxbs-grid-column-chooser-dialog.dxbs-dropdown-dialog .dxbs-dropdown-body {
  padding: 0;
}
.dxbs-grid-column-chooser-dialog.dxbs-dropdown-dialog .dxbs-scroll-viewer {
  max-height: 60vh;
  max-width: 325px;
}
.dxbs-grid-column-chooser-dialog.dxbs-dropdown-dialog .dxbs-grid-column-chooser-item:last-child {
  border-bottom-color: transparent;
}
.dxbs-grid-column-chooser-dialog.dxbs-sidepanel-dialog .dxbs-dropdown-header {
  white-space: nowrap;
}
.dxbs-grid-column-chooser-dialog.dxbs-sidepanel-dialog .dxbs-modal-body {
  padding: 0;
}
.dxbs-grid-column-chooser-dialog.dxbs-sidepanel-dialog .dxbs-scroll-viewer {
  height: 50vh;
}
.dxbs-grid-column-chooser-dialog dxbl-grid-column-chooser {
  width: 100%;
}
.dxbs-grid-column-chooser-dialog dxbl-grid-column-chooser .dxbs-grid-column-chooser-item {
  display: flex;
  transition: all 0.2s;
  border-style: solid;
  border-width: 1px 0;
  border-top-color: transparent;
}
.dxbs-grid-column-chooser-dialog dxbl-grid-column-chooser .dxbs-grid-column-chooser-item.dxbs-grid-column-chooser-dragging-item {
  transition: none;
  border-top-color: inherit;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 1;
}
.dxbs-grid-column-chooser-dialog dxbl-grid-column-chooser .dxbs-grid-column-chooser-item.dxbs-grid-column-chooser-dragging-item-next-sibling {
  border-top-color: inherit;
}
.dxbs-grid-column-chooser-dialog dxbl-grid-column-chooser .dxbs-grid-column-chooser-item .dxbs-checkbox {
  width: calc(100% - (42px + 0.5rem));
  padding: 12px 4px 12px 12px;
}
.dxbs-grid-column-chooser-dialog dxbl-grid-column-chooser .dxbs-grid-column-chooser-item .dxbs-checkbox label {
  width: 100%;
}
.dxbs-grid-column-chooser-dialog dxbl-grid-column-chooser .dxbs-grid-column-chooser-item .dxbs-grid-column-chooser-item-drag-anchor {
  display: flex;
  cursor: move;
  align-items: center;
  padding-left: 16px;
  padding-right: calc(8px + 0.5rem);
}
.dxbs-grid-column-chooser-dialog dxbl-grid-column-chooser .dxbs-grid-column-chooser-item .dxbs-grid-column-chooser-item-drag-anchor .dxbs-grid-column-chooser-drag-icon {
  width: 18px;
  height: 18px;
}
.dxbs-grid-column-chooser-dialog .dxbs-dropdown-body,
.dxbs-grid-column-chooser-dialog .dxbs-modal-body,
.dxbs-grid-column-chooser-dialog dxbl-grid-column-chooser,
.dxbs-grid-column-chooser-dialog .dxbs-scroll-viewer,
.dxbs-grid-column-chooser-dialog .dxbs-scroll-viewer .dxbs-scroll-viewer-content,
.dxbs-grid-column-chooser-dialog .dxbs-grid-column-chooser-item {
  background: inherit;
  border-color: inherit;
}

.dxbs-grid-sort-asc,
.dxbs-grid-sort-desc {
  margin-left: auto;
  display: flex;
  overflow: visible !important;
}

.dxbs-grid-sort-asc > .dx-image,
.dxbs-grid-sort-desc > .dx-image,
.dxbs-grid-dragging-up-target > .dx-image,
.dxbs-grid-dragging-down-target > .dx-image {
  width: 14px;
  height: 14px;
}

.dxbs-grid-sort-desc > .dx-image,
.dxbs-grid-dragging-down-target > .dx-image {
  transform: rotate(180deg);
}

.dxbs-grid-dragging-up-target,
.dxbs-grid-dragging-down-target {
  display: flex;
  position: absolute;
  z-index: 19999;
  transition: 0.1s;
  top: 0;
  left: 0;
  pointer-events: none;
}

@keyframes dxbs-skeleton-opacity-animation {
  0% {
    opacity: 0.05;
  }
  50% {
    opacity: 0.15;
  }
  100% {
    opacity: 0.05;
  }
}
.dxbs-grid {
  display: flex;
  flex-flow: column;
  width: 100%;
}
.dxbs-grid .dxbs-grid-empty-data {
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  padding: 3rem 1.5rem;
  border-top: 0;
  border-bottom: 0;
}
.dxbs-grid .dxbs-grid-action {
  cursor: pointer;
}
.dxbs-grid > .card {
  position: static;
  width: 100%;
  min-height: 0;
}
.dxbs-grid > .card .dxbs-scroll-viewer {
  background-color: inherit;
  flex-grow: 1;
}
.dxbs-grid > .card .dxbs-scroll-viewer > .dxbs-scroll-viewer-content {
  background-color: inherit;
}
.dxbs-grid > .card .dxbs-scroll-viewer > .dxbs-scroll-viewer-content > .dxbs-grid-table {
  background-color: inherit;
}
.dxbs-grid > .card .dxbs-scroll-viewer > .dxbs-scroll-viewer-content > .dxbs-grid-table.table > :not(:first-child) {
  border-top: inherit;
}
.dxbs-grid > .card .dxbs-scroll-viewer > .dxbs-scroll-viewer-content > .dxbs-grid-table > tfoot {
  background-color: inherit;
  position: sticky;
  bottom: 0;
  z-index: 1;
}
.dxbs-grid > .card .dxbs-scroll-viewer > .dxbs-scroll-viewer-content > .dxbs-grid-table > thead {
  background-color: inherit;
  top: 0;
  position: sticky;
  z-index: 2;
}
.dxbs-grid > .card .dxbs-scroll-viewer:last-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > tfoot > tr:last-child > td {
  border-bottom: 0;
}
.dxbs-grid > .card .dxbs-scroll-viewer:last-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > tbody > tr:last-child > td {
  border-bottom: 0;
}
.dxbs-grid > .card .dxbs-scroll-viewer:last-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > tfoot > tr.dxbs-grid-empty-footer-row {
  display: none;
}
.dxbs-grid > .card .dxbs-scroll-viewer:first-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > thead > tr:first-child > th, .dxbs-grid > .card .dxbs-scroll-viewer:first-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > tbody > tr:first-child > th {
  border-top-width: 0;
}
.dxbs-grid > .card .dxbs-scroll-viewer:not(:last-child) > .dxbs-scroll-viewer-content > .dxbs-grid-table > tbody:not(:last-child) > tr:last-child > td {
  border-bottom: 0;
}
.dxbs-grid > .card .dxbs-scroll-viewer:first-child, .dxbs-grid > .card .dxbs-scroll-viewer:first-child > .dxbs-scroll-viewer-content, .dxbs-grid > .card .dxbs-scroll-viewer:first-child > .dxbs-scroll-viewer-content > .dxbs-grid-table, .dxbs-grid > .card .dxbs-scroll-viewer:first-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > colgroup + thead, .dxbs-grid > .card .dxbs-scroll-viewer:first-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > colgroup + thead > tr:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.dxbs-grid > .card .dxbs-scroll-viewer:first-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > colgroup + thead > tr:first-child > th:first-child {
  border-top-left-radius: inherit;
}
.dxbs-grid > .card .dxbs-scroll-viewer:first-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > colgroup + thead > tr:first-child > th:last-child {
  border-top-right-radius: inherit;
}
.dxbs-grid > .card .dxbs-scroll-viewer:last-child, .dxbs-grid > .card .dxbs-scroll-viewer:last-child > .dxbs-scroll-viewer-content, .dxbs-grid > .card .dxbs-scroll-viewer:last-child > .dxbs-scroll-viewer-content > .dxbs-grid-table, .dxbs-grid > .card .dxbs-scroll-viewer:last-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > tfoot, .dxbs-grid > .card .dxbs-scroll-viewer:last-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > tfoot > tr:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.dxbs-grid > .card .dxbs-scroll-viewer:last-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > tfoot > tr:last-child > td:first-child {
  border-bottom-left-radius: inherit;
}
.dxbs-grid > .card .dxbs-scroll-viewer:last-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > tfoot > tr:last-child > td:last-child {
  border-bottom-right-radius: inherit;
}
.dxbs-grid > .card > .card-body, .dxbs-grid > .card > .card-header, .dxbs-grid > .card > .card-footer {
  flex-shrink: 0;
  flex-grow: 0;
}
.dxbs-grid .dxbs-grid-group-panel-content-container {
  padding: 0.75rem 1.25rem;
  display: flex;
  flex-flow: row wrap;
}
.dxbs-grid .dxbs-grid-group-panel-content-container .dxbs-grid-header {
  padding: 0.3rem 0.6rem;
  margin-right: 0.6rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
}
.dxbs-grid .dxbs-grid-group-panel-content-container .dxbs-grid-header,
.dxbs-grid .dxbs-grid-group-panel-content-container .dxbs-grid-group-content-free-space {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.dxbs-grid .dxbs-grid-group-panel-content-container .dxbs-grid-header:nth-last-child(-n+2) {
  margin-right: 0;
}
.dxbs-grid .dxbs-grid-group-panel-content-container .dxbs-grid-group-content-free-space {
  flex: 1 1;
}
.dxbs-grid .dxbs-grid-table-border-bottom {
  border-bottom-width: var(--dx-grid-table-border-width, 1px);
}
.dxbs-grid .dxbs-grid-table {
  table-layout: fixed;
  border-collapse: separate;
  border-width: 0;
  border-spacing: 0;
  height: 100%;
  margin: 0;
}
.dxbs-grid .dxbs-grid-table > * > tr > th:not(.dxbs-grid-header-indent-cell):not(.dxbs-grid-empty-header), .dxbs-grid .dxbs-grid-table > * > tr > td:not(.dxbs-grid-indent-cell):not(.dxbs-grid-expand-button-cell):not(.dxbs-grid-empty-cell):not(.dxbs-grid-empty-data):not(.dxbs-grid-edit-form):not(.dxbs-grid-detail-cell) {
  padding: 0.25rem 0.4rem;
}
.dxbs-grid .dxbs-grid-table > thead > tr > th span, .dxbs-grid .dxbs-grid-table > tbody > tr > td, .dxbs-grid .dxbs-grid-table > tbody > tr.dxbs-grid-group-footer-row > td > div, .dxbs-grid .dxbs-grid-table > tfoot > tr > td > div {
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: normal;
}
.dxbs-grid .dxbs-grid-table > thead > tr > th {
  position: relative;
}
.dxbs-grid .dxbs-grid-table > thead > tr > th > .dxbs-grid-column-resize-anchor {
  top: 0;
  right: calc((var(--dx-grid-table-border-width, 1px) - 7px) / 2 - var(--dx-grid-table-border-width, 1px));
  width: 7px;
  height: 100%;
  position: absolute;
  cursor: col-resize;
  z-index: 1;
  opacity: 0;
}
.dxbs-grid .dxbs-grid-table > thead > tr > th > .dxbs-grid-column-resize-anchor > div {
  pointer-events: none;
  width: 100%;
}
.dxbs-grid .dxbs-grid-table > thead > tr > th > .dxbs-grid-column-resize-anchor > div:focus {
  outline: 0;
}
.dxbs-grid .dxbs-grid-table > thead > tr > th > .dxbs-grid-column-resize-anchor.touch {
  width: calc(0.8rem + var(--dx-grid-table-border-width, 1px));
  right: calc(-0.4rem - var(--dx-grid-table-border-width, 1px));
}
.dxbs-grid .dxbs-grid-table > thead > tr > th > .dxbs-grid-column-resize-anchor.last {
  width: 3px;
  right: 0;
}
.dxbs-grid .dxbs-grid-table > tbody > tr:first-child > td {
  border-top: 0;
}
.dxbs-grid .dxbs-grid-table > tbody > tr:not(:last-child) > td {
  border-bottom: 0;
}
.dxbs-grid .dxbs-grid-table > * > tr > :first-child {
  border-left: 0;
}
.dxbs-grid .dxbs-grid-table > * > tr > td, .dxbs-grid .dxbs-grid-table > * > tr > th {
  border-right: 0;
  vertical-align: middle;
}
.dxbs-grid .dxbs-grid-table > * > tr > td.dxbs-grid-empty-cell {
  border-top-width: 0;
  border-right-width: 0;
  padding-left: 0;
  padding-right: 0;
}
.dxbs-grid .dxbs-grid-table > thead > tr > th.dxbs-grid-empty-cell {
  border-bottom-width: 0;
}
.dxbs-grid .dxbs-grid-table > tfoot > .dxbs-grid-empty-footer-row > td {
  border-top: 0 !important;
  height: 0;
  padding: 0 !important;
}
.dxbs-grid .dxbs-grid-table > tfoot > tr:not(:last-child) > td {
  border-bottom-width: 0;
}
.dxbs-grid .dxbs-grid-table > tfoot > tr:last-child > td {
  border-right-width: 0;
}
.dxbs-grid .dxbs-grid-table > thead > .dxbs-grid-arm-row {
  visibility: collapse;
}
.dxbs-grid .dxbs-grid-table > thead > .dxbs-grid-arm-row > th {
  border: 0;
}
.dxbs-grid .dxbs-grid-table > thead .dxbs-grid-header-row > th:not(.dxbs-grid-empty-cell) {
  background-color: var(--dx-grid-header-color, rgba(0, 0, 0, 0.05));
}
.dxbs-grid .dxbs-grid-table > thead .dxbs-grid-filter-row {
  background-color: inherit;
}
.dxbs-grid .dxbs-grid-table > thead .dxbs-grid-filter-row > td {
  background-color: inherit;
  border-top: 0;
}
.dxbs-grid .dxbs-grid-table > thead .dxbs-grid-filter-row > td.dxbs-grid-empty-cell {
  border-bottom: 0;
}
.dxbs-grid .dxbs-grid-table > thead .dxbs-grid-filter-row > td.dxbs-grid-indent-cell {
  border-left: 0;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-empty-header {
  padding: 0.75rem 1.25rem;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-indent-cell-size {
  width: 1.5rem;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-indent-cell-size:not(:first-child) {
  width: calc(1.5rem + var(--dx-grid-table-border-width, 1px));
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-indent-cell,
.dxbs-grid .dxbs-grid-table .dxbs-grid-empty-cell {
  background-color: transparent;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-header-indent-cell {
  border-left: 0;
  border-right: 0;
}
.dxbs-grid .dxbs-grid-table tr:not(.dxbs-grid-footer-row):not(.dxbs-grid-filter-row) .dxbs-grid-indent-cell {
  border-top-width: 0;
  border-right-width: 0;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-expand-button-cell {
  border-right: 0;
  padding: 0 0 0 1px;
  text-align: center;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-expand-button-cell + td {
  border-left: 0;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-expand-button-cell .dxbs-grid-expand-button {
  height: 0.6rem;
  width: 0.6rem;
  padding: 0.15rem;
  box-sizing: content-box;
  line-height: 1.5rem;
  border-radius: 0.75rem;
  color: currentcolor;
  vertical-align: baseline;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-expand-button-cell .dxbs-grid-expand-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-expand-button-cell .dxbs-grid-expand-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.2);
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-command-cell,
.dxbs-grid .dxbs-grid-table .dxbs-grid-selection-cell {
  padding-top: 0.15rem !important;
  padding-bottom: 0.15rem !important;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-command-cell .btn {
  height: 100%;
  line-height: 100%;
  max-height: 1.4rem;
  padding-top: 0;
  padding-bottom: 0;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-edit-form {
  overflow: visible;
  padding: 1rem 0.5rem;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-skeleton-content {
  display: inline-flex;
  max-width: 100%;
  border-radius: 10rem;
  background-color: currentcolor;
  animation: 2s linear infinite forwards dxbs-skeleton-opacity-animation;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-skeleton-content.dxbs-grid-skeleton-content-date {
  width: 8rem;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-skeleton-content.dxbs-grid-skeleton-content-bool {
  width: 4rem;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-skeleton-content.dxbs-grid-skeleton-content-string-1 {
  width: 50%;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-skeleton-content.dxbs-grid-skeleton-content-number-1 {
  width: 3em;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-skeleton-content.dxbs-grid-skeleton-content-string-2 {
  width: 60%;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-skeleton-content.dxbs-grid-skeleton-content-number-2 {
  width: 4em;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-skeleton-content.dxbs-grid-skeleton-content-string-3 {
  width: 70%;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-skeleton-content.dxbs-grid-skeleton-content-number-3 {
  width: 5em;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-skeleton-content.dxbs-grid-skeleton-content-string-4 {
  width: 80%;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-skeleton-content.dxbs-grid-skeleton-content-number-4 {
  width: 6em;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-skeleton-content.dxbs-grid-skeleton-content-string-5 {
  width: 90%;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-skeleton-content.dxbs-grid-skeleton-content-number-5 {
  width: 7em;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-empty-cell {
  overflow: hidden;
}
.dxbs-grid .dxbs-grid-table .dxbs-grid-empty-cell.dxbs-grid-hidden-empty-cell {
  width: 0;
}
.dxbs-grid .dxbs-grid-selected-row > td:not(.dxbs-grid-empty-cell):not(.dxbs-grid-indent-cell) {
  background-color: var(--dx-grid-selection-color, rgba(0, 0, 0, 0.1));
}
.dxbs-grid .dxbs-grid-touch-selection:not(.dxbs-grid-group-row) > td:not(.dxbs-grid-empty-cell):not(.dxbs-grid-indent-cell) {
  background-color: var(--dx-grid-selection-color, rgba(0, 0, 0, 0.1));
}
.dxbs-grid .dxbs-grid-touch-selection-edge > td:not(.dxbs-grid-empty-cell):not(.dxbs-grid-indent-cell) {
  background-color: var(--dx-grid-touch-color, rgba(0, 0, 0, 0.2));
}
.dxbs-grid .dxbs-grid-footer-row {
  font-weight: 700;
}
.dxbs-grid .dxbs-grid-footer-row > td:not(.dxbs-grid-empty-cell) {
  background-color: var(--dx-grid-footer-color, rgba(0, 0, 0, 0.03));
  border-right: 0;
  border-left: 0;
}
.dxbs-grid .dxbs-grid-group-footer-row {
  font-weight: 600;
}
.dxbs-grid .dxbs-grid-group-footer-row > td:not(.dxbs-grid-empty-cell) {
  background-color: var(--dx-grid-group-footer-color, rgba(0, 0, 0, 0.02));
  border-right: 0;
}
.dxbs-grid .dxbs-grid-group-footer-row > td.dxbs-grid-indent-cell {
  background-color: transparent;
}
.dxbs-grid .dxbs-grid-group-footer-row > td:not(.dxbs-grid-indent-cell) + td:not(.dxbs-grid-indent-cell):not(.dxbs-grid-empty-cell) {
  border-left: 0;
}
.dxbs-grid .dxbs-grid-detail-cell {
  padding: 1.9rem 1.9rem;
}
.dxbs-grid .dxbs-grid-pager-container {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0.4rem 0.4rem 0;
}
.dxbs-grid .dxbs-grid-pager-container > * {
  margin-bottom: 0.4rem;
}
.dxbs-grid .dxbs-grid-pager-container .dxbs-pager {
  margin-right: 0.4rem;
}
.dxbs-grid .dxbs-grid-pager-container .dxbs-grid-page-size-selector {
  display: flex;
  flex-wrap: nowrap;
}
.dxbs-grid .dxbs-grid-pager-container .dxbs-grid-page-size-selector > label {
  margin-right: 0.4rem;
  white-space: nowrap;
}
.dxbs-grid .dxbs-grid-pager-container .dxbs-grid-page-size-selector-edit {
  display: block;
  width: 80px;
}
.dxbs-grid .dxbs-grid-columns-separator {
  position: absolute;
  z-index: 3;
  width: 3px;
  height: 100%;
}
.dxbs-grid .dxbs-grid-columns-separator.active {
  background-color: var(--bs-primary);
  transition: background-color 0.25s;
  pointer-events: none;
  box-shadow: inset 0 0 0 9999px rgba(255, 255, 255, 0.2);
}

.dxbs-grid-edit-form-buttons {
  padding: 0.25rem 0.5rem 0;
  display: flex;
  justify-content: flex-end;
}
.dxbs-grid-edit-form-buttons > .btn:not(:last-child) {
  margin-right: 0.5rem;
}

.dxbs-grid-confirm-dialog-buttons {
  padding: 1rem 0 0;
  display: flex;
  justify-content: center;
}
.dxbs-grid-confirm-dialog-buttons > .btn {
  min-width: 3.25rem;
}
.dxbs-grid-confirm-dialog-buttons > .btn:not(:last-child) {
  margin-right: 0.5rem;
}

.dxbs-grid-command-cell .dxbs-grid-header-content,
.dxbs-grid-selection-cell .dxbs-grid-header-content {
  height: 100%;
  justify-content: flex-start;
}
.dxbs-grid-command-cell .dxbs-grid-header-content.text-center,
.dxbs-grid-selection-cell .dxbs-grid-header-content.text-center {
  justify-content: center;
}
.dxbs-grid-command-cell .dxbs-grid-header-content.text-right,
.dxbs-grid-selection-cell .dxbs-grid-header-content.text-right {
  justify-content: flex-end;
}
.dxbs-grid-command-cell .dxbs-checkbox,
.dxbs-grid-selection-cell .dxbs-checkbox {
  display: inline-block;
}

.dxbs-grid-header-content {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.dxbs-grid-header-content > :first-child:not(:only-child):not(.dxbs-checkbox) {
  margin-right: 0.6rem;
}

.dxbs-grid-draggable-header {
  visibility: hidden;
  position: absolute;
  justify-content: center;
  flex-direction: row;
  z-index: 20000;
  cursor: move;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  top: 0;
  left: 0;
  pointer-events: none;
  vertical-align: middle;
}
.dxbs-grid-draggable-header .dxbs-grid-header-content {
  width: 100%;
}
.dxbs-grid-draggable-header .dxbs-grid-header-content > :first-child {
  margin-right: 0.4rem;
}

.dxbs-grid-draggable-header::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: move;
}

.dxbs-resize-cursor,
.dxbs-resize-cursor .dxbs-grid th.dxbs-grid-action {
  cursor: col-resize;
}

.dxOpera .dxbs-grid .dxbs-scroll-viewer .dxbs-grid-table > tfoot > tr > td {
  position: sticky;
  bottom: 0;
  z-index: 1;
}
.dxOpera .dxbs-grid .dxbs-scroll-viewer .dxbs-grid-table > thead > .dxbs-grid-header-row > th {
  top: 0;
  position: sticky;
  z-index: 2;
}
.dxOpera .dxbs-grid .dxbs-scroll-viewer .dxbs-grid-table > thead > .dxbs-grid-filter-row > td {
  top: 0;
  position: sticky;
  z-index: 2;
}

dxbl-popup-cell {
  position: absolute;
  top: 0;
  left: 0;
}

dxbl-events-interceptor {
  display: none;
}

dxbl-modal > .dxbs-modal {
  overflow: auto;
  display: grid;
  align-items: center;
  justify-items: center;
}
dxbl-modal > .dxbs-modal > .dxbs-popup {
  outline: 0;
  box-sizing: border-box;
  opacity: 1;
  position: relative;
  left: auto;
  top: auto;
  padding: 0;
  align-self: center;
  justify-self: center;
  margin: 1.75rem;
  width: 500px;
}
dxbl-modal > .dxbs-modal > .dxbs-popup.dxbs-popup-fixed-width {
  min-width: 0;
  max-width: unset;
}
dxbl-modal > .dxbs-modal > .dxbs-popup.dxbs-popup-horizontalalignment-left {
  justify-self: start !important;
}
dxbl-modal > .dxbs-modal > .dxbs-popup.dxbs-popup-horizontalalignment-right {
  justify-self: end !important;
}
dxbl-modal > .dxbs-modal > .dxbs-popup.dxbs-popup-horizontalalignment-center {
  justify-self: center !important;
}
dxbl-modal > .dxbs-modal > .dxbs-popup.dxbs-popup-verticalalignment-top {
  align-self: start !important;
}
dxbl-modal > .dxbs-modal > .dxbs-popup.dxbs-popup-verticalalignment-bottom {
  align-self: end !important;
}
dxbl-modal > .dxbs-modal > .dxbs-popup.dxbs-popup-verticalalignment-center {
  align-self: center !important;
}
@media (max-width: 576px) {
  dxbl-modal > .dxbs-modal > .dxbs-popup.dxbs-popup-adaptivity {
    width: unset;
    align-self: center;
    justify-self: stretch;
  }
}
dxbl-modal > .dxbs-modal > .dxbs-popup.dxbs-popup-scrollable > .dxbs-modal-content {
  max-height: calc(100vh - 3.5rem);
  overflow-y: hidden;
  border-width: thin;
}
dxbl-modal > .dxbs-modal > .dxbs-popup.dxbs-popup-scrollable > .dxbs-modal-content > .dxbs-modal-body {
  overflow-y: auto;
}
dxbl-modal > .dxbs-modal > .dxbs-popup > .dxbs-modal-content > .dxbs-modal-header {
  padding: 0.5rem 1rem;
}
dxbl-modal > .dxbs-modal > .dxbs-popup > .dxbs-modal-content > .dxbs-modal-header.drag {
  cursor: default;
}
dxbl-modal > .dxbs-modal > .dxbs-popup > .dxbs-modal-content > .dxbs-modal-footer {
  margin: unset;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
}
dxbl-modal > .dxbs-modal > .dxbs-popup > .dxbs-modal-content > .dxbs-modal-footer > * {
  margin: unset;
}
dxbl-modal > .dxbs-modal > .dxbs-popup .dxbs-popup-icons-area {
  transform: translate(-0.5rem, 0);
}
dxbl-modal > .dxbs-modal > .dxbs-popup .dxbs-popup-buttons-area {
  transform: translate(0.5rem, 0);
}
dxbl-modal > .dxbs-modal > .dxbs-popup .dxbs-popup-header-button {
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  margin: unset;
  padding: unset;
  opacity: unset;
  color: inherit;
  background-color: transparent;
  border: 0;
}
dxbl-modal > .dxbs-modal > .dxbs-popup .dxbs-popup-header-button > .dxbs-popup-header-button-image {
  width: 16px;
  height: 16px;
  fill-opacity: 0.6;
}
@media (hover: hover) {
  dxbl-modal > .dxbs-modal > .dxbs-popup .dxbs-popup-header-button:not(:disabled):not(.disabled):hover::before, dxbl-modal > .dxbs-modal > .dxbs-popup .dxbs-popup-header-button:not(:disabled):not(.disabled):focus::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: currentcolor;
    opacity: 0.1;
    border-radius: 50%;
  }
}
dxbl-modal > .dxbs-modal-back {
  display: none;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  visibility: visible;
  opacity: 0.5;
  z-index: -1;
}

.dxbs-modal-open {
  overflow: hidden;
}

.dx-prevent-safari-scrolling {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

dxbl-modal > .dxbs-sidepanel > .dxbs-popup.dxbs-sidepanel-dialog.dxbs-sidepanel-position-left {
  margin-left: 0;
}
dxbl-modal > .dxbs-sidepanel > .dxbs-popup.dxbs-sidepanel-dialog.dxbs-sidepanel-position-left > .dxbs-modal-content {
  border-bottom-left-radius: unset;
  border-top-left-radius: unset;
}
dxbl-modal > .dxbs-sidepanel > .dxbs-popup.dxbs-sidepanel-dialog.dxbs-sidepanel-position-top {
  margin-top: 0;
}
dxbl-modal > .dxbs-sidepanel > .dxbs-popup.dxbs-sidepanel-dialog.dxbs-sidepanel-position-top > .dxbs-modal-content {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}
dxbl-modal > .dxbs-sidepanel > .dxbs-popup.dxbs-sidepanel-dialog.dxbs-sidepanel-position-right {
  margin-right: 0;
}
dxbl-modal > .dxbs-sidepanel > .dxbs-popup.dxbs-sidepanel-dialog.dxbs-sidepanel-position-right > .dxbs-modal-content {
  border-bottom-right-radius: unset;
  border-top-right-radius: unset;
}
dxbl-modal > .dxbs-sidepanel > .dxbs-popup.dxbs-sidepanel-dialog.dxbs-sidepanel-position-bottom {
  margin-bottom: 0;
}
dxbl-modal > .dxbs-sidepanel > .dxbs-popup.dxbs-sidepanel-dialog.dxbs-sidepanel-position-bottom > .dxbs-modal-content {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}

dxbl-events-interceptor {
  display: none;
}

dxbl-flyout {
  position: absolute;
  top: 0;
  left: 0;
}
dxbl-flyout:not(:defined) {
  display: none;
}
dxbl-flyout > .dxbs-flyout-dialog {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
  flex: 1 1 auto;
  display: flex;
  position: relative;
  min-width: 0;
  min-height: 0;
  max-width: unset;
  max-height: unset;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: flex-start;
  outline: 0;
  padding: unset;
  margin: unset;
}
dxbl-flyout > .dxbs-flyout-dialog.dxbs-flyout-scrollable > .dxbs-flyout-body {
  overflow-y: auto;
  min-height: 0;
}
dxbl-flyout > .dxbs-flyout-dialog > .dxbs-flyout-header {
  padding: 0.5rem 1rem;
}
dxbl-flyout > .dxbs-flyout-dialog > .dxbs-flyout-header.drag {
  cursor: default;
}
dxbl-flyout > .dxbs-flyout-dialog > .dxbs-flyout-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 auto;
  min-height: 0;
}
dxbl-flyout > .dxbs-flyout-dialog > .dxbs-flyout-body > * {
  min-height: 0;
}
dxbl-flyout > .dxbs-flyout-dialog > .dxbs-flyout-footer {
  margin: unset;
  padding: 0.5rem 0.75rem;
  gap: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
dxbl-flyout > .dxbs-flyout-dialog > .dxbs-flyout-footer > * {
  margin: unset;
}
dxbl-flyout > .dxbs-flyout-dialog > .dxbs-flyout-arrow {
  margin: unset;
}
dxbl-flyout > .dxbs-flyout-dialog-fade {
  opacity: 0;
}

dxbl-events-interceptor {
  display: none;
}

dxbl-dropdown {
  position: absolute;
  top: 0;
  left: 0;
  z-index: unset;
}
dxbl-dropdown:not(:defined) {
  display: none;
}
dxbl-dropdown:not([disable-margin-unset]) > .dxbs-dropdown-dialog {
  margin: unset;
}
dxbl-dropdown[x-drop-alignment=top] > .dxbs-dropdown-dialog > .dxbs-dropdown-top-sizegrip-area {
  display: block;
}
dxbl-dropdown[x-drop-alignment=bottom] > .dxbs-dropdown-dialog > .dxbs-dropdown-bottom-sizegrip-area {
  display: block;
}
dxbl-dropdown > .dxbs-dropdown-dialog {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
  flex: 1 1 auto;
  display: flex;
  position: relative;
  min-width: 0;
  min-height: 0;
  max-width: unset;
  max-height: unset;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: flex-start;
  outline: 0;
  padding: unset;
}
dxbl-dropdown > .dxbs-dropdown-dialog.dxbs-dropdown-scrollable > .dxbs-dropdown-body {
  overflow-y: auto;
  min-height: 0;
}
dxbl-dropdown > .dxbs-dropdown-dialog > .dxbs-dropdown-header {
  padding: 0.5rem 1rem;
  flex-wrap: nowrap;
}
dxbl-dropdown > .dxbs-dropdown-dialog > .dxbs-dropdown-header.drag {
  cursor: default;
}
dxbl-dropdown > .dxbs-dropdown-dialog > .dxbs-dropdown-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 0;
}
dxbl-dropdown > .dxbs-dropdown-dialog > .dxbs-dropdown-body > * {
  min-height: 0;
}
dxbl-dropdown > .dxbs-dropdown-dialog > .dxbs-dropdown-footer {
  margin: unset;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
}
dxbl-dropdown > .dxbs-dropdown-dialog > .dxbs-dropdown-footer > * {
  margin: unset;
}
dxbl-dropdown > .dxbs-dropdown-dialog > .dxbs-dropdown-sizegrip-area {
  background: unset;
  display: none;
}
dxbl-dropdown > .dxbs-dropdown-sizegrip {
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  margin: unset;
  padding: unset;
  opacity: unset;
  color: inherit;
  background-color: transparent;
}
dxbl-dropdown > .dxbs-dropdown-sizegrip > .dxbs-dropdown-sizegrip-image {
  width: 12px;
  height: 12px;
  fill-opacity: 0.6;
}

dxbl-events-interceptor {
  display: none;
}

dxbl-window {
  position: absolute;
  top: 0;
  left: 0;
  z-index: unset;
}
dxbl-window:not(:defined) {
  display: none;
}
dxbl-window > .dxbs-window-dialog {
  display: flex;
  position: relative;
  min-width: unset;
  max-width: unset;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  outline: 0;
  margin: unset;
}
dxbl-window > .dxbs-window-dialog.dxbs-window-scrollable > .dxbs-window-body {
  overflow-y: auto;
}
dxbl-window > .dxbs-window-dialog > .dxbs-window-content > .dxbs-window-header {
  padding: 0.5rem 1rem;
}
dxbl-window > .dxbs-window-dialog > .dxbs-window-content > .dxbs-window-header.drag {
  cursor: default;
}
dxbl-window > .dxbs-window-dialog > .dxbs-window-content > .dxbs-window-footer {
  margin: unset;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
}
dxbl-window > .dxbs-window-dialog > .dxbs-window-content > .dxbs-window-footer > * {
  margin: unset;
}
dxbl-window > .dxbs-window-sizegrip {
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  margin: unset;
  padding: unset;
  opacity: unset;
  color: inherit;
  background-color: transparent;
}
dxbl-window > .dxbs-window-sizegrip > .dxbs-window-sizegrip-image {
  width: 12px;
  height: 12px;
  fill-opacity: 0.6;
}

.dxbs-pager-navbutton-container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.dxbs-pager .pagination {
  margin: 0;
}
.dxbs-pager .pagination .page-link[data-args=PBP], .dxbs-pager .pagination .page-link[data-args=PBN] {
  font-weight: 500;
}
.dxbs-pager .pagination .page-link[data-args=PBP]::before, .dxbs-pager .pagination .page-link[data-args=PBN]::before {
  content: "0";
  visibility: hidden;
}
.dxbs-pager .pagination .page-item:not(.disabled) .page-link[data-args=PBP],
.dxbs-pager .pagination .page-item:not(.disabled) .page-link[data-args=PBN] {
  color: currentcolor;
}
.dxbs-pager .pagination > li.active > button {
  cursor: default;
}

.dxbs-date-edit:not(.dxbs-edit-clbv) input.form-control,
div.dxbs-spin-edit:not(.dxbs-edit-clbv) input.form-control,
.dxbs-combobox:not(.dxbs-edit-clbv) input.form-control {
  padding-right: 3.4em;
}

.dxbs-page-selector {
  display: flex;
  white-space: nowrap;
  margin-left: 5px;
  margin-right: 4px;
}

.dxbs-textbox-autofit {
  display: inline-block;
  position: relative;
}

.dxbs-page-selector .dxbs-textbox-autofit .input-absolute-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  box-sizing: border-box;
}

.dxbs-page-selector .dxbs-textbox-autofit .input-absolute-container:hover {
  background: none;
}

.dxbs-page-selector .dxbs-textbox-autofit input {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  line-height: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: inherit;
}

.dxbs-page-selector .page-link.dxbs-page-count {
  position: static;
  color: inherit;
  background: none;
  border-color: transparent;
  padding-left: 2px;
  padding-right: 2px;
}

.dxbs-textbox-autofit .dxbs-textbox-autofit-stencil {
  visibility: hidden;
  overflow: hidden;
  border-color: transparent;
  margin-left: 1px;
  display: block;
  width: 100%;
}

@media (min-width: 375px) {
  .dxbs-pager.dxbs-revert-pager.dxbs-pager-sm {
    display: none !important;
  }

  .dxbs-pager.dxbs-pager-sm {
    display: flex !important;
  }
}
@media (min-width: 625px) {
  .dxbs-pager.dxbs-revert-pager.dxbs-pager-lg {
    display: none !important;
  }

  .dxbs-pager.dxbs-pager-lg {
    display: flex !important;
  }
}
.dxbs-scroll-viewer {
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.dxbs-scroll-viewer-content {
  overflow: auto;
  width: 100%;
  scrollbar-width: none;
  scrollbar-height: none;
}

.dxbs-scroll-viewer-content::-webkit-scrollbar {
  display: none;
}

.dxbs-scroll-viewer-vert-scroll-bar,
.dxbs-scroll-viewer-hor-scroll-bar {
  bottom: 0;
  right: 0;
  position: absolute;
  display: none;
  align-items: flex-end;
  z-index: 1;
  background-color: transparent;
}
.dxbs-scroll-viewer-vert-scroll-bar.active,
.dxbs-scroll-viewer-hor-scroll-bar.active {
  display: flex;
}
.dxbs-scroll-viewer-vert-scroll-bar > .dxbs-scroll-viewer-scroll-thumb,
.dxbs-scroll-viewer-hor-scroll-bar > .dxbs-scroll-viewer-scroll-thumb {
  background-color: currentcolor;
  opacity: 0.5;
  border-radius: 0.1rem;
  cursor: default;
}

.dxbs-scroll-viewer-vert-scroll-bar {
  top: 0;
  width: 0.5rem;
  flex-direction: column;
  transition: width 0.3s;
}
.dxbs-scroll-viewer-vert-scroll-bar > .dxbs-scroll-viewer-scroll-thumb {
  width: 0.2rem;
  transition: width 0.3s;
}
.dxbs-scroll-viewer-vert-scroll-bar > .dxbs-scroll-viewer-scroll-thumb[data-qa-dxbl-dragging] {
  opacity: 0.75;
  width: 0.5rem;
  border-radius: 0.75rem;
}

.dxbs-scroll-viewer-hor-scroll-bar {
  left: 0;
  height: 0.5rem;
  flex-direction: row;
  transition: height 0.3s;
}
.dxbs-scroll-viewer-hor-scroll-bar > .dxbs-scroll-viewer-scroll-thumb {
  height: 0.2rem;
  transition: height 0.3s;
}
.dxbs-scroll-viewer-hor-scroll-bar > .dxbs-scroll-viewer-scroll-thumb[data-qa-dxbl-dragging] {
  opacity: 0.75;
  height: 0.5rem;
  border-radius: 0.75rem;
}

.dxbs-scroll-viewer-vert-scroll-bar:hover > .dxbs-scroll-viewer-scroll-thumb {
  opacity: 0.75;
  width: 0.5rem;
  border-radius: 0.75rem;
}

.dxbs-scroll-viewer-hor-scroll-bar:hover > .dxbs-scroll-viewer-scroll-thumb {
  opacity: 0.75;
  height: 0.5rem;
  border-radius: 0.75rem;
}

.dxbl-group > .dxbl-group-header {
  display: flex;
}
.dxbl-group > .dxbl-group-header .expand-btn {
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0.5rem;
  opacity: unset;
  color: inherit;
  background-color: transparent;
  border: 0;
  flex-basis: auto;
  position: relative;
  transform: scale(1);
  text-decoration: none;
}
.dxbl-group > .dxbl-group-header .expand-btn > .dxbs-expand-image {
  width: 1rem;
  height: 1rem;
}
.dxbl-group > .dxbl-group-header > .dxbl-group-header-text {
  flex-grow: 1;
}
.dxbl-group > .dxbl-group-header > .dxbl-group-header-text .expand-btn.btn.invisible {
  display: none;
}
.dxbl-group > .dxbl-group-header:not(.dxbl-group-header-start) > .dxbl-group-header-text {
  margin-right: auto;
}
.dxbl-group > .dxbl-group-header:not(.dxbl-group-header-start) .expand-btn {
  margin: 0 0 0 0.5rem;
}
.dxbl-group > .dxbl-group-header.dxbl-group-header-start > .dxbl-group-header-text {
  margin-left: auto;
}
.dxbl-group > .dxbl-group-header.dxbl-group-header-start .expand-btn {
  margin: 0 0.5rem 0 0;
}
.dxbl-group.transition > .dxbl-group-body {
  transition: height 200ms cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
  will-change: height;
}
.dxbl-group:not(.expanded):not(.transition) > .dxbl-group-header {
  border-bottom: none;
}
.dxbl-group:not([data-qa-dxbl-loaded]):not(.expanded) > .dxbl-group-body {
  display: none;
}

.btn.dxbs-cmd-btn:hover:before, .btn.dxbs-cmd-btn:focus:before, .dxbs-tree ul.nav > .nav-item > .nav-link:not(.disabled):not(.active):hover:before, .dxbs-listbox .list-group-item-action:not(.disabled):not(.dxbs-listbox-active):not(.active):hover:before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.1;
}

.btn.dxbs-cmd-btn:focus:hover:before {
  opacity: 0.2;
}

.dxbl-accordion > nav > .dxbl-accordion-group {
  display: flex;
  flex-direction: column;
}
.dxbl-accordion > nav > .dxbl-accordion-group:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.dxbl-accordion > nav > .dxbl-accordion-group:not(:first-child) {
  border-top: 0;
}
.dxbl-accordion > nav > .dxbl-accordion-group:first-child:not(:last-child) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dxbl-accordion > nav > .dxbl-accordion-group:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dxbl-accordion > nav > .dxbl-accordion-group > .dxbl-accordion-group-header {
  padding: 0.75rem;
  border-radius: inherit;
  align-items: center;
}
.dxbl-accordion > nav > .dxbl-accordion-group > .dxbl-accordion-group-header .expand-btn.btn {
  min-height: 1rem;
  min-width: 1rem;
  padding: 0;
  display: inline-flex;
  box-sizing: content-box;
  font-size: inherit;
}
.dxbl-accordion > nav > .dxbl-accordion-group > .dxbl-accordion-group-header .expand-btn.btn, .dxbl-accordion > nav > .dxbl-accordion-group > .dxbl-accordion-group-header .expand-btn.btn:hover, .dxbl-accordion > nav > .dxbl-accordion-group > .dxbl-accordion-group-header .expand-btn.btn:focus, .dxbl-accordion > nav > .dxbl-accordion-group > .dxbl-accordion-group-header .expand-btn.btn:active {
  box-shadow: none;
  font-weight: inherit;
  color: inherit;
}
.dxbl-accordion > nav > .dxbl-accordion-group > .dxbl-accordion-group-header .expand-btn.btn:before {
  top: -0.25rem;
  left: -0.25rem;
  height: calc(100% + 0.5rem);
  width: calc(100% + 0.5rem);
  opacity: 0.1;
}
.dxbl-accordion > nav > .dxbl-accordion-group:not(.dxbl-accordion-filter) > .dxbl-accordion-group-header {
  line-height: 1rem;
}
.dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-item > .dxbl-accordion-item-content, .dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-group-header .dxbl-group-header-text > .dxbl-accordion-item-content {
  display: flex;
  text-decoration: none;
  position: relative;
  transform: scale(1);
}
.dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-item > .dxbl-accordion-item-content:not(.disabled):not(.active), .dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-group-header .dxbl-group-header-text > .dxbl-accordion-item-content:not(.disabled):not(.active) {
  cursor: pointer;
  color: inherit;
}
.dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-group-header .dxbl-group-header-text > .dxbl-accordion-item-content {
  min-height: 1rem;
}
.dxbl-accordion > nav > .dxbl-accordion-group > .dxbl-accordion-group-body.card-body {
  padding: 0rem;
}
.dxbl-accordion > nav > .dxbl-accordion-group > .dxbl-accordion-group-body .dxbl-accordion-item {
  --dx-accordion-expand-width: 1rem;
}
.dxbl-accordion > nav > .dxbl-accordion-group > .dxbl-accordion-group-body .dxbl-accordion-item .dxbl-accordion-item-indent {
  --dx-accordion-indent-calc: var(--dx-accordion-indent, calc(var(--dx-accordion-expand-width) + 0.5rem));
  padding: 1.875rem calc(var(--dx-accordion-indent-calc) * var(--dx-accordion-group-level, 0)) 0 0;
}
.dxbl-accordion > nav > .dxbl-accordion-group > .dxbl-accordion-group-body .dxbl-accordion-item .dxbl-accordion-expand-button-indent {
  padding: 1.875rem 1.5rem 0 0;
}
.dxbl-accordion > nav > .dxbl-accordion-group > .dxbl-accordion-group-body .dxbl-accordion-item .dxbl-accordion-item-indent-end {
  --dx-accordion-indent-calc: var(--dx-accordion-indent, 0.75rem);
  padding: 1.875rem calc(var(--dx-accordion-indent-calc) * var(--dx-accordion-group-level, 0)) 0 0;
}
.dxbl-accordion > nav > .dxbl-accordion-group > .dxbl-accordion-group-body .dxbl-accordion-item .dxbl-accordion-item-text-container {
  font-size: 0.875rem;
  padding-top: 0;
  padding-bottom: 0;
}
.dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-item > .dxbl-accordion-item-content {
  cursor: pointer;
  padding: 0 0.75rem;
  align-items: center;
}
.dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-item > .dxbl-accordion-item-content:not(.disabled):hover:before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.1;
}
.dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-item > .dxbl-accordion-item-content:not(.disabled):active:before {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  left: 0;
  top: 0;
  opacity: 0.2;
}
.dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-item > .dxbl-accordion-item-content > .expand-btn.btn {
  min-height: 1rem;
  min-width: 1rem;
  padding: 0;
  display: inline-flex;
  box-sizing: content-box;
  font-size: inherit;
  margin: 0 0.5rem 0 0;
  border: 0;
}
.dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-item > .dxbl-accordion-item-content > .expand-btn.btn, .dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-item > .dxbl-accordion-item-content > .expand-btn.btn:hover, .dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-item > .dxbl-accordion-item-content > .expand-btn.btn:focus, .dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-item > .dxbl-accordion-item-content > .expand-btn.btn:active {
  box-shadow: none;
  font-weight: inherit;
  color: inherit;
}
.dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-item:not(.dxbl-accordion-item-start) > .dxbl-accordion-item-content > .expand-btn.btn {
  margin: 0 0 0 0.5rem;
}
.dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-item-text-container {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
}
.dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-item-text-container.form-control-sm {
  padding-left: 0;
  padding-right: 0;
  border-radius: unset;
}
.dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-item-text-container > .dxbl-accordion-item-text {
  flex: 1 1 auto;
}
.dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-item-text-container > .dxbl-accordion-item-text + .badge {
  margin-left: 0.5rem;
}
.dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-item-text-container > .dx-image:not(:last-child) {
  margin-right: 0.5rem;
}
.dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-group-header > .expand-btn > .dx-image, .dxbl-accordion > nav > .dxbl-accordion-group .dxbl-accordion-item-content > .expand-btn > .dx-image {
  opacity: 0.54;
}
.dxbl-accordion > nav .dxbl-accordion-filter > .dxbl-accordion-group-header {
  padding: 0.4375rem 0.75rem;
}
.dxbl-accordion > nav .dxbl-accordion-filter > .dxbl-accordion-group-header .dxbl-navigation-filter {
  border: none;
  background: none;
  width: 100%;
}
.dxbl-accordion > nav .dxbl-accordion-filter > .dxbl-accordion-group-header .dxbl-navigation-filter.dxbl-textbox[server-focused] {
  box-shadow: none;
}
.dxbl-accordion > nav .dxbl-accordion-filter > .dxbl-accordion-group-header .dxbl-navigation-filter.dxbl-textbox[has-clear-button] .dxbl-input-clear-button {
  padding: 0;
}
.dxbl-accordion > nav .dxbl-accordion-filter > .dxbl-accordion-group-header .dxbl-navigation-filter.dxbl-textbox[has-clear-button] .dxbl-input-clear-button > .dx-image {
  fill-opacity: 0.54;
}
.dxbl-accordion > nav .dxbl-accordion-filter > .dxbl-accordion-group-header .dxbl-navigation-filter.dxbl-textbox[has-clear-button] .dxbl-input-clear-button::before {
  top: -0.25rem;
  left: -0.25rem;
  height: calc(100% + 0.5rem);
  width: calc(100% + 0.5rem);
  opacity: 0.1;
}
.dxbl-accordion > nav .dxbl-accordion-filter > .dxbl-accordion-group-header .dxbs-filter-icon {
  align-self: center;
  flex: 1 0 auto;
  opacity: 0.5;
}
.dxbl-accordion .dxbl-filter-content {
  background-color: rgba(95, 54, 141, 0.75);
  color: #fff;
}

.dx-office-ribbon {
  border-bottom-color: inherit !important;
}

.dx-office-ribbon .dxbs-tabs > .dx-scroll-viewer {
  padding-top: 2px;
  padding-right: 1rem;
}

.dx-office-ribbon .dx-tabs-content-panel {
  overflow: hidden;
}

.dx-office-ribbon .dxbs-tabs .nav-tabs .nav-item .nav-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.dx-office-ribbon .dx-office-ribbon-context-tab .nav-link:not(.active):not(.hover) {
  background-color: transparent;
  position: relative;
}
.dx-office-ribbon .dx-office-ribbon-context-tab .nav-link:not(.active):not(.hover)::after {
  pointer-events: none;
  background-color: currentColor;
  opacity: 0.1;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dx-office-ribbon .dx-r-toolbar {
  padding: 0.625rem;
}

.dx-office-ribbon .dx-r-toolbar .dx-image,
.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown .dx-image {
  height: 18px;
  width: 18px;
}

.dx-office-ribbon .btn.disabled,
.dx-office-ribbon .btn:disabled,
.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown .btn.disabled,
.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown .btn:disabled {
  opacity: 0.5;
}

.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown .dxbs-toolbar-dropdown-item .image + span,
.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown .dxbs-toolbar-dropdown-item .image + .dxbs-combobox {
  padding-left: 0.2em;
}

.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown.dxbs-toolbar-modal.dx-office-bar-dropdown-horizontal-header {
  display: none;
}

.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown.dx-office-bar-dropdown-horizontal.dropdown-menu {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  min-width: max-content;
}

.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown.dx-office-bar-dropdown-horizontal.dropdown-menu .dx-office-bar-dropdown-horizontal-header {
  padding: 0 0.75rem 0.25rem;
  width: 100%;
}

.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown.dx-office-bar-dropdown-horizontal.dropdown-menu .dx-office-bar-dropdown-horizontal-header,
.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown.dx-office-bar-dropdown-horizontal.dropdown-menu .dx-office-bar-dropdown-horizontal-break {
  display: block;
}

.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown.dx-office-bar-dropdown-horizontal.dropdown-menu .dxbs-toolbar-dropdown-item.dropdown-item-text {
  display: inline-block;
  margin: 0.125em 0;
}

.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown.dx-office-bar-dropdown-horizontal.dropdown-menu .dx-office-bar-dropdown-horizontal-content.dropdown-item {
  padding: 0.625rem;
}

.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown.dx-office-bar-dropdown-horizontal.dropdown-menu .dx-office-bar-dropdown-horizontal-content span {
  display: none;
}

.dx-office-bar-combobox-large:not(.dxbs-toolbar-dropdown-item) {
  width: 120px;
}

.dx-office-bar-combobox-large.dxbs-toolbar-dropdown-item .dxbs-combobox {
  width: 180px;
}

.dx-office-bar-combobox-large.dxbs-toolbar-dropdown-item .image + span {
  padding-right: 2em;
}

.dx-office-bar-combobox-small:not(.dxbs-toolbar-dropdown-item) {
  width: 63px;
}

.dx-office-bar-combobox-small.dxbs-toolbar-dropdown-item .dxbs-combobox {
  width: 80px;
}

.dx-office-bar-combobox-middle:not(.dxbs-toolbar-dropdown-item) {
  width: 100px;
}

.dx-office-bar-spinedit:not(.dxbs-toolbar-dropdown-item) {
  width: 90px;
}

.dx-office-bar-spinedit > dxbl-toolbar-menu-item > div > .dxbs-spin-edit {
  width: 100px;
}

.dxbs-listbox .dxbs-preventsel > .dxbs-checkbox {
  padding-left: 0;
  margin-bottom: 0;
}
.dxbs-listbox .dxbs-preventsel > .dxbs-checkbox > input {
  margin-left: 0;
}
.dxbs-listbox > .dxbs-gridview tr {
  border-color: inherit;
}

.dxbs-scheduler > .dxbs-sc-dayview > .dxbs-sc-scroll-container > .dxbs-sc-vertical-view > tbody > tr:first-child,
.dxbs-scheduler .dxbs-sc-timeline .dxbs-sc-headers-container > .dxbs-sc-timescale:not(:first-child) > tbody > tr:first-child,
.dxbs-scheduler .dxbs-sc-timecells-container > .dxbs-sc-horizontal-view > tbody > tr:first-child,
.dxbs-scheduler .dxbs-sc-timecells-container > .dxbs-sc-horizontal-view > tbody > tr.dxbs-sc-arm + tr {
  border-top-width: 0;
}
.dxbs-scheduler .dxbs-sc-timecells-container > .dxbs-sc-horizontal-view > tbody > tr.dxbs-sc-arm {
  border-width: 0;
}
.dxbs-scheduler > .dxbs-sc-dayview > .dxbs-sc-scroll-container > .dxbs-sc-vertical-view > tbody > tr:last-child, .dxbs-scheduler > .dxbs-sc-month .dxbs-sc-timecells-container > .dxbs-sc-horizontal-view > tbody > tr:last-child {
  border-bottom-width: 0;
}
.dxbs-scheduler > .dxbs-sc-dayview > .dxbs-sc-scroll-container > .dxbs-sc-vertical-view > tbody > tr:nth-child(2n+1) {
  border-bottom-width: 0;
}
.dxbs-scheduler > .dxbs-sc-dayview > .dxbs-sc-scroll-container > .dxbs-sc-vertical-view > tbody > tr:nth-child(2n+1) > .dxbs-sc-minute-cell, .dxbs-scheduler > .dxbs-sc-dayview > .dxbs-sc-scroll-container > .dxbs-sc-vertical-view > tbody > tr:nth-child(2n+1) > .dxbs-sc-time-cell {
  border-bottom-width: 1px;
}
.dxbs-scheduler > .dxbs-sc-dayview > .dxbs-sc-scroll-container > .dxbs-sc-vertical-view > tbody > tr:nth-child(2n) {
  border-top-width: 0;
}

.dx-pivotgrid tr:last-child > .dxpg-header,
.dx-pivotgrid tbody:not(:last-child) > tr:last-child > td,
.dx-pivotgrid thead:not(:last-child) > tr:last-child > th {
  border-bottom-color: inherit;
}
.dx-pivotgrid .dxpg-area-column > .btn-link:not(:hover),
.dx-pivotgrid .dxpg-area-row > .btn-link:not(:hover) {
  text-decoration: none;
}
.dx-pivotgrid th.table-active,
.dx-pivotgrid td.table-active {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.075);
}

.dxbs-gridview .dxbs-data-row > td > .dxbs-checkbox,
.dxbs-gridview tr > th.dxbs-select-cell > .dxbs-checkbox {
  padding-left: 0;
  margin-bottom: 0;
}
.dxbs-gridview .dxbs-data-row > td > .dxbs-checkbox > input,
.dxbs-gridview tr > th.dxbs-select-cell > .dxbs-checkbox > input {
  margin-left: 0;
}
.dxbs-gridview .dxbs-select-cell > .dxbs-checkbox > .custom-checkbox > .form-check-input {
  opacity: 1;
  position: relative;
  z-index: auto;
}
.dxbs-gridview .dxbs-fixed-header-content > span:not(:hover),
.dxbs-gridview .dxbs-group-panel span:not(:hover) {
  text-decoration: none;
}
.dxbs-gridview > .card > .dxbs-table > * {
  border-style: none;
}
.dxbs-gridview > .card > .dxbs-table:first-child > thead > tr:first-child, .dxbs-gridview > .card > .dxbs-grid-vsd > .dxbs-table > tbody > tr:first-child, .dxbs-gridview > .card > .dxbs-grid-hsd > .dxbs-table > tbody > tr:first-child, .dxbs-gridview > .card > .dxbs-grid-header-container > .dxbs-table:first-child > thead > tr:first-child {
  border-top-width: 0;
}
.dxbs-gridview > .card > .dxbs-table > tbody > tr:last-child {
  border-bottom-width: 0;
}
.dxbs-gridview > .card > .dxgvHSDC tr, .dxbs-gridview > .card > .dxgvCSD tr:first-child {
  border-width: 0;
}
.dxbs-gridview > .card > .dxbs-grid-pager {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: inherit;
}
.dxbs-gridview > .card > .dxbs-grid-header-container.dxbs-grid-gph > .dxbs-fixed > thead > tr:first-child > th {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: inherit;
}
.dxbs-gridview > .card > .grid-header-template + .dxbs-table > thead > tr:first-child, .dxbs-gridview > .card > .grid-header-template + .dxbs-table > colgroup + tbody > tr:first-child {
  border-top-width: 0;
}
.dxbs-gridview > .card > .dxgvHSDC > .dxbs-table > thead,
.dxbs-gridview > .card > .dxgvCSD > .dxbs-table > tbody {
  border-top-width: 0;
  border-top-style: solid;
  border-top-color: inherit;
}

.dx-btn.btn-link:not(:hover) {
  text-decoration: none;
}

dxbl-dropdown.dxbs-dropdown > .dxbs-dropdown-dialog > .dxbs-dropdown-body table > :not(:first-child) {
  border-top-width: 0;
  border-top-color: inherit;
}

.dx-blazor-custom-control-label {
  display: none;
}

.dxbs-checkbox.form-switch.form-switch {
  padding-left: 0;
}
.dxbs-checkbox.form-switch.invalid .form-check-input {
  border-color: var(--red, var(--bs-red));
}
.dxbs-checkbox.form-switch.valid.modified .form-check-input {
  border-color: var(--green, var(--bs-green));
}
.dxbs-checkbox.form-switch .form-check-input {
  float: none;
  margin-left: 0;
}
.dxbs-checkbox.invalid .form-check-input {
  border-color: var(--red, var(--bs-red));
}
.dxbs-checkbox.valid.modified .form-check-input {
  border-color: var(--green, var(--bs-green));
}
.dxbs-checkbox.valid.modified .form-check-input:checked {
  background-color: var(--green, var(--bs-green));
}

.dxbs-combobox .dxbs-listbox > .dxbs-gridview .dropdown-item {
  border-color: inherit;
  border-width: 1px;
  border-style: solid;
}

.dxbs-grid .dxbs-grid-header-row .dxbs-grid-header-content > a:not(:hover),
.dxbs-grid .dxbs-grid-group-panel-content-container .dxbs-grid-header-content > a:not(:hover) {
  text-decoration: none;
}
.dxbs-grid .dxbs-scroll-viewer:not(:last-child) > .dxbs-scroll-viewer-content > .dxbs-grid-table > tfoot:last-child > tr:last-child > td {
  border-bottom: inherit;
}
.dxbs-grid .dxbs-scroll-viewer:not(:last-child) > .dxbs-scroll-viewer-content > .dxbs-grid-table > tbody:last-child > tr:last-child > td {
  border-bottom: inherit;
}
.dxbs-grid .dxbs-grid-table > thead > tr > th, .dxbs-grid .dxbs-grid-table > thead > tr > td:not(.dxbs-grid-empty-cell), .dxbs-grid .dxbs-grid-table > tfoot > tr > th, .dxbs-grid .dxbs-grid-table > tfoot > tr > td:not(.dxbs-grid-empty-cell), .dxbs-grid .dxbs-grid-table > tbody > tr > th, .dxbs-grid .dxbs-grid-table > tbody > tr > td:not(.dxbs-grid-empty-cell) {
  border-top: inherit;
  border-top-width: inherit;
}
.dxbs-grid .dxbs-grid-table > thead > tr:not(:last-child) > .dxbs-grid-table-border-bottom, .dxbs-grid .dxbs-grid-table > tfoot > tr:not(:last-child) > .dxbs-grid-table-border-bottom, .dxbs-grid .dxbs-grid-table > tbody > tr:not(:last-child) > .dxbs-grid-table-border-bottom {
  border-bottom-width: inherit;
}
.dxbs-grid .dxbs-grid-table > thead > tr > th.dxbs-grid-indent-cell {
  border-top-width: 1px;
  border-top-color: transparent;
}
.dxbs-grid .dxbs-grid-table > thead > .dxbs-grid-filter-row:not(:first-child) > td {
  border-top: 0;
  border-top-width: 0;
}
.dxbs-grid .dxbs-grid-table > thead > tr:last-child > * {
  border-bottom-color: inherit;
}
.dxbs-grid .dxbs-grid-table > tbody > tr:first-child > th, .dxbs-grid .dxbs-grid-table > tbody > tr:first-child > td:not(.dxbs-grid-empty-cell) {
  border-top: 0;
  border-top-width: 0;
}

.dxbs-pager .dxbs-page-selector input {
  min-height: unset;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

.btn-secondary,
.btn-secondary.disabled,
.btn-secondary:disabled {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

.btn-success,
.btn-success.disabled,
.btn-success:disabled {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

.btn-info,
.btn-info.disabled,
.btn-info:disabled {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

.btn-warning,
.btn-warning.disabled,
.btn-warning:disabled {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

.btn-danger,
.btn-danger.disabled,
.btn-danger:disabled {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

.btn-light,
.btn-light.disabled,
.btn-light:disabled {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

.btn-dark,
.btn-dark.disabled,
.btn-dark:disabled {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

.btn-secondary.disabled,
.btn-secondary:disabled,
.btn-secondary {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary:not(:disabled):not(.disabled):hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2), 0 0 0 0.25rem rgba(68, 68, 68, 0.2) !important;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2), 0 0 0 0.25rem rgba(68, 68, 68, 0.2) !important;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2), 0 0 0 0.25rem rgba(95, 54, 141, 0.5);
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2), 0 0 0 0.25rem rgba(95, 54, 141, 0.5);
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-outline-secondary {
  color: #686868;
  border-color: #d7d8d9;
}

.btn-group,
.btn-group-vertical {
  box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
}
.btn-group.btn-group-sm,
.btn-group-vertical.btn-group-sm {
  border-radius: 0.15rem;
}
.btn-group.btn-group-lg,
.btn-group-vertical.btn-group-lg {
  border-radius: 0.3rem;
}
.btn-group .btn-group,
.btn-group .btn-group-vertical,
.btn-group-vertical .btn-group,
.btn-group-vertical .btn-group-vertical {
  box-shadow: none;
}
.btn-group .btn:not(:focus),
.btn-group-vertical .btn:not(:focus) {
  box-shadow: none !important;
}
.btn-group .btn:disabled,
.btn-group .btn.disabled:hover,
.btn-group .btn.disabled:focus,
.btn-group-vertical .btn:disabled,
.btn-group-vertical .btn.disabled:hover,
.btn-group-vertical .btn.disabled:focus {
  z-index: auto;
}
.btn-group .btn-secondary:not(.active):not(:active):not(.disabled):not(disabled):hover,
.btn-group-vertical .btn-secondary:not(.active):not(:active):not(.disabled):not(disabled):hover {
  border-color: transparent;
}

.btn-group > .btn-primary:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn-primary:first-child {
  position: relative;
}
.btn-group > .btn-primary:not(:first-child):not(.active):not(:active):before, .btn-group > .btn-group:not(:first-child) > .btn-primary:first-child:not(.active):not(:active):before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: calc(100% + 2 * 1px);
  width: 1px;
  background-color: #4c2b71;
}
.btn-group > .btn-primary:not(:last-child), .btn-group > .btn-group:not(:last-child) > .btn-primary:last-child {
  position: relative;
}
.btn-group > .btn-primary:not(:last-child):not(.active):not(:active):not(.dropdown-toggle):after, .btn-group > .btn-group:not(:last-child) > .btn-primary:last-child:not(.active):not(:active):not(.dropdown-toggle):after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  height: calc(100% + 2 * 1px);
  width: 1px;
  background-color: #4c2b71;
}
.btn-group > .btn-secondary:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn-secondary:first-child {
  position: relative;
}
.btn-group > .btn-secondary:not(:first-child):not(.active):not(:active):before, .btn-group > .btn-group:not(:first-child) > .btn-secondary:first-child:not(.active):not(:active):before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: calc(100% + 2 * 1px);
  width: 1px;
  background-color: #eaeaea;
}
.btn-group > .btn-secondary:not(:last-child), .btn-group > .btn-group:not(:last-child) > .btn-secondary:last-child {
  position: relative;
}
.btn-group > .btn-secondary:not(:last-child):not(.active):not(:active):not(.dropdown-toggle):after, .btn-group > .btn-group:not(:last-child) > .btn-secondary:last-child:not(.active):not(:active):not(.dropdown-toggle):after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  height: calc(100% + 2 * 1px);
  width: 1px;
  background-color: #eaeaea;
}
.btn-group > .btn-success:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn-success:first-child {
  position: relative;
}
.btn-group > .btn-success:not(:first-child):not(.active):not(:active):before, .btn-group > .btn-group:not(:first-child) > .btn-success:first-child:not(.active):not(:active):before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: calc(100% + 2 * 1px);
  width: 1px;
  background-color: #218838;
}
.btn-group > .btn-success:not(:last-child), .btn-group > .btn-group:not(:last-child) > .btn-success:last-child {
  position: relative;
}
.btn-group > .btn-success:not(:last-child):not(.active):not(:active):not(.dropdown-toggle):after, .btn-group > .btn-group:not(:last-child) > .btn-success:last-child:not(.active):not(:active):not(.dropdown-toggle):after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  height: calc(100% + 2 * 1px);
  width: 1px;
  background-color: #218838;
}
.btn-group > .btn-info:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn-info:first-child {
  position: relative;
}
.btn-group > .btn-info:not(:first-child):not(.active):not(:active):before, .btn-group > .btn-group:not(:first-child) > .btn-info:first-child:not(.active):not(:active):before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: calc(100% + 2 * 1px);
  width: 1px;
  background-color: #008dc0;
}
.btn-group > .btn-info:not(:last-child), .btn-group > .btn-group:not(:last-child) > .btn-info:last-child {
  position: relative;
}
.btn-group > .btn-info:not(:last-child):not(.active):not(:active):not(.dropdown-toggle):after, .btn-group > .btn-group:not(:last-child) > .btn-info:last-child:not(.active):not(:active):not(.dropdown-toggle):after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  height: calc(100% + 2 * 1px);
  width: 1px;
  background-color: #008dc0;
}
.btn-group > .btn-warning:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn-warning:first-child {
  position: relative;
}
.btn-group > .btn-warning:not(:first-child):not(.active):not(:active):before, .btn-group > .btn-group:not(:first-child) > .btn-warning:first-child:not(.active):not(:active):before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: calc(100% + 2 * 1px);
  width: 1px;
  background-color: #e0a800;
}
.btn-group > .btn-warning:not(:last-child), .btn-group > .btn-group:not(:last-child) > .btn-warning:last-child {
  position: relative;
}
.btn-group > .btn-warning:not(:last-child):not(.active):not(:active):not(.dropdown-toggle):after, .btn-group > .btn-group:not(:last-child) > .btn-warning:last-child:not(.active):not(:active):not(.dropdown-toggle):after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  height: calc(100% + 2 * 1px);
  width: 1px;
  background-color: #e0a800;
}
.btn-group > .btn-danger:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn-danger:first-child {
  position: relative;
}
.btn-group > .btn-danger:not(:first-child):not(.active):not(:active):before, .btn-group > .btn-group:not(:first-child) > .btn-danger:first-child:not(.active):not(:active):before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: calc(100% + 2 * 1px);
  width: 1px;
  background-color: #c82333;
}
.btn-group > .btn-danger:not(:last-child), .btn-group > .btn-group:not(:last-child) > .btn-danger:last-child {
  position: relative;
}
.btn-group > .btn-danger:not(:last-child):not(.active):not(:active):not(.dropdown-toggle):after, .btn-group > .btn-group:not(:last-child) > .btn-danger:last-child:not(.active):not(:active):not(.dropdown-toggle):after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  height: calc(100% + 2 * 1px);
  width: 1px;
  background-color: #c82333;
}
.btn-group > .btn-light:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn-light:first-child {
  position: relative;
}
.btn-group > .btn-light:not(:first-child):not(.active):not(:active):before, .btn-group > .btn-group:not(:first-child) > .btn-light:first-child:not(.active):not(:active):before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: calc(100% + 2 * 1px);
  width: 1px;
  background-color: #d9d9d9;
}
.btn-group > .btn-light:not(:last-child), .btn-group > .btn-group:not(:last-child) > .btn-light:last-child {
  position: relative;
}
.btn-group > .btn-light:not(:last-child):not(.active):not(:active):not(.dropdown-toggle):after, .btn-group > .btn-group:not(:last-child) > .btn-light:last-child:not(.active):not(:active):not(.dropdown-toggle):after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  height: calc(100% + 2 * 1px);
  width: 1px;
  background-color: #d9d9d9;
}
.btn-group > .btn-dark:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn-dark:first-child {
  position: relative;
}
.btn-group > .btn-dark:not(:first-child):not(.active):not(:active):before, .btn-group > .btn-group:not(:first-child) > .btn-dark:first-child:not(.active):not(:active):before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: calc(100% + 2 * 1px);
  width: 1px;
  background-color: #101214;
}
.btn-group > .btn-dark:not(:last-child), .btn-group > .btn-group:not(:last-child) > .btn-dark:last-child {
  position: relative;
}
.btn-group > .btn-dark:not(:last-child):not(.active):not(:active):not(.dropdown-toggle):after, .btn-group > .btn-group:not(:last-child) > .btn-dark:last-child:not(.active):not(:active):not(.dropdown-toggle):after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  height: calc(100% + 2 * 1px);
  width: 1px;
  background-color: #101214;
}

.btn-group-vertical > .btn-primary:not(:first-child), .btn-group-vertical > .btn-group-vertical:not(:first-child) > .btn-primary:first-child {
  position: relative;
}
.btn-group-vertical > .btn-primary:not(:first-child):not(.active):not(:active):before, .btn-group-vertical > .btn-group-vertical:not(:first-child) > .btn-primary:first-child:not(.active):not(:active):before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: 1px;
  width: calc(100% + 2 * 1px);
  background-color: #4c2b71;
}
.btn-group-vertical > .btn-primary:not(:last-child), .btn-group-vertical > .btn-group-vertical:not(:last-child) > .btn-primary:last-child {
  position: relative;
}
.btn-group-vertical > .btn-primary:not(:last-child):not(.dropdown-toggle):after, .btn-group-vertical > .btn-group-vertical:not(:last-child) > .btn-primary:last-child:not(.dropdown-toggle):after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: -1px;
  height: 1px;
  width: calc(100% + 2 * 1px);
  background-color: #4c2b71;
}
.btn-group-vertical > .btn-secondary:not(:first-child), .btn-group-vertical > .btn-group-vertical:not(:first-child) > .btn-secondary:first-child {
  position: relative;
}
.btn-group-vertical > .btn-secondary:not(:first-child):not(.active):not(:active):before, .btn-group-vertical > .btn-group-vertical:not(:first-child) > .btn-secondary:first-child:not(.active):not(:active):before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: 1px;
  width: calc(100% + 2 * 1px);
  background-color: #eaeaea;
}
.btn-group-vertical > .btn-secondary:not(:last-child), .btn-group-vertical > .btn-group-vertical:not(:last-child) > .btn-secondary:last-child {
  position: relative;
}
.btn-group-vertical > .btn-secondary:not(:last-child):not(.dropdown-toggle):after, .btn-group-vertical > .btn-group-vertical:not(:last-child) > .btn-secondary:last-child:not(.dropdown-toggle):after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: -1px;
  height: 1px;
  width: calc(100% + 2 * 1px);
  background-color: #eaeaea;
}
.btn-group-vertical > .btn-success:not(:first-child), .btn-group-vertical > .btn-group-vertical:not(:first-child) > .btn-success:first-child {
  position: relative;
}
.btn-group-vertical > .btn-success:not(:first-child):not(.active):not(:active):before, .btn-group-vertical > .btn-group-vertical:not(:first-child) > .btn-success:first-child:not(.active):not(:active):before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: 1px;
  width: calc(100% + 2 * 1px);
  background-color: #218838;
}
.btn-group-vertical > .btn-success:not(:last-child), .btn-group-vertical > .btn-group-vertical:not(:last-child) > .btn-success:last-child {
  position: relative;
}
.btn-group-vertical > .btn-success:not(:last-child):not(.dropdown-toggle):after, .btn-group-vertical > .btn-group-vertical:not(:last-child) > .btn-success:last-child:not(.dropdown-toggle):after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: -1px;
  height: 1px;
  width: calc(100% + 2 * 1px);
  background-color: #218838;
}
.btn-group-vertical > .btn-info:not(:first-child), .btn-group-vertical > .btn-group-vertical:not(:first-child) > .btn-info:first-child {
  position: relative;
}
.btn-group-vertical > .btn-info:not(:first-child):not(.active):not(:active):before, .btn-group-vertical > .btn-group-vertical:not(:first-child) > .btn-info:first-child:not(.active):not(:active):before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: 1px;
  width: calc(100% + 2 * 1px);
  background-color: #008dc0;
}
.btn-group-vertical > .btn-info:not(:last-child), .btn-group-vertical > .btn-group-vertical:not(:last-child) > .btn-info:last-child {
  position: relative;
}
.btn-group-vertical > .btn-info:not(:last-child):not(.dropdown-toggle):after, .btn-group-vertical > .btn-group-vertical:not(:last-child) > .btn-info:last-child:not(.dropdown-toggle):after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: -1px;
  height: 1px;
  width: calc(100% + 2 * 1px);
  background-color: #008dc0;
}
.btn-group-vertical > .btn-warning:not(:first-child), .btn-group-vertical > .btn-group-vertical:not(:first-child) > .btn-warning:first-child {
  position: relative;
}
.btn-group-vertical > .btn-warning:not(:first-child):not(.active):not(:active):before, .btn-group-vertical > .btn-group-vertical:not(:first-child) > .btn-warning:first-child:not(.active):not(:active):before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: 1px;
  width: calc(100% + 2 * 1px);
  background-color: #e0a800;
}
.btn-group-vertical > .btn-warning:not(:last-child), .btn-group-vertical > .btn-group-vertical:not(:last-child) > .btn-warning:last-child {
  position: relative;
}
.btn-group-vertical > .btn-warning:not(:last-child):not(.dropdown-toggle):after, .btn-group-vertical > .btn-group-vertical:not(:last-child) > .btn-warning:last-child:not(.dropdown-toggle):after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: -1px;
  height: 1px;
  width: calc(100% + 2 * 1px);
  background-color: #e0a800;
}
.btn-group-vertical > .btn-danger:not(:first-child), .btn-group-vertical > .btn-group-vertical:not(:first-child) > .btn-danger:first-child {
  position: relative;
}
.btn-group-vertical > .btn-danger:not(:first-child):not(.active):not(:active):before, .btn-group-vertical > .btn-group-vertical:not(:first-child) > .btn-danger:first-child:not(.active):not(:active):before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: 1px;
  width: calc(100% + 2 * 1px);
  background-color: #c82333;
}
.btn-group-vertical > .btn-danger:not(:last-child), .btn-group-vertical > .btn-group-vertical:not(:last-child) > .btn-danger:last-child {
  position: relative;
}
.btn-group-vertical > .btn-danger:not(:last-child):not(.dropdown-toggle):after, .btn-group-vertical > .btn-group-vertical:not(:last-child) > .btn-danger:last-child:not(.dropdown-toggle):after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: -1px;
  height: 1px;
  width: calc(100% + 2 * 1px);
  background-color: #c82333;
}
.btn-group-vertical > .btn-light:not(:first-child), .btn-group-vertical > .btn-group-vertical:not(:first-child) > .btn-light:first-child {
  position: relative;
}
.btn-group-vertical > .btn-light:not(:first-child):not(.active):not(:active):before, .btn-group-vertical > .btn-group-vertical:not(:first-child) > .btn-light:first-child:not(.active):not(:active):before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: 1px;
  width: calc(100% + 2 * 1px);
  background-color: #d9d9d9;
}
.btn-group-vertical > .btn-light:not(:last-child), .btn-group-vertical > .btn-group-vertical:not(:last-child) > .btn-light:last-child {
  position: relative;
}
.btn-group-vertical > .btn-light:not(:last-child):not(.dropdown-toggle):after, .btn-group-vertical > .btn-group-vertical:not(:last-child) > .btn-light:last-child:not(.dropdown-toggle):after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: -1px;
  height: 1px;
  width: calc(100% + 2 * 1px);
  background-color: #d9d9d9;
}
.btn-group-vertical > .btn-dark:not(:first-child), .btn-group-vertical > .btn-group-vertical:not(:first-child) > .btn-dark:first-child {
  position: relative;
}
.btn-group-vertical > .btn-dark:not(:first-child):not(.active):not(:active):before, .btn-group-vertical > .btn-group-vertical:not(:first-child) > .btn-dark:first-child:not(.active):not(:active):before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: 1px;
  width: calc(100% + 2 * 1px);
  background-color: #101214;
}
.btn-group-vertical > .btn-dark:not(:last-child), .btn-group-vertical > .btn-group-vertical:not(:last-child) > .btn-dark:last-child {
  position: relative;
}
.btn-group-vertical > .btn-dark:not(:last-child):not(.dropdown-toggle):after, .btn-group-vertical > .btn-group-vertical:not(:last-child) > .btn-dark:last-child:not(.dropdown-toggle):after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: -1px;
  height: 1px;
  width: calc(100% + 2 * 1px);
  background-color: #101214;
}

.breadcrumb {
  font-weight: 500;
}

.breadcrumb-item a {
  color: #716d78;
}

.card-header {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
  background-color: rgba(34, 34, 34, 0.03);
  border-bottom: 1px solid rgba(34, 34, 34, 0.125);
  padding: 0.5rem 1.25rem;
}

.card-body {
  padding: 0.75rem 1.25rem;
}

.card-footer {
  padding: 0.5rem 1.25rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .dxbs-input-group-append > .btn,
.input-group > .btn,
.input-group > .input-group-append > .btn {
  box-shadow: none !important;
}

.input-group > .input-group-prepend > .btn-secondary,
.input-group > .dxbs-input-group-append > .btn-secondary,
.input-group > .btn-secondary,
.input-group > .input-group-append > .btn-secondary {
  border-color: #ced4da !important;
}

.form-control {
  box-shadow: inset 0 1px 2px rgba(34, 34, 34, 0.075);
}

.form-control:focus {
  box-shadow: inset 0 1px 2px rgba(34, 34, 34, 0.075), 0 0 0 0.25rem rgba(95, 54, 141, 0.25);
}

.list-group {
  border-radius: 0.25rem;
}

.modal-header {
  font-size: 1rem;
  font-weight: 500;
}

.modal-header {
  padding: 0.5rem 1rem;
}

.modal-footer {
  padding: 0.25rem 0.75rem;
}

.dxbs-modal-content {
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.25);
}

.nav-tabs .nav-link {
  border-width: 0 0 3px 0;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link.active:focus {
  border-color: #5f368d;
}

.custom-switch .custom-control-label::after {
  border-radius: 50%;
  top: 0.1rem;
  left: -2.375rem;
}

.custom-switch .custom-control-label::before {
  height: 0.875rem;
  top: calc(0.16rem + 2px);
  background-color: #000;
  border-color: #000;
  opacity: 0.3;
}

.custom-switch .custom-control-input:checked:disabled ~ .custom-control-label::before,
.custom-switch .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #000;
  border-color: #000;
  opacity: 0.1;
}

.custom-switch .custom-control-input:checked:disabled ~ .custom-control-label::after,
.custom-switch .custom-control-input:disabled ~ .custom-control-label::after {
  background-color: #eeeeee !important;
}

.custom-switch .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #000;
  opacity: 0.3;
}

.custom-switch .custom-control-label::after {
  box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #5f368d;
}

.input-group > .custom-file:not(:last-child) .custom-file-label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:first-child .custom-file-label {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

th {
  font-weight: 500;
  font-size: 0.875rem;
}

.table-sm,
.table-sm td,
.table-sm th {
  font-size: 0.8125rem;
}

.dxbl-radio-root {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}
.dxbl-radio-root:not(:defined) {
  display: none;
}
.dxbl-radio-root > .dxbl-radio-input {
  min-width: 1.25rem;
  width: 1.25rem;
  min-height: 1.25rem;
  height: 1.25rem;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(34, 34, 34, 0.25);
  border-radius: 50%;
  appearance: none;
  color-adjust: exact;
}
.dxbl-radio-root > .dxbl-radio-input:focus {
  border-color: #af9bc6;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.25);
}
.dxbl-radio-root > .dxbl-radio-input:checked {
  background-color: #5f368d;
  border-color: #af9bc6;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='white'/%3e%3c/svg%3e");
}
.dxbl-radio-root > .dxbl-radio-label.dxbl-checkbox-label-wrap {
  white-space: normal;
}
.dxbl-radio-root > .dxbl-radio-label.dxbl-checkbox-label-nowrap {
  white-space: nowrap;
}
.dxbl-radio-root > .dxbl-radio-label.dxbl-checkbox-label-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dxbl-radio-root > .dxbl-radio-label.dxbl-checkbox-label-left {
  text-align: right;
  padding-right: 0.5rem;
}
.dxbl-radio-root > .dxbl-radio-label.dxbl-checkbox-label-right {
  text-align: left;
  padding-left: 0.5rem;
}

.dxbl-radio-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  gap: 0.5rem;
}

.dxbl-button-root {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}
.dxbl-button-root:not(:defined) {
  display: none;
}
.dxbl-button-root .dxbl-button {
  font-size: 0.875rem;
  line-height: 1.42858;
  padding: 0.1875rem 0.625rem;
  column-gap: 0.44625rem;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  color: #212529;
  border-radius: 0.2rem;
}
.dxbl-button-root .dxbl-button > .dx-image {
  max-height: 1rem;
  max-width: 1rem;
}
.dxbl-button-root .dxbl-button:hover:not(disabled) {
  cursor: pointer;
}
.dxbl-button-root .dxbl-button:focus {
  outline: none;
}
.dxbl-button-root .dxbl-button[disabled] {
  pointer-events: none;
  opacity: 0.65;
}
.dxbl-button-root .dxbl-button.dxbl-button-text {
  border-color: transparent;
}
.dxbl-button-root .dxbl-button.dxbl-button-link, .dxbl-button-root .dxbl-button.dxbl-button-outline-link, .dxbl-button-root .dxbl-button.dxbl-button-text-link {
  color: #5f368d;
}
.dxbl-button-root .dxbl-button.dxbl-button-link:hover, .dxbl-button-root .dxbl-button.dxbl-button-outline-link:hover, .dxbl-button-root .dxbl-button.dxbl-button-text-link:hover {
  color: #4c2b71;
  text-decoration: underline;
}
.dxbl-button-root .dxbl-button.dxbl-button-sm {
  font-size: 0.75rem;
  line-height: 1.333334;
  padding: 0.1875rem 0.3125rem;
  column-gap: 0.2325rem;
  border-radius: 0.15rem;
}
.dxbl-button-root .dxbl-button.dxbl-button-sm > .dx-image {
  max-height: 1rem;
  max-width: 1rem;
}
.dxbl-button-root .dxbl-button.dxbl-button-lg {
  font-size: 1rem;
  line-height: 1.625;
  padding: 0.375rem 0.75rem;
  column-gap: 0.44rem;
  border-radius: 0.3rem;
}
.dxbl-button-root .dxbl-button.dxbl-button-lg > .dx-image {
  max-height: 1.125rem;
  max-width: 1.125rem;
}
.dxbl-button-root .dxbl-button > * {
  display: inline-flex;
}

.dxbl-input-validation-icon {
  font-size: 0.875rem;
  line-height: 1.42858;
  padding: 0.1875rem 0.625rem;
  column-gap: 0.44625rem;
  background-color: transparent;
  color: #dc3545;
  display: flex;
  align-items: center;
}
.dxbl-input-validation-icon > .dx-image {
  max-height: 1rem;
  max-width: 1rem;
}
.dxbl-input-validation-icon.dxbl-input-validation-icon-near {
  padding-right: unset;
}
.dxbl-input-validation-icon.dxbl-input-validation-icon-far {
  padding-left: unset;
}
.dxbl-input-validation-icon[icon-type=success] {
  color: #28a745;
}
.dxbl-input-validation-icon[icon-type=warning] {
  color: #fc781b;
}
.dxbl-input-validation-icon[icon-type=info] {
  color: #5f368d;
}
.dxbl-input-validation-icon[size-mode=small] {
  font-size: 0.75rem;
  line-height: 1.333334;
  padding: 0.1875rem 0.3125rem;
  column-gap: 0.2325rem;
}
.dxbl-input-validation-icon[size-mode=small] > .dx-image {
  max-height: 1rem;
  max-width: 1rem;
}
.dxbl-input-validation-icon[size-mode=small].dxbl-input-validation-icon-near {
  padding-right: unset;
}
.dxbl-input-validation-icon[size-mode=small].dxbl-input-validation-icon-far {
  padding-left: unset;
}
.dxbl-input-validation-icon[size-mode=large] {
  font-size: 1rem;
  line-height: 1.625;
  padding: 0.375rem 0.75rem;
  column-gap: 0.44rem;
}
.dxbl-input-validation-icon[size-mode=large] > .dx-image {
  max-height: 1.125rem;
  max-width: 1.125rem;
}
.dxbl-input-validation-icon[size-mode=large].dxbl-input-validation-icon-near {
  padding-right: unset;
}
.dxbl-input-validation-icon[size-mode=large].dxbl-input-validation-icon-far {
  padding-left: unset;
}
.dxbl-input-validation-icon > .dx-image {
  fill-opacity: 0.6;
  position: relative;
}

.dxbl-input-clear-button-root {
  background-color: transparent;
}
.dxbl-input-clear-button-root .dxbl-input-clear-button {
  color: currentColor;
  position: relative;
}
.dxbl-input-clear-button-root .dxbl-input-clear-button > .dx-image {
  fill-opacity: 0.6;
  position: relative;
}
.dxbl-input-clear-button-root:hover .dxbl-input-clear-button::before {
  content: "";
  position: absolute;
  background-color: currentColor;
  opacity: 0.15;
  border-radius: 50%;
  top: 0.1875rem;
  bottom: 0.1875rem;
  right: 0.625rem;
  left: 0.625rem;
}
.dxbl-input-clear-button-root:hover .dxbl-button-sm.dxbl-input-clear-button::before {
  top: 0.1875rem;
  bottom: 0.1875rem;
  right: 0.3125rem;
  left: 0.3125rem;
}
.dxbl-input-clear-button-root:hover .dxbl-button-lg.dxbl-input-clear-button::before {
  top: 0.375rem;
  bottom: 0.375rem;
  right: 0.75rem;
  left: 0.75rem;
}

.dxbl-spininput {
  font-size: 0.875rem;
  line-height: 1.42858;
  display: flex;
  border: 1px solid #ced4da;
  background-color: #fff;
  color: #212529;
  border-radius: 0.2rem;
}
.dxbl-spininput[server-focused] {
  border-color: #af9bc6;
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.25);
}
.dxbl-spininput[server-focused]:not([invalid])[modified] {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.dxbl-spininput[server-focused][invalid] {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.dxbl-spininput:not([invalid])[modified] {
  border-color: #28a745;
}
.dxbl-spininput[invalid] {
  border-color: #dc3545;
}
.dxbl-spininput .dxbl-spininput-input {
  box-shadow: none;
  border: 0;
  outline: none;
  flex-grow: 1;
  color: #212529;
  background-color: transparent;
  appearance: none;
  width: 100%;
}
.dxbl-spininput .dxbl-spininput-input[disabled] {
  background-color: #e9ecef;
  opacity: 0.5;
}
.dxbl-spininput [slot=right-buttons]:not([render-style-mode=text]),
.dxbl-spininput [slot=default-far-buttons]:not([render-style-mode=text]) {
  border-left: 1px solid #ced4da;
}
.dxbl-spininput [slot=left-buttons]:not([render-style-mode=text]) {
  border-right: 1px solid #ced4da;
}
.dxbl-spininput .dxbl-button-root .dxbl-input-button {
  border: 0;
  border-radius: 0;
}
.dxbl-spininput .dxbl-button-root .dxbl-input-button:focus, .dxbl-spininput .dxbl-button-root .dxbl-input-button.focus {
  box-shadow: none;
}
.dxbl-spininput .dxbl-button-root .dxbl-input-button:not(:disabled):not(.disabled):active:focus, .dxbl-spininput .dxbl-button-root .dxbl-input-button:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}
.dxbl-spininput [slot=left-buttons]:first-of-type .dxbl-input-button {
  border-top-left-radius: calc(0.2rem - 1px);
  border-bottom-left-radius: calc(0.2rem - 1px);
}
.dxbl-spininput [slot=right-buttons]:last-of-type .dxbl-input-button {
  border-top-right-radius: calc(0.2rem - 1px);
  border-bottom-right-radius: calc(0.2rem - 1px);
}
.dxbl-spininput .dxbl-spininput-input {
  font-size: 0.875rem;
  line-height: 1.42858;
  padding: 0.1875rem 0.625rem;
  border-radius: 0.2rem;
}
.dxbl-spininput.dxbl-spininput-sm {
  font-size: 0.75rem;
  line-height: 1.333334;
  border-radius: 0.15rem;
}
.dxbl-spininput.dxbl-spininput-sm [slot=left-buttons]:first-of-type .dxbl-input-button {
  border-top-left-radius: calc(0.15rem - 1px);
  border-bottom-left-radius: calc(0.15rem - 1px);
}
.dxbl-spininput.dxbl-spininput-sm [slot=right-buttons]:last-of-type .dxbl-input-button {
  border-top-right-radius: calc(0.15rem - 1px);
  border-bottom-right-radius: calc(0.15rem - 1px);
}
.dxbl-spininput.dxbl-spininput-sm .dxbl-spininput-input {
  font-size: 0.75rem;
  line-height: 1.333334;
  padding: 0.1875rem 0.3125rem;
  border-radius: 0.15rem;
}
.dxbl-spininput.dxbl-spininput-sm .dxbl-button-root-increment .dxbl-input-button,
.dxbl-spininput.dxbl-spininput-sm .dxbl-button-root-decrement .dxbl-input-button {
  column-gap: 0;
}
.dxbl-spininput.dxbl-spininput-sm .dxbl-button-root-increment .dxbl-input-button > .dx-image,
.dxbl-spininput.dxbl-spininput-sm .dxbl-button-root-decrement .dxbl-input-button > .dx-image {
  max-height: 0.68750025rem;
  max-width: 0.68750025rem;
}
.dxbl-spininput.dxbl-spininput-lg {
  font-size: 1rem;
  line-height: 1.625;
  border-radius: 0.3rem;
}
.dxbl-spininput.dxbl-spininput-lg [slot=left-buttons]:first-of-type .dxbl-input-button {
  border-top-left-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.dxbl-spininput.dxbl-spininput-lg [slot=right-buttons]:last-of-type .dxbl-input-button {
  border-top-right-radius: calc(0.3rem - 1px);
  border-bottom-right-radius: calc(0.3rem - 1px);
}
.dxbl-spininput.dxbl-spininput-lg .dxbl-spininput-input {
  font-size: 1rem;
  line-height: 1.625;
  padding: 0.375rem 0.75rem;
  border-radius: 0.3rem;
}
.dxbl-spininput.dxbl-spininput-lg .dxbl-button-root-increment .dxbl-input-button,
.dxbl-spininput.dxbl-spininput-lg .dxbl-button-root-decrement .dxbl-input-button {
  column-gap: 0;
}
.dxbl-spininput.dxbl-spininput-lg .dxbl-button-root-increment .dxbl-input-button > .dx-image,
.dxbl-spininput.dxbl-spininput-lg .dxbl-button-root-decrement .dxbl-input-button > .dx-image {
  max-height: 1.1875rem;
  max-width: 1.1875rem;
}
.dxbl-spininput[has-clear-button] .dxbl-input-clear-button-root .dxbl-input-clear-button {
  padding-left: 0;
}
.dxbl-spininput[has-clear-button] .dxbl-input-clear-button-root:hover .dxbl-input-clear-button::before {
  left: 0;
}
.dxbl-spininput[has-clear-button] .dxbl-spininput-input {
  padding-right: 0;
}
.dxbl-spininput .dxbl-button-root-increment .dxbl-input-button,
.dxbl-spininput .dxbl-button-root-decrement .dxbl-input-button {
  column-gap: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.dxbl-spininput .dxbl-button-root-increment .dxbl-input-button > .dx-image,
.dxbl-spininput .dxbl-button-root-decrement .dxbl-input-button > .dx-image {
  max-height: 0.81250375rem;
  max-width: 0.81250375rem;
}
.dxbl-spininput .dxbl-button-root-increment .dx-image {
  transform: rotate(180deg);
}

.dxbl-textbox {
  font-size: 0.875rem;
  line-height: 1.42858;
  display: flex;
  border: 1px solid #ced4da;
  background-color: #fff;
  color: #212529;
  border-radius: 0.2rem;
}
.dxbl-textbox[server-focused] {
  border-color: #af9bc6;
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.25);
}
.dxbl-textbox[server-focused]:not([invalid])[modified] {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.dxbl-textbox[server-focused][invalid] {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.dxbl-textbox:not([invalid])[modified] {
  border-color: #28a745;
}
.dxbl-textbox[invalid] {
  border-color: #dc3545;
}
.dxbl-textbox .dxbl-textbox-input {
  box-shadow: none;
  border: 0;
  outline: none;
  flex-grow: 1;
  color: #212529;
  background-color: transparent;
  appearance: none;
  width: 100%;
}
.dxbl-textbox .dxbl-textbox-input[disabled] {
  background-color: #e9ecef;
  opacity: 0.5;
}
.dxbl-textbox [slot=right-buttons]:not([render-style-mode=text]),
.dxbl-textbox [slot=default-far-buttons]:not([render-style-mode=text]) {
  border-left: 1px solid #ced4da;
}
.dxbl-textbox [slot=left-buttons]:not([render-style-mode=text]) {
  border-right: 1px solid #ced4da;
}
.dxbl-textbox .dxbl-button-root .dxbl-input-button {
  border: 0;
  border-radius: 0;
}
.dxbl-textbox .dxbl-button-root .dxbl-input-button:focus, .dxbl-textbox .dxbl-button-root .dxbl-input-button.focus {
  box-shadow: none;
}
.dxbl-textbox .dxbl-button-root .dxbl-input-button:not(:disabled):not(.disabled):active:focus, .dxbl-textbox .dxbl-button-root .dxbl-input-button:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}
.dxbl-textbox [slot=left-buttons]:first-of-type .dxbl-input-button {
  border-top-left-radius: calc(0.2rem - 1px);
  border-bottom-left-radius: calc(0.2rem - 1px);
}
.dxbl-textbox [slot=right-buttons]:last-of-type .dxbl-input-button {
  border-top-right-radius: calc(0.2rem - 1px);
  border-bottom-right-radius: calc(0.2rem - 1px);
}
.dxbl-textbox .dxbl-textbox-input {
  font-size: 0.875rem;
  line-height: 1.42858;
  padding: 0.1875rem 0.625rem;
  border-radius: 0.2rem;
}
.dxbl-textbox.dxbl-textbox-sm {
  font-size: 0.75rem;
  line-height: 1.333334;
  border-radius: 0.15rem;
}
.dxbl-textbox.dxbl-textbox-sm [slot=left-buttons]:first-of-type .dxbl-input-button {
  border-top-left-radius: calc(0.15rem - 1px);
  border-bottom-left-radius: calc(0.15rem - 1px);
}
.dxbl-textbox.dxbl-textbox-sm [slot=right-buttons]:last-of-type .dxbl-input-button {
  border-top-right-radius: calc(0.15rem - 1px);
  border-bottom-right-radius: calc(0.15rem - 1px);
}
.dxbl-textbox.dxbl-textbox-sm .dxbl-textbox-input {
  font-size: 0.75rem;
  line-height: 1.333334;
  padding: 0.1875rem 0.3125rem;
  border-radius: 0.15rem;
}
.dxbl-textbox.dxbl-textbox-lg {
  font-size: 1rem;
  line-height: 1.625;
  border-radius: 0.3rem;
}
.dxbl-textbox.dxbl-textbox-lg [slot=left-buttons]:first-of-type .dxbl-input-button {
  border-top-left-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.dxbl-textbox.dxbl-textbox-lg [slot=right-buttons]:last-of-type .dxbl-input-button {
  border-top-right-radius: calc(0.3rem - 1px);
  border-bottom-right-radius: calc(0.3rem - 1px);
}
.dxbl-textbox.dxbl-textbox-lg .dxbl-textbox-input {
  font-size: 1rem;
  line-height: 1.625;
  padding: 0.375rem 0.75rem;
  border-radius: 0.3rem;
}
.dxbl-textbox[has-clear-button] .dxbl-input-clear-button-root .dxbl-input-clear-button {
  padding-left: 0;
}
.dxbl-textbox[has-clear-button] .dxbl-input-clear-button-root:hover .dxbl-input-clear-button::before {
  left: 0;
}
.dxbl-textbox[has-clear-button] .dxbl-textbox-input {
  padding-right: 0;
}

.dxbl-textinput {
  font-size: 0.875rem;
  line-height: 1.42858;
  display: flex;
  border: 1px solid #ced4da;
  background-color: #fff;
  color: #212529;
  border-radius: 0.2rem;
}
.dxbl-textinput[server-focused] {
  border-color: #af9bc6;
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.25);
}
.dxbl-textinput[server-focused]:not([invalid])[modified] {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.dxbl-textinput[server-focused][invalid] {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.dxbl-textinput:not([invalid])[modified] {
  border-color: #28a745;
}
.dxbl-textinput[invalid] {
  border-color: #dc3545;
}
.dxbl-textinput .dxbl-textinput-input {
  box-shadow: none;
  border: 0;
  outline: none;
  flex-grow: 1;
  color: #212529;
  background-color: transparent;
  appearance: none;
  width: 100%;
}
.dxbl-textinput .dxbl-textinput-input[disabled] {
  background-color: #e9ecef;
  opacity: 0.5;
}
.dxbl-textinput [slot=right-buttons]:not([render-style-mode=text]),
.dxbl-textinput [slot=default-far-buttons]:not([render-style-mode=text]) {
  border-left: 1px solid #ced4da;
}
.dxbl-textinput [slot=left-buttons]:not([render-style-mode=text]) {
  border-right: 1px solid #ced4da;
}
.dxbl-textinput .dxbl-button-root .dxbl-input-button {
  border: 0;
  border-radius: 0;
}
.dxbl-textinput .dxbl-button-root .dxbl-input-button:focus, .dxbl-textinput .dxbl-button-root .dxbl-input-button.focus {
  box-shadow: none;
}
.dxbl-textinput .dxbl-button-root .dxbl-input-button:not(:disabled):not(.disabled):active:focus, .dxbl-textinput .dxbl-button-root .dxbl-input-button:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}
.dxbl-textinput [slot=left-buttons]:first-of-type .dxbl-input-button {
  border-top-left-radius: calc(0.2rem - 1px);
  border-bottom-left-radius: calc(0.2rem - 1px);
}
.dxbl-textinput [slot=right-buttons]:last-of-type .dxbl-input-button {
  border-top-right-radius: calc(0.2rem - 1px);
  border-bottom-right-radius: calc(0.2rem - 1px);
}
.dxbl-textinput .dxbl-textinput-input {
  font-size: 0.875rem;
  line-height: 1.42858;
  padding: 0.1875rem 0.625rem;
  border-radius: 0.2rem;
}
.dxbl-textinput.dxbl-textinput-sm {
  font-size: 0.75rem;
  line-height: 1.333334;
  border-radius: 0.15rem;
}
.dxbl-textinput.dxbl-textinput-sm [slot=left-buttons]:first-of-type .dxbl-input-button {
  border-top-left-radius: calc(0.15rem - 1px);
  border-bottom-left-radius: calc(0.15rem - 1px);
}
.dxbl-textinput.dxbl-textinput-sm [slot=right-buttons]:last-of-type .dxbl-input-button {
  border-top-right-radius: calc(0.15rem - 1px);
  border-bottom-right-radius: calc(0.15rem - 1px);
}
.dxbl-textinput.dxbl-textinput-sm .dxbl-textinput-input {
  font-size: 0.75rem;
  line-height: 1.333334;
  padding: 0.1875rem 0.3125rem;
  border-radius: 0.15rem;
}
.dxbl-textinput.dxbl-textinput-lg {
  font-size: 1rem;
  line-height: 1.625;
  border-radius: 0.3rem;
}
.dxbl-textinput.dxbl-textinput-lg [slot=left-buttons]:first-of-type .dxbl-input-button {
  border-top-left-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.dxbl-textinput.dxbl-textinput-lg [slot=right-buttons]:last-of-type .dxbl-input-button {
  border-top-right-radius: calc(0.3rem - 1px);
  border-bottom-right-radius: calc(0.3rem - 1px);
}
.dxbl-textinput.dxbl-textinput-lg .dxbl-textinput-input {
  font-size: 1rem;
  line-height: 1.625;
  padding: 0.375rem 0.75rem;
  border-radius: 0.3rem;
}
.dxbl-textinput[has-clear-button] .dxbl-input-clear-button-root .dxbl-input-clear-button {
  padding-left: 0;
}
.dxbl-textinput[has-clear-button] .dxbl-input-clear-button-root:hover .dxbl-input-clear-button::before {
  left: 0;
}
.dxbl-textinput[has-clear-button] .dxbl-textinput-input {
  padding-right: 0;
}

.dxbl-passwordbox {
  font-size: 0.875rem;
  line-height: 1.42858;
  display: flex;
  border: 1px solid #ced4da;
  background-color: #fff;
  color: #212529;
  border-radius: 0.2rem;
}
.dxbl-passwordbox[server-focused] {
  border-color: #af9bc6;
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.25);
}
.dxbl-passwordbox[server-focused]:not([invalid])[modified] {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.dxbl-passwordbox[server-focused][invalid] {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.dxbl-passwordbox:not([invalid])[modified] {
  border-color: #28a745;
}
.dxbl-passwordbox[invalid] {
  border-color: #dc3545;
}
.dxbl-passwordbox .dxbl-passwordbox-input {
  box-shadow: none;
  border: 0;
  outline: none;
  flex-grow: 1;
  color: #212529;
  background-color: transparent;
  appearance: none;
  width: 100%;
}
.dxbl-passwordbox .dxbl-passwordbox-input[disabled] {
  background-color: #e9ecef;
  opacity: 0.5;
}
.dxbl-passwordbox [slot=right-buttons]:not([render-style-mode=text]),
.dxbl-passwordbox [slot=default-far-buttons]:not([render-style-mode=text]) {
  border-left: 1px solid #ced4da;
}
.dxbl-passwordbox [slot=left-buttons]:not([render-style-mode=text]) {
  border-right: 1px solid #ced4da;
}
.dxbl-passwordbox .dxbl-button-root .dxbl-input-button {
  border: 0;
  border-radius: 0;
}
.dxbl-passwordbox .dxbl-button-root .dxbl-input-button:focus, .dxbl-passwordbox .dxbl-button-root .dxbl-input-button.focus {
  box-shadow: none;
}
.dxbl-passwordbox .dxbl-button-root .dxbl-input-button:not(:disabled):not(.disabled):active:focus, .dxbl-passwordbox .dxbl-button-root .dxbl-input-button:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}
.dxbl-passwordbox [slot=left-buttons]:first-of-type .dxbl-input-button {
  border-top-left-radius: calc(0.2rem - 1px);
  border-bottom-left-radius: calc(0.2rem - 1px);
}
.dxbl-passwordbox [slot=right-buttons]:last-of-type .dxbl-input-button {
  border-top-right-radius: calc(0.2rem - 1px);
  border-bottom-right-radius: calc(0.2rem - 1px);
}
.dxbl-passwordbox .dxbl-passwordbox-input {
  font-size: 0.875rem;
  line-height: 1.42858;
  padding: 0.1875rem 0.625rem;
  border-radius: 0.2rem;
}
.dxbl-passwordbox .dxbl-passwordbox-input::-ms-reveal, .dxbl-passwordbox .dxbl-passwordbox-input::-ms-clear {
  display: none;
}
.dxbl-passwordbox.dxbl-passwordbox-sm {
  font-size: 0.75rem;
  line-height: 1.333334;
  border-radius: 0.15rem;
}
.dxbl-passwordbox.dxbl-passwordbox-sm [slot=left-buttons]:first-of-type .dxbl-input-button {
  border-top-left-radius: calc(0.15rem - 1px);
  border-bottom-left-radius: calc(0.15rem - 1px);
}
.dxbl-passwordbox.dxbl-passwordbox-sm [slot=right-buttons]:last-of-type .dxbl-input-button {
  border-top-right-radius: calc(0.15rem - 1px);
  border-bottom-right-radius: calc(0.15rem - 1px);
}
.dxbl-passwordbox.dxbl-passwordbox-sm .dxbl-passwordbox-input {
  font-size: 0.75rem;
  line-height: 1.333334;
  padding: 0.1875rem 0.3125rem;
  border-radius: 0.15rem;
}
.dxbl-passwordbox.dxbl-passwordbox-lg {
  font-size: 1rem;
  line-height: 1.625;
  border-radius: 0.3rem;
}
.dxbl-passwordbox.dxbl-passwordbox-lg [slot=left-buttons]:first-of-type .dxbl-input-button {
  border-top-left-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.dxbl-passwordbox.dxbl-passwordbox-lg [slot=right-buttons]:last-of-type .dxbl-input-button {
  border-top-right-radius: calc(0.3rem - 1px);
  border-bottom-right-radius: calc(0.3rem - 1px);
}
.dxbl-passwordbox.dxbl-passwordbox-lg .dxbl-passwordbox-input {
  font-size: 1rem;
  line-height: 1.625;
  padding: 0.375rem 0.75rem;
  border-radius: 0.3rem;
}
.dxbl-passwordbox[has-clear-button] .dxbl-input-clear-button-root .dxbl-input-clear-button {
  padding-left: 0;
}
.dxbl-passwordbox[has-clear-button] .dxbl-input-clear-button-root:hover .dxbl-input-clear-button::before {
  left: 0;
}
.dxbl-passwordbox[has-clear-button] .dxbl-passwordbox-input {
  padding-right: 0;
}

.dxbl-memoinput {
  font-size: 0.875rem;
  line-height: 1.42858;
  display: flex;
  border: 1px solid #ced4da;
  background-color: #fff;
  color: #212529;
  border-radius: 0.2rem;
}
.dxbl-memoinput[server-focused] {
  border-color: #af9bc6;
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.25);
}
.dxbl-memoinput[server-focused]:not([invalid])[modified] {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.dxbl-memoinput[server-focused][invalid] {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.dxbl-memoinput:not([invalid])[modified] {
  border-color: #28a745;
}
.dxbl-memoinput[invalid] {
  border-color: #dc3545;
}
.dxbl-memoinput .dxbl-memoinput-input {
  box-shadow: none;
  border: 0;
  outline: none;
  flex-grow: 1;
  color: #212529;
  background-color: transparent;
  appearance: none;
  width: 100%;
}
.dxbl-memoinput .dxbl-memoinput-input[disabled] {
  background-color: #e9ecef;
  opacity: 0.5;
}
.dxbl-memoinput [slot=right-buttons]:not([render-style-mode=text]),
.dxbl-memoinput [slot=default-far-buttons]:not([render-style-mode=text]) {
  border-left: 1px solid #ced4da;
}
.dxbl-memoinput [slot=left-buttons]:not([render-style-mode=text]) {
  border-right: 1px solid #ced4da;
}
.dxbl-memoinput .dxbl-button-root .dxbl-input-button {
  border: 0;
  border-radius: 0;
}
.dxbl-memoinput .dxbl-button-root .dxbl-input-button:focus, .dxbl-memoinput .dxbl-button-root .dxbl-input-button.focus {
  box-shadow: none;
}
.dxbl-memoinput .dxbl-button-root .dxbl-input-button:not(:disabled):not(.disabled):active:focus, .dxbl-memoinput .dxbl-button-root .dxbl-input-button:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}
.dxbl-memoinput .dxbl-memoinput-input {
  font-size: 0.875rem;
  line-height: 1.42858;
  padding: 0.1875rem 0.625rem;
  border-radius: 0.2rem;
}
.dxbl-memoinput .dxbl-memoinput-input[has-clear-button], .dxbl-memoinput .dxbl-memoinput-input [has-overflow] {
  padding-right: 1.5rem;
}
.dxbl-memoinput .dxbl-memoinput-input:not(.dxbl-memoinput-input-reset-height) {
  min-height: 4rem;
}
.dxbl-memoinput .dxbl-memoinput-input.dxbl-memoinput-input-disable-resize {
  resize: none;
}
.dxbl-memoinput .dxbl-memoinput-input.dxbl-memoinput-input-vertical-resize {
  resize: vertical;
}
.dxbl-memoinput .dxbl-memoinput-input.dxbl-memoinput-input-horizontal-resize {
  resize: horizontal;
}
.dxbl-memoinput .dxbl-memoinput-input.dxbl-memoinput-input-both-resize {
  resize: both;
}
.dxbl-memoinput.dxbl-memoinput-sm {
  font-size: 0.75rem;
  line-height: 1.333334;
  border-radius: 0.15rem;
}
.dxbl-memoinput.dxbl-memoinput-sm .dxbl-memoinput-input {
  font-size: 0.75rem;
  line-height: 1.333334;
  padding: 0.1875rem 0.3125rem;
  border-radius: 0.15rem;
}
.dxbl-memoinput.dxbl-memoinput-sm .dxbl-memoinput-input[has-clear-button], .dxbl-memoinput.dxbl-memoinput-sm .dxbl-memoinput-input [has-overflow] {
  padding-right: 1.5rem;
}
.dxbl-memoinput.dxbl-memoinput-lg {
  font-size: 1rem;
  line-height: 1.625;
  border-radius: 0.3rem;
}
.dxbl-memoinput.dxbl-memoinput-lg .dxbl-memoinput-input {
  font-size: 1rem;
  line-height: 1.625;
  padding: 0.375rem 0.75rem;
  border-radius: 0.3rem;
}
.dxbl-memoinput.dxbl-memoinput-lg .dxbl-memoinput-input[has-clear-button], .dxbl-memoinput.dxbl-memoinput-lg .dxbl-memoinput-input [has-overflow] {
  padding-right: 2.5rem;
}
.dxbl-memoinput[has-clear-button] .dxbl-input-clear-button-root .dxbl-input-clear-button {
  padding-left: 0;
}
.dxbl-memoinput[has-clear-button] .dxbl-input-clear-button-root:hover .dxbl-input-clear-button::before {
  left: 0;
}

.dxbl-maskedinput {
  font-size: 0.875rem;
  line-height: 1.42858;
  display: flex;
  border: 1px solid #ced4da;
  background-color: #fff;
  color: #212529;
  border-radius: 0.2rem;
}
.dxbl-maskedinput[server-focused] {
  border-color: #af9bc6;
  box-shadow: 0 0 0 0.25rem rgba(95, 54, 141, 0.25);
}
.dxbl-maskedinput[server-focused]:not([invalid])[modified] {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.dxbl-maskedinput[server-focused][invalid] {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.dxbl-maskedinput:not([invalid])[modified] {
  border-color: #28a745;
}
.dxbl-maskedinput[invalid] {
  border-color: #dc3545;
}
.dxbl-maskedinput .dxbl-maskedinput-input {
  box-shadow: none;
  border: 0;
  outline: none;
  flex-grow: 1;
  color: #212529;
  background-color: transparent;
  appearance: none;
  width: 100%;
}
.dxbl-maskedinput .dxbl-maskedinput-input[disabled] {
  background-color: #e9ecef;
  opacity: 0.5;
}
.dxbl-maskedinput [slot=right-buttons]:not([render-style-mode=text]),
.dxbl-maskedinput [slot=default-far-buttons]:not([render-style-mode=text]) {
  border-left: 1px solid #ced4da;
}
.dxbl-maskedinput [slot=left-buttons]:not([render-style-mode=text]) {
  border-right: 1px solid #ced4da;
}
.dxbl-maskedinput .dxbl-button-root .dxbl-input-button {
  border: 0;
  border-radius: 0;
}
.dxbl-maskedinput .dxbl-button-root .dxbl-input-button:focus, .dxbl-maskedinput .dxbl-button-root .dxbl-input-button.focus {
  box-shadow: none;
}
.dxbl-maskedinput .dxbl-button-root .dxbl-input-button:not(:disabled):not(.disabled):active:focus, .dxbl-maskedinput .dxbl-button-root .dxbl-input-button:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}
.dxbl-maskedinput [slot=left-buttons]:first-of-type .dxbl-input-button {
  border-top-left-radius: calc(0.2rem - 1px);
  border-bottom-left-radius: calc(0.2rem - 1px);
}
.dxbl-maskedinput [slot=right-buttons]:last-of-type .dxbl-input-button {
  border-top-right-radius: calc(0.2rem - 1px);
  border-bottom-right-radius: calc(0.2rem - 1px);
}
.dxbl-maskedinput .dxbl-maskedinput-input {
  font-size: 0.875rem;
  line-height: 1.42858;
  padding: 0.1875rem 0.625rem;
  border-radius: 0.2rem;
}
.dxbl-maskedinput.dxbl-maskedinput-sm {
  font-size: 0.75rem;
  line-height: 1.333334;
  border-radius: 0.15rem;
}
.dxbl-maskedinput.dxbl-maskedinput-sm [slot=left-buttons]:first-of-type .dxbl-input-button {
  border-top-left-radius: calc(0.15rem - 1px);
  border-bottom-left-radius: calc(0.15rem - 1px);
}
.dxbl-maskedinput.dxbl-maskedinput-sm [slot=right-buttons]:last-of-type .dxbl-input-button {
  border-top-right-radius: calc(0.15rem - 1px);
  border-bottom-right-radius: calc(0.15rem - 1px);
}
.dxbl-maskedinput.dxbl-maskedinput-sm .dxbl-maskedinput-input {
  font-size: 0.75rem;
  line-height: 1.333334;
  padding: 0.1875rem 0.3125rem;
  border-radius: 0.15rem;
}
.dxbl-maskedinput.dxbl-maskedinput-lg {
  font-size: 1rem;
  line-height: 1.625;
  border-radius: 0.3rem;
}
.dxbl-maskedinput.dxbl-maskedinput-lg [slot=left-buttons]:first-of-type .dxbl-input-button {
  border-top-left-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.dxbl-maskedinput.dxbl-maskedinput-lg [slot=right-buttons]:last-of-type .dxbl-input-button {
  border-top-right-radius: calc(0.3rem - 1px);
  border-bottom-right-radius: calc(0.3rem - 1px);
}
.dxbl-maskedinput.dxbl-maskedinput-lg .dxbl-maskedinput-input {
  font-size: 1rem;
  line-height: 1.625;
  padding: 0.375rem 0.75rem;
  border-radius: 0.3rem;
}
.dxbl-maskedinput[has-clear-button] .dxbl-input-clear-button-root .dxbl-input-clear-button {
  padding-left: 0;
}
.dxbl-maskedinput[has-clear-button] .dxbl-input-clear-button-root:hover .dxbl-input-clear-button::before {
  left: 0;
}
.dxbl-maskedinput[has-clear-button] .dxbl-maskedinput-input {
  padding-right: 0;
}