@import "shared";

dxbl-modal {
    > .dxbs-modal {
        overflow: auto;
        display: grid;
        align-items: center;
        justify-items: center;

        > .dxbs-popup {
            @include disable-accessibility-border();

            box-sizing: border-box;
            opacity: 1;
            position: relative;
            left: auto;
            top: auto;
            padding: 0;
            align-self: center;
            justify-self: center;
            margin: 1.75rem;
            width: 500px;

            &.dxbs-popup-fixed-width {
                min-width: 0;
                max-width: unset;
            }

            &.dxbs-popup-horizontalalignment-left {
                justify-self: start !important; // stylelint-disable-line declaration-no-important
            }

            &.dxbs-popup-horizontalalignment-right {
                justify-self: end !important; // stylelint-disable-line declaration-no-important
            }

            &.dxbs-popup-horizontalalignment-center {
                justify-self: center !important; // stylelint-disable-line declaration-no-important
            }

            &.dxbs-popup-verticalalignment-top {
                align-self: start !important; // stylelint-disable-line declaration-no-important
            }

            &.dxbs-popup-verticalalignment-bottom {
                align-self: end !important; // stylelint-disable-line declaration-no-important
            }

            &.dxbs-popup-verticalalignment-center {
                align-self: center !important; // stylelint-disable-line declaration-no-important
            }

            @media (max-width: 576px) {
                &.dxbs-popup-adaptivity {
                    width: unset;
                    align-self: center;
                    justify-self: stretch;
                }
            }

            &.dxbs-popup-scrollable {
                > .dxbs-modal-content {
                    max-height: calc(100vh - 3.5rem);
                    overflow-y: hidden;
                    border-width: thin;

                    > .dxbs-modal-body {
                        overflow-y: auto;
                    }
                }
            }

            > .dxbs-modal-content {
                > .dxbs-modal-header {
                    padding: 0.5rem 1rem;

                    &.drag {
                        cursor: default;
                    }
                }

                > .dxbs-modal-footer {
                    margin: unset;
                    padding: 0.5rem 1rem;
                    gap: 0.5rem;

                    > * {
                        margin: unset;
                    }
                }
            }

            .dxbs-popup-icons-area {
                transform: translate(-0.5rem, 0);
            }

            .dxbs-popup-buttons-area {
                transform: translate(0.5rem, 0);
            }

            .dxbs-popup-header-button {
                @include disable-accessibility-border();

                display: flex;
                justify-content: center;
                align-items: center;
                width: 1.5rem;
                height: 1.5rem;
                margin: unset;
                padding: unset;
                opacity: unset;
                color: inherit;
                background-color: transparent;
                border: 0;

                > .dxbs-popup-header-button-image {
                    width: 16px;
                    height: 16px;
                    fill-opacity: 0.6;
                }

                // sticky hovers https://css-tricks.com/solving-sticky-hover-states-with-media-hover-hover/
                @media (hover: hover) {
                    &:not(:disabled):not(.disabled) {
                        &:hover,
                        &:focus {
                            &::before {
                                @include background-color-with-opacity(currentcolor, 0.1);

                                border-radius: 50%;
                            }
                        }
                    }
                }
            }
        }
    }

    > .dxbs-modal-back {
        display: none;
        right: 0;
        bottom: 0;
        left: 0;
        top: 0;
        visibility: visible;
        opacity: 0.5;
        z-index: -1;
    }
}

.dxbs-modal-open {
    overflow: hidden;
}

.dx-prevent-safari-scrolling { // stylelint-disable-line selector-class-pattern
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
