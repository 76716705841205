@import "variables";
@import "shared";

dxbl-dropdown {
    position: absolute;
    top: 0;
    left: 0;
    z-index: unset;

    &:not(:defined) {
        display: none;
    }

    &:not([disable-margin-unset]) > .dxbs-dropdown-dialog {
        margin: unset;
    }

    &[x-drop-alignment="top"] {
        > .dxbs-dropdown-dialog {
            > .dxbs-dropdown-top-sizegrip-area {
                display: block;
            }
        }
    }

    &[x-drop-alignment="bottom"] {
        > .dxbs-dropdown-dialog {
            > .dxbs-dropdown-bottom-sizegrip-area {
                display: block;
            }
        }
    }

    > .dxbs-dropdown-dialog {
        @include drop-shadow();

        flex: 1 1 auto;
        display: flex;
        position: relative;
        min-width: 0;
        min-height: 0;
        max-width: unset;
        max-height: unset;
        top: 0;
        left: 0;
        flex-direction: column;
        justify-content: flex-start;
        outline: 0;
        padding: unset;

        &.dxbs-dropdown-scrollable {
            > .dxbs-dropdown-body {
                overflow-y: auto;
                min-height: 0;
            }
        }

        > .dxbs-dropdown-header {
            padding: 0.5rem 1rem;
            flex-wrap: nowrap;

            &.drag {
                cursor: default;
            }
        }

        > .dxbs-dropdown-body {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            min-height: 0;

            > * {
                min-height: 0;
            }
        }

        > .dxbs-dropdown-footer {
            margin: unset;
            padding: 0.5rem 1rem;
            gap: 0.5rem;

            > * {
                margin: unset;
            }
        }

        > .dxbs-dropdown-sizegrip-area {
            background: unset;
            display: none;
        }
    }

    > .dxbs-dropdown-sizegrip {
        @include disable-accessibility-border();

        display: flex;
        justify-content: center;
        align-items: center;
        width: 1rem;
        height: 1rem;
        margin: unset;
        padding: unset;
        opacity: unset;
        color: inherit;
        background-color: transparent;

        > .dxbs-dropdown-sizegrip-image {
            width: 12px;
            height: 12px;
            fill-opacity: 0.6;
        }
    }
}
