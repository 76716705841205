.dx-office-ribbon {
    border-bottom-color: inherit !important;
}

.dx-office-ribbon .dxbs-tabs > .dx-scroll-viewer {
    padding-top: 2px;
    padding-right: 1rem;
}

.dx-office-ribbon .dx-tabs-content-panel {
    overflow: hidden;
}

.dx-office-ribbon .dxbs-tabs .nav-tabs .nav-item .nav-link {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.dx-office-ribbon .dx-office-ribbon-context-tab .nav-link:not(.active):not(.hover) {
    background-color: transparent;
    position: relative;

    &::after {
        pointer-events: none;
        background-color: currentColor;
        opacity: 0.1;
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.dx-office-ribbon .dx-r-toolbar {
    padding: 0.625rem;
}

.dx-office-ribbon .dx-r-toolbar .dx-image,
.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown .dx-image {
    height: 18px;
    width: 18px;
}

.dx-office-ribbon .btn.disabled,
.dx-office-ribbon .btn:disabled,
.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown .btn.disabled,
.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown .btn:disabled {
    opacity: 0.5;
}

.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown .dxbs-toolbar-dropdown-item .image + span,
.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown .dxbs-toolbar-dropdown-item .image + .dxbs-combobox {
    padding-left: 0.2em;
}

.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown.dxbs-toolbar-modal.dx-office-bar-dropdown-horizontal-header {
    display: none;
}

.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown.dx-office-bar-dropdown-horizontal.dropdown-menu {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    min-width: max-content;
}

.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown.dx-office-bar-dropdown-horizontal.dropdown-menu .dx-office-bar-dropdown-horizontal-header {
    padding: 0 0.75rem 0.25rem;
    width: 100%;
}

.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown.dx-office-bar-dropdown-horizontal.dropdown-menu .dx-office-bar-dropdown-horizontal-header,
.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown.dx-office-bar-dropdown-horizontal.dropdown-menu .dx-office-bar-dropdown-horizontal-break {
    display: block;
}

.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown.dx-office-bar-dropdown-horizontal.dropdown-menu .dxbs-toolbar-dropdown-item.dropdown-item-text {
    display: inline-block;
    margin: 0.125em 0;
}

.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown.dx-office-bar-dropdown-horizontal.dropdown-menu .dx-office-bar-dropdown-horizontal-content.dropdown-item {
    padding: 0.625rem;
}

.dx-office-ribbon-tab-content.dxbs-toolbar-dropdown.dx-office-bar-dropdown-horizontal.dropdown-menu .dx-office-bar-dropdown-horizontal-content span {
    display: none;
}

.dx-office-bar-combobox-large:not(.dxbs-toolbar-dropdown-item) {
    width: 120px;
}

.dx-office-bar-combobox-large.dxbs-toolbar-dropdown-item .dxbs-combobox {
    width: 180px;
}

.dx-office-bar-combobox-large.dxbs-toolbar-dropdown-item .image + span {
    padding-right: 2em;
}

.dx-office-bar-combobox-small:not(.dxbs-toolbar-dropdown-item) {
    width: 63px;
}

.dx-office-bar-combobox-small.dxbs-toolbar-dropdown-item .dxbs-combobox {
    width: 80px;
}

.dx-office-bar-combobox-middle:not(.dxbs-toolbar-dropdown-item) {
    width: 100px;
}

.dx-office-bar-spinedit:not(.dxbs-toolbar-dropdown-item) {
    width: 90px;
}
.dx-office-bar-spinedit > dxbl-toolbar-menu-item > div > .dxbs-spin-edit {
    width: 100px;
}
