.dxbs-spin-edit {
    &[use-adaptive-layout] {
        .input-group .dxbs-edit-btn {
            &.dec,
            &.inc {
                border-bottom: none;
                border-top: none;
                border-right: none;
                height: 100%;
            }
        }

        .dxbs-edit-clbv input.form-control {
            padding-right: 8em;
        }

        .dxbs-spin-btn-group {
            display: flex;
            flex-direction: row-reverse;

            .dxbs-editor-clear-btn {
                order: 1;
            }
        }
    }

    &:not([use-adaptive-layout]) {
        .dxbs-spin-btn-group .dxbs-edit-btn {
            display: flex;
            flex: 1 1 auto;
            padding-top: 0;
            padding-bottom: 0;
            height: 50%;
            border-top: 0;
            border-bottom: 0;
            border-right: 0;
            margin-top: 0;

            &.dec {
                position: absolute;
                right: 0;
                bottom: 0;
                width: auto;
                border-bottom: none;
            }

            &.inc {
                border-bottom: 0;
                border-bottom: 0px;
                border-bottom-right-radius: 0px;
            }
        }
    }

    &:not([data-qa-dxbl-loaded]) .dxbs-spin-btn-group {
        visibility: hidden;
    }

    .inc.dxbs-edit-btn + .dec.dxbs-edit-btn {
        margin-left: 0;
        border-top: 0px;
        border-top-right-radius: 0;
    }

    .dxbs-clickable.dxbs-edit-btn {
        width: 100%;
    }
}
