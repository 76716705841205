@use "sass:math";

@import "../navigation/scroll-viewer";

$column-chooser-drag-icon-size: 18px;
$column-chooser-item-drag-anchor-padding-left: 16px;
$column-chooser-item-drag-anchor-padding-right: 8px;

dxbl-modal > .dxbs-modal > .dxbs-popup.dxbs-sidepanel-dialog.dxbs-grid-column-chooser-dialog {
    width: 100vw;
    margin: 0;

    .dxbs-popup-header-button {
        margin-right: math.div($hovered-scroll-bar-size, 2);
    }
}

.dxbs-grid-column-chooser-dialog {
    &.dxbs-dropdown-dialog {
        & .dxbs-dropdown-header {
            white-space: nowrap;
        }

        & .dxbs-dropdown-body {
            padding: 0;
        }

        & .dxbs-scroll-viewer {
            max-height: 60vh;
            max-width: 325px;
        }

        & .dxbs-grid-column-chooser-item:last-child {
            border-bottom-color: transparent;
        }
    }

    &.dxbs-sidepanel-dialog {
        & .dxbs-dropdown-header {
            white-space: nowrap;
        }

        & .dxbs-modal-body {
            padding: 0;
        }

        & .dxbs-scroll-viewer {
            height: 50vh;
        }
    }

    & dxbl-grid-column-chooser {
        width: 100%;

        & .dxbs-grid-column-chooser-item {
            display: flex;
            transition: all 0.2s;
            border-style: solid;
            border-width: 1px 0;
            border-top-color: transparent;

            &.dxbs-grid-column-chooser-dragging-item {
                transition: none;
                border-top-color: inherit;
                box-shadow: 0 0 10px rgb(0 0 0 / 50%);
                position: relative;
                z-index: 1;
            }

            &.dxbs-grid-column-chooser-dragging-item-next-sibling {
                border-top-color: inherit;
            }

            & .dxbs-checkbox {
                width: calc(100% - ($column-chooser-drag-icon-size + $column-chooser-item-drag-anchor-padding-left + $column-chooser-item-drag-anchor-padding-right + $hovered-scroll-bar-size));
                padding: 12px 4px 12px 12px;

                & label {
                    width: 100%;
                }
            }

            & .dxbs-grid-column-chooser-item-drag-anchor {
                display: flex;
                cursor: move;
                align-items: center;
                padding-left: $column-chooser-item-drag-anchor-padding-left;
                padding-right: calc($column-chooser-item-drag-anchor-padding-right + $hovered-scroll-bar-size);

                & .dxbs-grid-column-chooser-drag-icon {
                    width: $column-chooser-drag-icon-size;
                    height: $column-chooser-drag-icon-size;
                }
            }
        }
    }

    .dxbs-dropdown-body,
    .dxbs-modal-body,
    dxbl-grid-column-chooser,
    .dxbs-scroll-viewer,
    .dxbs-scroll-viewer .dxbs-scroll-viewer-content,
    .dxbs-grid-column-chooser-item {
        background: inherit;
        border-color: inherit;
    }
}
