@use "sass:math";

.dxbl-spininput {
    @include dx-font-size();
    @include dx-input-editor-root("dxbl-spininput");
    @include dx-input-editor-utmost-buttons-radius($dx-input-editor-border-radius);

    border-radius: $dx-input-editor-border-radius;

    .dxbl-spininput-input {
        @include dx-line-box-size();

        border-radius: $dx-input-editor-border-radius;
    }

    &.dxbl-spininput-sm {
        @include dx-font-size-sm();
        @include dx-input-editor-utmost-buttons-radius($dx-input-editor-border-radius-sm);

        border-radius: $dx-input-editor-border-radius-sm;

        .dxbl-spininput-input {
            @include dx-line-box-size-sm();

            border-radius: $dx-input-editor-border-radius-sm;
        }

        .dxbl-button-root-increment,
        .dxbl-button-root-decrement {
            .dxbl-input-button {
                @include dx-button-icon-size(
                    math.div(dx-get-input-editor-height-sm(), 2),
                    0
                );
            }
        }
    }

    &.dxbl-spininput-lg {
        @include dx-font-size-lg();
        @include dx-input-editor-utmost-buttons-radius($dx-input-editor-border-radius-lg);

        border-radius: $dx-input-editor-border-radius-lg;

        .dxbl-spininput-input {
            @include dx-line-box-size-lg();

            border-radius: $dx-input-editor-border-radius-lg;
        }

        .dxbl-button-root-increment,
        .dxbl-button-root-decrement {
            .dxbl-input-button {
                @include dx-button-icon-size(
                    math.div(dx-get-input-editor-height-lg(), 2),
                    0
                );
            }
        }
    }

    &[has-clear-button] {
        @include dx-input-editor-clear-button-remove-extra-paddings();

        .dxbl-spininput-input {
            padding-right: 0;
        }
    }

    .dxbl-button-root-increment,
    .dxbl-button-root-decrement {
        .dxbl-input-button {
            @include dx-button-icon-size(
                math.div(dx-get-input-editor-height(), 2),
                0
            );

            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .dxbl-button-root-increment {
        .dx-image {
            transform: rotate(180deg);
        }
    }
}
