@import "../dx-blazor.params";

$dx-grid-border-width: var(--dx-grid-table-border-width, 1px);
$dx-grid-indent-cell-width: 1.5rem;
$dx-grid-touch-color: var(--dx-grid-touch-color, rgb(0 0 0 / 20%));
$dx-grid-selection-color: var(--dx-grid-selection-color, rgb(0 0 0 / 10%));
$dx-grid-header-color: var(--dx-grid-header-color, rgb(0 0 0 / 5%));
$dx-grid-footer-color: var(--dx-grid-footer-color, rgb(0 0 0 / 3%));
$dx-grid-group-footer-color: var(--dx-grid-group-footer-color, rgb(0 0 0 / 2%));

$dx-grid-empty-header-panel-padding: 0.75rem 1.25rem;
$dx-grid-group-panel-padding: 0.75rem 1.25rem;
$dx-grid-table-cell-h-padding: 0.4rem;
$dx-grid-table-cell-v-padding: 0.25rem;
$dx-grid-table-cmd-cell-v-padding: 0.15rem;
$dx-grid-pager-h-padding: $dx-grid-table-cell-h-padding;
$dx-grid-pager-v-padding: 0.4rem;
$dx-grid-detail-cell-h-padding: $dx-grid-indent-cell-width + $dx-grid-table-cell-h-padding;
$dx-grid-detail-cell-v-padding: $dx-grid-indent-cell-width + $dx-grid-table-cell-h-padding;
$dx-grid-edit-form-h-padding: 0.5rem;
$dx-grid-edit-form-v-padding: 1rem;
$dx-grid-edit-form-buttons-spacing: 0.25rem;
$dx-grid-pager-element-spacing: 0.4rem;
$dx-grid-empty-data-padding: 3rem 1.5rem;
$dx-grid-header-shadow: 0 2px 6px 0 rgb(0 0 0 / 12%);

@keyframes dxbs-skeleton-opacity-animation {
    0% {
        opacity: 0.05;
    }

    50% {
        opacity: 0.15;
    }

    100% {
        opacity: 0.05;
    }
}

.dxbs-grid {
    display: flex;
    flex-flow: column;
    width: 100%;

    .dxbs-grid-empty-data {
        text-align: center;
        font-weight: 600;
        font-size: 1rem;
        padding: $dx-grid-empty-data-padding;
        border-top: 0;
        border-bottom: 0;
    }

    .dxbs-grid-action {
        cursor: pointer;
    }

    & > .card {
        position: static;
        width: 100%;
        min-height: 0;

        .dxbs-scroll-viewer {
            background-color: inherit;
            flex-grow: 1;

            & > .dxbs-scroll-viewer-content {
                background-color: inherit;

                & > .dxbs-grid-table {
                    background-color: inherit;

                    &.table > :not(:first-child) {
                        border-top: inherit;
                    }

                    & > tfoot {
                        background-color: inherit;
                        position: sticky;
                        bottom: 0;
                        z-index: 1;
                    }

                    & > thead {
                        background-color: inherit;
                        top: 0;
                        position: sticky;
                        z-index: 2;
                    }
                }
            }

            &:last-child > .dxbs-scroll-viewer-content > .dxbs-grid-table {
                & > tfoot > tr:last-child > td {
                    border-bottom: 0;
                }

                & > tbody > tr:last-child > td {
                    border-bottom: 0;
                }

                & > tfoot > tr.dxbs-grid-empty-footer-row {
                    display: none;
                }
            }

            &:first-child > .dxbs-scroll-viewer-content > .dxbs-grid-table {
                & > thead,
                & > tbody {
                    & > tr:first-child > th {
                        border-top-width: 0;
                    }
                }
            }

            &:not(:last-child) > .dxbs-scroll-viewer-content > .dxbs-grid-table {
                & > tbody:not(:last-child) > tr:last-child > td {
                    border-bottom: 0;
                }
            }

            &:first-child,
            &:first-child > .dxbs-scroll-viewer-content,
            &:first-child > .dxbs-scroll-viewer-content > .dxbs-grid-table,
            &:first-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > colgroup + thead,
            &:first-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > colgroup + thead > tr:first-child {
                border-top-left-radius: inherit;
                border-top-right-radius: inherit;
            }

            &:first-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > colgroup + thead > tr:first-child {
                & > th:first-child {
                    border-top-left-radius: inherit;
                }

                & > th:last-child {
                    border-top-right-radius: inherit;
                }
            }

            &:last-child,
            &:last-child > .dxbs-scroll-viewer-content,
            &:last-child > .dxbs-scroll-viewer-content > .dxbs-grid-table,
            &:last-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > tfoot,
            &:last-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > tfoot > tr:last-child {
                border-bottom-left-radius: inherit;
                border-bottom-right-radius: inherit;
            }

            &:last-child > .dxbs-scroll-viewer-content > .dxbs-grid-table > tfoot > tr:last-child {
                & > td:first-child {
                    border-bottom-left-radius: inherit;
                }

                & > td:last-child {
                    border-bottom-right-radius: inherit;
                }
            }
        }

        & > .card-body,
        & > .card-header,
        & > .card-footer {
            flex-shrink: 0;
            flex-grow: 0;
        }
    }

    .dxbs-grid-group-panel-content-container {
        padding: $dx-grid-group-panel-padding;
        display: flex;
        flex-flow: row wrap;

        .dxbs-grid-header {
            padding: 0.3rem 0.6rem;
            margin-right: 0.6rem;
            box-shadow: $dx-grid-header-shadow;
        }

        .dxbs-grid-header,
        .dxbs-grid-group-content-free-space {
            margin-top: 0.25rem;
            margin-bottom: 0.25rem;
        }

        .dxbs-grid-header:nth-last-child(-n+2) {
            margin-right: 0;
        }

        .dxbs-grid-group-content-free-space {
            flex: 1 1;
        }
    }

    .dxbs-grid-table-border-bottom {
        border-bottom-width: $dx-grid-border-width;
    }

    .dxbs-grid-table {
        table-layout: fixed;
        border-collapse: separate;
        border-width: 0;
        border-spacing: 0;
        height: 100%;
        margin: 0;

        & > * > tr > th:not(.dxbs-grid-header-indent-cell):not(.dxbs-grid-empty-header),
        & > * > tr > td:not(.dxbs-grid-indent-cell):not(.dxbs-grid-expand-button-cell):not(.dxbs-grid-empty-cell):not(.dxbs-grid-empty-data):not(.dxbs-grid-edit-form):not(.dxbs-grid-detail-cell) { // stylelint-disable-line  max-line-length
            padding: $dx-grid-table-cell-v-padding $dx-grid-table-cell-h-padding;
        }

        & > thead > tr > th span,
        & > tbody > tr > td,
        & > tbody > tr.dxbs-grid-group-footer-row > td > div,
        & > tfoot > tr > td > div {
            overflow: hidden;
            text-overflow: ellipsis;
            overflow-wrap: normal;
        }

        & > thead > tr > th {
            position: relative;

            & > .dxbs-grid-column-resize-anchor {
                top: 0;
                right: calc(($dx-grid-border-width - 7px) / 2 - $dx-grid-border-width);
                width: 7px;
                height: 100%;
                position: absolute;
                cursor: col-resize;
                z-index: 1;
                opacity: 0;

                & > div {
                    pointer-events: none;
                    width: 100%;

                    &:focus {
                        outline: 0;
                    }
                }

                &.touch {
                    width: calc(2 * $dx-grid-table-cell-h-padding + $dx-grid-border-width);
                    right: calc(-1 * $dx-grid-table-cell-h-padding - $dx-grid-border-width);
                }

                &.last {
                    width: 3px;
                    right: 0;
                }
            }
        }

        & > tbody {
            & > tr:first-child > td {
                border-top: 0;
            }

            & > tr:not(:last-child) > td {
                border-bottom: 0;
            }
        }

        & > * > tr > :first-child {
            border-left: 0;
        }

        & > * > tr > td,
        & > * > tr > th {
            border-right: 0;
            vertical-align: middle;
        }

        & > * > tr > td.dxbs-grid-empty-cell {
            border-top-width: 0;
            border-right-width: 0;
            padding-left: 0;
            padding-right: 0;
        }

        & > thead > tr > th.dxbs-grid-empty-cell {
            border-bottom-width: 0;
        }

        & > tfoot {
            & > .dxbs-grid-empty-footer-row {
                & > td {
                    border-top: 0 !important; // stylelint-disable-line declaration-no-important
                    height: 0;
                    padding: 0 !important; // stylelint-disable-line declaration-no-important
                }
            }

            & > tr:not(:last-child) > td {
                border-bottom-width: 0;
            }

            & > tr:last-child > td {
                border-right-width: 0;
            }
        }

        & > thead {
            & > .dxbs-grid-arm-row {
                visibility: collapse;

                & > th {
                    border: 0;
                }
            }

            .dxbs-grid-header-row {
                & > th:not(.dxbs-grid-empty-cell) {
                    background-color: $dx-grid-header-color;
                }
            }

            .dxbs-grid-filter-row {
                background-color: inherit;

                & > td {
                    background-color: inherit;
                    border-top: 0;
                }

                & > td.dxbs-grid-empty-cell {
                    border-bottom: 0;
                }

                & > td.dxbs-grid-indent-cell {
                    border-left: 0;
                }
            }
        }

        .dxbs-grid-empty-header {
            padding: $dx-grid-empty-header-panel-padding;
        }

        .dxbs-grid-indent-cell-size {
            width: $dx-grid-indent-cell-width;
        }

        .dxbs-grid-indent-cell-size:not(:first-child) {
            width: calc($dx-grid-indent-cell-width + $dx-grid-border-width);
        }

        .dxbs-grid-indent-cell,
        .dxbs-grid-empty-cell {
            background-color: transparent;
        }

        .dxbs-grid-header-indent-cell {
            border-left: 0;
            border-right: 0;
        }

        tr:not(.dxbs-grid-footer-row):not(.dxbs-grid-filter-row) {
            .dxbs-grid-indent-cell {
                border-top-width: 0;
                border-right-width: 0;
            }
        }

        .dxbs-grid-expand-button-cell {
            border-right: 0;
            padding: 0 0 0 1px;
            text-align: center;

            + td {
                border-left: 0;
            }

            .dxbs-grid-expand-button {
                height: 0.6rem;
                width: 0.6rem;
                padding: 0.15rem;
                box-sizing: content-box;
                line-height: 1.5rem;
                border-radius: 0.75rem;
                color: currentcolor;
                vertical-align: baseline;
            }

            .dxbs-grid-expand-button:hover {
                background-color: rgb(0 0 0 / 10%);
            }

            .dxbs-grid-expand-button:focus {
                box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 20%);
            }
        }

        .dxbs-grid-command-cell,
        .dxbs-grid-selection-cell {
            padding-top: $dx-grid-table-cmd-cell-v-padding !important; // stylelint-disable-line declaration-no-important, max-line-length
            padding-bottom: $dx-grid-table-cmd-cell-v-padding !important; // stylelint-disable-line declaration-no-important, max-line-length
        }

        .dxbs-grid-command-cell .btn {
            height: 100%;
            line-height: 100%;
            max-height: 1.4rem;
            padding-top: 0;
            padding-bottom: 0;
        }

        .dxbs-grid-edit-form {
            overflow: visible;
            padding: $dx-grid-edit-form-v-padding $dx-grid-edit-form-h-padding;
        }

        .dxbs-grid-skeleton-content {
            display: inline-flex;
            max-width: 100%;
            border-radius: 10rem;
            background-color: currentcolor;
            animation: 2s linear infinite forwards dxbs-skeleton-opacity-animation;

            &.dxbs-grid-skeleton-content-date {
                width: 8rem;
            }

            &.dxbs-grid-skeleton-content-bool {
                width: 4rem;
            }

            @for $i from 1 through 5 {
                &.dxbs-grid-skeleton-content-string-#{$i} {
                    width: (4 + $i) * 10%;
                }

                &.dxbs-grid-skeleton-content-number-#{$i} {
                    width: #{(2 + $i)}em;
                }
            }
        }

        .dxbs-grid-empty-cell {
            overflow: hidden;

            &.dxbs-grid-hidden-empty-cell {
                width: 0;
            }
        }
    }

    .dxbs-grid-selected-row > td:not(.dxbs-grid-empty-cell):not(.dxbs-grid-indent-cell) {
        background-color: $dx-grid-selection-color;
    }

    .dxbs-grid-touch-selection:not(.dxbs-grid-group-row) > td:not(.dxbs-grid-empty-cell):not(.dxbs-grid-indent-cell) {
        background-color: $dx-grid-selection-color;
    }

    .dxbs-grid-touch-selection-edge > td:not(.dxbs-grid-empty-cell):not(.dxbs-grid-indent-cell) {
        background-color: $dx-grid-touch-color;
    }

    .dxbs-grid-footer-row {
        font-weight: 700;

        & > td:not(.dxbs-grid-empty-cell) {
            background-color: $dx-grid-footer-color;
            border-right: 0;
            border-left: 0;
        }
    }

    .dxbs-grid-group-footer-row {
        font-weight: 600;

        & > td:not(.dxbs-grid-empty-cell) {
            background-color: $dx-grid-group-footer-color;
            border-right: 0;
        }

        & > td.dxbs-grid-indent-cell {
            background-color: transparent;
        }

        & > td:not(.dxbs-grid-indent-cell) + td:not(.dxbs-grid-indent-cell):not(.dxbs-grid-empty-cell) {
            border-left: 0;
        }
    }

    .dxbs-grid-detail-cell {
        padding: $dx-grid-detail-cell-v-padding $dx-grid-detail-cell-h-padding;
    }

    .dxbs-grid-pager-container {
        background-color: transparent;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: $dx-grid-pager-v-padding $dx-grid-pager-h-padding 0;

        & > * {
            margin-bottom: $dx-grid-pager-v-padding;
        }

        .dxbs-pager {
            margin-right: $dx-grid-pager-element-spacing;
        }

        .dxbs-grid-page-size-selector {
            display: flex;
            flex-wrap: nowrap;

            & > label {
                margin-right: $dx-grid-pager-element-spacing;
                white-space: nowrap;
            }
        }

        .dxbs-grid-page-size-selector-edit {
            display: block;
            width: 80px;
        }
    }

    .dxbs-grid-columns-separator {
        position: absolute;
        z-index: 3;
        width: 3px;
        height: 100%;

        &.active {
            background-color: $ColorPrimary;
            transition: background-color 0.25s;
            pointer-events: none;
            box-shadow: inset 0 0 0 9999px rgb(255 255 255 / 20%);
        }
    }
}

.dxbs-grid-edit-form-buttons {
    padding: $dx-grid-edit-form-buttons-spacing $dx-grid-edit-form-h-padding 0;
    display: flex;
    justify-content: flex-end;

    & > .btn:not(:last-child) {
        margin-right: 0.5rem;
    }
}

.dxbs-grid-confirm-dialog-buttons {
    padding: 1rem 0 0;
    display: flex;
    justify-content: center;

    & > .btn {
        min-width: 3.25rem;
    }

    & > .btn:not(:last-child) {
        margin-right: 0.5rem;
    }
}

.dxbs-grid-command-cell,
.dxbs-grid-selection-cell {
    .dxbs-grid-header-content {
        height: 100%;
        justify-content: flex-start;
    }

    .dxbs-grid-header-content.text-center {
        justify-content: center;
    }

    .dxbs-grid-header-content.text-right {
        justify-content: flex-end;
    }

    .dxbs-checkbox {
        display: inline-block;
    }
}

.dxbs-grid-header-content {
    display: flex;
    align-items: center;
    font-weight: 600;

    > :first-child:not(:only-child):not(.dxbs-checkbox) {
        margin-right: 0.6rem;
    }
}

.dxbs-grid-draggable-header {
    visibility: hidden;
    position: absolute;
    justify-content: center;
    flex-direction: row;
    z-index: 20000;
    cursor: move;
    box-shadow: $dx-grid-header-shadow;
    top: 0;
    left: 0;
    pointer-events: none;
    vertical-align: middle;

    .dxbs-grid-header-content {
        width: 100%;

        > :first-child {
            margin-right: 0.4rem;
        }
    }
}

.dxbs-grid-draggable-header::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: move;
}

.dxbs-resize-cursor,
.dxbs-resize-cursor .dxbs-grid th.dxbs-grid-action {
    cursor: col-resize;
}

.dxOpera { // stylelint-disable-line selector-class-pattern
    .dxbs-grid {
        .dxbs-scroll-viewer {
            .dxbs-grid-table {
                & > tfoot {
                    & > tr > td {
                        position: sticky;
                        bottom: 0;
                        z-index: 1;
                    }
                }

                & > thead {
                    & > .dxbs-grid-header-row > th {
                        top: 0;
                        position: sticky;
                        z-index: 2;
                    }

                    & > .dxbs-grid-filter-row > td {
                        top: 0;
                        position: sticky;
                        z-index: 2;
                    }
                }
            }
        }
    }
}
