.dxbl-maskedinput {
    @include dx-font-size();
    @include dx-input-editor-root("dxbl-maskedinput");
    @include dx-input-editor-utmost-buttons-radius($dx-input-editor-border-radius);

    border-radius: $dx-input-editor-border-radius;

    .dxbl-maskedinput-input {
        @include dx-line-box-size();

        border-radius: $dx-input-editor-border-radius;
    }

    &.dxbl-maskedinput-sm {
        @include dx-font-size-sm();
        @include dx-input-editor-utmost-buttons-radius($dx-input-editor-border-radius-sm);

        border-radius: $dx-input-editor-border-radius-sm;

        .dxbl-maskedinput-input {
            @include dx-line-box-size-sm();

            border-radius: $dx-input-editor-border-radius-sm;
        }
    }

    &.dxbl-maskedinput-lg {
        @include dx-font-size-lg();
        @include dx-input-editor-utmost-buttons-radius($dx-input-editor-border-radius-lg);

        border-radius: $dx-input-editor-border-radius-lg;

        .dxbl-maskedinput-input {
            @include dx-line-box-size-lg();

            border-radius: $dx-input-editor-border-radius-lg;
        }
    }

    &[has-clear-button] {
        @include dx-input-editor-clear-button-remove-extra-paddings();

        .dxbl-maskedinput-input {
            padding-right: 0;
        }
    }
}
