.dxbs-filemanager {
    width: 100%;
    font-family: "Segoe UI", sans-serif;
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(34 34 34 / 12.5%);

    &:focus {
        outline: none;
    }

    /* toolbar */
    .dxbs-filemanager-toolbar {
        flex: 1 0 auto;
        border-bottom: 1px solid rgb(34 34 34 / 12.5%); /* TODO: change color to some constant like #dee2e6 */

        .dxbs-toolbar-group {
            box-shadow: none;

            .dxbs-icon-toolbar {
                display: inline-block;
                text-align: center;
                width: 1rem;
                position: relative;
            }

            .dxbs-toolbar-btn > .dx-image,
            .dxbs-dropdown-toggle > .dx-image,
            .dropdown-item > .dx-image {
                width: 18px;
                height: 18px;
            }

            .btn {
                background-color: transparent;
                border: none;
            }

            .dxbs-toolbar-btn {
                margin-top: 0.7rem;
                margin-bottom: 0.7rem;
                padding-top: 0.1rem;
                padding-bottom: 0.1rem;

                &:first-child {
                    margin-left: 0.45rem;
                    margin-right: 0;
                }

                &:last-child {
                    margin-left: 0.85rem;
                    margin-right: 0.65rem;
                }
            }
        }
    }

    .dxbs-filemanager-container {
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;
        overflow: hidden;
        height: 100%;

        /* treeView (part 1/2) is out of main hierarchy because it appeasr in both file manager and popups */
        .dxbs-filemanager-dirs-wrapper {
            position: relative;
            flex: 0 0 auto;
            padding-top: 10px;
            max-width: 100%;
            height: 100%;
            box-shadow: 0 0 0.5rem rgb(0 0 0 / 15%);

            /* splitter */
            & > .dxbs-splitter-wrapper {
                height: 100%;
                position: absolute;
                z-index: 998;
                top: 0;
                right: 0;

                .dxbs-splitter {
                    height: 100%;
                    display: block;
                    cursor: col-resize;
                    z-index: 999;
                    border-left: 2px solid transparent;
                    border-right: 2px solid transparent;
                    transform: translateX(3px);
                    box-sizing: content-box;
                    background-clip: content-box;

                    &.dxbs-splitter-transparent {
                        z-index: 998;
                        background-color: transparent;
                    }
                }
            }
        }

        /* itemsView */
        .dxbs-filemanager-items-panel {
            height: 100%;
            flex: 1 1 auto;
            overflow: hidden;
            position: relative;

            /* thumbnailsView */
            .dxbl-filemanager-thumbnails-scroll {
                height: 100%;

                .dxbl-filemanager-thumbnails-container {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: flex-start;
                    align-content: flex-start;
                    padding: 0.5rem 1rem;

                    div.dxbs-filemanager-thumbnail-item {
                        width: 96px;
                        padding: 0.7rem 1rem 1rem;
                        margin: 8px 7px 16px;
                        border: none;
                        border-radius: 0.2rem;
                        z-index: 0;
                        font-family: inherit;
                        font-weight: inherit;
                        flex: 0 0 auto;
                        position: relative;

                        &:hover::before {
                            content: " ";
                            position: absolute;
                            z-index: -1;
                            width: 100%;
                            height: 100%;
                            background-color: currentcolor;
                            left: 0;
                            top: 0;
                            opacity: 0.1;
                        }

                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        cursor: pointer;

                        & > span {
                            white-space: nowrap;
                            -webkit-touch-callout: none; /* iOS Safari */
                            user-select: none; /* Safari, Old versions of Firefox, Internet Explorer/Edge */
                        }

                        & > .dxbl-icon-thumbnail.dx-image {
                            display: inline-block;
                            width: 48px;
                            height: 48px;
                        }
                    }
                }
            }
        }
    }
}

/* treeView (part 2/2) is out of main hierarchy because it renders in both file manager and popups */
.dxbs-filemanager .dxbs-filemanager-container .dxbs-filemanager-dirs-wrapper,
.dxbs-popup .dxbl-filemanager-popup-content {
    & > .dxbs-filemanager-dirs-panel {
        height: 100%;

        > nav > .dxbs-tree-wraper {
            padding-right: 1rem;
        }

        .dxbs-filemanager-treeview-container {
            height: 100%;

            .dxbs-filemanager-treeview {
                height: 100%;
                width: 100%;

                .nav-link {
                    border-radius: 0;

                    .dxbs-filemanager-navpane-item {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        justify-content: flex-start;

                        & > .dx-image {
                            flex: 0 auto;
                            width: 24px;
                            height: 24px;
                        }

                        & > span {
                            padding-left: 1rem;
                            flex: 0 auto;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
}

/* TODO: wish it was out-of-the-box feature of the dropdown */
.dxbs-toolbar-dropdown.dropdown-menu.dxbs-filemanager-toolbar-dropdown {
    min-width: unset;

    & > .dxbs-dropdown-body > .dxbs-tb-dropdown-item-list {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;

        /* TODO: .dxbs-toolbar-dropdown-item:not(.dx-toolbar-split-btn) > .dxbl-toolbar-menu-item > .dropdown-item */
        .dxbs-toolbar-dropdown-item > .dxbl-toolbar-menu-item > .dropdown-item {
            padding-right: 1rem;
        }
    }

    .dxbs-toolbar-btn > .dx-image,
    .dxbs-dropdown-toggle > .dx-image,
    .dropdown-item > .dx-image {
        width: 18px;
        height: 18px;
    }
}

/* dialogs */
.dxbs-popup > .dxbs-modal-content {
    & > .dxbs-modal-body.dxbl-filemanager-popup-content {
        min-height: 300px;
        max-height: 350px;
        overflow-y: auto;

        &.dxbl-filemanager-delete-popup-content {
            min-height: unset;
        }

        .dxbs-grid {
            .dxbs-grid-header-row {
                display: none;
            }

            td,
            > .card {
                border: none;
            }

            .dxbs-filemanager-file-item {
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: flex-start;

                & > .dx-image {
                    flex: 0 auto;
                    width: 24px;
                    height: 24px;
                }

                & > span {
                    padding-left: 1rem;
                    flex: 0 auto;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            td {
                position: relative;
                z-index: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;

                &:hover::before {
                    content: " ";
                    position: absolute;
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                    background-color: currentcolor;
                    left: 0;
                    top: 0;
                    opacity: 0.1;
                }
            }
        }
    }

    & > .dxbl-filemanager-popup-footer {
        padding: 0.25rem 0.75rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        border-top: 1px solid #dee2e6;
        border-bottom-right-radius: calc(0.3rem - 1px);
        border-bottom-left-radius: calc(0.3rem - 1px);

        & > * {
            margin: 0.25rem;
        }
    }
}
