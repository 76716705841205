@use "mixins" as editor-mixins;

.dxbs-checkbox {
    display: flex;
    align-items: center;

    & .custom-control {
        z-index: auto;
        height: 1.28125rem;
        min-height: 1.28125rem;

        &.custom-radio,
        &.custom-checkbox {
            padding-left: 0;
            width: 1.25rem;
            min-width: 1.25rem;

            & .custom-control-label {
                &::before {
                    position: relative;
                    left: 0;
                }

                &::after {
                    left: 0;
                }
            }
        }

        &.custom-switch {
            width: 2.375rem;
            min-width: 2.375rem;
        }

        > .custom-control-input {
            width: 100%;
            height: 100%;
        }

        &:not(.custom-switch) > .custom-control-label {
            &::before {
                top: 0;
                width: 1.25rem;
                min-width: 1.25rem;
                height: 1.25rem;
                min-height: 1.25rem;
            }

            &::after {
                top: 0;
                width: 1.25rem;
                min-width: 1.25rem;
                height: 1.25rem;
                min-height: 1.25rem;
            }
        }

        > .form-check-input {
            margin-top: unset;
        }
    }

    & .dxbs-checkbox-label {
        &.dxbs-checkbox-label-wrap {
            white-space: normal;
        }

        &.dxbs-checkbox-label-nowrap {
            white-space: nowrap;
        }

        &.dxbs-checkbox-label-ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &.dxbs-checkbox-label-left {
            text-align: right;
            padding-right: 0.5rem;
        }

        &.dxbs-checkbox-label-right {
            text-align: left;
            padding-left: 0.5rem;
        }
    }

    &.dxbs-checkbox-align-left {
        justify-content: flex-start;
    }

    &.dxbs-checkbox-align-right {
        justify-content: flex-end;
    }

    &.dxbs-checkbox-align-center {
        justify-content: center;
    }

    &.dxbs-checkbox-space-between {
        justify-content: space-between;
    }

    &.dxbs-checkbox-space-around {
        justify-content: space-around;
    }

    &.invalid {
        .custom-control-input ~ .custom-control-label::before {
            @include editor-mixins.editor-validation-error-border-color();
        }
    }

    &.valid.modified {
        .custom-control-input ~ .custom-control-label::before {
            @include editor-mixins.editor-validation-valid-border-color();
        }

        .custom-control-input:checked ~ .custom-control-label::before {
            background-color: editor-mixins.$editor-valid-color;
        }
    }
}
