@import "variables";
@import "shared";

dxbl-flyout {
    position: absolute;
    top: 0;
    left: 0;

    &:not(:defined) {
        display: none;
    }

    > .dxbs-flyout-dialog {
        @include drop-shadow();

        flex: 1 1 auto;
        display: flex;
        position: relative;
        min-width: 0;
        min-height: 0;
        max-width: unset;
        max-height: unset;
        top: 0;
        left: 0;
        flex-direction: column;
        justify-content: flex-start;
        outline: 0;
        padding: unset;
        margin: unset;

        &.dxbs-flyout-scrollable {
            > .dxbs-flyout-body {
                overflow-y: auto;
                min-height: 0;
            }
        }

        > .dxbs-flyout-header {
            padding: 0.5rem 1rem;

            &.drag {
                cursor: default;
            }
        }

        > .dxbs-flyout-body {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 1 auto;
            min-height: 0;

            > * {
                min-height: 0;
            }
        }

        > .dxbs-flyout-footer {
            margin: unset;
            padding: 0.5rem 0.75rem;
            gap: 0.5rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;

            > * {
                margin: unset;
            }
        }

        > .dxbs-flyout-arrow {
            margin: unset;
        }
    }

    > .dxbs-flyout-dialog-fade {
        opacity: 0;
    }
}
