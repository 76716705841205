@import 'state-decorations';

.dxbs-gridview .dxbs-loading.dxbs-toolbar,
.dxbs-toolbar {
    display: flex;

    .dxbs-tb-flex1 {
        flex: 1;
    }
}

.dxbs-toolbar > .btn-toolbar > .btn-group > .btn-group[data-toggle='toolbar-dropdownmode'] > .dropdown-toggle::after {
    margin-left: 0;
}

.dxbs-toolbar:not(.dxbs-ta-nib) > .btn-toolbar > .btn-group:not(:first-child):not(.dxbs-tb-begin-group) {
    margin-left: 0.5rem;
}

.dxbs-toolbar .dxbs-toolbar-btn.btn.dxbs-ta-nib:not(:focus),
.dxbs-toolbar .dxbs-toolbar-btn > .btn.dxbs-ta-nib:not(:focus),
.dxbs-toolbar-dropdown .dropdown-item.btn:not(:focus),
.dxbs-toolbar.dxbs-ta-nib .dxbs-ta-ag > .btn:not(:focus) {
    box-shadow: none;
    border-color: transparent;
}

/*Layout*/
.dxbs-toolbar,
.dxbs-toolbar > .btn-toolbar {
    user-select: none;
    display: flex;
    flex-wrap: nowrap;
}

.dxbs-toolbar-adaptive {
    position: relative;
    flex: 1 1 auto;
    justify-content: flex-start;
    transition: height ease 0.3s;
    will-change: height;
    white-space: nowrap;
}

.dxbs-toolbar-adaptive > .btn-toolbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    padding: inherit;
    justify-content: inherit;
    align-items: flex-start;
}

.dxbs-toolbar-adaptive > .btn-toolbar > div,
.dxbs-toolbar-adaptive > .btn-toolbar > div > .btn,
.dxbs-toolbar-adaptive > .btn-toolbar > div > .btn-group {
    display: inline-flex;
    align-self: stretch;
}

.dxbs-ta-title {
    display: inline-flex;
    align-items: center;
    padding: 0 1rem;
}

.dxbs-toolbar .dxbs-toolbar-btn .image + span,
.dxbs-toolbar .dxbs-ta-ag .image + span,
.dxbs-toolbar .dxbs-toolbar-btn .dxbs-check.image,
.dxbs-toolbar-dropdown-item .image + span {
    margin-left: 0.4em;
}

.dxbs-toolbar-adaptive .btn.dropdown-toggle > .image:only-child {
    margin-right: 0.4em;
}

.dxbs-toolbar-btn > .dropdown-toggle:not(.dropdown-toggle-split)::after,
.dxbs-toolbar .dxbs-ta-ag > .dropdown-toggle:after {
    margin-left: .4em;
}

.dxbs-toolbar-adaptive > .no-item-text.btn-toolbar > .btn-group > .btn > .image:not(:only-child),
.dxbs-toolbar-adaptive > .no-item-text.btn-toolbar > .btn-group > .btn-group > .btn > .image:not(:only-child) {
    margin: 0;
}

.dxbs-toolbar-adaptive > .no-item-text.btn-toolbar > .btn-group > .btn > .image + span:not(.popout),
.dxbs-toolbar-adaptive > .no-item-text.btn-toolbar > .btn-group > .btn-group > .btn > .image + span:not(.popout) {
    display: none;
}

.dxbs-toolbar-dropdown .dropdown-item.dropdown-toggle:after {
    margin-left: auto;
}

.dxbs-toolbar-dropdown .dropdown-item.dropdown-toggle,
.dxbs-toolbar-dropdown .dropdown-item + .dropdown-toggle {
    padding-right: .75rem;
}

.dxbs-toolbar-dropdown .dropdown-item + .dropdown-toggle {
    padding-left: .75rem;
}

.dxbs-toolbar-btn > .btn.dropdown-toggle::before {
    //bootswatch materia
    border: none;
}

.dxbs-toolbar-btn > .btn.dropdown-toggle::after,
.dxbs-toolbar.dxbs-ta-nib .dxbs-ta-ag > .btn:after {
    //bootswatch materia
    opacity: 1;
    width: auto;
    height: auto;
    left: auto;
    top: auto;
    position: static;
}

/*states*/
.dxbs-toolbar-adaptive .dxbs-check.image {
    visibility: hidden;
}

.dxbs-toolbar-adaptive .checked > span:not(.image) {
    font-weight: 600;
}

/*no background mode*/
.dxbs-toolbar .dxbs-toolbar-btn.btn.dxbs-ta-nib,
.dxbs-toolbar .dxbs-toolbar-btn > .btn.dxbs-ta-nib,
.dxbs-toolbar.dxbs-ta-nib .dxbs-ta-ag > .btn {
    border-color: transparent;
    border-radius: 3px;
    margin-left: 0px;
}

.dx-toolbar-split-btn {
    & > .btn.dxbs-ta-nib.active {
        &.dx-toolbar-split-dropdown-btn {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
    }

    & > .btn.dxbs-ta-nib.active {
        &.dx-toolbar-split-main-btn {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
    }
}

.dxbs-toolbar-adaptive.dxbs-ta-nib > .btn-toolbar > .btn-group:not(:first-child):not(.dxbs-tb-begin-group):before,
.dxbs-toolbar-adaptive.dxbs-ta-nib > .btn-toolbar > .dxbs-toolbar-group:not(:first-child):not(.dxbs-tb-begin-group):before {
    content: " ";
    display: inline-flex;
    border-left: 1px solid currentColor;
    height: calc(100% - 0.25rem);
    opacity: 0.2;
    margin-right: -1px;
    transform: translateX(0.5rem);
    align-self: center;
}

.dxbs-toolbar-adaptive.dxbs-ta-nib > .btn-toolbar > .btn-group:not(:first-child):not(.dxbs-tb-begin-group) > :first-child,
.dxbs-toolbar-adaptive.dxbs-ta-nib > .btn-toolbar > .btn-group:not(:first-child):not(.dxbs-tb-begin-group) > .dx-toolbar-first-visible-child,
.dxbs-toolbar-adaptive.dxbs-ta-nib > .btn-toolbar > .dxbs-toolbar-group:not(:first-child):not(.dxbs-tb-begin-group) > :first-child,
.dxbs-toolbar-adaptive.dxbs-ta-nib > .btn-toolbar > .dxbs-toolbar-group:not(:first-child):not(.dxbs-tb-begin-group) > .dx-toolbar-first-visible-child {
    margin-left: 1rem;
}

.dxbs-toolbar-adaptive.dxbs-ta-nib > .btn-toolbar > .dxbs-ta-title + .btn-group > :first-child {
    margin-left: 0;
}

.dxbs-toolbar-adaptive.dxbs-ta-nib > .btn-toolbar > .dxbs-ta-title + .btn-group:before {
    display: none;
}

.dxbs-toolbar .dxbs-toolbar-btn.btn.dxbs-ta-nib:not(.dropdown-toggle)::after,
.dxbs-toolbar .dxbs-toolbar-btn > .btn.dxbs-ta-nib:not(.dropdown-toggle)::after,
.dxbs-toolbar .dxbs-toolbar-btn.btn.dxbs-ta-nib::before,
.dxbs-toolbar .dxbs-toolbar-btn > .btn.dxbs-ta-nib::before,
.dxbs-toolbar.dxbs-ta-nib .dxbs-ta-ag.dx-hide-dropdown-mark > .btn:after {
    opacity: 0;
}

.dxbs-toolbar.dxbs-ta-nib .dxbs-ta-ag > .btn:before {
    display: none;
}

.dxbs-toolbar.dxbs-ta-nib .dxbs-ta-ag > .btn:not(:hover):not(:active):not(.active),
.dxbs-toolbar .dxbs-toolbar-btn.btn.dxbs-ta-nib:not(:hover):not(:active):not(.active),
.dxbs-toolbar .dxbs-toolbar-btn > .btn.dxbs-ta-nib:not(:hover):not(:active):not(.active),
.dxbs-toolbar .dxbs-toolbar-btn.btn.dxbs-ta-nib:not(.active):disabled,
.dxbs-toolbar .dxbs-toolbar-btn > .btn.dxbs-ta-nib:not(.active):disabled {
    background: none;
    color: inherit;
}

/*before initialized*/
.dxbs-toolbar-adaptive.dxbs-loading > .btn-toolbar {
    position: relative;
    overflow: hidden;
    padding: 0;
    width: 100%;
}

.dxbs-toolbar-adaptive.dxbs-loading.dxbs-ta-nib > .btn-toolbar > .btn-group:before {
    display: none;
}

.dxbs-toolbar-adaptive.dxbs-loading > .btn-toolbar > .btn-group:not(.dxbs-ta-ag) {
    visibility: hidden;
    width: 1px;
}

.dxbs-toolbar-adaptive.dxbs-loading:not(.dx-r-toolbar) > .btn-toolbar > .btn-group:last-child > .btn:first-child {
    visibility: visible !important;
    position: static !important;
    opacity: 0.3;
    cursor: default;
}

/*overrides to refactor*/
.dxbs-toolbar-adaptive .dxbs-icon {
    display: inline-flex;
    line-height: unset;
    position: static;
    overflow: hidden;
}

/*toolbar button*/
.dxbs-toolbar-btn.btn,
.dxbs-toolbar-btn > .btn,
.dxbs-toolbar .dxbs-ta-ag > .btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &.disabled {
        pointer-events: auto;
        cursor: default;
    }
    
}

.dxbs-toolbar-dropdown-item > dxbl-toolbar-menu-item > .dropdown-item {
    display: inline-flex;
    justify-content: start;
    align-items: center;
}

.dxbs-toolbar-dropdown-item:not(.dx-toolbar-split-btn) > dxbl-toolbar-menu-item > .dropdown-item {
    padding-right: 2rem;
}

.dxbs-toolbar {
    & > .btn-toolbar.dxbs-focus-hidden .dxbs-toolbar-btn > .btn:focus,
    & > .btn-toolbar.dxbs-focus-hidden .dxbs-toolbar-btn.btn:focus,
    &.dxbs-ta-nib .dxbs-toolbar-group,
    &.dxbs-ta-nib .dxbs-ta-ag,
    & > .btn-toolbar.dxbs-focus-hidden .dxbs-ta-ag > .btn:focus,
    & > .dxbs-loading.dxbs-toolbar .dxbs-ta-ag,
    &.dxbs-ta-nib .dxbs-toolbar-group.dxbs-toolbar-group-lg > .btn-group, {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
}

.dxbs-toolbar-dropdown-item > dxbl-toolbar-menu-item > .dropdown-item:focus {
    outline: none;
}

.dxbs-toolbar-dropdown-item.dropdown-item-text {
    padding: 0;
    display: flex;
}

.dxbs-focus-hidden .dxbs-toolbar-dropdown-item > dxbl-toolbar-menu-item > .dropdown-item:focus:not(:hover):not(:active):not(.active) {
    background: none;
    color: inherit;
}

.tb-back-btn.btn.dxbs-cmd-btn, .tb-cancel-btn.btn.dxbs-cmd-btn {
    padding: calc(.3rem - 1px);
    color: inherit;
    width: auto;
    height: auto;
    flex: 0 0 auto;
}

.tb-hidden-btn {
    visibility: hidden;
}

.dx-hide-dropdown-mark.dropdown-toggle::after {
    content: none;
}

.dxbs-visually-hidden {
    visibility: hidden;
    transform: translatex(-100000px) !important;
}

/* item spacing large */
.dxbs-toolbar-adaptive > .no-item-text.btn-toolbar > .dxbs-toolbar-group.dxbs-toolbar-group-lg {
  & > .btn-group > .btn > .image:not(:only-child),
  & > .btn-group > .btn-group > .btn > .image:not(:only-child){
      margin: 0;
  }
    & > .btn-group > .btn > .image + span:not(.popout),
    & > .btn-group > .btn-group > .btn > .image + span:not(.popout) {
        display: none;
    }
}

.dxbs-toolbar > .btn-toolbar > .dxbs-toolbar-group.dxbs-toolbar-group-lg {
    & > .btn-group + .btn-group {
        margin-left: 0.5rem;
    }

    & + .dxbs-toolbar-group:not(.btn-group),
    & + .dxbs-tb-flex1 + .dxbs-toolbar-group:not(.btn-group),
    & + .dxbs-ta-ag,
    & + .dxbs-tb-flex1 + .dxbs-ta-ag {
        margin-left: 1rem;
    }
}

/* toolbar-edit-item */
.dx-r-toolbar.dxbs-toolbar.dxbs-ta-nib > .btn-toolbar > .btn-group > :not(:first-child) {
    margin-left: 0.5rem;
}

.dx-r-toolbar.dxbs-toolbar:not(.dxbs-ta-nib) > .btn-toolbar > .dx-toolbar-edit:not(:last-child) {
    margin-right: 0.5rem;
}

.dx-r-toolbar.dxbs-toolbar.dxbs-ta-nib > .btn-toolbar > .dx-toolbar-edit:not(:first-child):not(.dxbs-tb-begin-group) {
    margin-left: 1rem;
}

.dx-r-toolbar.dxbs-toolbar.dxbs-ta-nib.dxbs-loading .dx-toolbar-edit .dxbs-checkbox.form-control,
.dxbs-toolbar-dropdown .dxbs-checkbox.form-control {
    width: auto;
}

.dx-r-toolbar.dxbs-toolbar.dxbs-ta-nib .dx-toolbar-edit .dxbs-checkbox.form-control,
.dxbs-toolbar-dropdown .dx-toolbar-edit .dxbs-checkbox.form-control {
    background: none;
    border-color: transparent;
    color: currentColor;
}

@mixin _toolbar_backdrop_highlighted {
    @include _backdrop_base();
    opacity: 0.05;
}

@mixin _toolbar_backdrop_active {
    @include _backdrop_base();
    opacity: 0.2;
}

@mixin _toolbar_backdrop_selected {
    @include _backdrop_base();
    opacity: 0.1;
}

.dxbs-toolbar-dropdown .dx-toolbar-edit .dxbs-combobox {

    & button:not(:hover):not(:active):not(:focus) {
        background: none!important;
        color: inherit!important;
    }

    & .dxbs-input-group-append {
        color: inherit;
    }

    & input {
        background-color: transparent !important;
        color: inherit;
    }
}

.dx-r-toolbar.dxbs-toolbar.dxbs-ta-nib .dx-toolbar-edit .dxbs-combobox,
.dxbs-toolbar-dropdown .dx-toolbar-edit .dxbs-combobox {
    & input {
        border: none;
        box-shadow: none;
    }

    & .dxbs-editor-dropdown-button {
        border-left: none;
    }

    &:focus-within .input-group {
        z-index: 1;
    }
}

.dxbs-toolbar .dx-toolbar-edit,
.dxbs-toolbar-dropdown .dx-toolbar-edit {
    &  .dxbs-combobox {
        & input[readonly] {
            cursor: pointer;
        }
    }
}

.dxbs-toolbar-dropdown-item.dx-toolbar-edit .dxbs-combobox {
    & .btn.dxbs-editor-dropdown-button {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
}

.dx-toolbar-edit > dxbl-toolbar-menu-item > div,
.dx-toolbar-edit > div {
    padding-right: 0.5rem;
    display: inline-flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 400;
    width: 100%;
    height: 100%;
    white-space: nowrap;

    & > .image {
        flex-shrink: 0;
    }

    & > .dxbs-combobox:not(:first-child),
    & > .dxbs-spin-edit:not(:first-child),
    & > .dxbs-checkbox:not(:first-child){
        margin-left: 0.4em;
        &:before {
            content: '';
            margin-left: 0.4em;
        }
    }
}

.dxbs-toolbar-dropdown-item.dx-toolbar-edit + .dxbs-toolbar-dropdown-item:not(.dx-toolbar-edit),
.dxbs-toolbar-dropdown-item:not(.dx-toolbar-edit) + .dxbs-toolbar-dropdown-item.dx-toolbar-edit,
.dxbs-toolbar-dropdown.dxbs-toolbar-modal .dxbs-toolbar-dropdown-item.dx-toolbar-edit:first-child {
    padding-top: 0.25rem;
}

.dxbs-toolbar-dropdown-item.dx-toolbar-edit > dxbl-toolbar-menu-item > div {
    & > .dxbs-combobox,
    & > .dxbs-spin-edit,
    & > .dxbs-checkbox {
        margin-left: auto;
    }
}

.dxbs-focus-hidden .dxbs-toolbar-dropdown-item.dx-toolbar-edit > dxbl-toolbar-menu-item > div > .dxbs-combobox input:focus {
    box-shadow: none;
}

.dx-r-toolbar.dxbs-toolbar.dxbs-ta-nib .dxbs-ta-ag > .btn {
    &:before {
        display: block;
        // bootswatch materia
        border-top: none;
        border-right: none;
        border-left: none;
    }
}

.dxbs-toolbar.dxbs-ta-nib.dx-r-toolbar > .btn-toolbar > .btn-group .dxbs-toolbar-btn.btn,
.dxbs-toolbar.dxbs-ta-nib.dx-r-toolbar > .btn-toolbar > .btn-group .dxbs-toolbar-btn > .btn,
.dxbs-toolbar.dxbs-ta-nib.dx-r-toolbar .dxbs-ta-ag > .btn,
.dxbs-toolbar-dropdown-item > dxbl-toolbar-menu-item > .dropdown-item:not(:disabled) {
    background: none;
    color: currentColor;
    border-left-width: 0;
    border-right-width: 0;

    &:before {
        z-index: 1 !important;
        border-radius: 3px;
        pointer-events: none;
        margin-left: unset; // bootswatch materia
    }

    &:hover:not(:disabled):not(.active):not(:active) {
        &:before {
            @include _toolbar_backdrop_highlighted;
        }
    }

    &:active:not(:disabled) {
        &:before {
            @include _toolbar_backdrop_active;
        }
    }

    &.active {
        &:before {
            @include _toolbar_backdrop_selected;
        }
    }
}
.dxbs-toolbar-dropdown-item > dxbl-toolbar-menu-item > .dropdown-item:not(:disabled):before {
    border-radius: 0;
}

.dx-toolbar-edit.dx-toolbar-coloredit {
    & > .btn:first-child > .image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &:not(.image-h):after {
            content: " ";
            height: 2px;
            background-color: var(--back-color);
            min-width: 16px;
            opacity: 1;
            position: absolute;
            bottom: 0;
        }
    }
}

.dx-toolbar-edit.dx-toolbar-coloredit > .btn > .image-container.image-h,
.dxbs-toolbar-dropdown-item.dx-toolbar-coloredit > dxbl-toolbar-menu-item > .dropdown-item > .image-container {
    position: relative;
    overflow: hidden;
    content: " ";
    background-color: var(--back-color);
    width: 17px;
    height: 17px;
    border: 1px solid currentColor;
}

.dx-toolbar-edit.dx-toolbar-coloredit > .btn > .image-container.image-h.dx-toolbar-coloredit-empty:before,
.dxbs-toolbar-dropdown-item.dx-toolbar-coloredit > dxbl-toolbar-menu-item > .dropdown-item > .image-container.dx-toolbar-coloredit-empty:before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(-50% + 1px);
    right: 0;
    transform: rotate(-45deg) translateY(-1px);
    transform-origin: 100% 0;
    border-top: 2px solid red;
}

.dxbs-toolbar:not(.dx-r-toolbar) > .btn-toolbar > .btn-group > .btn-group.dxbs-ta-ag {
    display: none;
}


.dxbs-loaded.dx-r-toolbar .block-g.item-h ~ .block-g > .block-i.dxbs-ta-ag,
.dxbs-loaded.dx-r-toolbar .block-g.item-h > .block-i.dxbs-ta-ag {
    display: inline-flex;
}

.dxbs-loaded.dx-r-toolbar > .btn-toolbar > .btn-group > .btn.item-text-h > .image:not(:only-child),
.dxbs-loaded.dx-r-toolbar > .btn-toolbar > .btn-group > .btn-group.item-text-h > .btn > .image:not(:only-child) {
    margin-right: 0;
}
.dxbs-loaded.dx-r-toolbar > .btn-toolbar .block-g > .block-i.item-text-h:not(.dxbs-ta-ag):not(:only-child) > .image:not(:only-child),
.dxbs-loaded.dx-r-toolbar > .btn-toolbar .block-g > .block-i.item-text-h:not(.dxbs-ta-ag):not(:only-child) > .btn > .image:not(:only-child),
.dxbs-loaded.dx-r-toolbar > .btn-toolbar.item-text-h .block-g > .block-i:not(.dxbs-ta-ag):not(:only-child) > .image:not(:only-child),
.dxbs-loaded.dx-r-toolbar > .btn-toolbar.item-text-h .block-g > .block-i:not(.dxbs-ta-ag):not(:only-child) > .btn > .image:not(:only-child) {
    margin-right: 0.4em;
}

.dxbs-loaded.dx-r-toolbar .item-h .block-g.item-a + .dxbs-tb-flex1 ~ .block-g > .block-i > .btn > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar .item-h .block-g.item-a ~ .block-g > .block-i > .btn > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar .block-g.item-text-h + .dxbs-tb-flex1 ~ .block-g > .block-i:not(.dxbs-ta-ag) > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar .block-g.item-text-h + .dxbs-tb-flex1 ~ .block-g > .block-i:not(.dxbs-ta-ag) > .btn > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar .block-g.item-text-h ~ .block-g > .block-i:not(.dxbs-ta-ag) > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar .block-g.item-text-h ~ .block-g > .block-i:not(.dxbs-ta-ag) > .btn > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar > .btn-toolbar.item-text-h .block-g > .block-i:not(.dxbs-ta-ag):not(:only-child) > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar > .btn-toolbar.item-text-h .block-g > .block-i:not(.dxbs-ta-ag):not(:only-child) > .btn > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar .block-g > .block-i.item-text-h ~ .block-i:not(.dxbs-ta-ag) > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar .block-g > .block-i.item-text-h ~ .block-i:not(.dxbs-ta-ag) > .btn > .image + span:not(.image),
.dxbs-loaded.dx-r-toolbar > .btn-toolbar > .btn-group > .btn.item-text-h > .image + span,
.dxbs-loaded.dx-r-toolbar > .btn-toolbar > .btn-group > .btn-group.item-text-h > .btn > .image + span {
    display: none;
}

.dx-r-toolbar.dxbs-toolbar {
    position: static;
}

.dx-r-toolbar.dxbs-toolbar > .btn-toolbar {
    position: static;
    padding: unset;
    flex: 1;
}

.dx-r-toolbar.dxbs-toolbar.dxbs-loading > .btn-toolbar {
    position: static;
    overflow: unset;
}

.dx-r-toolbar.dxbs-toolbar.dxbs-loading > .btn-toolbar > .btn-group:not(.dxbs-ta-ag) {
    visibility: unset;
    width: auto;
}

.dxbs-toolbar .ta-hidden-item:not(.preview-image),
.dxbs-loaded.dxbs-toolbar .dxbs-ta-ag .preview-image.ta-hidden-item,
.dxbs-loaded.dxbs-toolbar .dxbs-ta-ag .preview-image.ta-hidden-item + .text {
    visibility: hidden !important;
    position: absolute !important;
    top: -10000px !important;
    left: -10000px !important;
}

.dxbs-loaded.dxbs-toolbar .item-h .block-g.item-a > .block-i > .btn > span:not(.image) {
    display: none;
}

.dx-r-toolbar > .btn-toolbar > .btn-group > .btn-group.dxbs-ta-ag > .btn:not(:active):not(.active):not(:hover):before {
    opacity: 0;
}

.dx-r-toolbar .btn-group {
    position: static;
}

.dxbs-toolbar.dxbs-toolbar.dx-r-toolbar .btn-group.dx-toolbar-split-btn:not(.disabled),
.dxbs-toolbar-dropdown .dxbs-toolbar-dropdown-item.dx-toolbar-split-btn:not(.disabled) > dxbl-toolbar-menu-item {
    &:hover {
        & > button.dx-toolbar-split-subbtn,
        & > a.dx-toolbar-split-subbtn {
            &:not(.active):not(:active):before {
                @include _toolbar_backdrop_highlighted;
                z-index: 1 !important;
            }

            &:hover {
                background-color: unset;
            }

            &.dx-toolbar-split-dropdown-btn:hover:not(:disabled):not(.active):before {
                @include _toolbar_backdrop_active;
            }
        }
    }
    & > .btn.dx-toolbar-split-dropdown-btn:before, {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
    & > .btn.dx-toolbar-split-main-btn:before {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
}

.dxbs-dropdown-toggle.dropdown-toggle::after {
    display: flex;
    border-top: .36em solid;
    border-right: .36em solid transparent;
    border-left: .36em solid transparent;
    margin-left: 0px;
}

.btn-sm > .dxbs-dropdown-toggle.dropdown-toggle::after,
.btn-sm.dxbs-dropdown-toggle.dropdown-toggle::after {
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.btn-lg > .dxbs-dropdown-toggle.dropdown-toggle::after,
.btn-lg.dxbs-dropdown-toggle.dropdown-toggle::after {
    border-top: 6px solid;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
}
