dxbl-modal {
    > .dxbs-sidepanel {
        > .dxbs-popup.dxbs-sidepanel-dialog {
            &.dxbs-sidepanel-position-left {
                margin-left: 0;

                > .dxbs-modal-content {
                    border-bottom-left-radius: unset;
                    border-top-left-radius: unset;
                }
            }

            &.dxbs-sidepanel-position-top {
                margin-top: 0;

                > .dxbs-modal-content {
                    border-top-left-radius: unset;
                    border-top-right-radius: unset;
                }
            }

            &.dxbs-sidepanel-position-right {
                margin-right: 0;

                > .dxbs-modal-content {
                    border-bottom-right-radius: unset;
                    border-top-right-radius: unset;
                }
            }

            &.dxbs-sidepanel-position-bottom {
                margin-bottom: 0;

                > .dxbs-modal-content {
                    border-bottom-left-radius: unset;
                    border-bottom-right-radius: unset;
                }
            }
        }
    }
}
