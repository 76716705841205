$scroll-bar-z-index: 1;
$scroll-bar-size: 0.2rem;
$hovered-scroll-bar-size: 0.5rem;

@mixin active-thumb-x {
    opacity: 0.75;
    width: $hovered-scroll-bar-size;
    border-radius: 0.75rem;
}

@mixin active-thumb-y {
    opacity: 0.75;
    height: $hovered-scroll-bar-size;
    border-radius: 0.75rem;
}

.dxbs-scroll-viewer {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.dxbs-scroll-viewer-content {
    overflow: auto;
    width: 100%;
    scrollbar-width: none; // stylelint-disable-line property-no-unknown
    scrollbar-height: none; // stylelint-disable-line property-no-unknown
}

.dxbs-scroll-viewer-content::-webkit-scrollbar {
    display: none;
}

.dxbs-scroll-viewer-vert-scroll-bar,
.dxbs-scroll-viewer-hor-scroll-bar {
    bottom: 0;
    right: 0;
    position: absolute;
    display: none;
    align-items: flex-end;
    z-index: $scroll-bar-z-index;
    background-color: transparent;

    &.active {
        display: flex;
    }

    > .dxbs-scroll-viewer-scroll-thumb {
        background-color: currentcolor;
        opacity: 0.5;
        border-radius: 0.1rem;
        cursor: default;
    }
}

.dxbs-scroll-viewer-vert-scroll-bar {
    top: 0;
    width: $hovered-scroll-bar-size;
    flex-direction: column;
    transition: width 0.3s;

    > .dxbs-scroll-viewer-scroll-thumb {
        width: $scroll-bar-size;
        transition: width 0.3s;
    }

    > .dxbs-scroll-viewer-scroll-thumb[data-qa-dxbl-dragging] {
        @include active-thumb-x();
    }
}

.dxbs-scroll-viewer-hor-scroll-bar {
    left: 0;
    height: $hovered-scroll-bar-size;
    flex-direction: row;
    transition: height 0.3s;

    > .dxbs-scroll-viewer-scroll-thumb {
        height: $scroll-bar-size;
        transition: height 0.3s;
    }

    > .dxbs-scroll-viewer-scroll-thumb[data-qa-dxbl-dragging] {
        @include active-thumb-y();
    }
}

.dxbs-scroll-viewer-vert-scroll-bar:hover {
    > .dxbs-scroll-viewer-scroll-thumb {
        @include active-thumb-x();
    }
}

.dxbs-scroll-viewer-hor-scroll-bar:hover {
    > .dxbs-scroll-viewer-scroll-thumb {
        @include active-thumb-y();
    }
}
