@use "mixins" as editor-mixins;

.dx-blazor-custom-control-label { // stylelint-disable-line selector-class-pattern
    display: none;
}

.dxbs-checkbox {
    &.form-switch {
        &.form-switch {
            padding-left: 0;
        }

        &.invalid {
            .form-check-input {
                @include editor-mixins.editor-validation-error-border-color();
            }
        }

        &.valid.modified {
            .form-check-input {
                @include editor-mixins.editor-validation-valid-border-color();
            }
        }

        .form-check-input {
            float: none;
            margin-left: 0;
        }
    }

    &.invalid {
        .form-check-input {
            @include editor-mixins.editor-validation-error-border-color();
        }
    }

    &.valid.modified {
        .form-check-input {
            @include editor-mixins.editor-validation-valid-border-color();
        }

        .form-check-input:checked {
            background-color: editor-mixins.$editor-valid-color;
        }
    }
}
