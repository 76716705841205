//////////
//old.css
.dxbs-fl .row {
    margin-left: 0;
    margin-right: 0;
}

.dxbs-fl .dxbs-fl-g { /*hidden*/
    padding-left: 0;
    padding-right: 0;
}

.dxbs-fl .dxbs-fl-gd,
.dxbs-fl .dxbs-fl-gt {
    margin-top: 1em;
}

.dxbs-fl .dxbs-fl-gd,
.dxbs-fl .dxbs-fl-gt {
    margin-bottom: 1em;
}

.dxbs-fl .dxbs-fl-g > .row, /*hidden*/
.dxbs-fl .dxbs-fl-gd > .card > .row,
.dxbs-fl .dxbs-fl-gr + .row:not(.dxbs-fl-gr) { /*hidden*/
    padding-top: 1em;
}

.dxbs-fl .form-group.row {
    margin-left: 0;
    margin-right: 0;
}

.dxbs-fl .form-group.row > .dxbs-fl-cpt {
    margin-bottom: 5px;
    align-self: flex-start;
}

.dxbs-fl .form-group.row > .dxbs-fl-cpt:not(:first-child):not(:last-child) { /*hidden*/
    padding-right: 0.6em;
    padding-left: 0.6em;
}

.dxbs-fl .form-group.row > .dxbs-fl-cpt:first-child {
    padding-right: 0.6em;
}

.dxbs-fl .form-group.row > .dxbs-fl-cpt:last-child { /*hidden*/
    padding-right: 0;
    padding-left: 0.6em;
    text-align: left;
}

@media (min-width: 992px) {
    .dxbs-fl .form-group.row > .col-form-label {
        white-space: nowrap;
    }

    .dxbs-fl:not(.dxbs-fl-calc) .form-group.row > .col-form-label {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .dxbs-fl .form-group.row > .dxbs-fl-ctrl:not(img) {
        width: 100%;
    }
}

.dxbs-fl-calc .form-group > .dxbs-fl-cpt {
    width: auto !important;
    flex: none !important;
}

.dxbs-fl-calc .form-group > .dxbs-fl-ctrl:not(img):not(.dxbs-fl-ctrl-nc) {
    width: 100% !important;
}

.dxbs-fl .dxbs-fl-cpt {
    font-weight: 600;
}

@media (min-width: 992px) {
    .dxbs-fl .form-group.row > .dxbs-fl-ctrl {
        align-self: center;
    }
}

.dxbs-fl .form-group.row {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;

    .dxbs-fl-empty-caption {
        display: none;
    }
}

@media (min-width: 992px) {
    .dxbs-fl .form-group.row {
        flex-flow: row nowrap;
    }

    .dxbs-fl .form-group.row > .col-form-label {
        flex: 1 0 auto;
    }
}

.dxbs-fl .form-group.row > .col-form-label {
    margin-bottom: 0;
}

.dxbs-fl .dxbs-fl-gd,
.dxbs-fl .dxbs-fl-gt {
    margin-top: 1rem;
}

.dxbs-fl .dxbs-fl-gd {
    margin-bottom: 1rem;
}

.dxbs-fl .dxbs-fl-g > .row, /*hidden*/
.dxbs-fl .dxbs-fl-gd > .card > .row {
    padding-top: 1rem;
}
//old.css
//////////

.dxbs-fl {
    .form-group {
        margin-bottom: .5rem;
    }
}

//editors' buttons borders conflicting with validation border
.invalid .form-control.dxbs-btn-group.dxbs-input-group-append,
.valid .form-control.dxbs-btn-group.dxbs-input-group-append {
    border-color: transparent;
}

.dxbs-fl .dxbs-fl-empty-caption {
    vertical-align: top;
}

.row > label.dxbs-fl-cpt,
.row > div.dxbs-fl-ctrl {
    padding-left: unset;    //rollback Bootstrap 5 breaking change
    padding-right: unset;
}

.dxbs-fl-row-break {
    flex-basis: 100%;
    height: 0;
}

.dxbs-fl .form-group.row {
    & > .dxbs-fl-cpt {
        width: auto;
        flex: 0 0 auto;
    }

    & > .dxbs-fl-ctrl {
        width: auto;
        flex: 1 1 auto;
        min-width: 0;
    }
}

.dxbl-fl-tab-content {
    padding: 0;
    padding-top: 1em;
}
