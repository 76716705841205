@use "./mixins/size-management";

.dxbl-button-root {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;

    &:not(:defined) {
        display: none;
    }

    .dxbl-button {
        @include dx-line-box-size();
        @include dx-button-icon-size($dx-button-icon-size, $dx-button-icon-gap);

        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: $dx-button-border-width solid transparent;
        color: $dx-body-color;
        border-radius: $dx-button-border-radius;

        &:hover:not(disabled) {
            cursor: pointer;
        }

        &:focus {
            outline: none;
        }

        &[disabled] {
            pointer-events: none;
            opacity: 0.65;
        }

        &.dxbl-button-text {
            border-color: transparent;
        }

        &.dxbl-button-link,
        &.dxbl-button-outline-link,
        &.dxbl-button-text-link {
            color: $dx-link-color;

            &:hover {
                color: $dx-link-hover-color;
                text-decoration: underline;
            }
        }

        &.dxbl-button-sm {
            @include dx-line-box-size-sm();
            @include dx-button-icon-size($dx-button-icon-size-sm, $dx-button-icon-gap-sm);

            border-radius: $dx-button-border-radius-sm;
        }

        &.dxbl-button-lg {
            @include dx-line-box-size-lg();
            @include dx-button-icon-size($dx-button-icon-size-lg, $dx-button-icon-gap-lg);

            border-radius: $dx-button-border-radius-lg;
        }

        > * {
            display: inline-flex;
        }
    }
}

.dxbl-input-validation-icon {
    @include dx-line-box-size();
    @include dx-button-icon-size($dx-button-icon-size, $dx-button-icon-gap);
    &.dxbl-input-validation-icon-near {
        padding-right: unset;
    }
    &.dxbl-input-validation-icon-far {
        padding-left: unset;
    }

    background-color: transparent;
    color: $dx-validation-critical;
    display: flex;
    align-items: center;

    &[icon-type='success'] {
        color: $dx-validation-success;
    }
    &[icon-type='warning'] {
        color: $dx-validation-warning;
    }
    &[icon-type='info'] {
        color: $dx-validation-info;
    }
    &[size-mode='small'] {
        @include dx-line-box-size-sm();
        @include dx-button-icon-size($dx-button-icon-size-sm, $dx-button-icon-gap-sm);

        &.dxbl-input-validation-icon-near {
            padding-right: unset;
        }
        &.dxbl-input-validation-icon-far {
            padding-left: unset;
        }
    }

    &[size-mode='large'] {
        @include dx-line-box-size-lg();
        @include dx-button-icon-size($dx-button-icon-size-lg, $dx-button-icon-gap-lg);

        &.dxbl-input-validation-icon-near {
            padding-right: unset;
        }
        &.dxbl-input-validation-icon-far {
            padding-left: unset;
        }
    }
    > .dx-image {
        fill-opacity: 0.6;
        position: relative;
    }
}
.dxbl-input-clear-button-root {
    background-color: transparent;

    .dxbl-input-clear-button {
        color: currentColor;
        position: relative;

        > .dx-image {
            fill-opacity: 0.6;
            position: relative;
        }
    }

    &:hover {
        .dxbl-input-clear-button::before {
            content: "";
            position: absolute;
            background-color: currentColor;
            opacity: 0.15;
            border-radius: 50%;
            top: $dx-line-box-padding-y;
            bottom: $dx-line-box-padding-y;
            right: $dx-line-box-padding-x;
            left: $dx-line-box-padding-x;
        }

        .dxbl-button-sm {
            &.dxbl-input-clear-button::before {
                top: $dx-line-box-padding-y-sm;
                bottom: $dx-line-box-padding-y-sm;
                right: $dx-line-box-padding-x-sm;
                left: $dx-line-box-padding-x-sm;
            }
        }

        .dxbl-button-lg {
            &.dxbl-input-clear-button::before {
                top: $dx-line-box-padding-y-lg;
                bottom: $dx-line-box-padding-y-lg;
                right: $dx-line-box-padding-x-lg;
                left: $dx-line-box-padding-x-lg;
            }
        }
    }
}
