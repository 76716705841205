@use "mixins" as editor-mixins;

.dxbs-listbox {
    display: flex;
    flex-direction: column;

    &.dxbs-listbox-single-column {
        overflow-y: auto;

        > div {
            display: flex;
            flex-shrink: 0;
        }

        > ul {
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 0;
            width: 100%;

            &.form-control {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            .dxbs-listbox-item {
                display: flex;
                align-items: center;
                padding-top: 0.25rem;
                padding-bottom: 0.25rem;

                > dxbl-check {
                    padding-right: 0.5rem;
                }
            }

            .dxbs-listbox-item,
            .dropdown-item {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }

            & li.dxbs-row-loading::before {
                content: "|";
                display: inline-flex;
                min-width: 4rem;
                width: 100%;
                background-color: currentcolor;
                opacity: 0;
                border-radius: 10rem;
                animation: 1s linear 0.35s infinite alternate both running dynamic_opacity;
            }
        }
    }

    &.dxbs-listbox-multi-column {
        .dxbs-grid-vsd {
            position: relative;
        }
    }

    &.invalid {
        @include editor-mixins.editor-validation-error-border-color();
    }

    &.valid.modified {
        @include editor-mixins.editor-validation-valid-border-color();
    }
}
