.dxbs-pager-navbutton-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.dxbs-pager .pagination {
    margin: 0;

    & .page-link[data-args="PBP"],
    & .page-link[data-args="PBN"] {
        font-weight: 500;
    }

    & .page-link[data-args="PBP"]::before,
    & .page-link[data-args="PBN"]::before {
        content: "0";
        visibility: hidden;
    }

    .page-item:not(.disabled) {
        .page-link[data-args="PBP"],
        .page-link[data-args="PBN"] {
            color: currentcolor;
        }
    }

    & > li.active > button {
        cursor: default;
    }
}

.dxbs-date-edit:not(.dxbs-edit-clbv) input.form-control,
div.dxbs-spin-edit:not(.dxbs-edit-clbv) input.form-control,
.dxbs-combobox:not(.dxbs-edit-clbv) input.form-control {
    padding-right: 3.4em;
}

.dxbs-page-selector {
    display: flex;
    white-space: nowrap;
    margin-left: 5px;
    margin-right: 4px;
}

.dxbs-textbox-autofit {
    display: inline-block;
    position: relative;
}

.dxbs-page-selector .dxbs-textbox-autofit .input-absolute-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    box-sizing: border-box;
}

.dxbs-page-selector .dxbs-textbox-autofit .input-absolute-container:hover {
    background: none;
}

.dxbs-page-selector .dxbs-textbox-autofit input {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    line-height: inherit;
    font-size: inherit;
    font-weight: inherit;
    padding: inherit;
}

.dxbs-page-selector .page-link.dxbs-page-count {
    position: static;
    color: inherit;
    background: none;
    border-color: transparent;
    padding-left: 2px;
    padding-right: 2px;
}

.dxbs-textbox-autofit .dxbs-textbox-autofit-stencil {
    visibility: hidden;
    overflow: hidden;
    border-color: transparent;
    margin-left: 1px;
    display: block;
    width: 100%;
}

@media (min-width: 375px) {
    .dxbs-pager.dxbs-revert-pager.dxbs-pager-sm {
        display: none !important; // stylelint-disable-line declaration-no-important
    }

    .dxbs-pager.dxbs-pager-sm {
        display: flex !important; // stylelint-disable-line declaration-no-important
    }
}

@media (min-width: 625px) {
    .dxbs-pager.dxbs-revert-pager.dxbs-pager-lg {
        display: none !important; // stylelint-disable-line declaration-no-important
    }

    .dxbs-pager.dxbs-pager-lg {
        display: flex !important; // stylelint-disable-line declaration-no-important
    }
}
