.dxbs-memo .form-control.dxbs-vertical-resize {
    resize: vertical;
}

.dxbs-memo .form-control.dxbs-horizontal-resize {
    resize: horizontal;
}

.dxbs-memo .form-control.dxbs-disable-resize {
    resize: none;
}

.dxbs-memo .form-control.dxbs-enable-resize {
    resize: both;
}

.dxbs-memo .form-control:not(.dxbs-disable-mh) {
    min-height: 4em;
}

.dxbs-memo .form-control {
    max-width: 100%;
}

.dxbs-memo > .input-group > .input-group-append.form-control {
    height: 100%;
}

.dxbs-textbox.dxbs-edit-clbv input.form-control {
    padding-right: 2.571em;
}

.dxbs-edit-clbv textarea.form-control {
    padding-right: calc(15px + 2.2em);
}

.dxbs-memo .dxbs-reset-width {
    width: min-content;
}
