
@mixin bottomsheet-border-radius {
    border-radius: 12px 12px 0 0;
}
@mixin modaldialog-min-width-576 {
    @media (min-width: 576px) {
        max-width: 580px;
    }
}

.dxbs-toolbar-dropdown {

    & dxbl-toolbar-menu-item {
        width: 100%;
    }

    & .dxbs-toolbar-dropdown-item.dropdown-item-text > dxbl-toolbar-menu-item {
        display: flex;
    }

    &.dropdown-menu {
        min-width: 10rem;
    }

    & > .modal-content > .modal-body,
    & > .modal-body {
        padding: 0;

        & > .dxbs-tb-dropdown-item-list {
            list-style: none;
            padding: 0;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            width: 100%;

            & > .dropdown-divider {
                margin: 0;

                &:first-child {
                    display: none;
                }
            }

            & dxbl-toolbar-menu-item > .dropdown-item {
                &:first-child > span.popout {
                    right: 0;
                    position: absolute;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }

                &:not(:first-child) {
                    width: auto;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }
    }

    &.dxbs-popup.dxbs-toolbar-modal {

        &.dxbs-toolbar-dialog {
            max-height: 75%;
        }

        &.dxbs-toolbar-bottomsheet {
            height: 100%;
            position: fixed;
            transition: top .5s ease;
            -webkit-transition: top .5s ease;
            -moz-transition: top .5s ease;
            -khtml-transition: top .5s ease;
            -o-transition: top .5s ease;

            &:not(show) {
                top: 100%;
            }

            &.topVertical.show {
                top: 58%;
                max-height: 42%;
            }

            &.topHorizontal.show {
                top: 50%;
                max-height: 50%;
            }

            & > .modal-content {
                height: 100%;
                @include bottomsheet-border-radius;

                & > .modal-header {
                    border-radius: inherit;
                }
            }
        }

        & dxbl-toolbar-menu-item > .dropdown-item {
            height: calc(1.5em + 13px + 2px);
            padding-top: 6.5px;
            padding-bottom: 6.5px;
        }
    }
}

dxbl-modal > .dxbs-modal > .dxbs-popup.dxbs-toolbar-dropdown.dxbs-toolbar-bottomsheet {
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.dxbs-toolbar-dropdown.modal-dialog {
    @include modaldialog-min-width-576;
}


//bottomsheet + dateedit scrollpicker integration
.dxbs-toolbar-dropdown.dxbs-toolbar-bottomsheet.modal-dialog {
    & .modal {
        &:before {
            display: none;
        }
        & > .modal-dialog {
            top: 0;
            height: 100%;
            width: 100%;
            display: flex;

            & > .modal-content {
                -webkit-box-shadow: none;
                box-shadow: none;
                border-width: 0;
                @include bottomsheet-border-radius;
                & > .modal-header {
                    border-radius: inherit;
                }
            }
            @include modaldialog-min-width-576;
        }
    }
}
