@use "mixins" as editor-mixins;

@import "../dx-blazor.params";

$dx-calendar-header-hover-color: var(--dx-calendar-header-hover-color, rgb(0 0 0 / 10%));

$dx-calendar-table-hover-color: var(--dx-calendar-table-hover-color, rgb(0 0 0 / 5%));
$dx-calendar-table-selection-color: var(--dx-calendar-table-selection-color, rgb(0 0 0 / 10%));
$dx-calendar-table-active-color: var(--dx-calendar-table-active-color, rgb(0 0 0 / 20%));

@mixin calendar-header-hover-color {
    &:hover {
        background-color: $dx-calendar-header-hover-color;
    }
}

.dxbs-calendar {
    &.card {
        display: inline-flex;
    }

    &[disabled],
    &[readonly] {
        .dxbs-calendar-table {
            td {
                cursor: default;
            }
        }

        .dxbs-calendar-footer {
            .btn {
                opacity: 0.25;
            }
        }
    }

    &[disabled] {
        .dxbs-calendar-header {
            .dxbs-calendar-header-title-btn {
                opacity: 0.25;
            }
        }

        .dxbs-calendar-table {
            opacity: 0.5;
        }
    }

    &[readonly] {
        .dxbs-calendar-header {
            .dxbs-calendar-header-title-btn {
                opacity: 1;
            }
        }
    }

    &:not([disabled]):not([readonly]) {
        &.invalid {
            @include editor-mixins.editor-validation-error-border-color();
        }

        &.valid.modified {
            @include editor-mixins.editor-validation-valid-border-color();
        }

        .dxbs-calendar-header {
            .dxbs-calendar-header-btn:not([disbled]) {
                @include calendar-header-hover-color();
            }

            .dxbs-calendar-header-title-btn {
                @include calendar-header-hover-color();
            }
        }

        .dxbs-calendar-table {
            td:not(.dxbs-calendar-disabled-item):not(.dxbs-calendar-table-week-number) {
                cursor: pointer;
            }

            td:not(.dxbs-calendar-disabled-item):not(.dxbs-calendar-table-today):not(.dxbs-calendar-table-week-number) {
                &:active {
                    background-color: $dx-calendar-table-active-color;
                }

                &:not(.dxbs-calendar-selected-item):not(.dxbs-calendar-table-week-number):hover {
                    background-color: $dx-calendar-table-hover-color;

                    &.dxbs-calendar-selected-range {
                        background-color: $dx-calendar-table-active-color;
                    }
                }
            }

            .dxbs-calendar-selected-item:hover {
                background-color: $dx-calendar-table-active-color;
            }

            .dxbs-calendar-selected-range {
                background-color: $dx-calendar-table-active-color;
            }
        }
    }

    .dxbs-calendar-header.btn-group {
        padding: 0;
        /* stylelint-disable declaration-block-no-redundant-longhand-properties */
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        /* stylelint-enable declaration-block-no-redundant-longhand-properties */
        &.card-header {
            box-shadow: none;
        }

        .dxbs-calendar-header-btn {
            border: 0;
            flex-grow: 0;
            color: currentcolor;
            box-shadow: none;
        }

        .dxbs-calendar-header-btn[disabled] {
            opacity: 0.25;
        }

        .dxbs-calendar-header-title-btn {
            flex-grow: 1;
            font-weight: 600;
            box-shadow: none;
        }
    }

    .dxbs-calendar-table-container {
        padding: 0.5rem 1rem;
        display: flex;
        justify-content: center;

        .dxbs-calendar-table {
            border: 0;
            margin: 0;
            width: unset;
            border-collapse: separate;
            border-spacing: 0;

            td {
                vertical-align: middle;
            }

            .dxbs-calendar-selected-item {
                background-color: $dx-calendar-table-selection-color;
            }

            .dxbs-calendar-disabled-item {
                opacity: 0.5;
                cursor: default;
            }

            &.table-sm {
                td,
                th {
                    padding: 0.25rem;
                }
            }

            th,
            td {
                border-color: transparent;
            }

            .dxbs-calendar-table-today {
                outline: solid 1px $ColorPrimary;
                outline-offset: -1px;
            }

            .dxbs-calendar-table-week-number {
                opacity: 0.6;
                text-align: right;
                cursor: default;
            }

            .dxbs-calendar-table-days-of-week {
                th {
                    opacity: 0.5;
                    font-family: monospace;
                }
            }
        }
    }

    .dxbs-calendar-footer {
        display: flex;
        justify-content: center;
        padding: 0.5rem 1rem;

        .btn + .btn {
            margin-left: 0.5rem;
        }
    }
}
