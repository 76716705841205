@import "variables";
@import "shared";

dxbl-window {
    position: absolute;
    top: 0;
    left: 0;
    z-index: unset;

    &:not(:defined) {
        display: none;
    }

    > .dxbs-window-dialog {
        display: flex;
        position: relative;
        min-width: unset;
        max-width: unset;
        top: 0;
        left: 0;
        flex-direction: column;
        width: 100%;
        outline: 0;
        margin: unset;

        &.dxbs-window-scrollable {
            > .dxbs-window-body {
                overflow-y: auto;
            }
        }

        > .dxbs-window-content {
            > .dxbs-window-header {
                padding: 0.5rem 1rem;

                &.drag {
                    cursor: default;
                }
            }

            > .dxbs-window-footer {
                margin: unset;
                padding: 0.5rem 1rem;
                gap: 0.5rem;

                > * {
                    margin: unset;
                }
            }
        }
    }

    > .dxbs-window-sizegrip {
        @include disable-accessibility-border();

        display: flex;
        justify-content: center;
        align-items: center;
        width: 1rem;
        height: 1rem;
        margin: unset;
        padding: unset;
        opacity: unset;
        color: inherit;
        background-color: transparent;

        > .dxbs-window-sizegrip-image {
            width: 12px;
            height: 12px;
            fill-opacity: 0.6;
        }
    }
}

