@use "mixins" as editor-mixins;

.dxbs-spin-edit {
    display: flex;

    .dxbs-spin-edit-button {
        @include editor-mixins.editor-button-sizes();

        &.inc { // stylelint-disable-line selector-class-pattern
            > .dx-image {
                transform: rotate(180deg);
            }
        }
    }
}
