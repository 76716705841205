.dxbs-date-time-edit {
    display: flex;
}

.dxbs-date-time-edit-dropdown {
    &.dropdown-menu {
        text-align: center;
    }

    .dxbs-date-time-edit-dropdown-title {
        justify-content: center;
        display: flex;
        align-self: center;
        font-size: 0.9rem;
        font-weight: 600;
        width: 100%;
        margin: 0;
    }

    .dxbs-date-time-edit-dropdown-cancel,
    .dxbs-date-time-edit-dropdown-apply {
        width: 16px;
        height: 16px;
        border: 0;
        position: relative;
    }

    .dxbs-date-time-edit-dropdown-cancel:hover::before {
        /* stylelint-disable declaration-block-no-redundant-longhand-properties */
        border-top-left-radius: inherit;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        /* stylelint-enable declaration-block-no-redundant-longhand-properties */
    }

    .dxbs-date-time-edit-dropdown-apply:hover::before {
        /* stylelint-disable declaration-block-no-redundant-longhand-properties */
        border-top-right-radius: inherit;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        /* stylelint-enable declaration-block-no-redundant-longhand-properties */
    }

    .dxbs-date-time-edit-dropdown-cancel {
        margin-right: 0.5rem;
    }

    .dxbs-date-time-edit-dropdown-apply {
        margin-left: 0.5rem;
    }

    .dxbs-date-time-edit-dropdown-tabs {
        .dxbs-date-time-edit-dropdown-tabs-date,
        .dxbs-date-time-edit-dropdown-tabs-time {
            flex-grow: 1;
            text-align: center;

            .nav-link {
                border-radius: 0;
                border-top: 0;
                justify-content: center;
            }
        }

        .dxbs-date-time-edit-dropdown-tabs-date {
            .nav-link {
                border-left: 0;
            }
        }

        .dxbs-date-time-edit-dropdown-tabs-time {
            .nav-link {
                border-right: 0;
            }
        }
    }

    .dx-tabs-content { // stylelint-disable-line selector-class-pattern
        display: flex;
        flex-direction: column;

        .dxbs-date-time-edit-dropdown-calendar {
            border: 0;

            .dxbs-date-time-edit-dropdown-calendar-header {
                background: none;
                padding-top: 0.4rem;
                border: 0;
            }
        }
    }
}
