@use "../dx-blazor.params" as bs4;
@use "../dx-blazor.bs5.params" as bs5;

$editor-button-padding: 0.45rem;
$editor-button-padding-sm: 0.25rem;
$editor-button-padding-lg: 0.5rem;

$editor-valid-color: var(bs4.$ColorGreenName, var(bs5.$ColorGreenName));

@mixin editor-button-sizes {
    padding: $editor-button-padding;

    &.btn {
        border-top: none;
        border-bottom: none;
    }

    &.btn-sm {
        padding: $editor-button-padding-sm;
    }

    &.btn-lg {
        padding: $editor-button-padding-lg;

        > .dx-image {
            width: 2rem;
            height: 2rem;
        }
    }

    > .dx-image {
        width: 1rem;
        height: 1rem;

        > use {
            fill: currentcolor;
            opacity: 0.85;
        }
    }
}

@mixin editor-validation-error-border-color {
    border-color: var(bs4.$ColorRedName, var(bs5.$ColorRedName));
}

@mixin editor-validation-valid-border-color {
    border-color: $editor-valid-color;
}
